import { Observable, Subject } from 'rxjs';
import { DefaultTableDataCollection } from './default.table-data-collection';

interface IFilter {
  filters: Array<{ key: string; value: string }>;
}

export interface IChangedModel {
  action: string;
  elements: DefaultTableDataCollection<any>;
  paging: any;
  filter: IFilter;
  sort: any;
}

export abstract class BaseDataSource {
  protected onChangedSource = new Subject<any>();
  protected onLoadingData = new Subject<any>();

  public abstract getAll(): Promise<any>;

  public abstract getElements(): Promise<any>;

  public abstract getSort(): any;

  public abstract getFilter(): any;

  public abstract getPaging(): any;

  public abstract getCurrentPage(): number;

  public abstract count(): number;

  public refreshElements() {}

  public refresh(): void {
    this.emitOnChanged('refresh');
  }

  public load(data?: any[]): Promise<any> {
    this.emitOnChanged('load');
    return Promise.resolve();
  }

  public onChanged(): Observable<IChangedModel> {
    return this.onChangedSource.asObservable();
  }

  public onLoading(): Observable<any> {
    return this.onLoadingData.asObservable();
  }

  public empty(doEmit?: boolean): Promise<any> {
    if (doEmit) {
      this.emitOnChanged('empty');
    }
    return Promise.resolve();
  }

  public setSort(conf: any, doEmit?: boolean): void {
    if (doEmit) {
      this.emitOnChanged('sort');
    }
  }

  public setFilter(filters: any[], doEmit?: boolean): void {
    // eslint-disable-line , , , ,
    if (doEmit) {
      this.emitOnChanged('filter');
    }
  }

  public addFilter(key: string, value: any, doEmit?: boolean): void {
    if (doEmit) {
      this.emitOnChanged('filter');
    }
  }

  public setPaging(page: number, perPage: number, doEmit?: boolean): void {
    if (doEmit) {
      this.emitOnChanged('paging');
    }
  }

  public setPage(page: number, doEmit?: boolean): void {
    if (doEmit) {
      this.emitOnChanged('page');
    }
  }

  protected emitOnChanged(action: string): void {
    this.getElements().then((elements) =>
      this.onChangedSource.next({
        action,
        elements,
        paging: this.getPaging(),
        filter: this.getFilter(),
        sort: this.getSort(),
      })
    );
  }
}
