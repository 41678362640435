import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlConcat'
})
export class UrlConcatPipe implements PipeTransform {
  transform(url: any, args?: any): any {
    if (url && url.length > 20) {
      return `${url.slice(0, 25)}...`;
    }
    return url;
  }
}
