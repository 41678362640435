import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
  HostListener,
  ElementRef,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { FormContainerComponent } from '../form-container/form-container.component';

@Component({
  selector: 'ot-inline-edit',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InlineEditComponent implements OnInit {
  private _editMode = false;

  public get editMode(): boolean {
    return this._editMode;
  }

  public set editMode(value: boolean) {
    this._editMode = value;
    if (value) {
      this.savePrevValue();
    }
  }

  @Input() public getFormControlName = false;
  @Input() public disabled = false;
  @Input() public inline = false;
  @ContentChild(FormContainerComponent) public editor;
  @Output() protected edit = new EventEmitter();
  @Output() protected save = new EventEmitter();
  @Output() protected cancel = new EventEmitter();
  private value: any;

  constructor(private _eref: ElementRef) {}

  public ngOnInit(): void {}

  public toggleEdit($event) {
    $event.preventDefault();
    if (this.disabled) {
      return;
    }
    this.edit.emit();
    if (this.editor) {
      this.editMode = true;
    }
  }

  public cancelEdit($event) {
    $event.preventDefault();
    this.editMode = false;
    this.restoreValue();
  }

  public saveEdit($event) {
    $event.preventDefault();
    if (!this.editor) {
      return;
    }
    const control = this.editor._control.ngControl.control as FormControl;
    if (control.invalid) {
      return;
    }
    if (this.editor._control._select) {
      this.getFormControlName
        ? this.save.emit({
            controlName: this.editor._control.ngControl.name,
            value: this.editor._control._select._value,
          })
        : this.save.emit(this.editor._control._select._value);
    } else {
      this.getFormControlName
        ? this.save.emit({
            controlName: this.getControlName(control),
            value: control.value,
          })
        : this.save.emit(control.value);
    }
  }

  private savePrevValue() {
    if (!this.editor) {
      return;
    }
    const control = this.editor._control.ngControl.control as FormControl;
    this.value = control.value;
  }

  private restoreValue() {
    if (!this.editor || !this.value) {
      return;
    }
    const control = this.editor._control.ngControl.control as FormControl;
    control.setValue(this.value);
  }

  private getControlName(control: AbstractControl) {
    let controlName = null;
    const parent = control['_parent'];

    if (parent instanceof FormGroup) {
      Object.keys(parent.controls).forEach((name) => {
        if (control === parent.controls[name]) {
          controlName = name;
        }
      });
    }
    return controlName;
  }

  @HostListener('document:click', ['$event'])
  private outsideClick(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.editMode = false;
      this.restoreValue();
    }
  }
}
