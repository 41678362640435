import {
  Component,
  HostBinding,
  OnInit,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'ot-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageTitleComponent implements OnInit {
  @HostBinding('class.mt-3') private header = true;

  constructor() {}

  ngOnInit() {}
}
