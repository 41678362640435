import { InjectionToken, Injectable } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';

Injectable();
export const ERROR_GLOBAL_OPTIONS = new InjectionToken<ErrorOptions>(
  'error-global-options'
);

export type ErrorStateMatcher = (
  control: FormControl,
  form: FormGroupDirective | NgForm
) => boolean;

export interface ErrorOptions {
  errorStateMatcher?: ErrorStateMatcher;
}

/** Returns whether control is invalid and is either touched or is a part of a submitted form. */
export function defaultErrorStateMatcher(
  control: FormControl,
  form: FormGroupDirective | NgForm
) {
  const isSubmitted = form && form.submitted;
  return !!(control.invalid && (control.touched || isSubmitted));
}

/** Returns whether control is invalid and is either dirty or is a part of a submitted form. */
export function showOnDirtyErrorStateMatcher(
  control: FormControl,
  form: FormGroupDirective | NgForm
) {
  const isSubmitted = form && form.submitted;
  return !!(control.invalid && (control.dirty || isSubmitted));
}
