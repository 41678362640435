<div
  ngbDropdown class="d-inline-block w-100 date-range"
  [autoClose]="false"
  otFitDropdown
  (clickOutside)="close()"
  [delayClickOutsideInit]="true"
>
  <div class="form-control" ngbDropdownToggle>
    <span class="toggle-title">
      {{current}}
    </span>
    <span class="dropdown-arrow" inlineSVG="/assets/svg/arrow-down.svg"></span>
  </div>
  <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownBasic1">
    <button class="dropdown-item" *ngFor="let item of variants" (click)="select(item)">{{ item.label }}</button>
    <ngb-datepicker
      *ngIf="showDatePicker"
      #dp
      ngModel
      (ngModelChange)="onDateChange($event)"
      [displayMonths]="datePickerDisplayMonths$ | async"
      [dayTemplate]="t"
      otFitDatePicker
      (clickOutside)="closeDatePicker($event)"
      [delayClickOutsideInit]="true"
    >
    </ngb-datepicker>
  </div>
</div>


<ng-template #t let-date="date" let-focused="focused" let-currentMonth="currentMonth">
  <span class="custom-day"
        [class.focused]="focused"
        [class.today]="isToday(date)"
        [class.text-muted]="date.month < currentMonth"
        [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
        [class.faded]="isHovered(date) || isInside(date)">
    {{ date.day }}
  </span>
</ng-template>
