import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HeaderConfig } from './factories/header-config';
import { HeaderLink } from './factories/header-link';

@Injectable()
export class HeaderService {
  private _config = new HeaderConfig();

  get config() {
    return this.state.asObservable();
  }

  public test = +new Date();
  private state = new BehaviorSubject<HeaderConfig>(null);

  constructor() {
    this.resetDefault();
  }

  public setSearch(template, func) {
    this._config.searchTemplate = template;
    this._config.searchFn = func;
    this.state.next(this._config);
  }

  public setLogo(logo) {
    this._config.logo = logo;
    this.state.next(this._config);
  }

  public setBadge(url, badge = 0) {
    this._config.setBadge(url, badge);
    this.state.next(this._config);
  }

  public resetHeaderLinks() {
    this._config.resetLinks();
    this.state.next(this._config);
  }

  public addLink(link: HeaderLink) {
    this._config.addLink(link);
    this.state.next(this._config);
  }

  public hideSearch() {
    this._config.hideSearch = true;
  }

  public resetDefault() {
    this.resetHeaderLinks();
  }

  public agencyLinks() {
    this.resetHeaderLinks();
    this.showFlyout();
    this.addLink(
      new HeaderLink({ title: 'Dashboard', link: ['/dashboard'], badge: 0 })
    );
    this.addLink(new HeaderLink({ title: 'Deals', link: ['/deal'], badge: 0 }));
    // this.addLink(
    //   new HeaderLink({ title: 'Contacts', link: ['/contacts'], badge: 0 })
    // );
    this.addLink(
      new HeaderLink({
        title: 'Reports',
        link: ['/reports'],
      })
    );
  }

  public adminLinks() {
    this.resetHeaderLinks();
  }

  private showFlyout() {
    this._config.flyout = true;
  }
}
