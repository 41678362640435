import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@tenant/helpers';
import {
  TableDataBase,
  TableResponse,
} from '../../../../table/src/lib/data-table/ds-meta';
import { Observable, timer } from 'rxjs';
import { map, mergeMap, takeWhile } from 'rxjs/operators';
import { ReportModel } from '../report.model';
import * as download from 'downloadjs';

@Injectable({
  providedIn: 'root',
})
export class ReportService implements TableDataBase<ReportModel> {
  constructor(private apiHttp: HttpClient) {}

  public create(data) {
    return this.apiHttp.post<any>(`${ApiConfig.transunion}/reports`, data);
  }

  public checkStatus(ids) {
    return this.apiHttp
      .get<any[]>(`${ApiConfig.transunion}/reports/status?id__in=${ids}`)
      .pipe(map((res: any) => res.results));
  }

  public requesters(q) {
    return this.apiHttp
      .get<any>(`${ApiConfig.applicantReports}/requesters`, {
        params: { name: q },
      })
      .pipe(
        map((res: any) => {
          return res.data;
        })
      );
  }

  public details(reportId: number) {
    return this.apiHttp.get<any>(`${ApiConfig.transunion}/reports/${reportId}`);
  }

  public downloadPdfLink(reportId, name): Observable<boolean> {
    return this.apiHttp
      .post<any>(
        `${ApiConfig.transunion}/reports/${reportId}/pdf`,
        {},
        { responseType: 'blob' as 'json', observe: 'response' }
      )
      .pipe(
        map((res: any) => {
          download(res.body, name);
          return true;
        })
      );
  }

  public load(additionalParams): Observable<TableResponse<ReportModel>> {
    const tmp = Object.assign({}, additionalParams);
    let params = new HttpParams();
    for (const key in tmp) {
      if (tmp.hasOwnProperty(key) && tmp[key] != null) {
        params = params.append(key, tmp[key]);
      }
    }
    return this.apiHttp.get<any>(`${ApiConfig.transunion}/reports`, { params });
  }
}
