import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { filter, map, share } from 'rxjs/operators';
import { ProfileService } from '../services/profile.service';

interface ICondition {
  except?: boolean;
  role: string;
}

@Directive({
  selector: '[otIfRole]'
})
export class IfRoleDirective {
  @Input()
  set otIfRole(condition: ICondition) {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
      this.subscription$ = null;
    }
    this.subscription$ = this.role$.subscribe((role) => {
      let needShow = true;
      if (condition && condition.role) {
        if (condition.except) {
          needShow = role[condition.role] === false;
        } else {
          needShow = role[condition.role] === true;
        }
      }

      if (needShow && !this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      } else if (!needShow && this.hasView) {
        this.viewContainer.clear();
        this.hasView = false;
      }
    });
  }

  private subscription$: Subscription;
  private role$: Observable<any>;
  private hasView: boolean;

  constructor(
    private profile: ProfileService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    this.role$ = profile.state.pipe(
      filter((v) => !!v),
      map((user: any) => (user?.roles)),
      share()
    );
  }
}
