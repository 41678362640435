import { Injectable, Injector } from '@angular/core';

import { SessionService } from './session.service';

@Injectable()
export class ApiHttpService {
  constructor(private injector: Injector) {}

  public request(url: string | Request, options?: any) {
    const _session = this.injector.get(SessionService);
    const token = _session.token;
    let target: Request | any = null;
    if (options) {
      target = options;
    } else {
      target = url as Request;
    }
    target.headers.delete('Authorization');
    target.headers.delete('Accept');
    target.headers.delete('UserId');
    target.headers.delete('AdminId');
    target.headers.delete('AdminName');
    if (_session.isLoggedIn) {
      target.headers.set('Authorization', 'Bearer ' + token);
      target.headers.set('User-Id', _session.userId);
      target.headers.set('Admin-Id', _session.adminId);
      target.headers.set('Admin-Name', _session.adminName);
    }
    target.headers.set('Accept', 'application/json; version=1');
  }
}
