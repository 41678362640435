import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/browser';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if(environment.ENV_NAME !== 'local') {
  Sentry.init({
    dsn: 'https://c7ba74c03b874a74a1081b022618f485@sentry.bitstudios.com/36',
    environment: environment.ENV_NAME,
    release: environment.CI_COMMIT_SHA
  });  
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
