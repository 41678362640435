<div class="sort-header-container" [class.mat-sort-header-position-before]="arrowPosition == 'before'">
  <div class="content">
    <ng-content></ng-content>
  </div>

  <div class="sort">
    <span inlineSVG="/assets/svg/sort-asc.svg" class="asc"
      [class.active]="_sort.active === id && _sort.direction === 'asc'" alt="asc"></span>
    <span inlineSVG="/assets/svg/sort-desc.svg" class="desc"
      [class.active]="_sort.active === id && _sort.direction === 'desc'" alt="desc"></span>
  </div>
</div>