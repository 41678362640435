import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressComponent } from '../address/address.component';

@Component({
  selector: 'ot-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressModalComponent implements AfterViewInit {
  @Input() public groupValue: any;
  @Input() public patchFunction = null;
  @Input()
  public addressConfig = {
    hideCountry: true,
    title: 'Edit Contact Address',
  };
  public errors: any;
  @ViewChild(AddressComponent) public address;

  constructor(public active: NgbActiveModal, private chd: ChangeDetectorRef) {}

  public ngAfterViewInit() {
    if (this.groupValue) {
      this.address.group.patchValue(this.groupValue);
      this.chd.detectChanges();
    }
  }

  public save() {
    if (this.address.group.valid && this.patchFunction !== null) {
      this.patchFunction(this.address.group.value).subscribe(
        (res) => {
          this.active.close(res);
        },
        (errors) => {
          // eslint-disable-line , ,

          const flattenObject = function (ob) {
            return Object.keys(ob).reduce(function (toReturn, k) {
              if (Object.prototype.toString.call(ob[k]) === '[object Date]') {
                toReturn[k] = ob[k].toString();
              } else if (
                typeof ob[k] === 'object' &&
                ob[k] &&
                !Array.isArray(ob[k])
              ) {
                const flatObject = flattenObject(ob[k]);
                Object.keys(flatObject).forEach(function (k2) {
                  toReturn[k2] = flatObject[k2];
                });
              } else {
                toReturn[k] = ob[k];
              }

              return toReturn;
            }, {});
          };
          this.errors = flattenObject(errors);
          this.chd.detectChanges();

          // this.errors = errors.errors;
        }
      );
    }
  }
}
