import {
  Component,
  ElementRef,
  forwardRef,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFieldDirective } from '../dynamic-field.directive';
import { Field } from '../models/field.interface';
import { GroupField } from '../models/group-field';

@Component({
  selector: 'ot-dynamic-group',
  templateUrl: './dynamic-group.component.html',
  styleUrls: ['./dynamic-group.component.scss']
})
export class DynamicGroupComponent implements Field, OnInit {
  @ViewChildren(forwardRef(() => DynamicFieldDirective))
  public inputs: QueryList<DynamicFieldDirective>;
  public config: GroupField;
  public group: FormGroup;
  public rootGroup: FormGroup;
  public errors: any;
  public readonly: any;

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  public ngOnInit(): void {
    this.renderer.addClass(
      this.element.nativeElement,
      'field-' + this.config.field
    );
  }
}
