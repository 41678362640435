import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Patterns } from '@tenant/helpers';
import { UniversalValidators } from 'ngx-validators';
import { Subscription } from 'rxjs';
import { AbstractField } from './abstract-field';
import { DealTypes } from '../../../../../../apps/admin/src/app/deal/models/deal';

export class AddressField extends AbstractField {
  public full = true;
  public hideCountry = true;
  public dealType: DealTypes;

  private fieldSet: any[] = [
    {
      key: 'address_line_1',
      controlProps: [
        '',
        [
          Validators.required,
          UniversalValidators.noEmptyString,
          Validators.maxLength(50),
        ],
      ],
      controlPropsNotRequired: ['', [Validators.maxLength(50)]],
    },
    {
      key: 'address_line_2',
      controlProps: ['', [Validators.maxLength(50)]],
      controlPropsNotRequired: ['', [Validators.maxLength(50)]],
    },
    {
      key: 'city',
      controlProps: [
        '',
        [
          Validators.required,
          UniversalValidators.noEmptyString,
          Validators.minLength(3),
          Validators.maxLength(50),
        ],
      ],
      controlPropsNotRequired: [
        '',
        [Validators.minLength(3), Validators.maxLength(50)],
      ],
    },
    {
      key: 'state',
      controlProps: [
        '',
        [Validators.required, UniversalValidators.noEmptyString],
      ],
      controlPropsNotRequired: ['', []],
    },
    {
      key: 'zip_code',
      controlProps: [
        '',
        [
          Validators.required,
          UniversalValidators.noEmptyString,
          Validators.pattern(Patterns.POST_CODE),
        ],
      ],
      controlPropsNotRequired: ['', [Validators.pattern(Patterns.POST_CODE)]],
      short: true,
    },
    {
      key: 'country',
      controlProps: [
        'United States',
        [Validators.required, UniversalValidators.noEmptyString],
      ],
      controlPropsNotRequired: ['United States', []],
      short: true,
    },
  ];
  private countryChange$: Subscription;

  constructor(field: any) {
    super(field);
    this.full = field.type !== 'custom_address';
  }

  public control(): AbstractControl {
    const validators = this.getValidators();
    this.validation = validators;
    const group = new FormGroup({});
    this.initFieldSet(group);
    return group;
  }

  private initFieldSet(group) {
    this.fieldSet.forEach((field) => {
      if (!group.contains(field.key)) {
        if (this.full === false && field.short === true) {
          group.removeControl(field.key);
          return;
        }
        if (this.required) {
          group.addControl(
            field.key,
            new FormControl(field.controlProps[0], field.controlProps[1])
          );
        } else {
          group.addControl(
            field.key,
            new FormControl(
              field.controlPropsNotRequired[0],
              field.controlPropsNotRequired[1]
            )
          );
        }
      }
    });
    if (this.countryChange$) {
      this.countryChange$.unsubscribe();
    }
    if (this.full !== true) {
      return;
    }
  }
}
