import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { Mask, OtValidators, parseSentenceForNumber } from '@tenant/helpers';
import { takeWhile } from 'rxjs/operators';

export const COMMISSIONCURRENCY_GROUP_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CommissionCurrencyComponent), // eslint-disable-line
  multi: true
};

@Component({
  selector: 'ot-commission-currency',
  templateUrl: './commission-currency.component.html',
  styleUrls: ['./commission-currency.component.scss'],
  providers: [COMMISSIONCURRENCY_GROUP_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommissionCurrencyComponent
  implements ControlValueAccessor, OnDestroy, OnInit {
  public get maxValue() {
    if (this.commissionGroup.get('type').value === '%') {
      return 100;
    }
    if (this.max) {
      return parseSentenceForNumber(this.max);
    }
    return 0;
  }

  public disabled: boolean;
  @Input() public max;
  @Input() public required;
  public types = [{ value: '$', label: '$' }, { value: '%', label: '%' }];
  public commissionGroup = new FormGroup({
    value: new FormControl(null, []),
    type: new FormControl('$')
  });
  public currencyMask: any = Mask.NUMBER;
  private onTouch: any;
  private onChange: any;
  private destroy: any;

  constructor() {}

  public focus() {
    // asd
  }

  public ngOnDestroy(): void {
    this.destroy = true;
  }

  public writeValue(obj: any): void {
    if (obj) {
      this.commissionGroup.patchValue(obj);
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public ngOnInit() {
    this.commissionGroup.valueChanges
      .pipe(takeWhile(() => !this.destroy))
      .subscribe((val) => {
        if (this.onChange) {
          this.onChange(val);
        }
        if (this.onTouch) {
          this.onTouch();
        }
      });
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.commissionGroup.get('value').disable();
      this.commissionGroup.get('type').disable();
    } else {
      this.commissionGroup.get('value').enable();
      this.commissionGroup.get('type').enable();
    }
  }

  private changeValidation() {
    const limit = this.maxValue;

    const comm = this.commissionGroup.get('value');
    const validators = [];
    if (limit > 0) {
      validators.push(OtValidators.lessThan(limit));
    }
    if (this.required) {
      validators.push(Validators.required);
    }
    comm.setValidators(validators);
    comm.updateValueAndValidity();
  }
}
