import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@tenant/helpers';
import * as moment from 'moment-mini-ts';
import { map } from 'rxjs/operators';

@Injectable()
export class DashboardService {
  constructor(public api: HttpClient) {}

  public tasks() {
    return this.api.get<any>(ApiConfig.dashTasks);
  }

  public summary(type = null) {
    const params: any = {
      from_date: moment().startOf('month').format('YYYY-MM-DD'),
      to_date: moment().endOf('month').format('YYYY-MM-DD'),
      volume: 'gross'
    };
    if (type && type !== 'all') {
      params.deal_type = type;
    }
    return this.api.get(ApiConfig.summary, { params }).pipe(map((res) => res));
  }
}
