export * from './lib/ot-global.module';

export { OtGlobalModule } from './lib/ot-global.module';
export { HeaderService } from './lib/header/header.service';
export {
  OverlayNotificationsService
} from './lib/overlay-notifications/overlay-notifications.service';
export {
  OverlayNotificationModel
} from './lib/overlay-notifications/overlay-notification.model';
export { HeaderLink } from './lib/header/factories/header-link';
