import { Component, Inject, OnInit } from '@angular/core';
import { InstanceConfigHolderService } from 'ng-busy';

@Component({
  selector: 'ot-busy-config-template',
  template: `
    <div class="ot-preloader">
      <div class="loader-blow-up"></div>
      <!-- <span>{{ message }}</span> -->
    </div>
  `,
  styles: [],
})
export class BusyConfigTemplateComponent implements OnInit {
  constructor(
    @Inject('instanceConfigHolder')
    private instanceConfigHolder: InstanceConfigHolderService
  ) {}

  get message() {
    return this.instanceConfigHolder.config.message;
  }

  ngOnInit(): void {}
}
