// eslint-disable  , , @angular-eslint/directive-class-suffix, @angular-eslint/component-class-suffix
// eslint-disable  max-classes-per-file

import { CDK_TABLE_TEMPLATE, CdkTable } from '@angular/cdk/table';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

export const _OtDataTable = CdkTable;

@Component({
  selector: 'ot-data-table',
  exportAs: 'otDataTable',
  template: CDK_TABLE_TEMPLATE,
  styleUrls: ['table.scss'],
  host: {
    class: 'ot-data-table',
  },
  providers: [],
  encapsulation: ViewEncapsulation.None,
  // preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtDataTable<T> extends _OtDataTable<T> {}
