import { Component, OnDestroy, OnInit, QueryList } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Mask, parseSentenceForNumber } from '@tenant/helpers';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';

import {
  distinctUntilChanged,
  filter,
  map,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { DynamicFieldDirective } from '../dynamic-field.directive';
import { Field } from '../models/field.interface';
import { GrossCurrencyField } from '../models/gross-currency-field';

@Component({
  selector: 'ot-dynamic-gross',
  templateUrl: './dynamic-gross.component.html',
  styleUrls: ['./dynamic-gross.component.scss'],
})
export class DynamicGrossComponent implements Field, OnInit, OnDestroy {
  private get price() {
    const price = this.getPriceField();
    if (!price || !price.value) {
      return 0;
    }

    return parseSentenceForNumber(price.value);
  }

  public valueEnabled$: Observable<boolean>;
  autoEnabled$: Observable<any>;
  public config: GrossCurrencyField;
  public group: FormGroup;
  public rootGroup: FormGroup;
  public errors: any;
  public grossGroup: FormGroup;
  // {disabled: true, value: ''}
  public currencyMask: any = Mask.AMOUNT;
  public inputs: QueryList<DynamicFieldDirective>;
  public readonly: any;
  private valueChanges$: Subscription;
  private destroy = new Subject();

  public ngOnInit(): void {
    this.grossGroup = this.group.get(this.config.field) as FormGroup;
    this.processAuto();

    this.valueChanges$ = this.grossGroup.valueChanges
      .pipe(
        takeUntil(this.destroy),
        filter((v: any) => {
          const tmp = parseSentenceForNumber(v.value);
          return !(!v.value || tmp === v.value || !(tmp > 0) || v.value === '');
        })
      )
      .subscribe((val) => {
        val.value = +parseSentenceForNumber(val.value);
      });
  }

  public ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }

  private getPriceField() {
    return (
      this.rootGroup.get('sale_price') || this.rootGroup.get('rental_price')
    );
  }

  private processAuto() {
    const auto = this.grossGroup.get('auto');
    const value = this.grossGroup.get('value');
    const potentialCommission = this.rootGroup.get('potential_commission');
    const mlsCheckbox = this.rootGroup.get('coop_mls_agency_is_involved');
    const mlsPayment = this.rootGroup.get('coop_mls_agency_payment_due');
    const priceField = this.getPriceField();
    this.autoEnabled$ = potentialCommission.statusChanges.pipe(
      takeUntil(this.destroy),
      startWith(!potentialCommission.disabled),
      map(() => !potentialCommission.disabled)
    );

    const mlsChecked = this.detectCheckboxValue(mlsCheckbox);

    let autoEnabledOnce = false;

    this.valueEnabled$ = combineLatest([
      this.autoEnabled$,
      auto.valueChanges.pipe(startWith(auto.value)),
    ]).pipe(
      distinctUntilChanged(),
      tap(([enabled, autoValue]) => {
        if (
          enabled &&
          !autoValue &&
          !potentialCommission.value.value &&
          !autoEnabledOnce
        ) {
          auto.setValue(true);
          autoEnabledOnce = true;
        } else {
          if (autoValue && !enabled) {
            auto.setValue(false);
          }
        }
      }),
      map(([enabled, autoValue]) => !enabled || (enabled && !autoValue))
    );

    combineLatest([
      this.detectCheckboxValue(auto),
      mlsChecked,
      potentialCommission.valueChanges.pipe(
        startWith(potentialCommission.value)
      ),
      this.detectMLSDueValue(mlsPayment),
      priceField.valueChanges.pipe(startWith(priceField.value)),
    ])
      .pipe(
        takeUntil(this.destroy),
        filter((values: any) => {
          return values[0];
        })
      )
      .subscribe(([hasMls, commission, mlsDue]) => {
        let val = this.getCommission(commission);
        if (hasMls) {
          val = val - parseSentenceForNumber(mlsDue.value);
        }
        value.setValue(val > 0 ? val : 0);
      });
  }

  private mapEnabled(status) {
    return status !== 'DISABLED';
  }

  private detectCheckboxValue(field) {
    return combineLatest([
      field.statusChanges.pipe(startWith(field.status)),
      field.valueChanges.pipe(startWith(field.value)),
    ]).pipe(
      takeUntil(this.destroy),
      map(([status, value]) => this.mapEnabled(status) && value)
    );
  }

  private detectMLSDueValue(field) {
    return combineLatest([
      field.statusChanges.pipe(startWith(field.status)),
      field.valueChanges.pipe(startWith(field.value)),
    ]).pipe(
      takeUntil(this.destroy),
      map(([status, value]) => (this.mapEnabled(status) ? value : 0))
    );
  }

  private getCommission(commission) {
    if (!(commission instanceof Object)) {
      return 0;
    }

    if (commission.type === 'M') {
      return parseSentenceForNumber(commission.value);
    }

    return (this.price * parseSentenceForNumber(commission.value)) / 100;
  }
}
