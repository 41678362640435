export class HeaderLink {
  public badge = 0;
  public title: string;
  public link: any[];
  public className: string;
  public isDivider = false;
  public access = [];
  public permissions = [];

  constructor({
    badge = 0,
    title = '',
    link = [] as any[],
    permissions = [] as any[],
    className = '',
    isDivider = false
  }) {
    this.badge = badge;
    this.title = title;
    this.link = link;
    this.className = className;
    this.isDivider = isDivider;
    this.permissions = permissions;
  }
}
