import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { SortDirection } from './sort-direction';
import { SortHeaderComponent } from './sort-header.component';

/** The current sort state. */
export interface Sort {
  /** The id of the column being sorted. */
  active: string;

  /** The sort direction. */
  direction: SortDirection;
}

@Directive({
  selector: '[otSort]',
  exportAs: 'otSort',
})
export class SortDirective {
  private _disabled = false;

  get disabled() {
    return this._disabled;
  }

  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }

  private _disableClear: boolean;

  @Input('otSortDisableClear')
  get disableClear() {
    return this._disableClear;
  }

  set disableClear(v: boolean) {
    this._disableClear = coerceBooleanProperty(v);
  }

  private _direction: SortDirection = '';

  get direction(): SortDirection {
    return this._direction;
  }

  @Input('otSortDirection')
  set direction(direction: SortDirection) {
    if (direction && direction !== 'asc' && direction !== 'desc') {
      throw new Error('Invalid sort direction');
    }
    this._direction = direction;
  }

  @Input('otSortActive') public active: string;
  @Input('otSortStart') start: 'asc' | 'desc' = 'asc';
  public _stateChanges = new Subject<void>();
  /** Event emitted when the user changes either the active sort or sort direction. */
  @Output('otSortChange')
  readonly sortChange: Subject<any> = new Subject<Sort | null>();
  private sortables = new Map<string, SortHeaderComponent>();

  constructor() {}

  public register(header: SortHeaderComponent) {
    if (this.sortables.has(header.id)) {
      throw new Error('Sortable id must be unique');
    }
    this.sortables.set(header.id, header);
  }

  public unregister(header: SortHeaderComponent) {
    this.sortables.delete(header.id);
  }

  public sort(sortable: SortHeaderComponent) {
    if (this.active !== sortable.id) {
      this.active = sortable.id;
      this.direction = sortable.start ? sortable.start : this.start;
    } else {
      this.direction = this.getNextSortDirection(sortable);
    }

    this.sortChange.next({ active: this.active, direction: this.direction });
  }

  private getNextSortDirection(sortable: SortHeaderComponent): SortDirection {
    if (!sortable) {
      return '';
    }

    // Get the sort direction cycle with the potential sortable overrides.
    const disableClear =
      sortable.disableClear != null ? sortable.disableClear : this.disableClear;
    const sortDirectionCycle = getSortDirectionCycle(
      sortable.start || this.start,
      disableClear
    );

    // Get and return the next direction in the cycle
    let nextDirectionIndex = sortDirectionCycle.indexOf(this.direction) + 1;
    if (nextDirectionIndex >= sortDirectionCycle.length) {
      nextDirectionIndex = 0;
    }
    return sortDirectionCycle[nextDirectionIndex];
  }
}

function getSortDirectionCycle(
  start: 'asc' | 'desc',
  disableClear: boolean
): SortDirection[] {
  const sortOrder: SortDirection[] = ['asc', 'desc'];
  if (start === 'desc') {
    sortOrder.reverse();
  }
  // if (!disableClear) {
  //   sortOrder.push('');
  // }

  return sortOrder;
}
