import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { GroupField } from './group-field';
import { FormConfigType } from '../dynamic-form';

export class ArrayField extends GroupField {
  /*
    constructor(
      group: IDynamicField,
      components = null
    ) {
      super(group, components);
    }*/

  public control(): AbstractControl {
    return new FormArray([]);
  }

  public childrenGroup() {
    const fg = new FormGroup({});
    if (this.children) {
      this.children.forEach((field) => {
        fg.setControl(field.field, field.control());
      });
    }
    return fg;
  }
}
