import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OtFormsModule } from '@tenant/forms';
import { busyConf } from '@tenant/ot';
import { InlineSVGModule } from 'ng-inline-svg';

import { NgxPermissionsModule } from 'ngx-permissions';
import { TableFiltersComponent } from './table-filters/table-filters.component';
import { TableComponent } from './table/table.component';
import { BusyConfig, NgBusyModule } from 'ng-busy';

const COMPONENTS = [TableComponent, TableFiltersComponent];

@NgModule({
  imports: [
    CommonModule,
    OtFormsModule,
    NgBusyModule,
    NgxPermissionsModule,
    InlineSVGModule,
  ],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
  providers: [{ provide: BusyConfig, useFactory: busyConf }],
})
export class TableModule {}
