import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '@tenant/core';
import { OtFormsModule } from '@tenant/forms';
import { OtModule, PipesModule } from '@tenant/ot';
import { TableModule } from '@tenant/table';
import { TasksActionsService, TasksModule } from '@tenant/tasks';
import { InlineSVGModule } from 'ng-inline-svg';
import { DashHeaderComponent } from './components/dash-header/dash-header.component';
import { DashLeaderboardComponent } from './components/dash-leaderboard/dash-leaderboard.component';
import { DashSummaryComponent } from './components/dash-summary/dash-summary.component';
import { DashTasksItemComponent } from './components/dash-tasks-item/dash-tasks-item.component';
import { DashTasksListComponent } from './components/dash-tasks-list/dash-tasks-list.component';
import { DashTasksComponent } from './components/dash-tasks/dash-tasks.component';
import { DashboardComponent } from './dashboard.component';
import { DashboardService } from './dashboard.service';
import { DashboardRoutingModule } from './dashboard-routing.module';

const COMPONENTS = [
  DashboardComponent,
  DashTasksComponent,
  DashSummaryComponent,
  DashHeaderComponent,
  DashTasksListComponent,
  DashTasksItemComponent,
  DashLeaderboardComponent,
];

@NgModule({
  imports: [
    CommonModule,
    DashboardRoutingModule,
    TasksModule,
    OtModule,
    OtFormsModule,
    InlineSVGModule,
    NgbTooltipModule,
    TableModule,
    CoreModule,
    RouterModule,
    PipesModule,
  ],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
  providers: [DashboardService, TasksActionsService],
})
export class DashboardModule {}
