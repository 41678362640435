<div class="backdrop" *ngIf="images.length > 0" [@fadeIn]="images.length > 0"></div>
<div class="preview-wrapper" *ngIf="images.length > 0" [@fadeIn]="images.length > 0">
  <div class="close-preview" (click)="close()"><i class="fa fa-times" aria-hidden="true"></i></div>
  <div class="arrow-left" (click)="showPrev()"><i class="fa fa-chevron-left" aria-hidden="true"></i></div>
  <div class="arrow-right" (click)="showNext()"><i class="fa fa-chevron-right" aria-hidden="true"></i></div>
  <div class="main-area">
    <div class="image" *ngFor="let img of images; let i = index"
      [style.transform]="'translateX(' + (-100 * currentIndex) + '%)'">
      <img [src]="((img | officeTroopImage) | async)" (click)="showNext()">
    </div>
  </div>
</div>