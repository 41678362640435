import { NgControl } from '@angular/forms';
import { Observable } from 'rxjs';

export abstract class FormFieldControl<T> {
  /** The value of the control. */
  public value: T;

  /**
   * Stream that emits whenever the state of the control changes such that the parent `MdFormField`
   * needs to run change detection.
   */
  public readonly stateChanges: Observable<void>;

  /** The element ID for this control. */
  public readonly id: string;

  /** The placeholder for this control. */
  public readonly placeholder: string;

  /** Gets the NgControl for this control. */
  public readonly ngControl: NgControl | null;

  /** Whether the control is focused. */
  public readonly focused: boolean;

  /** Whether the control is empty. */
  public readonly empty: boolean;

  /** Whether the control is required. */
  public readonly required: boolean;

  /** Whether the control is disabled. */
  public readonly disabled: boolean;

  /** Whether the control is in an error state. */
  public readonly errorState: boolean;

  /** Sets the list of element IDs that currently describe this control. */
  public abstract setDescribedByIds(ids: string[]): void;

  /** Focuses this control. */
  public abstract focus(): void;
}
