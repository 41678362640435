import { AbstractField } from './abstract-field';
import { AddressField } from './address-field';
import { DropdownField } from './dropdown-field';
import { GroupField } from './group-field';
import { TextField } from './text-field';
import { TypeaheadField } from './typeahead-field';
import { DealTypes } from '../../../../../../apps/admin/src/app/deal/models/deal';

export class DynamicFormConfig {
  public fields: Array<AbstractField | GroupField> = [];
  private components = {};

  constructor(fields: any[], components = {}) {
    this.components = components;

    this.fields = fields.map((v) => this.preformField(v, components));
  }

  public preformField(field, components = null) {
    if (!components) {
      components = this.components;
    }
    if (!field.type) {
      throw Error('Field type is required');
    }
    if (field.field === 'company_gross_commission') {
      field.type = 'gross';
    }
    if (field.field === 'square_footage') {
    }
    switch (field.type) {
      case 'custom_address':
      case 'address':
        return new AddressField(field);
      case 'array':
      case 'group':
      case 'closing_questions':
        return new GroupField(field, components);
      case 'custom_dropdown':
      case 'dropdown':
        return new DropdownField(field);
      case 'reference':
        return new TypeaheadField(field, components.reference);
      case 'text':
      case 'number':
      case 'paragraph':
        return new TextField(field);
      default:
        return new AbstractField(field, components.other);
    }
  }

  /*private dotSet(obj, field, value) {
    const fieldName = field.split('.');
    if (fieldName.length > 1) {
      if (!obj[fieldName.unshift()]) {
        obj[fieldName.unshift()] =
      }
      this.dotSet(obj[fieldName.unshift()], fieldName.join('.'), value);
    }
  }*/
}
