import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { TasksActionsService } from '@tenant/tasks';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'ot-dash-tasks',
  templateUrl: './dash-tasks.component.html',
  styleUrls: ['./dash-tasks.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('collapse', [
      state(
        'collapsed',
        style({
          height: '0px',
          minHeight: '0',
          visibility: 'hidden',
          overflow: 'hidden',
          margin: '0',
        })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class DashTasksComponent implements OnInit, OnDestroy {
  private _tasks;

  public get tasks() {
    return this._tasks;
  }

  @Input()
  public set tasks(value) {
    this._tasks = value;
  }

  @Output() public reloadTasks = new EventEmitter();
  @Input() public loading;
  public selectedDeal = null;
  private alive = true;

  constructor(private tasksActions: TasksActionsService) {}

  ngOnInit() {
    this.tasksActions.taskUpdated
      .asObservable()
      .pipe(takeWhile(() => this.alive))
      .subscribe((v: any) => {
        this.reloadTasks.emit();
      });
  }

  public addTask($event) {
    const dealOption = this.tasks.map((d) => {
      return { label: this.prepareAddress(d.address), value: d.id };
    });
    this.tasksActions.add($event, dealOption).subscribe(
      (res) => {
        this.reloadTasks.emit();
      },
      () => null
    );
  }

  public tasksUpdate() {
    this.reloadTasks.emit();
  }

  public toggleDeal(id) {
    if (this.selectedDeal === id) {
      this.selectedDeal = null;
    } else {
      this.selectedDeal = id;
    }
  }

  public ngOnDestroy(): void {
    this.alive = false;
  }

  private prepareAddress(addr) {
    let addressLines = addr.address_line_1;
    if (addr.address_line_2) {
      addressLines = addr.address_line_1 + ' ' + addr.address_line_1;
    }
    const addressParts = [
      addressLines,
      addr.city,
      addr.state,
      addr.zip_code,
      addr.country,
    ];
    return addressParts.filter((v) => v && v.length > 0).join(', ');
  }
}
