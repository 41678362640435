import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { from as fromPromise } from 'rxjs';
import { SweetAlertService } from '../services/sweetalert.service';

@Directive({
  selector: '[otConfirmClick]'
})
export class ConfirmClickDirective {
  @Input() public title = 'Are you sure?';
  @Input() public text = '';
  @Output() public confirm = new EventEmitter();
  @Output() public dismiss = new EventEmitter();

  constructor(private swal: SweetAlertService) {}

  @HostListener('click', ['$event'])
  public click($event) {
    $event.preventDefault();
    fromPromise(
      this.swal.confirm({
        title: this.title,
        text: this.text
      })
    ).subscribe(
      () => {
        this.confirm.emit($event);
      },
      () => {
        this.dismiss.emit($event);
      }
    );
  }
}
