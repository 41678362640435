/* eslint-disable */

import { Directive } from '@angular/core';

// eslint-disable  @angular-eslint/directive-selector
@Directive({
  selector: 'ot-label'
})
export class OtLabelDirective {}

@Directive({
  selector: 'ot-label-right'
})
export class OtLabelRightDirective {}
