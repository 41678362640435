import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map, merge, mergeMap } from 'rxjs/operators';

export type TypeAHeadSearchFunction = (text: string) => Observable<any[]>;
export type TypeAHeadFormatFunction = (data: any, value: string) => string;

@Component({
  selector: 'ot-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TypeaheadComponent implements OnInit, OnChanges, OnDestroy {
  public get isInvalid() {
    if (this.noValidate) {
      return false;
    }
    return (
      (this.control.invalid &&
        (this.control.touched || this.submitted === true)) ||
      this.serverErrors
    );
  }

  public get isSuccess() {
    if (this.noValidate) {
      return false;
    }
    return this.control.valid && !this.serverErrors;
  }

  public get actualErrors() {
    // if(this.serverErrors.is)
    if (this.serverErrors) {
      if (Array.isArray(this.serverErrors)) {
        return this.serverErrors[0];
      } else {
        return this.serverErrors;
      }
    }
    for (const error in this.control.errors) {
      if (this.errorMapping[error]) {
        return this.errorMapping[error];
      }
    }
    return '';
  }

  @ViewChild('myDrop') public myDrop: NgbDropdown;
  @Input() public control: FormControl;
  @Input() public inputControl: FormControl = new FormControl();
  @Input() public submitted = false;
  @Input() public hideLabel = false;
  @Input() public readOnly = false;
  @Input() public placeholder = '';
  @Input() public type = 'text';
  @Input() public label = null;
  @Input() public errorLabel = null;
  @Input() public helpText = '';
  @Input() public afterInput = '';
  @Input() public name = null;
  @Input() public serverErrors = null;
  @Input() public search: TypeAHeadSearchFunction = null;
  @Input() public formatResult: TypeAHeadFormatFunction = null;
  @Input() public searchDelay = 300;
  @Input() public searchTemplate: TemplateRef<any> = null;
  @Input()
  public errorMapping = {
    required: 'This field is required',
    email: 'Email is not valid',
    pattern: 'This field is not valid',
    url: 'URL is not valid',
    minlength: 'This field is too short',
    noPhoneNumber: 'Phone number is not valid',
  };
  @Input() public className = null;
  @Input() public noValidate = false;
  public data: any[] = [];
  public requiredMark: any = true;
  public ctx: any;
  private _prevValue: string;
  private _valueChangesSubscription: Subscription;
  private _inputSub: Subscription;
  private _focusElement: Subject<any> = new Subject<any>();
  private clearSub: Subscription;

  constructor(private elementRef: ElementRef) {
    this.control = this.control || new FormControl('');
    this.ctx = { typeahead: this}
  }

  public ngOnDestroy(): void {
    if (this._valueChangesSubscription) {
      this._valueChangesSubscription.unsubscribe();
    }
    if (this._inputSub) {
      this._inputSub.unsubscribe();
    }
  }

  public highlight(textToHighlight) {
    const text = this.inputControl.value;
    const index = textToHighlight?.toLowerCase().indexOf(text?.toLowerCase());
    if (index >= 0) {
      textToHighlight =
        textToHighlight.substring(0, index) +
        "<span class='highlight'>" +
        textToHighlight.substring(index, index + text.length) +
        '</span>' +
        textToHighlight.substring(index + text.length);
    }
    return textToHighlight;
  }

  public ngOnInit() {
    this.cleanErrors();
    this.requiredMark = this.control.hasError('required');
    this._inputSub = this.inputControl.valueChanges
      .pipe(
        merge(this._focusElement.pipe(map((v) => this.inputControl.value))),
        map((v) => {
          this.hideDropdown();
          return v;
        }),
        debounceTime(this.searchDelay),
        mergeMap((value) => this.search(this.inputControl.value))
      )
      .subscribe((data) => {
        if (Array.isArray(data)) {
          this.data = data.slice(0, 5);
        } else {
          this.data = data;
        }
        if (this.inputControl.value !== this._prevValue) {
          this.showDropdown(false);
          if (this.control.value) {
            this.control.setValue(null);
          }
        }
      });
  }

  public showDropdown(doEmit = true) {
    if (doEmit) {
      this._focusElement.next(+new Date());
    }
    this.myDrop.open();
  }

  public hideDropdown() {
    this.myDrop.close();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.control) {
      this.cleanErrors();
      this.inputControl.patchValue(changes.control.currentValue.value);
      if (this.clearSub) {
        this.clearSub.unsubscribe();
      }
      this.clearSub = this.control.valueChanges
        .pipe(filter((value: any) => !value || value.length === 0))
        .subscribe(() => {
          this.inputControl.patchValue('', {
            emitEvent: false,
            emitModelToViewChange: true,
          });
        });
    }
  }

  public selectItem(row) {
    this._prevValue = row.name;
    this.inputControl.setValue(row.name);
    this.control.setValue(row.id);
    this.hideDropdown();
    return row;
  }

  public toDisplay(row) {
    if (this.formatResult) {
      return this.formatResult(row, this.inputControl.value);
    }
    return row;
  }

  private cleanErrors() {
    if (this._valueChangesSubscription) {
      this._valueChangesSubscription.unsubscribe();
    }
    this._valueChangesSubscription = this.control.valueChanges.subscribe(
      (value) => {
        this.serverErrors = null;
      }
    );
  }

  @HostListener('window:click', ['$event'])
  private detectClickOutside($event) {
    if (
      !this.elementRef.nativeElement.contains($event.target) &&
      this.myDrop.isOpen()
    ) {
      this.hideDropdown();
    }
  }
}
