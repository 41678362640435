import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { timer as observableTimer } from 'rxjs';

import { debounce } from 'rxjs/operators';
import { ServerDataSource } from '../ds/server.data-source';

import { TableFiltersComponent } from '../table-filters/table-filters.component';
import {
  TableCheckboxMeta,
  TableColumn,
  TableConfig,
} from '../table.interfaces';

@Component({
  selector: 'ot-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent implements OnInit, OnChanges {
  public get tableCells(): TableColumn[] {
    return this.config.columns.filter((cell) => cell.hidden !== true);
  }

  public get hasNextPage() {
    if (!this.collection || !this.collection.meta) {
      return false;
    }
    const meta = this.collection.meta;
    return meta.page * meta.per_page < meta.total;
  }

  public get meta() {
    if (!this.collection) {
      return {};
    }
    const meta = this.collection.meta;
    const current = meta.page * meta.per_page;
    meta.visible = current <= meta.total ? current : meta.total;
    return meta;
  }

  @ViewChild(TableFiltersComponent) public tableFilters: TableFiltersComponent;
  @ContentChild('metaTemplate') public metaTemplate: TemplateRef<any>;
  @Input() public config: TableConfig;
  @Input() public hideMeta = false;
  @Input() public alternativeTemplate: TemplateRef<any>;
  @Input() public useAlternativeTemplate = false;
  @Input() public checkbox: TableCheckboxMeta = null;
  @Output() public checkedEvent = new EventEmitter<any>();
  public loading: any;
  public collection;
  public sort: any = {};
  public checked = [];
  private page = 1;

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.config) {
      this.initComponent();
    }
  }
  public loadMore() {
    this.page = this.meta.page + 1;
    this.config.dataSource.setPage(this.page);
  }

  public toggleSort(cell) {
    if (!cell.sortable) {
      return;
    }
    let direction = 'asc';
    if (this.sort.field === cell.sortableKey && this.sort.direction === 'asc') {
      direction = 'desc';
    }
    this.sort = {
      field: cell.sortableKey,
      direction,
    };
    const ds: ServerDataSource = this.config.dataSource as ServerDataSource;
    ds.empty();
    ds.setSort([this.sort], false);
    ds.setPage(1);
  }

  public processFilters(filterValue, doEmit = true) {
    const filters = [];
    Object.keys(filterValue)
      .filter(
        (key) => filterValue[key] && filterValue[key] !== 'not_to_send_value'
      )
      .forEach((key) => {
        const value = filterValue[key];
        const filtersCustomParam = this.config.filters.filter(
          (f) => f.requestKey === key
        )[0]?.customParams;
        if (value !== null && typeof value === 'object') {
          if (filterValue[key].customParams) {
            filters.push({
              key: filterValue[key].customParams.split(',')[0],
              value: filterValue[key].from,
            });
            filters.push({
              key: filterValue[key].customParams.split(',')[1],
              value: filterValue[key].to,
            });
          } else if (filtersCustomParam) {
            filters.push({
              key: filtersCustomParam.split(',')[0],
              value: filterValue[key].from,
            });
            filters.push({
              key: filtersCustomParam.split(',')[1],
              value: filterValue[key].to,
            });
          } else {
            const dr = this.config.filters.find((v) => v.type === 'date-range');
            let keyFrom = 'from';
            let keyTo = 'to';
            if (dr && dr.rangePrefix) {
              keyFrom = dr.rangePrefix + '_' + keyFrom;
              keyTo = dr.rangePrefix + '_' + keyTo;
            }
            if (value.from) {
              filters.push({ key: keyFrom, value: value.from });
            }
            if (value.to) {
              filters.push({ key: keyTo, value: value.to });
            }
          }
        } else {
          filters.push({ key, value });
        }
      });
    // eslint-disable-line , , , ,
    if (
      this.config.dataSource.getFilter().filters.length === 0 &&
      filters.length === 0
    ) {
      return;
    }
    this.config.dataSource.setFilter(filters, doEmit);
  }

  public emitChecked() {
    this.checkedEvent.emit(this.checked);
  }

  public setAllChecked() {
    const notBlocked = this.collection.data.filter(
      (item) => !this.checkbox.disabledIf || !this.checkbox.disabledIf(item)
    );
    if (notBlocked.length === this.checked.length) {
      this.checked = [];
    } else {
      this.checked = [];
      this.collection.data.forEach((item) => {
        if (!this.checkbox.disabledIf || !this.checkbox.disabledIf(item)) {
          this.checked.push(item.id);
        }
      });
    }
    this.checkedEvent.emit(this.checked);
  }

  public getCheckboxValue(id) {
    if (this.checked.indexOf(id) === -1) {
      this.checked.push(id);
    } else {
      this.checked.splice(this.checked.indexOf(id), 1);
    }
    this.checkedEvent.emit(this.checked);
  }

  public markChecked(id) {
    return this.checked.indexOf(id) !== -1;
  }

  private initComponent() {
    if (!this.config) {
      return;
    }

    let loadingResolve = null;

    // Debounce only when onLoading() emits true.
    this.config.dataSource
      .onLoading()
      .pipe(debounce((val: boolean) => observableTimer(val ? 500 : 0)))
      .subscribe((loading) => {
        if (loading) {
          this.loading = new Promise((resolve) => {
            loadingResolve = resolve;
          });
        } else {
          if (loadingResolve) {
            loadingResolve();
          }
        }
      });
    this.config.dataSource.onChanged().subscribe((data) => {
      this.collection = data.elements;
      this.page = this.config.dataSource.getCurrentPage();
    });
    this.applyDefaultFilters();
    this.applyDefaultSort();
  }

  private applyDefaultFilters() {
    const defFilters = {};
    this.config.filters.forEach((filter) => {
      if (filter.value) {
        if (filter.customParams) {
          defFilters[filter.customParams] = filter.value;
          defFilters[filter.customParams]['customParams'] = filter.customParams;
        } else {
          defFilters[filter.requestKey] = filter.value;
        }
      }
    });
    this.processFilters(defFilters, false);
  }

  private applyDefaultSort() {
    const meta = this.config.meta;
    if (meta && meta.order) {
      this.sort = {
        field: meta.order.key,
        direction: meta.order.direction,
      };
      const ds: ServerDataSource = this.config.dataSource as ServerDataSource;
      ds.setSort([this.sort], false);
    }
  }
}
