import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class Unsubscribe implements OnDestroy {
  protected _destroy: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  protected _unsubscribeTake() {
    return takeUntil(this._destroy);
  }
}
