import {
  Directive,
  DoCheck,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Renderer2,
  Self
} from '@angular/core';
import {
  FormControl,
  FormGroupDirective,
  NgControl,
  NgForm
} from '@angular/forms';
import { Subject } from 'rxjs';
import {
  defaultErrorStateMatcher,
  ERROR_GLOBAL_OPTIONS,
  ErrorOptions,
  ErrorStateMatcher
} from '../error-options';
import { FormFieldControl } from '../form-field-control';
import { CommissionCurrencyComponent } from './commission-currency.component';

let nextUniqueId = 1;

@Directive({
  selector: 'ot-commission-currency[otInput]',
  providers: [
    { provide: FormFieldControl, useExisting: CommissionCurrencyDirective }
  ]
})
export class CommissionCurrencyDirective
  implements FormFieldControl<any>, OnChanges, OnDestroy, DoCheck {
  protected _disabled = false;

  /** Whether the element is disabled. */
  @Input()
  get disabled() {
    return this.ngControl ? this.ngControl.disabled : this._disabled;
  }

  set disabled(value: any) {
    this._disabled = !!value;
    this._select.setDisabledState(this._disabled);
  }

  // @HostBinding('required')
  protected _required = false;

  /** Whether the element is required. */
  @Input()
  get required() {
    return this._required;
  }

  set required(value: any) {
    this._required = !!value;
  }

  protected _id: string;

  /** Unique id of the element. */
  @Input()
  get id() {
    return this._id;
  }

  set id(value: string) {
    this._id = value || this._uid;
  }

  /** The input element's value. */
  get value() {
    return this.ngControl && this.ngControl.control
      ? this.ngControl.control.value
      : null;
  }

  set value(value: string) {
    if (value !== this.value) {
      this.ngControl.control.setValue(value);
      this.stateChanges.next();
    }
  }

  // Implemented as part of MdFormFieldControl.
  get empty(): boolean {
    return this.value == null || this.value === '';
  }

  public stateChanges = new Subject<void>();
  public placeholder: string;
  public focused: boolean;
  @Input() public errorStateMatcher: ErrorStateMatcher;
  @HostBinding('attr.aria-describedby') public _ariaDescribedby: string;
  @HostBinding('class.is-valid') public formControlSuccess = false;
  @HostBinding('class.is-invalid') public formControlDanger = false;
  @HostBinding('attr.aria-invalid') public errorState = false;
  protected _uid = `ot-input-${nextUniqueId++}`;
  protected _errorOptions: ErrorOptions;
  protected _previousNativeValue = this.value;

  constructor(
    protected _elementRef: ElementRef,
    protected _renderer: Renderer2,
    protected _select: CommissionCurrencyComponent,
    @Self() public ngControl: NgControl,
    @Optional() protected _parentForm: NgForm,
    @Optional() protected _parentFormGroup: FormGroupDirective,
    @Optional()
    @Inject(ERROR_GLOBAL_OPTIONS)
    errorOptions: ErrorOptions
  ) {
    this._errorOptions = errorOptions ? errorOptions : {};
    this.errorStateMatcher =
      this._errorOptions.errorStateMatcher || defaultErrorStateMatcher;
  }

  public ngOnChanges() {
    this.stateChanges.next();
  }

  public ngOnDestroy() {
    this.stateChanges.complete();
  }

  public ngDoCheck() {
    if (this.ngControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions). This means
      // that whatever logic is in here has to be super lean or we risk destroying the performance.
      this._updateErrorState();
    }
  }

  public setDescribedByIds(ids: string[]): void {
    this._ariaDescribedby = ids.join(' ');
  }

  public focus(): void {
    this._select?.focus();
  }

  /** Callback for the cases where the focused state of the input changes. */
  @HostListener('blur', ['false'])
  @HostListener('focus', ['true'])
  public _focusChanged(isFocused: boolean) {
    if (isFocused !== this.focused) {
      this.focused = isFocused;
      this.stateChanges.next();
    }
  }

  protected _updateErrorState() {
    const oldState = this.errorState;
    const ngControl = this.ngControl;
    const parent = this._parentForm || this._parentFormGroup;
    const newState =
      ngControl &&
      this.errorStateMatcher(ngControl.control as FormControl, parent);

    if (newState !== oldState) {
      this.errorState = newState;
      this.formControlDanger = newState;
      this.formControlSuccess = !this.formControlDanger;
      this.stateChanges.next();
    }
  }
}
