import {
  Deal,
  Statuses,
  SubmissionStatuses,
  AgentTypes,
  AgentRoles,
  DealTypes,
  DealFee,
} from '../models/deal';
import { Profile } from '@tenant/core';
import { ContactDetails, Contact } from '../shared/deal-contact';
import { TaskModel, Progress } from '@tenant/tasks';

export class DealMatrix {
  markAsDead(arr: [Deal, Profile]) {
    return (
      arr[0].status === Statuses.PENDING ||
      arr[0].status === Statuses.ACTIVE ||
      arr[0].status === Statuses.COMING_SOON
    );
  }

  markAsComingSoon(arr: [Deal, Profile]) {
    return false;
  }

  markAsActive(arr: [Deal, Profile]) {
    return arr[0].status === Statuses.COMING_SOON;
  }

  // tslint:disable-next-line: no-identical-functions
  markAsClosed(arr: [Deal, Profile]) {
    return (
      arr[0].status === Statuses.PENDING ||
      arr[0].status === Statuses.ACTIVE ||
      arr[0].status === Statuses.COMING_SOON
    );
  }

  markAsPending(arr: [Deal, Profile]) {
    return (
      arr[0].status === Statuses.COMING_SOON ||
      arr[0].status === Statuses.ACTIVE ||
      arr[0].status === Statuses.CLOSED
    );
  }

  markAsArchived(arr: [Deal, Profile]) {
    return (
      (arr[0].status === Statuses.CLOSED || arr[0].status === Statuses.DEAD) &&
      !arr[1]?.roles?.is_ot_agent
    );
  }

  markAsDelete(arr: [Deal, Profile]) {
    return arr[0].status === Statuses.ARCHIVED && arr[1]?.roles?.is_ot_admin;
  }

  allowEditStatus(arr: [Deal, Profile]) {
    if (arr[0].status === Statuses.ARCHIVED && !arr[1]?.roles?.is_ot_admin) {
      return false;
    } else if (arr[0].status === Statuses.DEAD && arr[1]?.roles?.is_ot_agent) {
      return false;
    } else {
      return true;
    }
  }

  editContact(arr: [Deal, Profile, ContactDetails[]]) {
    if (
      (arr[0].status !== Statuses.ARCHIVED &&
        arr[0].status !== Statuses.DEAD &&
        arr[0].submission_status !== SubmissionStatuses.APPROVED) ||
      (arr[1]?.roles?.is_ot_admin &&
        arr[0].submission_status === SubmissionStatuses.APPROVED)
    ) {
      return true;
    } else {
      return false;
    }
  }

  deleteContact(arr: [Deal, Profile, Contact, Contact[]]) {
    const lastRequired =
      arr[2].required &&
      arr[0]?.contacts?.filter((c) => c.contact_type === arr[2]?.contact_type)
        .length === 1;
    if (
      (!lastRequired &&
        arr[0].status !== Statuses.ARCHIVED &&
        arr[0].status !== Statuses.DEAD &&
        arr[0].submission_status !== SubmissionStatuses.APPROVED) ||
      (arr[1]?.roles?.is_ot_admin &&
        arr[0].submission_status === SubmissionStatuses.APPROVED)
    ) {
      return true;
    } else {
      return false;
    }
  }

  // tslint:disable-next-line: no-identical-functions
  addContact(arr: [Deal, Profile]) {
    return (
      (arr[0].status !== Statuses.ARCHIVED &&
        arr[0].status !== Statuses.DEAD &&
        arr[0].submission_status !== SubmissionStatuses.APPROVED) ||
      (arr[1]?.roles?.is_ot_admin &&
        arr[0].submission_status === SubmissionStatuses.APPROVED)
    );
  }

  editDetails(arr: [Deal, Profile]) {
    return (
      arr[0].status !== Statuses.DEAD && arr[0].status !== Statuses.ARCHIVED
    );
  }

  addAgent(arr: [Deal, Profile]) {
    if (
      arr[0].status !== Statuses.DEAD &&
      arr[0].status !== Statuses.ARCHIVED &&
      arr[0].submission_status !== SubmissionStatuses.APPROVED &&
      arr[0]?.agents?.length < 10
    ) {
      return true;
    } else if (
      arr[0].status !== Statuses.DEAD &&
      arr[0].status !== Statuses.ARCHIVED &&
      arr[1]?.roles?.is_ot_admin &&
      arr[0].submission_status === SubmissionStatuses.APPROVED &&
      arr[0]?.agents?.length < 10
    ) {
      return true;
    } else {
      return false;
    }
  }

  editAgent(arr: [Deal, Profile]) {
    if (
      arr[0].status !== Statuses.DEAD &&
      arr[0].status !== Statuses.ARCHIVED &&
      arr[0].submission_status !== SubmissionStatuses.APPROVED
    ) {
      return true;
    } else if (
      arr[0].status !== Statuses.DEAD &&
      arr[0].status !== Statuses.ARCHIVED &&
      arr[1]?.roles?.is_ot_admin &&
      arr[0].submission_status === SubmissionStatuses.APPROVED
    ) {
      return true;
    } else {
      return false;
    }
  }

  deleteAgent(arr: [Deal, Profile, string]) {
    if (
      arr[0].status !== Statuses.DEAD &&
      arr[0].status !== Statuses.ARCHIVED &&
      arr[2] !== AgentTypes.PRIMARY &&
      arr[0].submission_status !== SubmissionStatuses.APPROVED
    ) {
      return true;
    } else if (
      arr[0].status !== Statuses.DEAD &&
      arr[0].status !== Statuses.ARCHIVED &&
      arr[2] !== AgentTypes.PRIMARY &&
      arr[1]?.roles?.is_ot_admin &&
      arr[0].submission_status === SubmissionStatuses.APPROVED
    ) {
      return true;
    } else {
      return false;
    }
  }

  addDocuments(arr: [Deal, Profile]) {
    return (
      (arr[0].status !== Statuses.ARCHIVED &&
        arr[0].submission_status !== SubmissionStatuses.APPROVED) ||
      ((arr[1]?.roles?.is_ot_admin || arr[1]?.roles?.is_teamlead) &&
        arr[0].submission_status === SubmissionStatuses.APPROVED &&
        arr[0].status !== Statuses.ARCHIVED)
    );
  }

  // tslint:disable-next-line: no-identical-functions
  editDocuments(arr: [Deal, Profile]) {
    return (
      (arr[0].status !== Statuses.ARCHIVED &&
        arr[0].submission_status !== SubmissionStatuses.APPROVED &&
        arr[0].submission_status !== SubmissionStatuses.SUBMITTED) ||
      (arr[1]?.roles?.is_ot_admin &&
        (arr[0].submission_status === SubmissionStatuses.APPROVED ||
          arr[0].submission_status !== SubmissionStatuses.SUBMITTED) &&
        arr[0].status !== Statuses.ARCHIVED) ||
      (arr[1]?.roles?.is_ot_admin &&
        arr[0].submission_status === SubmissionStatuses.SUBMITTED &&
        arr[0].status !== Statuses.ARCHIVED)
    );
  }

  deleteDocuments(arr: [Deal, Profile]) {
    const status =
      arr[0].status !== Statuses.DEAD &&
      arr[0].status !== Statuses.ARCHIVED &&
      arr[0].submission_status !== SubmissionStatuses.APPROVED &&
      arr[0].submission_status !== SubmissionStatuses.SUBMITTED;
    if (status || arr[1]?.roles?.is_ot_admin) {
      return true;
    }
  }

  deleteNotes(arr: [Deal, Profile]) {
    const status =
      arr[0].status !== Statuses.DEAD &&
      arr[0].status !== Statuses.ARCHIVED &&
      arr[0].submission_status !== SubmissionStatuses.APPROVED &&
      arr[0].submission_status !== SubmissionStatuses.SUBMITTED;
    if (status) {
      return true;
    } else if (
      arr[1]?.roles?.is_ot_admin &&
      arr[0].submission_status === SubmissionStatuses.APPROVED
    ) {
      return true;
    } else {
      return false;
    }
  }

  editCGC(arr: [Deal, Profile]) {
    if (
      (!arr[0].sold_price && arr[0].deal_type === DealTypes.SALES) ||
      (!arr[0].rented_price && arr[0].deal_type === DealTypes.RENTAL)
    ) {
      return false;
    } else if (
      arr[0].company_gross_commission &&
      arr[0].status !== Statuses.DEAD &&
      arr[0].status !== Statuses.ARCHIVED &&
      arr[0].submission_status !== SubmissionStatuses.APPROVED &&
      arr[0].submission_status !== SubmissionStatuses.SUBMITTED
    ) {
      return true;
    } else if (
      arr[0].company_gross_commission &&
      arr[0].status !== Statuses.ARCHIVED &&
      (arr[0].submission_status === SubmissionStatuses.APPROVED ||
        arr[0].submission_status === SubmissionStatuses.SUBMITTED) &&
      arr[1]?.roles?.is_ot_admin
    ) {
      return true;
    } else {
      return false;
    }
  }

  editAgentCommission(arr: [Deal, Profile]) {
    if (
      arr[0].status !== Statuses.DEAD &&
      arr[0].status !== Statuses.ARCHIVED &&
      arr[0].submission_status !== SubmissionStatuses.APPROVED &&
      arr[0].submission_status !== SubmissionStatuses.SUBMITTED
    ) {
      return true;
    } else if (
      (arr[0].submission_status === SubmissionStatuses.APPROVED ||
        arr[0].submission_status === SubmissionStatuses.SUBMITTED) &&
      arr[1]?.roles?.is_ot_admin
    ) {
      return true;
    } else {
      return false;
    }
  }

  addDeleteAgentDeduction(arr: [Deal, Profile]) {
    if (
      arr[0].status !== Statuses.DEAD &&
      arr[0].status !== Statuses.ARCHIVED &&
      arr[0].submission_status !== SubmissionStatuses.APPROVED &&
      (arr[1]?.roles?.is_ot_admin || arr[1]?.roles?.is_teamlead)
    ) {
      return true;
    } else if (
      arr[0].status !== Statuses.DEAD &&
      arr[0].status !== Statuses.ARCHIVED &&
      arr[0].submission_status === SubmissionStatuses.APPROVED &&
      arr[1]?.roles?.is_ot_admin
    ) {
      return true;
    } else {
      return false;
    }
  }

  editTotalCommission(arr: [Deal, Profile]) {
    if (
      arr[0].status !== Statuses.DEAD &&
      arr[0].status !== Statuses.ARCHIVED &&
      arr[0].submission_status !== SubmissionStatuses.APPROVED
    ) {
      return true;
    } else if (
      arr[0].submission_status === SubmissionStatuses.APPROVED &&
      arr[1]?.roles?.is_ot_admin
    ) {
      return true;
    } else {
      return false;
    }
  }

  addMLSAgency(arr: [Deal, Profile]) {
    if (
      (arr[0].status === Statuses.PENDING ||
        arr[0].status === Statuses.CLOSED) &&
      (arr[0].agent_role === AgentRoles.LISTING_AGENT ||
        arr[0].agent_role === AgentRoles.TENANT_AGENT ||
        arr[0].agent_role === AgentRoles.BUYER_AGENT ||
        arr[0].agent_role === AgentRoles.REFERRAL_AGENT) &&
      (arr[0].submission_status !== SubmissionStatuses.APPROVED ||
        arr[1]?.roles?.is_ot_admin)
    ) {
      return true;
    } else {
      return false;
    }
  }

  deleteMLSAgency(arr: [Deal, Profile]) {
    if (
      arr[0].mls_agency &&
      arr[0].submission_status !== SubmissionStatuses.APPROVED
    ) {
      return true;
    } else if (
      arr[0].submission_status === SubmissionStatuses.APPROVED &&
      arr[1]?.roles?.is_ot_admin
    ) {
      return true;
    } else {
      return false;
    }
  }

  editMLSStatus(arr: [Deal, Profile]) {
    if (
      arr[0].status !== Statuses.DEAD &&
      arr[0].status !== Statuses.ARCHIVED &&
      arr[1].roles.is_ot_admin
    ) {
      return true;
    } else {
      return false;
    }
  }

  editMLSCommission(arr: [Deal, Profile]) {
    if (
      arr[0].status !== Statuses.DEAD &&
      arr[0].status !== Statuses.ARCHIVED &&
      arr[0].submission_status !== SubmissionStatuses.APPROVED
    ) {
      return true;
    } else if (
      arr[0].submission_status === SubmissionStatuses.APPROVED &&
      arr[1]?.roles?.is_ot_admin
    ) {
      return true;
    } else {
      return false;
    }
  }

  canSeeContactDetails(arr: [Deal, Profile, Contact]) {
    if (arr[1]?.roles?.is_ot_admin || arr[1]?.roles?.is_teamlead) {
      return true;
    } else if (
      arr[2].assigned_users.filter((c) => c.id === arr[1]?.id).length
    ) {
      return true;
    } else {
      return false;
    }
  }

  addNotes(arr: [Deal, Profile]) {
    if (arr[0].submission_status !== SubmissionStatuses.APPROVED) {
      return true;
    } else if (
      arr[0].submission_status === SubmissionStatuses.APPROVED &&
      (arr[1]?.roles?.is_ot_admin || arr[1]?.roles?.is_teamlead)
    ) {
      return true;
    } else {
      return false;
    }
  }

  // tslint:disable-next-line: no-identical-functions
  editNotes(arr: [Deal, Profile]) {
    if (
      arr[0].status !== Statuses.DEAD &&
      arr[0].status !== Statuses.ARCHIVED &&
      arr[0].submission_status !== SubmissionStatuses.APPROVED
    ) {
      return true;
    } else if (
      arr[0].submission_status === SubmissionStatuses.APPROVED &&
      arr[1]?.roles?.is_ot_admin
    ) {
      return true;
    } else {
      return false;
    }
  }

  allowSubmitForApprovalAlert(arr: [Deal, TaskModel[]]) {
    if (
      arr[1]?.length &&
      arr[1]?.findIndex((v) => v.progress === Progress.ACTIVE) === -1 &&
      arr[0].status === Statuses.CLOSED &&
      // arr[0].additional_info.receive_commission_method &&
      arr[0].agent_role === 'RA' &&
      arr[0].submission_status !== SubmissionStatuses.SUBMITTED &&
      arr[0].submission_status !== SubmissionStatuses.APPROVED
    ) {
      return true;
    } else if (
      arr[1]?.length &&
      arr[1]?.findIndex((v) => v.progress === Progress.ACTIVE) === -1 &&
      arr[0].status === Statuses.CLOSED &&
      arr[0].submission_status !== SubmissionStatuses.SUBMITTED &&
      arr[0].submission_status !== SubmissionStatuses.APPROVED &&
      arr[0].agent_role !== 'RA'
      // &&
      // arr[0].additional_info.receive_commission_method &&
      // arr[0].additional_info.activate_follow_up_plan !== null &&
      // arr[0].additional_info.email_marketing !== null &&
      // arr[0].additional_info.boost_program !== null
    ) {
      return true;
    } else {
      return false;
    }
  }

  allowAlertForStatus(arr: [Deal, TaskModel[]]) {
    if (
      arr[1]?.length &&
      arr[1]?.findIndex((v) => v.progress === Progress.ACTIVE) === -1 &&
      arr[0].status !== Statuses.CLOSED &&
      arr[0].submission_status !== SubmissionStatuses.SUBMITTED &&
      arr[0].submission_status !== SubmissionStatuses.APPROVED
    ) {
      return true;
    } else if (
      arr[1].length &&
      arr[1]?.findIndex((v) => v.progress === Progress.ACTIVE) === -1 &&
      // arr[0].additional_info.receive_commission_method &&
      arr[0].agent_role === 'RA' &&
      arr[0].submission_status !== SubmissionStatuses.SUBMITTED &&
      arr[0].submission_status !== SubmissionStatuses.APPROVED
    ) {
      return true;
    } else if (
      arr[1].length &&
      arr[1]?.findIndex((v) => v.progress === Progress.ACTIVE) === -1 &&
      arr[0].agent_role !== 'RA' &&
      arr[0].submission_status !== SubmissionStatuses.SUBMITTED &&
      arr[0].submission_status !== SubmissionStatuses.APPROVED
      //  &&
      // arr[0].additional_info.receive_commission_method &&
      // arr[0].additional_info.activate_follow_up_plan !== null &&
      // arr[0].additional_info.email_marketing !== null &&
      // arr[0].additional_info.boost_program !== null
    ) {
      return true;
    } else {
      return false;
    }
  }

  addFee(arr: [Deal]) {
    return (
      arr[0].status !== Statuses.DEAD && arr[0].status !== Statuses.ARCHIVED
    );
  }

  editDeleteFee(arr: [Deal, DealFee]) {
    return (
      arr[0].status !== Statuses.DEAD &&
      arr[0].status !== Statuses.ARCHIVED &&
      arr[1].editable
    );
  }

  editDeleteGlobalFee(arr: [Deal, Profile]) {
    return (
      arr[0].status !== Statuses.DEAD &&
      arr[0].status !== Statuses.ARCHIVED &&
      arr[1]?.roles?.is_ot_admin
    );
  }
}
