import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Profile } from '@tenant/core';
import {
  TaskModel,
  TasksActionsService,
  TaskType,
  Progress,
} from '@tenant/tasks';
import * as moment from 'moment-mini-ts';
import { SubmissionStatuses } from '../../../deal/models/deal';

@Component({
  selector: 'ot-dash-tasks-item',
  templateUrl: './dash-tasks-item.component.html',
  styleUrls: ['./dash-tasks-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashTasksItemComponent implements AfterViewInit {
  public get check() {
    return this.task.progress === Progress.COMPLETE;
  }

  @Input() public type = '';
  @Input() public task: TaskModel;
  @Input() public deal: any;
  @Output() public taskUpdated = new EventEmitter<any>();
  @ViewChild('notification') public notification: TemplateRef<any>;

  public progress = Progress;
  public submissionStatuses = SubmissionStatuses;
  public taskType = TaskType;
  public user: Profile;
  constructor(private taskActions: TasksActionsService) {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  public ngAfterViewInit() {
    this.taskActions.template = this.notification;
  }

  public toggleComplete() {
    this.taskActions.toggleComplete(this.task).subscribe();
    this.taskUpdated.emit();
  }

  public undo($event, data) {
    this.taskActions.undo($event, data);
    this.taskUpdated.emit();
  }

  public edit($event) {
    if (this.task.progress !== Progress.ACTIVE) {
      return;
    }
    this.taskActions.edit($event, this.task, this.deal).subscribe(
      (res) => {
        this.taskUpdated.emit();
      },
      () => null
    );
  }

  public reopen($event) {
    if (this.task.disabled) {
      return;
    }
    this.taskActions.reopen($event, this.task);
  }

  public exempt($event) {
    if (this.task.disabled) {
      $event.preventDefault();
      return;
    }
    this.taskActions.exempt($event, this.task).subscribe(
      () => null,
      () => null
    );
  }

  public overdue() {
    return (
      moment(new Date()).isAfter(moment(this.task.due_date)) &&
      this.task.progress === Progress.ACTIVE
    );
  }
}
