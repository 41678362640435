import { Component } from '@angular/core';
import { flyInOut, flyInOutState } from '@tenant/ot';

@Component({
  selector: 'ot-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  animations: [flyInOut, flyInOutState]
})
export class NotificationsComponent {
  public showNotifications = false;

  public toggleNotifications($event) {
    $event.preventDefault();
    this.showNotifications = !this.showNotifications;
  }

  public closeNotification($event) {
    if (
      $event.target.localName !== 'circle' &&
      $event.target.localName !== 'path'
    ) {
      this.showNotifications = false;
    }
  }
}
