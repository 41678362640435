<div class="modal-header">
    <div class="modal-succeed-header">
        <h4 class="modal-succeed-title">Success</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(null)">
        <span aria-hidden="true" [inlineSVG]="'/assets/svg/close.svg'"></span>
    </button>
</div>
<div class="modal-body">
    <div inlineSVG="/assets/svg/succeed.svg" class="succeed-icon"></div>
    <p class="modal-body-text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat massa accumsan.
    </p>
</div>
  