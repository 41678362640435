import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// eslint-disable  @angular-eslint/directive-selector

@Directive({
  selector: 'input[type=file]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputFileDirective,
      multi: true
    }
  ]
})
export class InputFileDirective implements ControlValueAccessor {
  public onChange: any;
  public onTouched: any;

  constructor(private element: ElementRef, private render: Renderer2) {}

  @HostListener('change', ['$event.target.files'])
  public _handleInput(event) {
    // eslint-disable-line , , , 
    if (this.onChange) {
      this.onChange(event);
    }
    if (this.onTouched) {
      this.onTouched(event);
    }
    this.render.setProperty(this.element.nativeElement, 'value', '');
  }

  public writeValue(value: any) {
    const normalizedValue = '';
    // eslint-disable-line , 
    this.render.setProperty(
      this.element.nativeElement,
      'value',
      normalizedValue
    );
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
