import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'ot-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TagComponent {
  @Input() public option;
  @Output() public removeTag = new EventEmitter();

  public remove($event, item) {
    $event.preventDefault();
    this.removeTag.emit(item);
  }
}
