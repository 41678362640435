import { Type } from '@angular/core';
///<reference path="../dynamic-form.d.ts"/>
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { IDynamicField, IDynamicMeta } from '../dynamic-form';
import { ConditionQuery } from './condition-query';
import { Field } from './field.interface';
import { OtValidators } from '@tenant/helpers';

export class AbstractField implements IDynamicField {
  /**
   * @deprecated
   */
  get max() {
    return +this.meta.max;
  }

  // [key: string]: any;
  name: string;
  field_type_name: string;
  display_section: string;
  create_section: string;
  required: boolean;
  read_only: boolean;
  editable_on_show: boolean;
  order: number;
  meta: IDynamicMeta;
  default_order: number;
  label: string;
  hint: string;
  field: string;
  type: string;
  component: Type<Field>;
  conditions: ConditionQuery;
  value: any;
  validation: any;
  disabled: boolean;
  valueState = new BehaviorSubject(null);
  rerenderState = new Subject();
  readonlyState = new BehaviorSubject(false);
  options = [];

  constructor(field: IDynamicField, components: any = null) {
    this.label = field.label;
    this.hint = field.hint;
    this.field = field.field;
    this.display_section = field.display_section;
    this.create_section = field.create_section;
    this.type = field.type;
    this.editable_on_show = field.editable_on_show;
    this.meta = field.meta || {};
    this.read_only = !!field.read_only;
    this.required = !!field.required;
    if (!(field.conditions instanceof ConditionQuery)) {
      this.conditions = new ConditionQuery(field.conditions);
    } else {
      this.conditions = field.conditions;
    }
    this.valueState.next(this.meta.default_value);
  }

  setReadonly(val: boolean) {
    this.readonlyState.next(val);
  }

  setComponent(component: Type<Field>) {
    this.component = component;
    this.rerenderState.next(component);
  }

  public control(): AbstractControl {
    const validators = this.getValidators();
    this.validation = validators;
    return new FormControl(this.meta.default_value);
  }

  public getValidators() {
    const validators = [];
    if (this.required) {
      validators.push(Validators.required);
    }
    if (!isNaN(+this.meta.min)) {
      validators.push(Validators.minLength(+this.meta.min));
    }
    if (!isNaN(+this.meta.max)) {
      validators.push(Validators.maxLength(+this.meta.max));
    }
    if(this.meta.email) {
      validators.push(OtValidators.email);
    }
    return validators;
  }
}
