import { QueryList } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFieldDirective } from '../dynamic-field.directive';
import { AbstractField } from './abstract-field';
import { GroupField } from './group-field';

export abstract class Field {
  public config: AbstractField | GroupField;
  public group: FormGroup;
  public rootGroup: FormGroup;
  public errors: any = null;
  public readonly: any = false;
  public inputs?: QueryList<DynamicFieldDirective>;
}
