import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TaskModel } from '@tenant/tasks';
import { dynamicSort } from '@tenant/helpers';
import { TaskType, Progress } from '@tenant/tasks';

@Component({
  selector: 'ot-dash-tasks-list',
  templateUrl: './dash-tasks-list.component.html',
  styleUrls: ['./dash-tasks-list.component.scss'],
})
export class DashTasksListComponent implements OnChanges {
  @Input() public data: TaskModel[] = [];
  @Output() public tasksUpdate = new EventEmitter<string>();
  public dealOption: [{ value: number; label: string }];
  public status = 'active';
  public tasks: TaskModel[] = [];
  public documents: TaskModel[] = [];
  @Input() private deal;

  constructor(private cd: ChangeDetectorRef) {}

  public taskChanged() {
    this.tasksUpdate.emit('');
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      this.sortTasks();
    }
    if (changes.deal && changes.deal.currentValue) {
      this.dealOption = [
        { value: this.deal.id, label: this.prepareAddress(this.deal.address) },
      ];
    }
  }

  public toggleStatus($event) {
    $event.preventDefault();
    this.status = this.status === 'active' ? 'all' : 'active';
    this.sortTasks();
  }

  private sortTasks() {
    this.tasks = this.data
      .filter((i) => {
        return i.task_type === TaskType.TASK && this.stateFilter(i);
      })
      .sort(dynamicSort('order'));
    this.documents = this.data
      .filter((i) => {
        return i.task_type === TaskType.DOCUMENT && this.stateFilter(i);
      })
      .sort(dynamicSort('order'));
    this.cd.markForCheck();
  }

  private stateFilter(i: TaskModel) {
    if (this.status === 'active') {
      return i.progress === Progress.ACTIVE;
    }
    return true;
  }

  private prepareAddress(addr) {
    let addressLines = addr.address_line_1;
    if (addr.address_line_1) {
      addressLines = addr.address_line_1 + ' ' + addr.address_line_1;
    }
    const addressParts = [
      addressLines,
      addr.city,
      addr.state,
      addr.zip_code,
      addr.country,
    ];
    return addressParts.filter((v) => v && v.length > 0).join(', ');
  }
}
