import { ErrorHandler as AngularErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/browser';
import { environment } from '../environments/environment';

@Injectable()
export class SentryErrorHandler implements AngularErrorHandler {
  public constructor() {
    Sentry.configureScope((scope) => {
      scope.addEventProcessor((event) => {
        event.sdk = {
          ...event.sdk,
          name: 'sentry.javascript.angular',
          packages: [
            ...((event.sdk && event.sdk.packages) || []),
            {
              name: 'npm:@sentry/angular',
              version: Sentry.SDK_VERSION,
            },
          ],
          version: Sentry.SDK_VERSION,
        };

        return event;
      });
    });
  }

  /**
   * Method called for every value captured through the ErrorHandler
   */
  public handleError(error: any): void {
    const extractedError = this._extractError(error) || 'Handled unknown error';

    // Capture handled exception and send it to Sentry.
    if (environment.production || environment.ENV_NAME !== 'local') {
      Sentry.captureException(extractedError);
    }

    // When in development mode, log the error to console for immediate feedback.
    if (!environment.production || environment.ENV_NAME === 'local') {
      console.error(extractedError);
    }
  }

  /**
   * Default implementation of error extraction that handles default error wrapping, HTTP responses, ErrorEvent and few other known cases.
   */
  private _extractError(error) {
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string') {
      return error;
    }

    if (error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message || error.error || error;
    }

    // Skip if there's no error, and let user decide what to do with it.
    return null;
  }
}
