import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AutoUnsubscribe } from '@tenant/helpers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ot-file-dnd',
  templateUrl: './file-dnd.component.html',
  styleUrls: ['./file-dnd.component.scss']
})
@AutoUnsubscribe()
export class FileDndComponent implements OnInit {
  public get typeStr() {
    return this.allowedExtensions.join(', ');
  }

  public hover: boolean;
  public fileId = 'dropzone' + (+new Date() * Math.random()).toFixed(0);
  @Input() public allowedExtensions: any[] = [];
  @Input() public showTypes = false;
  public fileControl = new FormControl();
  @Output()
  private filesInvalidEmitter: EventEmitter<File[]> = new EventEmitter();
  @Output()
  private filesChangeEmitter: EventEmitter<File[]> = new EventEmitter();
  private fileChanges$: Subscription;

  public ngOnInit(): void {
    this.fileChanges$ = this.fileControl.valueChanges.subscribe((val) => {
      this.changeFile(val);
    });
  }

  @HostListener('dragover', ['$event'])
  public onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragenter', ['$event'])
  public onDragEnter(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.hover = true;
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.hover = false;
  }

  @HostListener('drop', ['$event'])
  public onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.hover = false;
    const files = evt.dataTransfer.files;
    this.processFiles(files);
  }

  public changeFile(files) {
    // eslint-disable-line , , , , 
    this.processFiles(files);
  }

  private processFiles(files: FileList) {
    const validFiles: File[] = [];
    const invalidFiles: File[] = [];
    if (files && files.length > 0) {
      [].forEach.call(files, (file: File) => {
        const ext = file.name.split('.')[file.name.split('.').length - 1];
        if (
          (ext &&
            this.allowedExtensions.lastIndexOf(ext?.toLowerCase()) !== -1) ||
          this.allowedExtensions.length === 0
        ) {
          validFiles.push(file);
        } else {
          invalidFiles.push(file);
        }
      });
      // eslint-disable-line , , , 
      this.filesChangeEmitter.emit(validFiles);
      this.filesInvalidEmitter.emit(invalidFiles);
    }
  }
}
