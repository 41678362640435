import { BaseDataSource } from './base.data-source';
import { LocalFilter } from './local/local.filter';
import { LocalPager } from './local/local.pager';
import { LocalSorter } from './local/local.sorter';

export class LocalDataSource extends BaseDataSource {
  protected loadMore = false;
  protected data: any[] = [];
  protected filteredAndSorted: any[] = [];
  protected sortConf: any[] = [];
  protected filterConf = {
    filters: [],
  };
  protected pagingConf = {
    page: 1,
    perPage: 15,
  };

  constructor(data: any[] = [], loadMore = false) {
    super();
    this.loadMore = loadMore;

    this.data = data;
  }

  public getCurrentPage() {
    return this.pagingConf.page;
  }

  public load(data: any[]): Promise<any> {
    this.data = data;
    return super.load(data);
  }

  public find(element: any): Promise<any> {
    const found = this.data.find((el) => el === element);
    if (found) {
      return Promise.resolve(found);
    }

    return Promise.reject(new Error('Element was not found in the dataset'));
  }

  public getElements(): Promise<any> {
    const data = this.data.slice(0);
    return Promise.resolve(this.prepareData(data));
  }

  public getAll(): Promise<any> {
    const data = this.data.slice(0);
    return Promise.resolve(data);
  }

  public reset(silent = false): void {
    if (silent) {
      this.filterConf = {
        filters: [],
      };
      this.sortConf = [];
      this.pagingConf['page'] = 1;
    } else {
      this.setFilter([], false);
      this.setSort([], false);
      this.setPage(1);
    }
  }

  public empty(doEmit?: boolean): Promise<any> {
    this.data = [];

    return super.empty(doEmit);
  }

  public count(): number {
    return this.filteredAndSorted.length;
  }

  /**
   *
   * Array of conf objects
   * [
   *  {field: string, direction: asc|desc|null, compare: Function|null},
   * ]
   * @param conf
   * @param doEmit
   * @returns {LocalDataSource}
   */
  public setSort(conf: any, doEmit = true): LocalDataSource {
    if (conf !== null) {
      this.sortConf = conf;
    }
    super.setSort(conf, doEmit);
    return this;
  }

  /**
   *
   * Array of conf objects
   * [
   *  {field: string, search: string, filter: Function|null},
   * ]
   * @param conf
   * @param doEmit
   * @returns {LocalDataSource}
   */
  public setFilter(conf: any[], doEmit = true): LocalDataSource {
    if (conf && conf.length > 0) {
      this.filterConf = {
        filters: [],
      };
      conf.forEach((fieldConf) => {
        this.addFilter(fieldConf.key, fieldConf.value, false);
      });
    } else {
      this.filterConf = {
        filters: [],
      };
    }
    this.pagingConf['page'] = 1;
    super.setFilter(conf, doEmit);
    return this;
  }

  public addFilter(key, value = null, doEmit: boolean = true): LocalDataSource {
    const fieldConf = { key, value };
    let found = false;
    this.filterConf.filters.forEach((currentFieldConf, index) => {
      if (currentFieldConf['key'] === key) {
        this.filterConf.filters[index].value = value;
        found = true;
      }
    });
    if (!found) {
      this.filterConf.filters.push(fieldConf);
    }
    super.addFilter(key, value, doEmit);
    return this;
  }

  public setPaging(
    page: number,
    perPage: number,
    doEmit: boolean = true
  ): LocalDataSource {
    this.pagingConf['page'] = page;
    this.pagingConf['perPage'] = perPage;

    super.setPaging(page, perPage, doEmit);
    return this;
  }

  public setPage(page: number, doEmit: boolean = true): LocalDataSource {
    this.pagingConf['page'] = page;
    super.setPage(page, doEmit);
    return this;
  }

  public getSort(): any {
    return this.sortConf;
  }

  public getFilter(): any {
    return this.filterConf;
  }

  public getPaging(): any {
    return this.pagingConf;
  }

  protected prepareData(data: any[]): any[] {
    data = this.filter(data);
    data = this.sort(data);
    this.filteredAndSorted = data.slice(0);

    return this.paginate(data);
  }

  protected sort(data: any[]): any[] {
    return data;
  }

  protected filter(data: any[]): any[] {
    return data;
  }

  protected paginate(data: any[]): any[] {
    if (
      this.pagingConf &&
      this.pagingConf['page'] &&
      this.pagingConf['perPage']
    ) {
      data = LocalPager.paginate(
        data,
        this.pagingConf['page'],
        this.pagingConf['perPage'],
        this.loadMore
      );
    }
    return data;
  }
}
