import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';
// eslint-disable  @angular-eslint/directive-selector
let nextUniqueId = 0;

@Directive({
  selector: 'ot-form-error'
})
export class OtFormErrorDirective {
  @HostBinding('attr.id')
  @Input()
  public id = `ot-error-${nextUniqueId++}`;
  @HostBinding('class.d-none') public hidden = true;
  @Input() public key;

  @HostBinding('class.text-danger') protected className = true;
  @HostBinding('attr.role') protected role = 'alert';
  @Output() private remove = new EventEmitter();

  constructor(public element: ElementRef) {}

  public destroy() {
    this.remove.emit(this.id);
  }
}
