<ot-typeahead
  [noValidate]="true"
  [search]="searchFn"
  [placeholder]="'Search by Deal Address or Contact'"
  [className]="'header-search'"
  [searchTemplate]="headerSearch"
>
</ot-typeahead>

<ng-template #headerSearch let-typeahead="typeahead">
  <ng-content [select]="additionalContent"></ng-content>
  <div
    class="dropdown-menu search-dropdown"
    *ngIf="deals?.count > 0 || contacts?.count > 0 || invoices?.count > 0"
    aria-labelledby="dropdownManual"
  >
    <ng-container *ngIf="deals?.count > 0">
      <button class="dropdown-item search-title">
        Deals
        <ng-container>
          ({{
            deals?.limit + deals?.offset < deals?.count
              ? deals.limit + deals?.offset
              : deals?.count
          }}
          of {{ deals?.count }})</ng-container
        >
      </button>
      <button
        class="dropdown-item"
        *ngFor="let item of deals.data"
        (click)="typeahead.hideDropdown()"
        [routerLink]="['/deal', item.id]"
      >
        <span
          class="address"
          *ngIf="item.address"
          [innerHTML]="typeahead.highlight(item.address | address)"
        ></span>
        <span class="type-status"
          >{{ item.deal_type | dealType }} /
          {{ item.status | dealStatus }}</span
        >
      </button>
      <a
        class="toggle-link"
        href=""
        [ngClass]="{
          'display-none': deals?.limit + deals?.offset > deals?.count
        }"
        (click)="loadMoreDeals($event)"
      >
        Load More</a
      >
    </ng-container>
    <ng-container *ngIf="contacts?.count > 0">
      <button class="dropdown-item search-title">
        Contacts
        <ng-container>
          ({{
            contacts?.limit + contacts?.offset < contacts?.count
              ? contacts.limit + contacts?.offset
              : contacts?.count
          }}
          of {{ contacts?.count }})</ng-container
        >
      </button>
      <a
        class="dropdown-item"
        *ngFor="let item of contacts.data"
        (click)="typeahead.hideDropdown()"
        href="{{
          environment.FULTONGRACE_URL + '/account/clients/contacts/' + item.id
        }}"
        target="{{ width > 900 ? '_blank' : '_self' }}"
      >
        <span
          *ngIf="item.name"
          [innerHTML]="typeahead.highlight(item.name)"
        ></span>
      </a>
      <a
        class="toggle-link"
        href=""
        [ngClass]="{
          'display-none': contacts?.limit + contacts?.offset > contacts?.count
        }"
        (click)="loadMoreContacts($event)"
      >
        Load More</a
      >
    </ng-container>
    <ng-container *ngIf="invoices?.count > 0">
      <button class="dropdown-item search-title">
        Invoices
        <ng-container>
          ({{
            invoices?.limit + invoices?.offset < invoices?.count
              ? invoices.limit + invoices?.offset
              : invoices?.count
          }}
          of {{ invoices?.count }})</ng-container
        >
      </button>
      <button
        class="dropdown-item"
        *ngFor="let item of invoices.data"
        (click)="typeahead.hideDropdown()"
        [routerLink]="['/deal', item.id]"
      >
        <span
          *ngIf="item.address"
          [innerHTML]="typeahead.highlight(item.invoice)"
        ></span>
        <span>{{ item.invoice }}</span>
      </button>
      <a
        class="toggle-link"
        href=""
        [ngClass]="{
          'display-none': invoices?.limit + invoices?.offset > invoices?.count
        }"
        (click)="loadMoreInvoices($event)"
      >
        Load More</a
      >
    </ng-container>
  </div>
</ng-template>
