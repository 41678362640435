import {
  CdkTableModule,
  _COALESCED_STYLE_SCHEDULER,
  _CoalescedStyleScheduler,
  
} from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OtModule } from '@tenant/ot';
import {
  OtCell,
  OtCellDef,
  OtColumnDef,
  OtHeaderCell,
  OtHeaderCellDef,
} from './cell';
import { FilterContainerComponent } from './filters/filter-container/filter-container.component';
import { FilterDirective } from './filters/filter.directive';
import { OtHeaderRow, OtHeaderRowDef, OtRow, OtRowDef } from './row';
import { OtDataTable } from './table';
import { TableWrapperComponent } from './table-wrapper/table-wrapper.component';
import {
  _DisposeViewRepeaterStrategy,
  _VIEW_REPEATER_STRATEGY,
} from '@angular/cdk/collections';

const COMPONENTS = [
  FilterContainerComponent,
  FilterDirective,
  TableWrapperComponent,
  OtDataTable,
  OtCellDef,
  OtHeaderCellDef,
  OtColumnDef,
  OtHeaderCell,
  OtCell,
  OtHeaderRow,
  OtRow,
  OtHeaderRowDef,
  OtRowDef,
];

@NgModule({
  imports: [CommonModule, OtModule, CdkTableModule],
  exports: [COMPONENTS],
  declarations: [COMPONENTS],
  providers: [
    { provide: _VIEW_REPEATER_STRATEGY, useClass: _DisposeViewRepeaterStrategy },
    { provide: _COALESCED_STYLE_SCHEDULER, useClass: _CoalescedStyleScheduler },
  ],
})
export class DataTableModule {}
