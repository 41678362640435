import { Pipe, PipeTransform } from '@angular/core';
import heic2any from 'heic2any';

@Pipe({
  name: 'officeTroopImage',
})
export class OfficeTroopImagePipe implements PipeTransform {

  async transform(img: string) {
    let file: any = img;
    if (/.hei(c|f)/.test(img)) {
      await fetch(img)
        .then((res) => res.blob())
        .then((blob) => heic2any({ blob, quality: 0.5 }))
        .then(async (conversionResult: Blob) => {
          file = await new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(conversionResult);
          });
        })
        .catch((e) => {});
    }
    return file;
  }
}
