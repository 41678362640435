<div class="wrapper ot-multiselect">
  <label *ngIf="label" class="label">
    <span> {{ label }}</span>
    <span
      *ngIf="tip"
      inlineSVG="/assets/svg/question-mark-desktop.svg"
      ngbPopover="{{ tip }}"
      triggers="hover"
      class="tip"
    ></span
  ></label>
  <div>
    <ng-select
      (close)="searchControl.reset(); selectComponent.close()"
      [bindLabel]="bindLabel"
      [bindValue]="bindValue"
      (ngModelChange)="onModelChange($event)"
      [(ngModel)]="value"
      [clearable]="true"
      [closeOnSelect]="false"
      [loading]="loadingState"
      [multiple]="true"
      [searchable]="false"
      [trackByFn]="trackByFn"
      [items]="items"
      [placeholder]="placeholder"
      [disabled]="disabled"
      [dropdownPosition]="dropdownPosition"
    >
      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <ot-checkbox2
          id="item-{{ index }}"
          class="size-sm"
          [ngModel]="item$.selected"
        ></ot-checkbox2>

        <!--        <input-->
        <!--          id="item-{{ index }}"-->
        <!--          type="checkbox"-->
        <!--          [ngModel]="item$.selected"-->
        <!--        />-->
        <span>{{ item.name }}</span>
      </ng-template>

      <ng-template ng-label-tmp let-item="item">
        {{ item.name }}
        <span
          class="comma"
          *ngIf="
            value && value.length > 1 && value[value.length - 1] !== item.id
          "
          >,
        </span>
      </ng-template>
    </ng-select>
  </div>
</div>
