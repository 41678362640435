<div class="leaderboard-header">
  <div class="name">Agent Leaderboard - This Month</div>
  <div class="legend">
    <div class="legend-rental">
      <span [style.background-color]="colors?.rental"></span>
      Rental
    </div>
    <div class="legend-sale">
      <span [style.background-color]="colors?.sales"></span>
      Sale
    </div>
  </div>
</div>

<ot-table [hideMeta]="true" [config]="config"></ot-table>
<ng-template #grossCommissionTemplate let-row="row">
  <div class="d-flex align-items-center">
    <span class="comission-value">{{row.gross_volume | currency:'USD':'symbol':'1.0-0'}}</span>

    <div class="gross-wrapper">
      <div class="gross-line" [style.width.%]="row.totalPercents" [style.max-width.%]="row.totalPercents">
        <div class="gross-item gross-rental" [style.background-color]="colors?.rental"
          [style.flex-basis.%]="row.rentalPercents"></div>
        <div class="gross-item gross-sales" [style.background-color]="colors?.sales"
          [style.flex-basis.%]="row.salesPercents"></div>
      </div>
    </div>
  </div>


</ng-template>
<ng-template #currencyCell let-row="row" let-cell="cell">
  {{row[cell.field] | currency:'USD':'symbol':'1.0-0'}}
</ng-template>