import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  NgbCalendar,
  NgbDateStruct,
  NgbDropdown,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-mini-ts';
import {
  BehaviorSubject,
  fromEvent as observableFromEvent,
  Subscription,
} from 'rxjs';

import { debounceTime } from 'rxjs/operators';

/**
 *
 * @param {NgbDateStruct} one
 * @param {NgbDateStruct} two
 */
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one &&
  two &&
  two.year === one.year &&
  two.month === one.month &&
  two.day === one.day;

/**
 *
 * @param {NgbDateStruct} one
 * @param {NgbDateStruct} two
 */
const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day < two.day
      : one.month < two.month
    : one.year < two.year;

/**
 *
 * @param {NgbDateStruct} one
 * @param {NgbDateStruct} two
 */
const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day > two.day
      : one.month > two.month
    : one.year > two.year;

@Component({
  selector: 'ot-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent implements OnInit, OnDestroy, OnChanges {
  public get controlField() {
    return this.control.value ? this.control.value.field : 'all';
  }

  public get current() {
    const found = this.variants.find((v) => v.type === this.controlField);
    if (found) {
      if (found.type === 'custom') {
        const { from, to } = this.formatFromTo('MMM DD, YYYY');
        return `${from} - ${to}`;
      }
      return found.label;
    }
    return 'Undefined';
  }

  public hoveredDate: NgbDateStruct;
  public fromDate: NgbDateStruct;
  public toDate: NgbDateStruct;
  public today: NgbDateStruct;
  public showDatePicker = false;
  public datePickerDisplayMonths$: BehaviorSubject<number> =
    new BehaviorSubject(2);
  @Input() public control;
  @Input() public hideOptions = [];
  @Input() formatDateTime = null;
  @Input() public customParams = null;
  @ViewChild(NgbDropdown) public dropdown;
  public variants = [
    { label: 'This Month', type: 'this_month', key: 'month', sub: 0 },
    { label: 'Last Month', type: 'last_month', key: 'month', sub: 1 },
    { label: 'This Quarter', type: 'this_quarter', key: 'quarter', sub: 0 },
    { label: 'Last Quarter', type: 'last_quarter', key: 'quarter', sub: 1 },
    { label: 'This Year', type: 'this_year', key: 'year', sub: 0 },
    { label: 'Last Year', type: 'last_year', key: 'year', sub: 1 },
    { label: 'All Time', type: 'all', key: 'all', sub: 0 },
    { label: 'Custom', type: 'custom', key: 'custom', sub: 0 },
  ];
  public initVariants = [
    { label: 'This Month', type: 'this_month', key: 'month', sub: 0 },
    { label: 'Last Month', type: 'last_month', key: 'month', sub: 1 },
    { label: 'This Quarter', type: 'this_quarter', key: 'quarter', sub: 0 },
    { label: 'Last Quarter', type: 'last_quarter', key: 'quarter', sub: 1 },
    { label: 'This Year', type: 'this_year', key: 'year', sub: 0 },
    { label: 'Last Year', type: 'last_year', key: 'year', sub: 1 },
    { label: 'All Time', type: 'all', key: 'all', sub: 0 },
    { label: 'Custom', type: 'custom', key: 'custom', sub: 0 },
  ];
  private _valueChangesSubscription: Subscription;

  constructor(private calendar: NgbCalendar) {}

  public isHovered(date) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      after(date, this.fromDate) &&
      before(date, this.hoveredDate)
    );
  }

  public isInside(date) {
    return after(date, this.fromDate) && before(date, this.toDate);
  }

  public isFrom(date) {
    return equals(date, this.fromDate);
  }

  public isTo(date) {
    return equals(date, this.toDate);
  }

  public select(variant) {
    let date = moment();
    this.showDatePicker = false;
    date.startOf('day');
    let from: any = date;
    let to: any = date;
    switch (variant.key) {
      case 'all':
        from = null;
        to = null;
        this.dropdown.close();
        break;
      case 'custom':
        this.showDatePicker = true;
        break;
      default:
        if (variant.sub) {
          date = date.subtract(variant.sub, variant.key);
        }
        from = date.startOf(variant.key).format(this.formatDateTime);
        to = date.endOf(variant.key).endOf('day').format(this.formatDateTime);
        this.dropdown.close();
    }
    if (variant.key !== 'custom') {
      this.control.setValue(
        { field: variant.type, from, to, customParams: this.customParams },
        true
      );
    }
  }

  public ngOnInit() {
    // this.fromDate = this.calendar.getToday();
    // this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 10);
    if (!this.control) {
      this.control = new FormControl({ field: 'all' });
    }

    if (this.hideOptions && this.hideOptions.length > 0) {
      this.filterVariants();
    }

    this.initDatepickerUpdateListener();
    this.initToday();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hideOptions) {
      this.filterVariants();
    }
    this.subscribeToChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribe();
  }

  public filterVariants(): void {
    let initalVariants = this.initVariants;
    this.variants = initalVariants.filter(
      (item) => this.hideOptions.indexOf(item.type) === -1
    );
  }

  public onDateChange(date: NgbDateStruct) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    if (this.toDate && this.fromDate) {
      const { from, to } = this.formatFromTo();
      this.showDatePicker = false;
      this.dropdown.close();

      this.control.setValue({
        field: 'custom',
        from,
        to,
        customParams: this.customParams,
      });
    }
  }

  public closeDatePicker(event) {
    if (event?.target?.className?.indexOf('custom-day') <= -1) {
      this.showDatePicker = false;
      setTimeout(() => this.close());
    }
  }

  public close() {
    if (this.showDatePicker === false) {
      this.dropdown.close();
    }
  }

  public isToday(date: NgbDateStruct): boolean {
    if (!this.today) return false;
    return (
      date.year === this.today.year &&
      date.month === this.today.month &&
      date.day === this.today.day
    );
  }

  private formatFromTo(format = null) {
    const strToDT = (str) => {
      const tmp = moment(str);
      return { year: tmp.year(), month: tmp.month() + 1, day: tmp.date() };
    };

    let fromDate = { ...this.fromDate };
    let toDate = { ...this.toDate };

    if (this.control.value.from && !(this.fromDate && this.fromDate.year)) {
      fromDate = strToDT(this.control.value.from);
    }
    if (this.control.value.to && !(this.fromDate && this.fromDate.year)) {
      toDate = strToDT(this.control.value.to);
    }

    fromDate.month = fromDate.month - 1;
    toDate.month = toDate.month - 1;
    const fromMoment = moment(fromDate)
      // .utc()
      .startOf('day');
    const toMoment = moment(toDate)
      // .utc()
      .endOf('day');
    let from = fromMoment.format(this.formatDateTime);
    let to = toMoment.format(this.formatDateTime);
    if (format) {
      from = fromMoment.format(format);
      to = toMoment.format(format);
    }
    return { from, to };
  }

  // FIXME[Dmitry Teplov] remove unused subscription?
  private subscribeToChanges() {
    this.unsubscribe();
    if (this.control) {
      this._valueChangesSubscription = this.control.valueChanges.subscribe(
        (v) => {}
      );
    }
  }

  private unsubscribe() {
    if (this._valueChangesSubscription) {
      this._valueChangesSubscription.unsubscribe();
    }
  }

  private updateDatePickerDisplayMonths(width: number) {
    if (width < 575) {
      this.datePickerDisplayMonths$.next(1);
    } else if (this.datePickerDisplayMonths$.value !== 2) {
      this.datePickerDisplayMonths$.next(2);
    }
  }

  private initDatepickerUpdateListener() {
    this.updateDatePickerDisplayMonths(document.body.clientWidth);
    observableFromEvent(window, 'resize')
      .pipe(debounceTime(300))
      .subscribe(() =>
        this.updateDatePickerDisplayMonths(document.body.clientWidth)
      );
  }

  private initToday(): void {
    const now = new Date();
    this.today = {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate(),
    };
  }
}
