import {
  AfterContentInit,
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  HostBinding,
  Optional,
  Renderer2,
  Self
} from '@angular/core';
import { AutoUnsubscribe } from '@tenant/helpers';
import { ConditionService } from './condition.service';
import { DynamicFieldDirective } from './dynamic-field.directive';
import { Field } from './models/field.interface';

@Directive({
  selector: '[otDynamicCondition]',
  providers: [ConditionService]
})
@AutoUnsubscribe()
export class DynamicConditionDirective implements AfterContentInit {
  @HostBinding('class.hidden') public hidden = false;

  constructor(
    @Self()
    @Optional()
    private field: DynamicFieldDirective,
    private cond: ConditionService,
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef
  ) {}

  public ngAfterContentInit(): void {
    if (this.field && this.field.component && this.field.rootGroup) {
      const cmp = this.field.component.instance;

      const conditions = cmp.config.conditions;

      const root = this.field.rootGroup;

      this.cond.state.subscribe((result) => {
        if (result) {
          this.disable(this.field.component);
        } else {
          this.enable(this.field.component);
        }
        this.cdRef.detectChanges();
      });
      this.cond.subscribe(conditions, root);
    }
  }

  private enable(cmp: ComponentRef<Field>) {
    this.renderer.removeStyle(cmp.location.nativeElement, 'display');
    this.hidden = true;
    cmp.instance.group.get(cmp.instance.config.field).enable();
  }

  private disable(cmp: ComponentRef<Field>) {
    this.renderer.setStyle(cmp.location.nativeElement, 'display', 'none');
    this.hidden = false;
    cmp.instance.group.get(cmp.instance.config.field).disable();
  }
}
