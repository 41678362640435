<div [class]="'form-group ' + className" [class.has-danger]="isInvalid" [class.has-success]="isSuccess">
  <label [for]="name" *ngIf="label && hideLabel == false">
    {{label}}

  </label>
  <div ngbDropdown #myDrop="ngbDropdown" [autoClose]="false">
    <input #input [class.form-control-success]="isSuccess" [class.form-control-danger]="isInvalid"
           [readonly]="readOnly" [placeholder]="placeholder" autocomplete="off" (focus)="showDropdown()"
           class="form-control" [formControl]="inputControl" [id]="name" [name]="name" [type]="type">
    <ng-container *ngTemplateOutlet="searchTemplate || search; context: ctx"></ng-container>
    <ng-template #search>
      <div class="dropdown-menu" aria-labelledby="dropdownManual" *ngIf="data.length > 0">
        <button class="dropdown-item" *ngFor="let item of data" (click)="selectItem(item)">{{ toDisplay(item) }}
        </button>
      </div>
    </ng-template>
  </div>
  <div class="form-control-feedback" *ngIf="isInvalid">{{ actualErrors }}</div>
  <div class="help-block" *ngIf="helpText">
    <p class="text-info">{{ helpText }}</p>
  </div>
</div>
