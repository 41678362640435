import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AutoUnsubscribe } from '@tenant/helpers';
import { deepCompare } from '@tenant/ot';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, map, takeWhile, tap } from 'rxjs/operators';
import { FilterDirective } from '../filter.directive';
import { Router } from '@angular/router';

@Component({
  selector: 'ot-filter-container',
  templateUrl: './filter-container.component.html',
  styleUrls: ['./filter-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe()
export class FilterContainerComponent
  implements AfterContentInit, OnDestroy, AfterViewInit
{
  get restoredFiters(): any {
    return this._restoredFiters;
  }

  set restoredFiters(value: any) {
    this._restoredFiters = value;
    if (this.fg) {
      this.fg.patchValue(value);
      this.cd.detectChanges();
    }
  }
  public fg = new FormGroup({});
  public filterChanged = false;
  @ContentChildren(FilterDirective) filters: QueryList<FilterDirective>;
  @Output() filterChange: Subject<any> = new Subject();
  @ViewChild('container', { read: ViewContainerRef })
  private container: ViewContainerRef;
  private initial: any = {};
  private destroy: any;
  private changeSubscription$: Subscription;
  private _restoredFiters = {};

  constructor(private cd: ChangeDetectorRef, private readonly router: Router) {}

  public ngOnDestroy(): void {
    this.destroy = true;
  }

  public ngAfterViewInit(): void {
    this.init();

    this.filters.changes.pipe(takeWhile(() => !this.destroy)).subscribe(() => {
      this.init();
    });
  }

  public ngAfterContentInit() {
    // this.initial = this.fg.value;
  }

  public clear($event) {
    $event.preventDefault();
    this.fg.patchValue(this.initial);
  }

  private init() {
    this.container?.clear();
    this.filters.forEach((v) => {
      if (!this.fg.get(v.name)) {
        this.fg.setControl(v.name, new FormControl(v.defaultValue));
        this.initial[v.name] = v.defaultValue;

        if (v.name === 'status') {
          if (this.router.url !== '/deal') {
            this.fg.setControl(v.name, new FormControl(null));
            this.initial[v.name] = v.defaultValue;
          }
        }
      }
      this.container?.createEmbeddedView(v.template, {
        $implicit: {
          control: this.fg.get(v.name),
        },
      });
      this.cd.detectChanges();
    });
    if (this._restoredFiters) {
      this.fg.patchValue(this._restoredFiters);
      // @ts-ignore
      this.filterChanged = Object.values(this._restoredFiters).length > 0;
    }
    if (this.changeSubscription$) {
      this.changeSubscription$.unsubscribe();
    }
    this.changeSubscription$ = this.fg.valueChanges
      .pipe(
        takeWhile(() => !this.destroy),
        debounceTime(300),
        tap((v) => {
          this.filterChanged = deepCompare(this.initial, v) === false;
        }),
        map((v: any) => {
          const res = {};
          for (const key in v) {
            if (v.hasOwnProperty(key)) {
              if (
                v[key] != null &&
                (typeof v[key] !== 'string' || v[key].length > 0)
              ) {
                res[key] = v[key];
              }
            }
          }
          return res;
        })
      )
      .subscribe((v) => {
        this.filterChange.next(v);
        this.cd.detectChanges();
      });
    this.cd.markForCheck();
  }
}
