import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@tenant/ot';
import { InlineSVGModule } from 'ng-inline-svg';
import { TagInputComponent } from './tag-input.component';
import { TagComponent } from './tag/tag.component';
import { TagInputDirective } from './tag-input.directive';

const COMPONENTS = [TagComponent, TagInputComponent, TagInputDirective];

@NgModule({
  imports: [
    CommonModule,
    InlineSVGModule,
    ReactiveFormsModule,
    FormsModule,
    PipesModule
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS]
})
export class TagInputModule {}
