/* tslint:disable */
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export class Mask {
  public static CARD = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  public static phoneMaskFnc = (userInput) => {
    const numbers = userInput.match(/\d/g);
    let numberLength = 0;
    if (numbers) {
      numberLength = numbers.join('').length;
    }
    if (numberLength > 10) {
      return [
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        'e',
        'x',
        't',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    } else {
      return [
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    }
  };

  public static PHONE = {
    mask: Mask.phoneMaskFnc,
    guide: false,
    modelClean: true,
  };

  public static amountMaskFnc = createNumberMask({
    allowDecimal: true,
    prefix: '$ ',
    suffix: '',
  });
  public static AMOUNT = {
    mask: Mask.amountMaskFnc,
    guide: false,
    modelClean: true,
  };
  public static numberWithSeparate = createNumberMask({
    allowDecimal: true,
    prefix: '',
    suffix: '',
  });
  public static NUMBER_WITH_SEPARATOR = {
    mask: Mask.numberWithSeparate,
    guide: false,
    modelClean: true,
  };
  public static NUMBER_WITHOUT_SEPARATOR = {
    mask: createNumberMask({
      allowDecimal: true,
      prefix: '',
      suffix: '',
      thousandsSeparatorSymbol: '',
    }),
    guide: false,
    modelClean: true,
  };

  public static amountMaskFncWidthotDecimal = createNumberMask({
    allowDecimal: false,
    prefix: '$ ',
    suffix: '',
  });
  public static AMOUNT_WITHOUT_DECIMAL = {
    mask: Mask.amountMaskFncWidthotDecimal,
    guide: false,
    modelClean: true,
  };
  public static persentsMaskFnc = createNumberMask({
    allowDecimal: true,
    prefix: '',
    suffix: '%',
    integerLimit: 2,
  });
  public static PERCENTS = {
    mask: Mask.persentsMaskFnc,
    guide: true,
    modelClean: true,
  };

  public static NUMBER = {
    mask: createNumberMask({
      allowDecimal: true,
      prefix: '',
      suffix: '',
    }),
    guide: false,
    modelClean: true,
  };

  public static PERCENTS_WITHOUT_P = {
    mask: createNumberMask({
      allowDecimal: true,
      prefix: '',
      suffix: '',
      integerLimit: 2,
    }),
    guide: false,
    modelClean: true,
  };

  public static PHONE_WITHOUT_EXT = {
    mask: [
      '(',
      /[0-9]/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    guide: false,
    modelClean: true,
  };
  public static SSN = {
    mask: [
      /[0-9X]/,
      /[0-9X]/,
      /[0-9X]/,
      '-',
      /[0-9X]/,
      /[0-9X]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
    ],
    guide: true,
    modelClean: true,
  };
  public static expMask = {
    mask: [/\d/, /\d/, '/', /\d/, /\d/],
    guide: true,
    modelClean: true,
  };
}
