<ng-content select=".ot-table-header"></ng-content>
<div class="table-filter-container ">
  <ot-table-filters *ngIf="config && config?.filters.length > 0" [filters]="config.filters"
    (filterChange)="processFilters($event)"></ot-table-filters>
    <ng-content select=".ot-table-additional-content"></ng-content>
</div>
<div class="meta-block" *ngIf="collection && !hideMeta && meta">
  <ng-container *ngTemplateOutlet="metaTemplate || metaDefault; context: {meta: meta }"></ng-container>
</div>

<div class="table-content" *ngIf="config">

  <div [ngBusy]="loading" class="loader"></div>
  <table class="ot-table">
    <thead *ngIf="!useAlternativeTemplate">
      <tr>
        <th *ngIf="checkbox && checkbox.enable" class="checkbox-col">
          <!--<input type="checkbox" [checked]="collection?.data.length === checked.length" (click)="setAllChecked()">-->
          <ng-container *ngTemplateOutlet="checkbox.templateHead || checkboxHeaderDefault"></ng-container>
        </th>
        <ng-template ngFor let-cell [ngForOf]="tableCells">
          <ng-template [ngxPermissionsOnly]="cell.permissions">
            <th [ngStyle]="cell.headerStyle">
              <div class="cell-header-wrapper" [class.sortable]="cell.sortable" (click)="toggleSort(cell)">
                <div>
                  <ng-container *ngTemplateOutlet="cell.headerTemplate || headerDefault; context: {cell: cell}">
                  </ng-container>
                </div>
                <ng-container *ngIf="cell.sortable">
                  <div class="position-relative">
                    <span inlineSVG="/assets/svg/sort-asc.svg" class="asc"
                      [class.active]="sort.field == cell.sortableKey && sort.direction == 'asc'" alt="asc"></span>
                    <span inlineSVG="/assets/svg/sort-desc.svg" class="desc"
                      [class.active]="sort.field == cell.sortableKey && sort.direction == 'desc'" alt="desc"></span>
                  </div>
                </ng-container>
              </div>
            </th>
          </ng-template>
        </ng-template>
      </tr>
    </thead>
    <tbody *ngIf="useAlternativeTemplate !== true">
      <tr *ngFor="let row of collection?.data">
        <td *ngIf="checkbox && checkbox.enable" [ngStyle]="checkbox.checkboxStyle" class="checkbox-row">
          <ng-container *ngTemplateOutlet="checkbox.templateCell || checkboxDefault; context:{row:row}"></ng-container>
        </td>
        <ng-template ngFor let-cell [ngForOf]="tableCells">
          <ng-template [ngxPermissionsOnly]="cell.permissions">
            <td [ngStyle]="cell.cellStyle">
              <ng-container
                *ngTemplateOutlet="cell.cellTemplate || cellDefault; context: {cell: cell, row: row, meta: meta}">
              </ng-container>
            </td>
          </ng-template>
        </ng-template>
      </tr>
    </tbody>
  </table>
  <ng-content select=".mobile-table" *ngIf="!useAlternativeTemplate"></ng-content>
  <div *ngIf="useAlternativeTemplate === true">
    <!--<ng-container-->
    <!--*ngTemplateOutlet="alternativeTemplate; context: {collection: collectionSize, cells: tableCells}"></ng-container>-->
    <ng-content select=".alternative-content"></ng-content>
  </div>
  <ng-content select=".no-table-content" *ngIf="collection?.data?.length == 0"></ng-content>
</div>
<div class="next-page" *ngIf="hasNextPage">
  <button class="btn-load-more" (click)="loadMore()">Load More</button>
</div>

<ng-template #headerDefault let-c="cell">
  {{ c.label }}
</ng-template>

<ng-template #cellDefault let-row="row" let-c="cell">
  {{ row[c.field] }}
</ng-template>

<ng-template #metaDefault let-meta="meta">
  <div class="meta-pages" *ngIf="meta">Showing {{meta.visible}} out of {{meta.total}}</div>
</ng-template>

<ng-template #checkboxHeaderDefault>
  <!--<input type="checkbox" [checked]="collection?.data.length === checked.length" (click)="setAllChecked()">-->
  <ot-checkbox2 [ngModel]="collection?.data.length != 0 && collection?.data.length === checked.length"
    (ngModelChange)="setAllChecked()"></ot-checkbox2>
</ng-template>

<ng-template #checkboxDefault let-row="row">
  <ot-checkbox2 [ngModel]="markChecked(row.id)" (ngModelChange)="getCheckboxValue(row.id)"
    [disabled]="checkbox?.disabledIf ? checkbox?.disabledIf(row) : false" [value]="row.id"></ot-checkbox2>
  <!--<input type="checkbox" [name]="'checkbox-' + row.id" [checked]="markChecked(row.id)" [value]="row.id" (change)="getCheckboxValue(row.id)">-->
</ng-template>

<ng-template #checkMeta let-meta="meta">
  <div class="checkbox-meta" *ngIf="meta">
    <button class="btn btn-primary" [disabled]="checked.length === 0" (click)="emitChecked()">123</button>
    <div class="meta-pages">Showing {{meta.visible}} out of {{meta.count}}</div>
  </div>
</ng-template>
