import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { createAutoCorrectedDatePipe } from 'text-mask-addons/dist/textMaskAddons';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export const DATE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateComponent), // eslint-disable-line
  multi: true,
};

@Component({
  selector: 'ot-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  providers: [DATE_VALUE_ACCESSOR],
  encapsulation: ViewEncapsulation.None,
})
export class DateComponent implements ControlValueAccessor, OnInit {
  private _value;

  public get value(): any {
    return this._value;
  }

  public set value(value: any) {
    if (this.value === value) {
      return;
    }
    this._value = value;
    if (this.onChange) {
      this.onChange(value);
    }
    if (value && this.onTouched) {
      this.onTouched();
    }
  }

  public today: NgbDateStruct;
  public datePickerModel;
  public showDatePicker;
  @Input() public control: FormControl = new FormControl();
  @Input() public required = false;
  @Input() public minDate = null;
  @Input() public maxDate = null;
  @Input() public readonly = false;
  @Input() public placeholder = 'Type here';
  public dateMask = {
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    pipe: createAutoCorrectedDatePipe('mm/dd/yyyy'),
    keepCharPositions: true,
  };
  private onChange: (val: any) => void;
  private onTouched: () => void;
  @ViewChild('input') private input: ElementRef;
  private valueChanges$: Subscription;
  private first: boolean;
  private disabled: boolean;

  constructor(private cd: ChangeDetectorRef, private eRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  public clickOutside($event) {
    if (
      !this.eRef.nativeElement.contains($event.target) &&
      this.showDatePicker
    ) {
      setTimeout(() => {
        this.showDatePicker = false;
      });
    }
  }

  public ngOnInit(): void {
    this.valueChanges$ = this.control.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((val) => {
        this.value = val;
        this.updateDatePickerValues();
      });
    this.initToday();
  }

  private updateDatePickerValues() {
    if (this.value) {
      const [month, day, year] = this.value.split('/');
      this.datePickerModel = { year: +year, month: +month, day: +day };
    }
  }

  private initToday(): void {
    const now = new Date();
    this.today = {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate(),
    };
  }

  public openDatePicker($event) {
    if (this.disabled || this.readonly) {
      return;
    }
    this.showDatePicker = !this.showDatePicker;
    this.first = true;
  }

  public changeDate(date: any) {
    if (this.disabled || this.readonly) {
      return;
    }
    const curr = this.datePickerModel || {};
    if (
      Object.values(date).join('') !== Object.values(curr).join('') ||
      !this.first
    ) {
      setTimeout(() => {
        this.showDatePicker = false;
      });
      this.datePickerModel = date;
      const tmp = `${date.month < 10 ? '0' : ''}${date.month}/${
        date.day < 10 ? '0' : ''
      }${date.day}/${date.year}`;
      this.control.setValue(tmp);
    }
    this.first = false;
  }

  public writeValue(obj: any): void {
    this.control.setValue(obj);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.control.disable();
      this.showDatePicker = false;
    } else {
      this.control.enable();
    }
  }

  public focus() {
    if (this.input) {
      this.input.nativeElement?.focus();
    }
  }

  public isToday(date: NgbDateStruct): boolean {
    if (!this.today) return false;
    return (
      date.year === this.today.year &&
      date.month === this.today.month &&
      date.day === this.today.day
    );
  }
}
