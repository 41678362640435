// eslint-disable  @typescript-eslint/member-ordering

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { SelectItemComponent } from './select-item/select-item.component';
import { SelectItems } from './select-item';

function deepCompare(x, y) {
  if (x === y) {
    return true;
  }

  if (!(x instanceof Object) || !(y instanceof Object)) {
    return false;
  }

  if (x.constructor !== y.constructor) {
    return false;
  }

  for (const p in x) {
    if (!x.hasOwnProperty(p)) {
      continue;
    }

    if (!y.hasOwnProperty(p)) {
      return false;
    }

    if (x[p] === y[p]) {
      continue;
    }

    if (typeof x[p] !== 'object' || !deepCompare(x[p], y[p])) {
      return false;
    }
  }

  for (const p in y) {
    if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
      return false;
    }
  }
  return true;
}

const flyInOut = trigger('flyInOut', [
  state('in', style({ transform: 'translateY(10%)', opacity: 0 })),
  transition('void => *', [
    style({ transform: 'translateY(-10%)', opacity: 1 }),
    animate(100),
  ]),
  transition('* => void', [
    animate(100, style({ transform: 'translateY(-10%)', opacity: 0 })),
  ]),
]);

export const SELECTBOX_GROUP_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => OtSelectBoxComponent), // eslint-disable-line
  multi: true,
};

@Component({
  selector: 'ot-select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.scss'],
  animations: [flyInOut],
  providers: [SELECTBOX_GROUP_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtSelectBoxComponent
  implements ControlValueAccessor, OnInit, OnDestroy, AfterContentInit
{
  protected _showDropdown = false;
  private _valueToWrite: any;

  public get showDropdown() {
    return this._showDropdown;
  }

  public set showDropdown(value) {
    if (this.readonly) {
      return;
    }
    this._showDropdown = !!value;
    if (!this._showDropdown) {
      this.onTouched();
    } else {
      this.filteredOptions = this.filterOptions();
    }
    this.cd.markForCheck();
  }

  @Input()
  public get disabled() {
    return this.filterControl.disabled;
  }

  public set disabled(value) {
    this.setDisabledState(!!value);
  }

  private _options: SelectItems = [];

  additionalClick($event: MouseEvent) {
    $event.preventDefault();
    this.additionalAction.emit(this.filterControl.value);
    this.reset();
  }

  @Input()
  public set options(value: SelectItems) {
    if (deepCompare(value, this._options)) {
      return;
    }
    this._options = value;
    if (
      !this.typeahead &&
      this._value &&
      this._value.value &&
      !value.find((item) => item.value === this._value.value)
    ) {
      this.reset();
      this.onChange(null);
    }
    this.filteredOptions = this.filterOptions();
    this.cd.markForCheck();
  }

  private _value: any;

  public get value(): any {
    return this._value;
  }

  public set value(value: any) {
    if (value) {
      this._value = value;
      if (this.onChange) {
        this.onChange(value.value);
      }
      if (this.onTouched) {
        this.onTouched();
      }
      this.filterControl.setValue(value.label, { emitEvent: false });
    } else {
      this._value = null;
      this.onChange(null);
      this.filterControl.setValue(null, { emitEvent: false });
      this.filter.emit('');
    }
    this.filteredOptions = this.filterOptions();
    this.cd.markForCheck();
  }

  @Input() public additionalActionText;
  @Output() public additionalAction = new EventEmitter();
  @Input() forceEmpty = false;
  @Input() public readonly = false;
  public filterControl = new FormControl();
  @ViewChild('input') public input: ElementRef;
  @Input() public placeholder = 'Please Select';
  @Input() public typeahead: boolean;
  @Output() public filter = new EventEmitter();
  @Output() public onSelect = new EventEmitter();
  @HostBinding('class.is-valid') public formControlSuccess;
  @HostBinding('class.is-invalid') public formControlDanger;
  // private _optionsState = new BehaviorSubject<SelectItems>(null);
  @ContentChildren(SelectItemComponent)
  public selectItems: QueryList<SelectItemComponent>;
  @Input() public clear = false;
  public filteredOptions: SelectItems = [];
  private unsubscribe = new Subject();
  @HostBinding('tabindex') private tabIndex = 0;
  private onChange;
  private onTouched;
  private valueChanges$: Subscription;
  private focused: boolean;
  private prevValue: any;
  private prevOpts: any;

  constructor(
    private _element: ElementRef,
    private cd: ChangeDetectorRef,
    private trust: DomSanitizer
  ) {}

  public reset() {
    this._value = { value: null, label: null };
    this.cd.detectChanges();
    // this.filterControl.setValue(null, {emitEvent: false,  emitModelToViewChange: true});
    setTimeout(() => {
      this.filterControl.setValue('', {
        emitEvent: false,
        emitModelToViewChange: true,
      });
      this.filteredOptions = this.filterOptions();
      this.filter.emit('');
      this.cd.detectChanges();
    }, 1);
    this.cd.detectChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  public filterOptions(): SelectItems {
    const value = this.filterControl.value;
    const res = this._options.filter((option) => {
      // eslint-disable-line
      if (
        typeof value === 'undefined' ||
        value === null ||
        value.length === 0 ||
        (this._value &&
          this._value.label &&
          value &&
          value?.toLowerCase() === this._value.label?.toLowerCase())
      ) {
        return true;
      }
      return (
        option && option.label?.toLowerCase().indexOf(value?.toLowerCase()) > -1
      );
    });
    if (!value && this._valueToWrite) {
      const opt = res.find((v) => v.value == this._valueToWrite); // eslint-disable-line
      if (opt) {
        this.filterControl.setValue(opt.label);
        this.selectItem(opt);
        this._valueToWrite = null;
      }
    } else if (value && this._valueToWrite) {
      this._valueToWrite = null;
    }
    return res;
  }

  public ngAfterContentInit(): void {
    this._prepareOpts();
    this.selectItems.changes
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((v) => {
        this._prepareOpts();
      });
  }

  public ngOnInit(): void {
    this._showDropdown = false;
    if (this._value) {
      this.filterControl.setValue(this._value.label, { emitEvent: false });
    } else {
      this.filterControl.setValue(null);
    }
    this.valueChanges$ = this.filterControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value) => {
        this.filter.emit(value);
        this.filteredOptions = this.filterOptions();
        this.cd.markForCheck();
      });
    this.filteredOptions = this.filterOptions();
    this.cd.markForCheck();
  }

  public inputClick($event: Event) {
    $event.preventDefault();
    this.resetValue();
    this.showDropdown = !this.showDropdown;
  }

  public focus() {
    if (this.readonly) {
      return;
    }
    this.focused = true;
    // this.tabIndex = -11;
    this.cd.markForCheck();
  }

  public blur() {
    this.focused = false;
    this.resetValue();
    // this.tabIndex = 0;
    this.cd.markForCheck();
  }

  public selectItem(opt) {
    this.value = opt;
    this.showDropdown = false;
    this.onSelect.emit(opt);
    this.cd.markForCheck();
  }

  @HostListener('focus', ['$event'])
  public onFocus(event) {
    this.showDropdown = true;
    if (this.input) {
      this.input.nativeElement?.focus();
    }
    this.cd.markForCheck();
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(e) {
    const keyDown = e.which === 40 || e.keyCode === 40;
    const keyUp = e.which === 38 || e.keyCode === 38;
    const enter = e.which === 13 || e.keyCode === 13;
    const escape = e.which === 27 || e.keyCode === 27;
    const tab = e.which === 9 || e.keyCode === 9;
    if (escape) {
      this.input.nativeElement.blur();
      this.showDropdown = false;
      this.cd.markForCheck();
      return;
    }
    if (!this.showDropdown) {
      this.showDropdown = true;
      this.cd.markForCheck();
    }
    if (enter) {
      return;
    }
    if (tab && this.showDropdown && this._options.length === 0) {
      this.showDropdown = false;
      this.cd.markForCheck();
      return;
    }
    if (e.target.nodeName?.toLowerCase() === 'input' && (keyDown || tab)) {
      const btns = this._element.nativeElement.querySelectorAll('button');
      if (btns.length > 0) {
        btns[0]?.focus();
      }
      e.preventDefault();
      this.cd.markForCheck();
      return;
    }
    if (tab && this.showDropdown && e.srcElement.nextElementSibling) {
      e.preventDefault();
      e.srcElement.nextElementSibling?.focus();
    }
    if (
      tab &&
      !e.srcElement.nextElementSibling &&
      e.target.nodeName?.toLowerCase() !== 'input' &&
      this.showDropdown
    ) {
      this.showDropdown = false;
      this.cd.markForCheck();
    }
    if (keyDown) {
      if (e.srcElement.nextElementSibling) {
        e.srcElement.nextElementSibling?.focus();
      }
    }
    if (keyUp) {
      if (e.srcElement.previousElementSibling) {
        e.srcElement.previousElementSibling?.focus();
      } else {
        this.input.nativeElement?.focus();
      }
    }
  }

  public writeValue(val: any): void {
    if (val instanceof Object) {
      this._value = val;
    } else {
      this._valueToWrite = val;
      this._value = this._options.find((opt) => opt.value == val); // eslint-disable-line  eqeqeq
    }
    if (this._value) {
      this.filterControl.setValue(this._value.label);
    } else {
      this.filterControl.setValue(null);
    }
    this.onSelect.emit(val);
    this.cd.markForCheck();
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.filterControl.disable();
    } else {
      this.filterControl.enable();
    }
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    this.cd.markForCheck();
    if (!targetElement || this.showDropdown !== true) {
      return;
    }

    const clickedInside = this._element.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.showDropdown = false;
      this.resetValue();
      if (!this._value || !this._value.label) {
        this.filter.emit('');
      }
      this.cd.markForCheck();
    }
  }

  private resetValue() {
    if (this.showDropdown === false) {
      if (this._value && this._value.label !== this.filterControl.value) {
        this.filterControl.setValue(this._value.label, { emitEvent: false });
      } else {
        if (!this._value || !this._value.label) {
          this.filterControl.setValue('', { emitEvent: false });
        }
      }
      this.filteredOptions = this.filterOptions();
    }
    this.cd.markForCheck();
  }

  private _prepareOpts() {
    if (this.selectItems.length > 0) {
      const opts = [];
      this.selectItems.forEach((v) => {
        opts.push({
          value: v.value,
          label: v.label || v.elem.nativeElement.innerHTML,
        });
      });
      this.options = opts;
    }
  }
}
