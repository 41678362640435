import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImagePreviewComponent } from './image-preview.component';
import { PipesModule } from 'libs/ot/pipes/pipes.module';

@NgModule({
  imports: [CommonModule, PipesModule],
  declarations: [ImagePreviewComponent],
  exports: [ImagePreviewComponent],
})
export class ImagePreviewModule {}
