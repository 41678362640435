import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { Profile, SessionService, UserPermissions } from '@tenant/core';
import { flyInOut, flyInOutState } from '@tenant/ot';
import { environment } from '../../../../../apps/admin/src/environments/environment';

@Component({
  selector: 'ot-user-bar',
  templateUrl: './user-bar.component.html',
  styleUrls: ['./user-bar.component.scss'],
  animations: [flyInOut, flyInOutState],
})
export class UserBarComponent {
  @Input() public profile: Profile;
  @Output() public dropdownClosed: EventEmitter<boolean> = new EventEmitter();
  public showMenu = false;
  public fultongraceUrl = environment.FULTONGRACE_URL;
  public userPermissions = UserPermissions;
  constructor(private session: SessionService, private _eref: ElementRef) {}

  public toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  public logout($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.showMenu = false;
    this.session.logout();
  }

  public onClick() {
    this.closeDropdown();
    this.dropdownClosed.emit(true);
  }

  @HostListener('document:click', ['$event'])
  private outsideClick(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.closeDropdown();
    }
  }

  private closeDropdown() {
    this.showMenu = false;
  }
}
