<div [formGroup]="group">
  <ot-form-container>
    <ot-label>
      {{config.label}}
    </ot-label>
    <ot-select-box otInput [formControlName]="config.field" [readonly]="readonly"
                   [required]="config?.required">
      <ot-select-item *ngFor="let opt of options" [value]="opt.value" [label]="opt.label">{{opt.label}}</ot-select-item>
    </ot-select-box>
    <ot-form-error key="custom" (remove)="errors = null" *ngIf="errors && errors.length > 0">{{errors[0]}}
    </ot-form-error>
  </ot-form-container>
</div>
