import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlFileExtension',
})
export class UrlFileExtensionPipe implements PipeTransform {
  transform(url: string): string {
    let extension;

    if (url && url?.indexOf('?') !== -1) {
      extension = url.slice(0, url.indexOf('?'));
    }
    extension = extension?.split('.')?.pop();
    return extension;
  }
}
