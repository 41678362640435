import { TemplateRef } from '@angular/core';

export class OverlayNotificationModel {
  private _id = null;

  public get id() {
    if (!this._id) {
      this._id = this.guidGenerator();
    }
    return this._id;
  }

  public text: string;
  public timeout = 0;
  public type: 'success' | 'warning' | 'error';
  public customConfig: { data: any; template: TemplateRef<any> };

  constructor({ text, timeout, type }, customConfig = null) {
    this.text = text;
    this.timeout = timeout;
    this.type = type;
    this.customConfig = customConfig;
  }

  private guidGenerator() {
    const S4 = () => {
      /* eslint-disable  no-bitwise */
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      S4() +
      S4()
    );
  }
}
