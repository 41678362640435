import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ot-paid-status',
  templateUrl: './paid-status.component.html',
  styleUrls: ['./paid-status.component.scss']
})
export class PaidStatusComponent {
  @Input() public label = 'Paid';
  @Input() public display = null;
  @Input() public value = false;
  @Input() public editable = true;
  @Input() public disabled = false;
  @Input() public disableFalse = false;

  @Output() public change = new EventEmitter();

  public changeValue(value) {
    this.change.emit(value);
  }
}
