import {
  Directive,
  DoCheck,
  Host,
  Input,
  OnInit,
  Optional
} from '@angular/core';
import { FormGroupDirective, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as SmoothScroll from 'smooth-scroll';

@Directive({
  selector: '[otScrollToError]',
  exportAs: 'scrollToError'
})
export class ScrollToErrorDirective implements DoCheck, OnInit {
  private get formInstance(): NgForm | FormGroupDirective {
    return this.form || this.group;
  }

  @Input() private request: Subscription;
  @Input() private manual = false;
  private dynamicForm = null;

  constructor(
    @Optional()
    @Host()
    private form: NgForm,
    @Optional()
    @Host()
    private group: FormGroupDirective
  ) {}

  public ngOnInit(): void {
    if (this.manual) {
      return;
    }
    if (this.dynamicForm) {
      this.dynamicForm.onSubmit.subscribe(() => {
        this.scroll();
      });
    }
    if (this.formInstance) {
      this.formInstance.ngSubmit.subscribe(() => {
        setTimeout(() => {
          this.scroll();
        }, 100);
      });
    }
  }

  public ngDoCheck(): void {
    if (this.manual) {
      return;
    }
    if (this.request && !this.request['added']) {
      this.request.add(() => {
        setTimeout(() => {
          this.scroll();
        }, 100);
      });
      this.request['added'] = true;
    }
  }

  public scroll() {
    const errors = document.querySelectorAll('ot-form-error');
    let visibleElements;

    for (let i = 0; i < errors.length; i++) {
      // eslint-disable-line
      const currentElement = errors[i];
      const $style = window.getComputedStyle(currentElement, null);

      if (!currentElement) {
        visibleElements = null;
      } else if (!$style) {
        visibleElements = null;
      } else if ($style.display === 'none') {
        visibleElements = null;
      } else {
        visibleElements = currentElement;
        break;
      }
      if (visibleElements) {
        // eslint-disable-line , , , , 
      }
    }
    if (visibleElements) {
      // const top = errors.offsetHeight;
      const scroll = new SmoothScroll();
      scroll.animateScroll(
        Math.max(
          visibleElements.getBoundingClientRect().top + window.scrollY - 300,
          0
        )
      );
    }
  }
}
