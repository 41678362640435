import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ot-popover',
  template: `
    <ng-container>
      <img
        class="d-none d-xl-block"
        [ngbPopover]="content"
        triggers="hover"
        placement="left"
        src="/assets/svg/{{ icon }}.svg"
        alt="icon"
      />
      <img
        class="d-xl-none"
        [ngbPopover]="content"
        triggers="click:click"
        placement="auto"
        src="/assets/svg/{{ icon }}.svg"
        alt="icon"
      />
    </ng-container>
  `,
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  @Input() content: string;
  @Input() icon: string;

  constructor() {}

  ngOnInit(): void {}
}
