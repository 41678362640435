import { Pipe, PipeTransform } from '@angular/core';
import { preformAddress } from '../utils';

@Pipe({
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  transform(addr: any, args?: any): any {
    return preformAddress(addr);
  }
}
