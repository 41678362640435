<!--<div class="tag-input">-->
  <div class="tags" *ngIf="value?.length > 0">
    <ot-tag *ngFor="let item of value" [option]="item" (removeTag)="onRemove.emit($event)"></ot-tag>
  </div>
  <div class="input-wrap">
    <div class="position-relative">
      <input [placeholder]="placeholder" autocomplete="off" #input class="tag-control" (focus)="focus()" (blur)="blur()"
             [formControl]="control" (click)="inputClick($event)" otInput
             [readonly]="readonly" name="tag-input">
      <span *ngIf="showDropdown" inlineSVG="/assets/svg/close-table.svg" class="clear-input" (click)="onClearInput()"></span>
    </div>
    <div class="dropdown-wrap">
      <div class="dropdown-menu show" [@flyInOut]="showDropdown" *ngIf="showDropdown">
        <p class="no-results" *ngIf="options?.length === 0">No results found</p>
        <button type="button" class="dropdown-item" *ngFor="let opt of options" (click)="selectItem(opt)"
                [innerHTML]="opt.label|safeHtml">
        </button>
        <button type="button" class="dropdown-item additional" *ngIf="additionalActionText" (click)="additionalAction.emit(input.value);showDropdown = false; reset()"
                [innerHTML]="additionalActionText + (input.value ? ': '+input.value : '')|safeHtml">
        </button>
      </div>
    </div>
  </div>
<!--</div>-->
