import { HttpClient } from '@angular/common/http';
import {
  Component,
  Input,
  AfterViewInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectorRef,
} from '@angular/core';
import {
  ServerDataSource,
  TableColumn,
  TableConfig,
  TableFilter,
} from '@tenant/table';
import * as moment from 'moment-mini-ts';
import { DashLeaderboardDataCollection } from './dash-leaderboard.data-collection';
import { LeaderboardModel } from '../../../reports/agent-leaderboard/leaderboard.model';
import { ApiConfig } from '@tenant/helpers';

@Component({
  selector: 'ot-dash-leaderboard',
  templateUrl: './dash-leaderboard.component.html',
  styleUrls: ['./dash-leaderboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashLeaderboardComponent implements AfterViewInit {
  private _dealType = '';

  public get dealType() {
    return this._dealType;
  }

  @Input()
  public set dealType(value) {
    this._dealType = value;
    this.updateFilters();
  }

  public colors: any;
  @ViewChild('grossCommissionTemplate')
  public grossCommissionTemplate: TemplateRef<any>;
  @ViewChild('currencyCell') public currencyCell: TemplateRef<any>;
  public config: TableConfig;
  public alternative = true;
  public allCount = 0;
  public currentCount = 0;

  constructor(private api: HttpClient, private cdr: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    this.colors = {
      sale: '#3eb049',
      rental: '#00a7e1',
    };
    const columns = this.createColumns();
    const filters: TableFilter[] = [
      new TableFilter({
        type: 'date-range',
        requestKey: 'date',
        label: 'This Month',
        customParams: 'from_date,to_date',
        formatDateTime: 'YYYY-MM-DD',
        value: {
          field: 'this_month',
          from: moment().startOf('month').format('YYYY-MM-DD'),
          to: moment().endOf('month').format('YYYY-MM-DD'),
        },
      }),
    ];
    this.config = new TableConfig({
      columns,
      filters,
      meta: {
        order: {
          key: 'gross_volume',
          direction: 'desc',
        },
      },
      dataSource: new ServerDataSource(this.api, {
        endPoint: ApiConfig.companyLeaderboard,
        dataCollection: new DashLeaderboardDataCollection<LeaderboardModel>(
          LeaderboardModel
        ),
      }),
    });
    this.cdr.detectChanges();
  }

  public changeType($event: string) {
    this.alternative = $event !== 'table';
    this.config.columns = this.createColumns();
  }

  private createColumns() {
    return [
      new TableColumn({
        label: 'Rank',
        field: 'rank',
        sortable: false,
        headerStyle: { width: '100px' },
      }),
      new TableColumn({
        label: 'Agent',
        field: 'name',
        sortable: false,
        headerStyle: { width: '250px' },
      }),
      new TableColumn({
        label: 'Closings',
        field: 'number_of_closings',
        sortable: false,
        hidden: this.alternative,
        headerStyle: { width: '250px' },
      }),
      new TableColumn({
        label: 'Rental',
        field: 'rental',
        hidden: this.alternative,
        sortable: false,
        cellTemplate: this.currencyCell,
        headerStyle: { width: '250px' },
      }),
      new TableColumn({
        label: 'Sales',
        field: 'sales',
        hidden: this.alternative,
        sortable: false,
        cellTemplate: this.currencyCell,
        headerStyle: { width: '250px' },
      }),
      new TableColumn({
        label: 'Gross Volume',
        field: 'gross_volume',
        sortable: false,
        cellTemplate: this.alternative
          ? this.grossCommissionTemplate
          : this.currencyCell,
        headerStyle: { width: this.alternative ? 'initial' : '150px' },
      }),
      new TableColumn({
        label: 'Closings',
        field: 'number_of_closings',
        hidden: !this.alternative,
        sortable: false,
        headerStyle: { width: '100px' },
      }),
    ];
  }

  private updateFilters() {
    if (!this.config || !this.config.dataSource) {
      return;
    }
    this.config.dataSource.setFilter([
      ...(this.dealType !== 'all'
        ? [{ key: 'deal_type', value: this.dealType }]
        : []),
      {
        key: 'from_date',
        value: moment().startOf('month').format('YYYY-MM-DD'),
      },
      {
        key: 'to_date',
        value: moment().endOf('month').format('YYYY-MM-DD'),
      },
    ]);
  }
}
