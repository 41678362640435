import { Pipe, PipeTransform } from '@angular/core';
import { AgentTypes, DealAgents } from 'apps/admin/src/app/deal/models/deal';
@Pipe({
  name: 'primaryAgent',
})
export class PrimaryAgent implements PipeTransform {
  public transform(items: DealAgents[]): string {
    if (!items) {
      return '-';
    }
    return items.filter((item) => item.agent_type === AgentTypes.PRIMARY)[0]
      .agent?.first_name;
  }
}
