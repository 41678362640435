import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'agentsTeam',
})
export class AgentsTeamPipe implements PipeTransform {
  public transform(value: string[]): string {
    if (value?.length !== 1 || value[0] === 'Fulton Grace Realty - No Team') {
      return '';
    } else {
      return `(${value[0]})`;
    }
  }
}
