import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

let uniqueCheckboxId = 0;

export const CHECKBOX_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => Checkbox2Component), // eslint-disable-line
  multi: true,
};

@Component({
  selector: 'ot-checkbox2',
  templateUrl: './checkbox2.component.html',
  styleUrls: ['./checkbox2.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CHECKBOX_VALUE_ACCESSOR],
})
export class Checkbox2Component implements ControlValueAccessor {
  @Input()
  public identifier = `ot-checkbox-${uniqueCheckboxId++}${+new Date()}`;
  @Input() public value: any;
  public _value: boolean;
  public onChange: any;
  public onTouched: any;
  public disabled: boolean;
  @Input() public readonly: boolean;

  constructor(private cd: ChangeDetectorRef) {}

  public writeValue(obj: any): void {
    if (obj === 'true') {
      this._value = true;
    } else if (obj === 'false') {
      this._value = false;
    } else {
      this._value = !!obj;
    }
    this.cd.markForCheck();
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public switchCheckbox($event) {
    this.stop($event);
    if (this.readonly) {
      return;
    }
    if (this.value) {
      if (this._value) {
        this._value = this.value;
        this.onChange(this.value);
        this.onTouched();
      } else {
        this._value = null;
        this.onChange(null);
        this.onTouched();
      }
    } else {
      this._value = !this._value;
      this.onChange(this._value);
      this.onTouched();
    }
    this.cd.markForCheck();
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.markForCheck();
  }

  stop($event) {
    $event.stopPropagation();
  }
}
