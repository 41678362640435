import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, AfterViewInit } from '@angular/core';
import { Field } from '../models/field.interface';
import { DealAddress } from 'apps/admin/src/app/deal/models/deal';
import { FormGroup } from '@angular/forms';
import { AbstractField } from '../models/abstract-field';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ot-dynamic-duplicate-warning',
  templateUrl: './dynamic-duplicate-warning.component.html',
  styleUrls: ['./dynamic-duplicate-warning.component.scss'],
  providers: [],
})
export class DynamicDuplicateWarning implements Field, AfterViewInit {
  public config: AbstractField;
  public group: FormGroup;
  public rootGroup: FormGroup;
  public errors: any;
  public readonly: any;
  public duplicateDealId = '';

  constructor(private api: HttpClient) {}

  private checkDuplicate() {
    const dealAddress = this.group.get('deal_address');
    if (dealAddress.valid) {
      let params = new HttpParams();
      Object.entries(dealAddress.value as DealAddress).forEach(([key, value]) => {
        if (value) {
          params = params.set(key, value);
        }
      });

      this.api
        .get<{ id?: string }>(this.config.meta.endpoint, { params })
        .subscribe((res) => {
          this.duplicateDealId = res?.id ?? '';
        }, () => this.duplicateDealId = '');
    }
  }

  public ngAfterViewInit() {
    const dealAddress = this.group.get('deal_address');
    dealAddress.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.checkDuplicate();
      });
  }

  get dealUrl() {
    return '/deal/' + this.duplicateDealId;
  }
}
