import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'otCurrency',
})
export class OtCurrencyPipe extends CurrencyPipe implements PipeTransform {
  transform(
    value: any,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | boolean,
    digits?: string,
    locale?: string
  ): any {
    let defaultDigits = digits;
    if (isNaN(value)) {
      return value;
    }
    if (value === Math.ceil(value)) {
      defaultDigits = '1.0-2';
    }
    if (display === true) {
      display = 'symbol';
    }
    return super.transform(value, currencyCode, display, defaultDigits, locale);
  }
}
