import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
  private units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  public transform(bytes: number = 0, precision: number = 2): any {
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) {
      return '?';
    }

    let unit = 0;

    while (bytes >= 1024) {
      bytes /= 1024;
      unit++;
    }

    if (+precision > 0) {
      bytes = parseFloat(bytes.toFixed(+precision));
    }

    return bytes + ' ' + this.units[unit];
  }
}
