import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ot-dash-header',
  templateUrl: './dash-header.component.html',
  styleUrls: ['./dash-header.component.scss'],
})
export class DashHeaderComponent implements OnInit {
  private _dealType = 'all';

  public get dealType(): string {
    return this._dealType;
  }

  public set dealType(value: string) {
    this._dealType = value;
    this.changeDealType.emit(value);
  }

  @Output() private changeDealType = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
