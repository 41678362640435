import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg';

import { ModalSucceedComponent } from './components/modal-succeed/modal-succeed.component';
import { PopoverComponent } from './components/popover/popover.component';
import {NgbPopoverModule} from "@ng-bootstrap/ng-bootstrap";
import { StatusComponent } from './components/status/status.component';

@NgModule({
  imports: [CommonModule, InlineSVGModule, NgbPopoverModule],
  declarations: [ModalSucceedComponent, PopoverComponent, StatusComponent],
  exports: [ModalSucceedComponent, PopoverComponent, StatusComponent],
})
export class SharedModule {}
