import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { AbstractField } from './abstract-field';

export class GrossCurrencyField extends AbstractField {
  public control(): AbstractControl {
    const validators = this.getValidators();
    this.validation = validators;
    return new FormGroup({
      value: new FormControl({ disabled: true, value: '' }, [
        Validators.required
      ]),
      auto: new FormControl(true)
    });
  }
}
