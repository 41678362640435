import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dealType',
})
export class DealTypePipe implements PipeTransform {
  public transform(value: any): string {
    if (value === 'S') {
      return 'Sale';
    } else if (value === 'R') {
      return 'Rental';
    } else {
      return value;
    }
  }
}
