import { Pipe, PipeTransform } from '@angular/core';
import { DealMatrix } from './deal-matrix';

@Pipe({
  name: 'dealAction',
})
export class DealActionPipe implements PipeTransform {
  transform(arr: any, action: keyof DealMatrix): boolean {
    const matrix = new DealMatrix();
    if (matrix[action]) {
      return matrix[action](arr);
    }
    console.error(`Action ${action} not found in matrix`);
    return false;
  }
}
