import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  OverlayNotificationModel,
  OverlayNotificationsService,
} from '@tenant/ot-global';

import { from as fromPromise, Observable, throwError as _throw } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TaskExemptModalComponent } from './task-exempt-modal/task-exempt-modal.component';
import { TaskModalComponent } from './task-modal/task-modal.component';
import { TaskModel, Progress } from './taskModel';
import { TasksService } from './tasks.service';
import { Subject } from 'rxjs';

@Injectable()
export class TasksActionsService {
  private _template: TemplateRef<any> = null;

  public get template() {
    return this._template;
  }

  public set template(template) {
    this._template = template;
  }

  public taskUpdated = new Subject();

  constructor(
    private tasksService: TasksService,
    private overlay: OverlayNotificationsService,
    private modal: NgbModal
  ) {}

  public add($event, deals, prePopulateAgent = null, contacts = []) {
    $event.preventDefault();
    const modal = this.modal.open(TaskModalComponent, {
      keyboard: true,
      backdrop: 'static',
    });
    modal.componentInstance.isCreate = true;
    modal.componentInstance.deals = deals;
    modal.componentInstance.prePopulateAgent = prePopulateAgent;
    modal.componentInstance.contacts = contacts;
    return fromPromise(modal.result).pipe(
      map((res: any) => {
        this.taskUpdated.next('ADD');
        if (res.progress === Progress.EXEMPT) {
          const customConfig = {
            template: this.template,
            data: { id: res.id, event: 'activate' },
          };
          this.overlay.add(
            new OverlayNotificationModel(
              {
                text: 'Task Exempted',
                type: 'success',
                timeout: 3000,
              },
              customConfig
            )
          );
        }
        return res;
      })
    );
  }

  public edit($event, data: TaskModel, deals, contacts = []) {
    $event.preventDefault();
    if (
      data.progress === Progress.COMPLETE ||
      data.progress === Progress.EXEMPT
    ) {
      return;
    }
    const modal = this.modal.open(TaskModalComponent, {
      keyboard: true,
      backdrop: 'static',
    });
    modal.componentInstance.task = data;
    modal.componentInstance.deals = deals;
    modal.componentInstance.contacts = contacts;

    return fromPromise(modal.result).pipe(
      map((res: any) => {
        this.taskUpdated.next('EDIT');
        if (res.data.progress === Progress.EXEMPT) {
          const customConfig = {
            template: this.template,
            data: { id: res.data.id, event: 'activate' },
          };
          this.overlay.add(
            new OverlayNotificationModel(
              {
                text: 'Task Exempted',
                type: 'success',
                timeout: 3000,
              },
              customConfig
            )
          );
        }
        return res;
      }),
      catchError((err) => {
        if (err && err.exempt === true) {
          return this.exempt(null, data);
        }
        return _throw(err);
      })
    );
  }

  public exempt($event = null, data) {
    if ($event) {
      $event.preventDefault();
    }
    const customConfig = {
      template: this.template,
      data: { id: data.id, event: 'activate' },
    };
    if (!data.required) {
      return this.tasksService.taskEvent(data.id, 'exempt', null).pipe(
        map(() => {
          data.disabled = true;
          this.taskUpdated.next(null);
          this.overlay.add(
            new OverlayNotificationModel(
              {
                text: 'Task Exempted',
                type: 'success',
                timeout: 3000,
              },
              customConfig
            )
          );
        }),
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            const errorMessage = `Task can't be exempted`;
            this.overlay.add(
              new OverlayNotificationModel({
                text: errorMessage,
                type: 'error',
                timeout: 3000,
              })
            );
          }
          return _throw(err);
        })
      );
    }
    const modal = this.modal.open(TaskExemptModalComponent, {
      keyboard: true,
      backdrop: 'static',
    });
    modal.componentInstance.task = data;
    return fromPromise(modal.result).pipe(
      map(() => {
        data.disabled = true;
        this.taskUpdated.next(null);
        this.overlay.add(
          new OverlayNotificationModel(
            {
              text: 'Task Exempted',
              type: 'success',
              timeout: 3000,
            },
            customConfig
          )
        );
      }),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          const errorMessage = `Task can't be exempted`;
          this.overlay.add(
            new OverlayNotificationModel({
              text: errorMessage,
              type: 'error',
              timeout: 3000,
            })
          );
        }
        return _throw(err);
      })
    );
  }

  public reopen($event, data) {
    data.disabled = true;
    $event.preventDefault();
    const customConfig = {
      template: this.template,
      data: {
        id: data.id,
        event: 'exempt',
        message: { exempt_message: data.exempt_message },
      },
    };
    this.tasksService.taskEvent(data.id, 'activate', null).subscribe(
      (res) => {
        this.taskUpdated.next(null);
        this.overlay.add(
          new OverlayNotificationModel(
            {
              text: 'Task Reopened',
              type: 'success',
              timeout: 3000,
            },
            customConfig
          )
        );
      },
      (err) => {
        this.overlay.add(
          new OverlayNotificationModel({
            text: `Task can't be reopened`,
            type: 'error',
            timeout: 3000,
          })
        );
      }
    );
  }

  public toggleComplete(data: TaskModel) {
    data.disabled = true;
    let event = data.progress === 'C' ? 'activate' : 'complete';
    return this.tasksService.taskEvent(data.id, event, {}).pipe(
      tap((res: any) => {
        event = res.progress === 'C' ? 'activate' : 'complete';
        const customConfig = {
          template: this.template,
          data: { id: data.id, event },
        };
        this.taskUpdated.next(null);
        this.overlay.add(
          new OverlayNotificationModel(
            {
              text:
                data.progress === 'C' ? 'Task Reactivated' : 'Task Completed',
              type: 'success',
              timeout: 3000,
            },
            customConfig
          )
        );
      }),
      catchError((err) => {
        let errorMessage = `Task can't be  ${
          event === 'C' ? 'completed' : 'reactivated'
        }`;
        if (err.error && err.error.non_field_errors) {
          errorMessage = err.error.non_field_errors[0];
        }
        if (err.error && err.error.detail) {
          errorMessage = err.error.detail;
        }
        this.overlay.add(
          new OverlayNotificationModel({
            text: errorMessage,
            type: 'error',
            timeout: 3000,
          })
        );
        this.taskUpdated.next(err);
        return _throw(err);
      })
    );
  }

  public undo($event, data) {
    if ($event) {
      $event.preventDefault();
    }
    this.tasksService
      .taskEvent(data.id, data.event, data.message)
      .pipe(
        tap(() => {
          this.taskUpdated.next('UNDO');
        })
      )
      .subscribe();
  }

  public undoObs($event, data): Observable<any> {
    if ($event) {
      $event.preventDefault();
    }
    return this.tasksService.taskEvent(data.id, data.event, data.message).pipe(
      tap(() => {
        this.taskUpdated.next(null);
      })
    );
  }
}
