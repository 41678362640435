import { AbstractField } from './abstract-field';

export class TextField extends AbstractField {
  public inputType: string;
  public numberField: boolean;

  constructor(field: any) {
    super(field);
    this.inputType = field.type === 'paragraph' ? 'textarea' : 'text';
    this.numberField = field.type === 'number';
  }
}
