import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'ot-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent {
  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
    if (value) {
      this.isDisabled = true;
    } else {
      this.isDisabled = null;
    }
  }

  @Input() checked = false;
  @Output() select = new EventEmitter();
  @Input() value = true;
  @HostBinding('attr.disabled') public isDisabled = null;

  @HostListener('click', ['$event'])
  public click() {
    if (this._disabled) {
      return;
    }
    this.select.emit(this.value);
  }
}
