import { AbstractControl, FormGroup } from '@angular/forms';
import { FormConfigType, IDynamicField } from '../dynamic-form';
import { AbstractField } from './abstract-field';

export class GroupField extends AbstractField {
  public children: FormConfigType[] = [];
  /*
  constructor(
    group: IDynamicField,
    components = null
  ) {
    super(group, components);
  }*/

  public control(): AbstractControl {
    const fg = new FormGroup({});
    if (this.children) {
      this.children.forEach((field) => {
        fg.setControl(field.field, field.control());
      });
    }
    return fg;
  }
}
