import { Component, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-succeed',
  templateUrl: './modal-succeed.component.html',
  styleUrls: ['./modal-succeed.component.scss']
})
export class ModalSucceedComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  public ngOnInit(): void { }

}
