import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ot-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements OnInit {
  @Output() showMenuBar: EventEmitter<boolean> = new EventEmitter(false);

  constructor() { }

  ngOnInit(): void {
  }

  openMenu(event: boolean) {
    this.showMenuBar.emit(event);
  }

}
