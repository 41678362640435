import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SortHeaderComponent } from './sort-header.component';
import { SortDirective } from './sort.directive';
import { InlineSVGModule } from 'ng-inline-svg';

const COMPONENTS = [SortDirective, SortHeaderComponent];

@NgModule({
  imports: [CommonModule, InlineSVGModule],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
})
export class SortModule {}
