<div class="modal-header">
  <h4 class="modal-title">{{ task ? 'Edit Task' : 'New Task' }}</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="active.dismiss(false)"
  >
    <span aria-hidden="true" [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>
</div>
<div class="modal-body">
  <form
    name="task-form"
    id="task-form"
    [formGroup]="fg"
    (submit)="addTask($event)"
  >
    <div class="row ml-0 mr-0">
      <div class="col-12 pl-0 pr-0 pb-3">
        <div
          class="btn-group"
          ngbRadioGroup
          name="radioBasic"
          [formControl]="type"
          *ngIf="!task"
        >
          <label
            ngbButtonLabel
            class="btn pl-0 d-flex align-items-center"
            [hidden]="task && type.value !== 'D'"
          >
            <div class="radio-btn"></div>
            <input ngbButton type="radio" value="D" /> Document
          </label>
          <label
            ngbButtonLabel
            class="btn d-flex align-items-center"
            [hidden]="task && type.value !== 'T'"
          >
            <div class="radio-btn"></div>
            <input ngbButton type="radio" value="T" /> Task
          </label>
        </div>
      </div>
      <div class="col-12 pl-0 pr-0">
        <ot-form-container>
          <ot-label>{{
            type.value === 'T' ? 'Task Summary' : 'Document Name'
          }}</ot-label>
          <input
            type="text"
            [readonly]="
              (editable$ | async) === false || task?.is_created_from_default
            "
            otInput
            name="summary"
            formControlName="summary"
            [required]="true"
            placeholder="Type here"
          />
          <ot-form-error *ngIf="errors?.summary" key="custom">{{
            errors?.summary[0]
          }}</ot-form-error>
        </ot-form-container>
      </div>
      <div
        class="col-12 pl-0 pr-0"
        *ngIf="
          (canCreateRequired$ | async) === true || (editable$ | async) === false
        "
      >
        <ot-checkbox2
          [readonly]="!(isCreate$ | async)"
          formControlName="required"
          >Mark as Required</ot-checkbox2
        >
      </div>
      <div class="col-12 pl-0 pr-0 pb-3">
        <ot-file-dnd
          *ngIf="!document && type.value === 'D'"
          (filesChangeEmitter)="changeFile($event)"
        >
        </ot-file-dnd>
        <ot-task-document-item
          class="modal-doc"
          (fileNameChanged)="fileNameChanged($event)"
          (removeDocument)="removeHandler($event)"
          *ngIf="document"
          [document]="document"
          [errors]="errors"
        ></ot-task-document-item>

        <p class="text-danger m-0" *ngIf="errors?.file">
          {{ errors?.file[0] }}
        </p>
        <div class="text-center text-danger" *ngIf="sizeError">
          Documents size could not be more than 100MB
        </div>
      </div>
      <ng-container *ngIf="type.value === 'T'">
        <div class="col-md-6 pl-0 pr-md-2 pr-0">
          <ot-form-container>
            <ot-label>Assign to</ot-label>
            <ot-select-box
              [readonly]="
                (editable$ | async) === false || task?.is_created_from_default
              "
              otInput
              formControlName="deal_id"
              [options]="deals"
              name="deal_id"
              [required]="true"
              placeholder="Type here"
            ></ot-select-box>
            <ot-form-error *ngIf="errors?.deal_id" key="custom">{{
              errors?.deal_id[0]
            }}</ot-form-error>
          </ot-form-container>
        </div>
        <div class="col-md-6 pl-0 pl-md-2 pr-0">
          <ot-form-container>
            <ot-label>Assign</ot-label>
            <ot-select-box
              [readonly]="(editable$ | async) === false"
              otInput
              formControlName="assignee"
              [options]="agents"
              name="assignee"
              [required]="true"
              placeholder="Select"
            ></ot-select-box>
            <ot-form-error *ngIf="errors?.assignee" key="custom">{{
              errors?.assignee[0]
            }}</ot-form-error>
          </ot-form-container>
        </div>
        <div class="col-md-6 pl-0 pr-md-2 pr-0">
          <ot-form-container>
            <ot-label>Due Date</ot-label>
            <ot-date
              otInput
              [readonly]="(editable$ | async) === false"
              formControlName="due_date"
              name="due_date"
              placeholder="MM/DD/YYYY"
            ></ot-date>
            <ot-form-error *ngIf="errors?.due_date" key="custom">{{
              errors?.due_date
            }}</ot-form-error>
          </ot-form-container>
        </div>
        <div class="col-md-6 pl-0 pl-md-2 pr-0">
          <ot-form-container>
            <ot-label>Time</ot-label>
            <input
              [readonly]="(editable$ | async) === false"
              otInput
              type="time"
              [disabled]="
                !fg.get('due_date').value || fg.get('due_date').invalid
              "
              otTimePickerFix
              formControlName="time"
              name="time"
              placeholder="HH:MM"
              [class.has-time]="fg.get('time').value != null"
            />
          </ot-form-container>
        </div>
      </ng-container>
      <ng-container *ngIf="type.value === 'D'">
        <div class="col-12 pl-0 pr-0">
          <ot-form-container>
            <ot-multiselect
              [dropdownPosition]="'bottom'"
              [disabled]="!document"
              placeholder="Select Contacts"
              label="Share with Deal Contact(s)"
              tip="This document will be shared with the deal contacts through their FultonGrace portal and they will receive an email notification that the document has been shared."
              [items]="contacts"
              bindLabel="name"
              bindValue="id"
              [formControl]="fg.get('shared_with')"
            ></ot-multiselect>
          </ot-form-container>
        </div>
      </ng-container>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    (click)="exempt($event)"
    *ngIf="!isCreate"
    class="btn btn-secondary mr-auto"
  >
    Exempt
  </button>
  <button type="button" class="btn btn-secondary" (click)="active.dismiss()">
    Close
  </button>
  <button
    type="submit"
    class="btn btn-primary"
    [ladda]="loading && taskService.progress / 100"
    [disabled]="!((fileChanged || fg.dirty) && fg.valid)"
    form="task-form"
  >
    Save
  </button>
</div>
