<ng-template ngFor let-filter [ngForOf]="filters" let-i="index">
  <ng-template [ngxPermissionsOnly]="filter.permissions">
    <ot-input [control]="filtersGroup.get(filter.requestKey)" class="no-space is-search" [noValidate]="true"
      [placeholder]="filter.label" [ngStyle]="filter.style" *ngIf="filter.type === 'search'"></ot-input>
    <ot-select class="no-space" [clear]="!!filter.clear" [forceEmpty]="!!filter.clear" [placeholder]="filter.label"
      *ngIf="filter.type === 'dropdown'" [control]="filtersGroup.get(filter.requestKey)" [options]="filter.options"
      [ngStyle]="filter.style"></ot-select>
    <ot-typeahead [noValidate]="true" class="no-space" [placeholder]="filter.label" *ngIf="filter.type === 'typeahead'"
      [control]="filtersGroup.get(filter.requestKey)" [search]="filter.searchFunction"
      [formatResult]="filter.formatResult" [ngStyle]="filter.style"></ot-typeahead>
    <ot-date-range class="no-space" *ngIf="filter.type === 'date-range'" [control]="filtersGroup.get(filter.requestKey)"
      [hideOptions]="filter.hideOptions" [ngStyle]="filter.style" [customParams]="filter.customParams"
      [formatDateTime]="filter.formatDateTime"></ot-date-range>
    <ot-checkbox class="no-space" *ngIf="filter.type === 'checkbox'" [control]="filtersGroup.get(filter.requestKey)"
      [ngStyle]="filter.style" [label]="filter.label"></ot-checkbox>

  </ng-template>
</ng-template>
<button class="btn reset-filters" *ngIf="filtersChanged" (click)="resetFilters()">Reset Filters</button>