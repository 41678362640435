<div class="container-fluid">
  <div class="row">
    <div class="col-sm-4 col-lg-3">
      <ot-dash-tasks [tasks]="tasks" [loading]="tasksLoading" (reloadTasks)="reloadTasks()"></ot-dash-tasks>
    </div>
    <div class="col-sm-8 col-lg-9 mt-3 mt-sm-0">
      <ot-dash-header (changeDealType)="changeDealType($event)"></ot-dash-header>
      <ot-dash-summary [role]="role$|async" [data]="summary"></ot-dash-summary>
      <ot-dash-leaderboard [dealType]="dealType"> </ot-dash-leaderboard>
      <div class="iframe-wrapper" *ngIf="calendarUrl">
        <!-- <iframe [src]="calendarUrl" class="google-calendar-iframe" frameborder="0"></iframe> -->
      </div>
    </div>
  </div>
</div>