<div class="input-group">
  <input
    #input
    class="form-control"
    [placeholder]="placeholder"
    [formControl]="control"
    [textMask]="dateMask"
    [required]="required"
    [readonly]="readonly"
  />
  <div class="input-group-prepend">
    <button
      class="btn"
      (click)="openDatePicker($event)"
      type="button"
    >
      <img
        src="/assets/svg/date-picker.svg"
        style="width: 1.2rem; height: 1rem; cursor: pointer"
      />
    </button>
  </div>

  <ng-container *ngIf="showDatePicker">
    <ngb-datepicker
      #dp
      [ngModel]="datePickerModel"
      (ngModelChange)="changeDate($event)"
      [dayTemplate]="customDay"
      [minDate]="minDate"
      [maxDate]="maxDate"
    ></ngb-datepicker>
  </ng-container>
</div>

<ng-template
  #customDay
  let-date="date"
  let-currentMonth="currentMonth"
  let-selected="selected"
  let-disabled="disabled"
  let-focused="focused"
>
  <span
    class="custom-day"
    [class.focused]="focused"
    [class.bg-primary]="selected"
    [class.today]="isToday(date)"
    [class.text-muted]="date.month !== currentMonth"
  >
    {{ date.day }}
  </span>
</ng-template>
