import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@tenant/helpers';
import { Observable, Subject } from 'rxjs';
import { SessionService } from '@tenant/core';

@Injectable({
  providedIn: 'root',
})
export class PayloadService {
  private orgID = 'acct_3dz1KCofB6sQ8P76sydN8';

  tokenInitialized$: Subject<string> = new Subject();

  constructor(
    private readonly api: HttpClient,
    private readonly sessionService: SessionService
  ) {}

  setPayloadToken() {
    this.getToken().subscribe((res) => {
      // @ts-ignore
      Payload(res.client_token);
      // @ts-ignore
      new Payload.Connect({
        org_id: this.orgID,
        scope: 'org',
        auth_only: true,
      }).on('connected', (e: { code: string }) => {
        this.payloadAuth(e.code).subscribe((res) => {
          this.sessionService.payloadToken = res.access_token;
          this.tokenInitialized$.next(res.access_token);
        });
      });
    });
  }

  payWithPayload(
    deal_id: string | number,
    agent_id: string | number,
    dto: { amount: string | number }
  ): Observable<any> {
    return this.api.post(
      `${ApiConfig.deal}${deal_id}/agents/${agent_id}/commission-payments/pay-payload`,
      dto ?? {}
    );
  }

  payWithOther(
    deal_id: string | number,
    agent_id: string | number,
    dto: { amount: string | number }
  ): Observable<any> {
    return this.api.post(
      `${ApiConfig.deal}${deal_id}/agents/${agent_id}/commission-payments/pay-other`,
      dto ?? {}
    );
  }

  // only used for zero commission agents
  setPaid(
    deal_id: string | number,
    agent_id: string | number,
  ): Observable<any> {
    return this.api.post(
      `${ApiConfig.deal}${deal_id}/agents/${agent_id}/commission-payments/pay-other`,
      { amount: 0}
    );
  }

  unPayWithOther(
    deal_id: string | number,
    agent_id: string | number,
    payment_id: string | number
  ): Observable<any> {
    return this.api.delete(
      `${ApiConfig.deal}${deal_id}/agents/${agent_id}/commission-payments/${payment_id}/void-other`,
      {}
    );
  }

  unPayWithPayload(
    deal_id: string | number,
    agent_id: string | number,
    payment_id: string | number
  ): Observable<any> {
    return this.api.delete(
      `${ApiConfig.deal}${deal_id}/agents/${agent_id}/commission-payments/${payment_id}/void-payload`,
      {}
    );
  }

  refund(
    deal_id: string | number,
    agent_id: string | number,
    dto: { amount: string | number }
  ): Observable<any> {
    return this.api.post(
      `${ApiConfig.deal}${deal_id}/agents/${agent_id}/commission-payments/refund`,
      dto ?? {}
    );
  }

  private getToken(): Observable<{ client_token: string }> {
    return this.api.post<{ client_token: string }>(
      `${ApiConfig.userPayload}token/`,
      {}
    );
  }

  private payloadAuth(code: string): Observable<{ access_token: string }> {
    return this.api.post<{ access_token: string }>(
      `${ApiConfig.userPayload}oauth/`,
      { code }
    );
  }

  // refreshToken(): Observable<{ access_token: string }> {
  //   return this.api.post<{ access_token: string }>(
  //     `${ApiConfig.userPayload}oauth/refresh/`,
  //     {}
  //   );
  // }
}
