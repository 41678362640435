import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
// import { SelectService } from '@widgets/forms/services/select.service';
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AbsComponentWithSelect } from './abs-component-with-select';

@Component({
  selector: 'ot-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MultiselectComponent),
    },
  ],
})
export class MultiselectComponent
  extends AbsComponentWithSelect
  implements ControlValueAccessor, OnInit
{
  @ViewChild(NgSelectComponent) selectComponent!: NgSelectComponent;

  searchControl = new FormControl('', { updateOn: 'change' });

  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }

  ngOnInit(): void {
    this.onSearch();
  }

  onSearch(): void {
    this.searchControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
        !!this.apiUrl ? debounceTime(1000) : tap()
      )
      .subscribe((term) => {
        this.selectComponent.filter(term as string);
        this.selectComponent.detectChanges();
        return;
      });
  }
}
