<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
    <span aria-hidden="true" [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>
</div>
<div class="modal-body">
  <div [innerHTML]="text"></div>
</div>
<div class="modal-footer">
  <button type="button" *ngIf="type == 'confirm'" class="btn btn-secondary" (click)="activeModal.dismiss(false)">
    {{cancelButtonText}}
  </button>
  <button type="submit" *ngIf="type == 'confirm'" class="btn btn-primary"
          (click)="activeModal.close(true)">
    {{ confirmButtonText }}
  </button>
  <button type="submit" *ngIf="type == 'alert'" class="btn btn-primary"
          (click)="activeModal.close(true)">
    {{ confirmButtonText }}
  </button>
</div>
