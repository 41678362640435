import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@tenant/helpers';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { SessionService } from '../services/session.service';
import { environment } from '../../../../../apps/admin/src/environments/environment';
import { PayloadService } from '../../../../../apps/admin/src/app/deal/deal-details/commission-tracker/payload.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  readonly payloadUrl = 'commission-payments';
  static refreshTokenInProgress = false;

  static tokenRefreshedSource = new Subject();
  static tokenRefreshed$ =
    AuthorizationInterceptor.tokenRefreshedSource.asObservable();

  constructor(
    private session: SessionService,
    private readonly payloadService: PayloadService
  ) {}

  addAuthHeader(request: HttpRequest<any>) {
    if (this.session.token) {
      let headers = new HttpHeaders();

      if (request.url.includes(`${environment.FULTONGRACE_URL}/api`)) {
        headers = headers.set('Authorization', `Bearer ${this.session.token}`);
      }

      if (this.session.impersonate) {
        headers = headers.set(
          'x-impersonationid',
          JSON.stringify(this.session.impersonate)
        );
      }

      if (request.url.includes(this.payloadUrl) && this.session.payloadToken) {
        headers = headers.set(
          'X-Payload-Auth-Token',
          this.session.payloadToken
        );
      }

      return request.clone({
        url: request.url,
        headers: headers,
      });
    }
    return request;
  }

  refreshToken() {
    if (AuthorizationInterceptor.refreshTokenInProgress) {
      return new Observable((observer) => {
        AuthorizationInterceptor.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      AuthorizationInterceptor.refreshTokenInProgress = true;

      return this.session.doRefreshToken().pipe(
        tap(() => {
          AuthorizationInterceptor.refreshTokenInProgress = false;
          AuthorizationInterceptor.tokenRefreshedSource.next(true);
        }),
        catchError((e) => {
          return throwError(e);
        })
      );
    }
  }

  logout() {
    this.session.logout();
    this.redirectToLogin();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const apiReq = request.clone({
      url: request.url,
    });
    // Handle request
    if (request.url.indexOf(ApiConfig.tokenRefresh) === 0) {
      return next.handle(apiReq);
    }
    request = this.addAuthHeader(request);

    // Handle response
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401) {
          if (!error.url.includes(this.payloadUrl)) {
            return this.refreshToken().pipe(
              switchMap(() => {
                request = this.addAuthHeader(request);
                return next.handle(request);
              }),
              catchError((err) => {
                this.logout();
                return throwError(err);
              })
            );
          } else {
            return this.handlePayloadToken(request, next);
          }
        }
        return throwError(error);
      })
    );
  }

  private handlePayloadToken(request: HttpRequest<any>, next: HttpHandler) {
    this.payloadService.setPayloadToken();
    return this.payloadService.tokenInitialized$.pipe(
      switchMap((res) => {
        this.session.payloadToken = res;
        request = this.addAuthHeader(request);
        return next.handle(request);
      }),
      catchError((err) => {
        return throwError(err);
      })
    );

    // return this.payloadService.refreshToken().pipe(
    //   switchMap((res) => {
    //     this.session.payloadToken = res.access_token;
    //     request = this.addAuthHeader(request);
    //     return next.handle(request);
    //   }),
    //   catchError((err) => {
    //     this.payloadService.setPayloadToken();
    //     return this.payloadService.tokenInitialized$.pipe(
    //       switchMap((res) => {
    //         this.session.payloadToken = res;
    //         request = this.addAuthHeader(request);
    //         return next.handle(request);
    //       }),
    //       catchError((err) => {
    //         return throwError(err);
    //       })
    //     );
    //   })
    // );
  }

  private redirectToLogin() {
    window.location.href = `${environment.FULTONGRACE_URL}/logout/?next=/`;
  }
}
