import { Injectable } from '@angular/core';
import * as parseUrl from 'url-parse';
import { ProfileService } from './profile.service';
import { SessionService } from './session.service';
import { TenantSessionService } from './tenant-session.service';
import { environment } from '../../../../../apps/admin/src/environments/environment';
import * as Sentry from '@sentry/browser';

@Injectable()
export class AppLoadService {
  constructor(
    private sessionService: SessionService,
    private tenantSession: TenantSessionService,
    private profileService: ProfileService
  ) {}

  public initializeApp(): Promise<any> {
    return new Promise<any>( async (resolve, reject) => {
      const url = parseUrl(window.location.href);
      const query = this.qs(url.query.substring(1));
      const code = query.auth_code;

      if (url.pathname.includes('applications-payment')) {
        this.hidePreloader();
        resolve(true);
        return;
      }

      if (!code && !this.sessionService.isLoggedIn && !this.sessionService.refreshToken) {
        window.location.href = environment.FULTONGRACE_URL;
        reject();
      }

      if (!!query && !!code && !this.sessionService.refreshToken && !this.sessionService.token) {
        this.sessionService.killSession();
        this.profileService.forgetProfile();
      }

      if (!this.sessionService.token && this.sessionService.refreshToken) {
         await this.sessionService.doRefreshToken().toPromise()
      }

      if (this.sessionService.isLoggedIn) {
        this.sessionService.resetSession();
        this.hidePreloader();
        resolve(true);
      } else if (code || this.sessionService.token) {
        if (code) {
          this.tenantSession.setTokenCfg(query);
        }
        this.profileService.loadData().subscribe(
          (profile) => {
            Sentry.configureScope((scope) => {
              scope.setUser({
                id: profile.id.toString(),
                username: `${profile?.first_name} ${profile?.last_name}`,
                email: profile.email,
              });
            });
          },
          (error) => {
            console.error(error);
          }
        );

        this.hidePreloader();
        resolve(true);
      }
    });
  }

  private hidePreloader() {
    setTimeout(() => {
      document.getElementById('site-preloader').style.display = 'none';
    }, 100);
  }

  private qs(search): any {
    if (!search || search.length < 3) {
      return {};
    }
    return JSON.parse(
      '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      function (key, value) {
        return key === '' ? value : decodeURIComponent(value);
      }
    );
  }
}
