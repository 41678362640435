<div class="modal-header">
  <h4 class="modal-title">Mark as Exempt</h4>
</div>
<div class="modal-body">
  <form name="exempt-form" id="exempt-form" #form="ngForm" [formGroup]="exemptFg"
    (submit)="exempt($event)">
    <div class="row">
      <div class="col-12">
        <ot-form-container>
          <ot-label>Please specify the reason why the required task {{'"'+ task?.summary+ '"'}} is exempt for this deal:
          </ot-label>
          <textarea (keydown)="checkSubmit($event, form)" otInput formControlName="exempt_reason" name="exempt"
            [errorStateMatcher]="errorStateMatcher"></textarea>
          <ot-form-error key="custom" *ngIf="errors?.task?.exempt_reason">{{errors?.task?.exempt_reason}}
          </ot-form-error>
        </ot-form-container>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="active.dismiss()">Cancel</button>
  <button type="submit" class="btn btn-primary" form="exempt-form">Save</button>
</div>