import { Address } from './address.model';
import { ReportType } from '@tenant/tenant-report';

export interface ApplicationResponseModel {
  property_information: ApplicationPropertyInformation;
  id: number;
  applicant_information: ApplicationApplicantInformation;
  current_address_information: ApplicationCurrentAddressInformation;
  current_employment_information: ApplicationCurrentEmploymentInformation;
  emergency_contact_information: ApplicationEmergencyContactInformation;
  additional_information: ApplicationAdditionalInformation;
  how_will_pay_the_application_fee: string;
  status: string;
  status_changed_date: string;
  status_changed_by: string;
  note: string;
  deal: any;
  date_created: string;
  paid_confirmed_by: ApplicationPaidConfirmedBy;
  paid_confirmed_date: string;
  application_fee_payment: ApplicationFeePayment;
  is_paid: boolean;
  assigned_agent?: number;
}

export interface ApplicationFeePayment {
  status: string;
  reason: string;
  date_modified: string;
}

export interface ApplicationPaidConfirmedBy {
  username: string;
  first_name: string;
  last_name: string;
}

export interface ApplicationPropertyInformation {
  address: Address;
  parking: string;
  monthly_rent: string;
  desired_lease_start_date: string;
  desired_lease_end_date: string;
}

export interface ApplicationApplicantInformation {
  first_name: string;
  last_name: string;
  social_security_number: string;
  birthday: string;
  email: string;
  cell_phone: string;
  driver_licence: string;
  driver_licence_back_side: string;
}

export interface ApplicationCurrentAddressInformation {
  address: Address;
  how_many_month: number;
  monthly_rent: string;
  current_landlord: string;
  landlord_telephone: string;
  landlord_email: string;
  can_we_contact_your_landlord: string;
}

export interface ApplicationCurrentEmploymentInformation {
  currently_employed: boolean;
  employer: string;
  employer_address: any;
  salary: any;
  position: string;
  supervisor_phone: string;
  supervisor_email: string;
  additional_sources_of_income: string;
  images: any[];
  amount_and_frequency_of_additional_income?: string;
  length_of_employment?: string;
}

export interface ApplicationEmergencyContactInformation {
  address: Address;
  name: string;
  phone: string;
}

export interface ApplicationAdditionalInformation {
  have_a_pets: boolean;
  pets_description: string;
  occupants_description: string;
  agent: any;
  name_of_non_fgr_agent: string;
  additional_information: string;
  how_did_view_this_property: string;
  pets_images?: { image: string }[];
}

export enum ApplicationStatuses {
  NEW = 'New',
  IN_PROGRESS = 'In Progress',
  REJECTED = 'Rejected',

  // Deal-related statuses
  PENDING = 'Pending',
  CLOSED = 'Closed',
  ACTIVE = 'Active',
  DEAD = 'Dead',
  COMING_SOON = 'Coming Soon',
  ARCHIVED = 'Archived',
}

export interface ApplicationFormReport {
  id: number;
  status: string;
  type: ReportType;
}

export class ApplicationModel {
  public id: number;
  public property_information: ApplicationPropertyInformation;
  public applicant_information: ApplicationApplicantInformation;
  public current_address_information: ApplicationCurrentAddressInformation;
  public current_employment_information: ApplicationCurrentEmploymentInformation;
  public emergency_contact_information: ApplicationEmergencyContactInformation;
  public additional_information: ApplicationAdditionalInformation;
  public how_will_pay_the_application_fee: string;
  public status: string;
  public note: string;
  public status_changed_date: string;
  public status_changed_by: string;
  public deal: any;
  public date_created: string;
  public is_paid: boolean;
  public paid_confirmed_by: ApplicationPaidConfirmedBy;
  public paid_confirmed_date: string;
  public application_fee_payment: ApplicationFeePayment;
  public assigned_agent?: number;

  constructor(json: ApplicationResponseModel) {
    this.id = json.id;
    this.property_information = json.property_information;
    this.applicant_information = json.applicant_information;
    this.current_address_information = json.current_address_information;
    this.current_employment_information = json.current_employment_information;
    this.emergency_contact_information = json.emergency_contact_information;
    this.additional_information = json.additional_information;
    this.how_will_pay_the_application_fee =
      json.how_will_pay_the_application_fee;
    this.status = json.status;
    this.status_changed_date = json.status_changed_date;
    this.status_changed_by = json.status_changed_by;
    this.deal = json.deal;
    this.date_created = json.date_created;
    this.paid_confirmed_by = json.paid_confirmed_by;
    this.paid_confirmed_date = json.paid_confirmed_date;
    this.application_fee_payment = json.application_fee_payment;
    this.is_paid = json.is_paid;
    this.note = json.note;
    this.assigned_agent = json?.assigned_agent;
  }

  public hasField(key) {
    return !!this.property_information[key] || !!this[key];
  }

  public getField(key, def = null) {
    if (key === 'propertyAddress') {
      return this.prepareAddress(this.property_information['address']);
    }
    if (key === 'currentAddress') {
      return this.prepareAddress(this.current_address_information['address']);
    }
    if (key === 'currentEmployerAddress') {
      return this.prepareAddress(
        this.current_employment_information['employer_address']
      );
    }

    if (key === 'emergencyAddress') {
      return this.prepareAddress(this.emergency_contact_information['address']);
    }
    return this.property_information[key]
      ? this.property_information[key]
      : this[key]
      ? this[key]
      : def;
  }

  public getFirstName() {
    return this.getField('applicant_information')?.first_name;
  }

  public getLastName() {
    return this.getField('applicant_information')?.last_name;
  }

  public prepareAddress(addr) {
    if (!addr) {
      return '';
    }
    let addressLines = addr.address_line_1;
    if (addr.address_line_2) {
      addressLines = addr.address_line_1 + ', ' + addr.address_line_2;
    }
    const addressParts = [
      addressLines,
      addr.city,
      addr.state,
      addr.zip_code,
      addr.country,
    ];
    return addressParts.filter((v) => v && v.length > 0).join(', ');
  }

  public isNew() {
    return this.status === ApplicationStatuses.NEW;
  }

  public isArchived() {
    return (
      this.status === ApplicationStatuses.ARCHIVED ||
      this.status === ApplicationStatuses.REJECTED
    );
  }
}
