// eslint-disable  max-classes-per-file

import { AbstractField } from './abstract-field';
import { ConditionQuery } from './condition-query';

export class DropdownOption {
  public value: any;
  public label: string;
  public order: number;
  public conditions: ConditionQuery;

  constructor(option: any) {
    this.value = option.id || option.value;
    this.label = option.label || option.name;
    this.order = option.order || null;
    this.conditions = new ConditionQuery(option.conditions);
  }
}

export class DropdownField extends AbstractField {
  public options: DropdownOption[] = [];

  constructor(field: any) {
    super(field);
    if (field.options) {
      this.options = field.options.map((opt) => new DropdownOption(opt));
    }
  }

  public availableOptions(fields) {
    return this.options.filter((opt) => !opt.conditions.check(fields));
  }
}
