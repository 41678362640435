<div class="container-fluid">
  <div class="row">
    <ng-container
      *ngTemplateOutlet="
        data?.customConfig?.template || defaultNotification;
        context: { data: data, processClose: processClose.bind(this) }
      "
    ></ng-container>
  </div>
</div>

<ng-template
  #defaultNotification
  let-data="data"
  let-processClose="processClose"
>
  <div
    class="col-12 notify"
    [class.error]="data.type !== 'success'"
  >
    <a
      *ngIf="data.type === 'success'"
      class="icon-24"
      inlineSVG="/assets/svg/check-circle.svg"
    ></a>
    <a
      *ngIf="data.type !== 'success'"
      class="icon-24"
      inlineSVG="/assets/svg/close-circle.svg"
    ></a>

    <span class="mx-2">{{ data.text }}</span>

    <a
      (click)="processClose()"
      class="icon-24 close"
      inlineSVG="/assets/svg/close.svg"
    ></a>
  </div>
</ng-template>
