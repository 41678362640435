import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const flyInOut = trigger('flyInOut', [
  state('in', style({ transform: 'translateY(10%)', opacity: 0 })),
  transition('void => *', [
    style({ transform: 'translateY(-10%)', opacity: 1 }),
    animate(100),
  ]),
  transition('* => void', [
    animate(100, style({ transform: 'translateY(-10%)', opacity: 0 })),
  ]),
]);

export const flyInOutState = trigger('flyInOutState', [
  state(
    'shown',
    style({
      opacity: 1,
      transform: 'translateY(0)',
      display: 'block',
    })
  ),
  state(
    'hidden',
    style({
      opacity: 0,
      transform: 'translateY(-10%)',
      display: 'none',
    })
  ),
  transition('* => *', animate(100)),
]);

export const fadeIn = trigger('fadeIn', [
  state('in', style({ opacity: 0 })),
  transition('void => *', [style({ opacity: 1 }), animate(100)]),
  transition('* => void', [animate(100, style({ opacity: 0 }))]),
]);
