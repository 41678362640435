export class LocalPager {
  public static paginate(
    data: any[],
    page: number,
    perPage: number,
    loadMore?: boolean
  ): any[] {
    const start = loadMore ? 0 : perPage * (page - 1);
    return data.slice(start, perPage * page);
  }
}
