import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[otTimePickerFix]'
})
export class TimePickerFixDirective implements AfterViewInit {
  constructor(private renderer: Renderer2, private elem: ElementRef) {
    // eslint-disable-line , , , , , 
  }

  public ngAfterViewInit(): void {
    const inputs = this.elem.nativeElement.getElementsByTagName('input');
    Array.from(inputs).forEach((input) =>
      this.renderer.listen(input, 'keydown', (event) => {
        if (event.ctrlKey || event.altKey) {
          return;
        }
        const key = event.key || String.fromCharCode(event.charCode);
        if (key.length !== 1 || key === '\x00') {
          return;
        }
        // eslint-disable-line , , , , 
        if (key < '0' || key > '9') {
          event.preventDefault(); // Stop non-numeric}
        }
      })
    );
  }
}
