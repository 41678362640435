import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@tenant/helpers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContactDetailModel, ContactDetails } from '../models';

type GetContactExistsResponse = Pick<
  ContactDetails,
  'id' | 'email' | 'name' | 'phone'
>;

@Injectable()
export class ContactService {
  constructor(private api: HttpClient) {}

  public getContactList() {
    return this.api.get<any>(ApiConfig.contacts);
  }

  public create(data) {
    return this.api.post<any>(`${ApiConfig.contacts}/`, data);
  }

  public getContact(id) {
    return this.api
      .get<any>(`${ApiConfig.contacts}/${id}`)
      .pipe(map((res: any) => new ContactDetailModel(res)));
  }

  public patchContact(id, data) {
    return this.api
      .patch<any>(`${ApiConfig.contacts}/${id}`, data)
      .pipe(map((res: any) => new ContactDetailModel(res)));
  }

  public archiveContacts(ids: number[]) {
    return this.api.post<any>(`${ApiConfig.contacts}/archive/`, {
      contacts: ids,
    });
  }

  public restoreContacts(ids: number[]) {
    return this.api.post<any>(`${ApiConfig.contacts}/restore/`, {
      contacts: ids,
    });
  }

  public clearFailedReports(id: number): Observable<boolean> {
    return this.api.delete<any>(`${ApiConfig.contacts}/${id}/failed_reports`);
  }

  public getContactExists(email: string) {
    const url = new URL(ApiConfig.contactExists);
    url.searchParams.set('email', email);
    return this.api.get<GetContactExistsResponse>(url.toString());
  }

  public updateAssigneeUsers(contactId: number, assigneeUsers: number[]) {
    return this.api.post<any>(`${ApiConfig.contacts}/${contactId}/assign`, {
      assignee_users: assigneeUsers,
    });
  }
}
