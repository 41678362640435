import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ot-pick-photo',
  templateUrl: './pick-photo.component.html',
  styleUrls: ['./pick-photo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PickPhotoComponent implements OnInit, OnDestroy, OnChanges {
  @ContentChild('fileInput') public fileInput: ElementRef;
  @ContentChild('pickerTemplate') public pickerTemplate: TemplateRef<any>;
  @Input() public control: FormControl;
  @Input() public name = null;
  @Input() public sizeLimit = 2;
  @Input() public resize: { width: number; height: number } | null = null;
  @Input() public photo: string | ArrayBuffer = null;
  @Input() public resolveObject = false;
  @Output() public onChange: EventEmitter<any> = new EventEmitter<any>();
  public ctx: PickPhotoComponent;
  public files = [];

  public uploadError: string = null;
  public photoName: any;

  private subsc: Subscription;

  constructor(private sanitizer: DomSanitizer) {
    this.ctx = this;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.control) {
      this.subscribeToControlChanges();
      this.photo = this.control.value;
    }
  }

  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribeToControlChanges();
  }

  public changeFile($event) {
    const file = $event ? $event[0] : null;
    if (!file) {
      return;
    }
    const allowed = ['image/png', 'image/gif', 'image/jpeg'];
    if (allowed.indexOf(file.type) === -1) {
      // console.warn('This file type not allowed');
      this.uploadError = 'This file type not allowed';
      return;
    } else {
      this.uploadError = null;
    }
    if (file.size > this.sizeLimit * 1000000) {
      // console.warn('Image is too large');
      this.uploadError = 'Image is too large';
      return;
    }
    if (this.resolveObject) {
      this.control.setValue(file);
      this.onChange.emit(file);
      return;
    }
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.uploadError = null;
      this.photo = reader.result;
      if (this.resize) {
        /*const image = new Image();
        image.onload = (imageEvent) => {

          const canvas = document.createElement('canvas');
          canvas.style.backgroundColor = 'rgba(158, 167, 184, 0)';
          canvas.width = this.resize.width;
          canvas.height = this.resize.height;
          this.drawImageProp(canvas.getContext('2d'), image, 0, 0, this.resize.width, this.resize.height);
        };*/
        // image.src = event.target.result;

        this.photo = event.target.result;
        this.control.setValue(this.photo);
        this.onChange.emit(this.photo);
      } else {
        this.control.setValue(this.photo);
        this.onChange.emit(this.photo);
      }
      this.photoName = file.name;
    };

    reader.onerror = (event: any) => {
      // eslint-disable-line , 
    };

    reader.readAsDataURL(file);
  }

  public changeFileMultiple($event) {
    const allowed = ['image/png', 'image/gif', 'image/jpeg'];
    const files = [];
    for (const item in $event) {
      if ($event.hasOwnProperty(item)) {
        const file = $event[item];
        if (allowed.indexOf(file.type) === -1) {
          // console.warn('This file type not allowed');
          this.uploadError = 'This file type not allowed';
          return;
        } else {
          this.uploadError = null;
        }
        if (file.size > this.sizeLimit * 1000000) {
          // console.warn('Image is too large');
          this.uploadError = 'Image is too large';
          return;
        }
        files.push(file);
      }
    }
    this.control.setValue(files);
    this.onChange.emit(files);
  }

  public removeFile($event) {
    $event.preventDefault();
    if (this.photo !== null) {
      this.photo = null;
      this.fileInput.nativeElement.value = '';
      this.uploadError = null;
      this.control.setValue(null);
      this.onChange.emit(this.photo);
    }
  }

  public formatBytes(dm = 2) {
    const bytes = this.sizeLimit * 1024 * 1024;
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  private subscribeToControlChanges() {
    this.unsubscribeToControlChanges();
    if (this.control) {
      this.subsc = this.control.valueChanges.subscribe((v) => {
        this.photo = v;
      });
    }
  }

  private unsubscribeToControlChanges() {
    if (this.subsc) {
      this.subsc.unsubscribe();
    }
  }

  private drawImageProp(ctx, img, x, y, w, h, offsetX = 0, offsetY = 0) {
    /*
    if (arguments.length === 2) {
      x = y = 0;
      w = ctx.canvas.width;
      h = ctx.canvas.height;
    }

    // default offset is center
    offsetX = offsetX ? offsetX : 0.5;
    offsetY = offsetY ? offsetY : 0.5;

    // keep bounds [0.0, 1.0]
    if (offsetX < 0) {
      offsetX = 0;
    }
    if (offsetY < 0) {
      offsetY = 0;
    }
    if (offsetX > 1) {
      offsetX = 1;
    }
    if (offsetY > 1) {
      offsetY = 1;
    }

    const iw = img.width;
    const ih = img.height;
    const r = Math.min(w / iw, h / ih);
    let nw = iw * r;   /// new prop. width
    let nh = ih * r;   /// new prop. height
    let cx: number;
    let cy: number;
    let cw: number;
    let ch: number;
    let ar = 1;
        // decide which gap to fill
        if (nw < w) ar = w / nw;
        if (nh < h) ar = h / nh;
        nw *= ar;
        nh *= ar;

    // calc source rectangle
    cw = iw / (nw / w);
    ch = ih / (nh / h);

    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY;

    // make sure source rectangle is valid
    if (cx < 0) {
      cx = 0;
    }
    if (cy < 0) {
      cy = 0;
    }
    if (cw > iw) {
      cw = iw;
    }
    if (ch > ih) {
      ch = ih;
    }

    // fill image in dest. rectangle
    ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
    */

    // let hRatio = this.resize.width / img.width;
    // let vRatio = this.resize.height / img.height;
    // let ratio = Math.min(hRatio, vRatio);
    // ctx.fillStyle = '#ffffff';
    // ctx.fill();
    // ctx.restore()
    // ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, img.width * ratio, img.height * ratio);
    this.drawImageScaled(img, ctx);
  }

  private drawImageScaled(img, ctx) {
    const canvas = ctx.canvas;
    const hRatio = canvas.width / img.width;
    const vRatio = canvas.height / img.height;
    const ratio = Math.min(hRatio, vRatio);
    const centerShiftX = (canvas.width - img.width * ratio) / 2;
    const centerShiftY = (canvas.height - img.height * ratio) / 2;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(
      img,
      0,
      0,
      img.width,
      img.height,
      centerShiftX,
      centerShiftY,
      img.width * ratio,
      img.height * ratio
    );
  }
}
