import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DirectivesModule } from '@tenant/directives';
import { OtFormsModule } from '@tenant/forms';
import { OtModule, PipesModule } from '@tenant/ot';
import { TextMaskModule } from 'angular2-text-mask';

import { TaskDocumentItemComponent } from './task-document-item/task-document-item.component';
import { TaskExemptModalComponent } from './task-exempt-modal/task-exempt-modal.component';
import { TaskModalComponent } from './task-modal/task-modal.component';
import { TasksActionsService } from './tasks-actions.service';
import { TasksService } from './tasks.service';

const ENTRY_COMPONENTS = [TaskModalComponent, TaskExemptModalComponent];

const COMPONENTS = [TaskDocumentItemComponent];

const PROVIDERS = [TasksService, TasksActionsService];

@NgModule({
  imports: [
    CommonModule,
    OtModule,
    OtFormsModule,
    NgbModule,
    TextMaskModule,
    DirectivesModule,
    PipesModule,
  ],
  declarations: [ENTRY_COMPONENTS, COMPONENTS],
  providers: [PROVIDERS],
  exports: [ENTRY_COMPONENTS, COMPONENTS, NgbModule],
})
export class TasksModule {}
