<div [formGroup]="grossGroup">
  <ot-form-container *ngIf="config && group">
    <ot-label>
      {{ config.label }}
    </ot-label>
    <div class="group">
      <input otInput formControlName="value" [textMask]="currencyMask" [readonly]="!(valueEnabled$|async)"
             [required]="config?.required">
    </div>
    <div *ngIf="config.hint && config.hint.length > 0">
      <ot-form-hint (remove)="errors = null">{{config.hint}}</ot-form-hint>
    </div>
    <ot-form-error key="custom" (remove)="errors = null" *ngIf="errors && errors.length > 0">{{errors[0]}}
    </ot-form-error>
  </ot-form-container>
  <ot-checkbox2 formControlName="auto" *ngIf="autoEnabled$|async">Auto-Calculated</ot-checkbox2>
  <!---->
</div>
