<div [formGroup]="group">
  <ot-form-container *ngIf="config && group">
    <ot-label>
      {{ config.label }}
    </ot-label>
    <input type="text" otInput [formControlName]="config.field" [textMask]="percentsMask" [required]="config?.required">
    <ot-form-error key="custom" (remove)="errors = null" *ngIf="errors && errors.length > 0">{{errors[0]}}
    </ot-form-error>
  </ot-form-container>
</div>
