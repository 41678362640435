import { coerceNumberProperty } from '@angular/cdk/coercion';

export function parseSentenceForNumber(sentence) {
  if (typeof sentence === 'number') {
    return sentence;
  }
  if (!sentence) {
    return 0;
  }
  if (typeof sentence !== 'string') {
    return 0;
  }
  const matches = sentence
    .replace(/,/g, '')
    .match(/([+-])?((\d+(\.\d+)?)|(\.\d+))/);
  return coerceNumberProperty(matches && matches[0]);
}

export function deepCompare(x, y) {
  if (x === y) {
    return true;
  }

  if (!(x instanceof Object) || !(y instanceof Object)) {
    return false;
  }

  if (x.constructor !== y.constructor) {
    return false;
  }

  for (const p in x) {
    if (!x.hasOwnProperty(p)) {
      continue;
    }

    if (!y.hasOwnProperty(p)) {
      return false;
    }

    if (x[p] === y[p]) {
      continue;
    }

    if (typeof x[p] !== 'object' || !deepCompare(x[p], y[p])) {
      return false;
    }
  }

  for (const p in y) {
    if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
      return false;
    }
  }
  return true;
}

export function preformAddress(addr: any) {
  if (addr && typeof addr === 'object') {
    let addressLines = addr.address_line_1;
    if (addr.address_line_1) {
      addressLines = addr.address_line_1 + ' ' + addr.address_line_2;
    }
    const addressParts = [
      addressLines,
      addr.city,
      addr.state,
      addr.zip_code,
      addr.country,
    ];
    return addressParts.filter((v) => v && v.length > 0).join(', ');
  }
  return addr;
}

export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function mergeDeep(target, ...sources) {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        if (isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          }
          mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export function formatNumber(n, c = 0, d = '.', t = ',') {
  const s = n < 0 ? '-' : '';
  const i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)), 10));
  let j = i.length;
  j = j > 3 ? j % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
    (c
      ? d +
        Math.abs(n - +i)
          .toFixed(c)
          .slice(2)
      : '')
  );
}

export function formatPhone(number) {
  return number.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
}

export function findByObj(array: any[], needle: any, searchPath = null) {
  return array.find((obj) => {
    let found = true;
    Object.keys(needle).forEach((key) => {
      if (found === false) {
        return;
      }
      let foundValue = obj[key];
      if (searchPath) {
        foundValue = obj[searchPath][key];
      }
      if (needle[key] !== foundValue) {
        found = false;
      }
    });
    return found;
  });
}

export function dynamicSort(property) {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return (a, b) => {
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}
