import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { combineLatest, Observable } from 'rxjs';

@Directive({
  selector: '[otSticky]',
})
export class StickyDirective implements AfterViewInit, OnInit {
  @HostBinding('style.position') public position;
  @HostBinding('style.top') public top;
  @Input() public stickyClass = '';
  @Input() public stickyRecalc: Observable<any>[];
  private firstPos = 0;
  private isSticky = false;

  constructor(
    private element: ElementRef,
    private _renderer: Renderer2,
    private cdk: ChangeDetectorRef
  ) {}

  public ngOnInit() {}

  public ngAfterViewInit() {
    if (this.stickyRecalc) {
      combineLatest([...this.stickyRecalc]).subscribe((val) => {
        if (val.some((v) => v)) {
          this.reset();
          setTimeout(() => {
            this.firstPos = this.element.nativeElement.offsetTop;
          });
        }
      });
    }

    this.calculatePosition();
  }

  private calculatePosition() {
    this.firstPos = this.element.nativeElement.offsetTop;
    this.checkPosition();
    this.cdk.detectChanges();
  }

  @HostListener('window:scroll')
  private checkPosition() {
    const scrollTop = this.getScrollTop();
    if (scrollTop >= this.firstPos) {
      if (this.isSticky === false) {
        this.makeSticky();
      }
    } else {
      if (this.isSticky === true) {
        this.reset();
      }
    }
  }

  private makeSticky() {
    this.position = 'fixed';
    this.top = 0;
    this.isSticky = true;
    this._renderer.addClass(this.element.nativeElement, this.stickyClass);
  }

  private reset() {
    this.position = null;
    this.top = null;
    this.isSticky = false;
    this._renderer.removeClass(this.element.nativeElement, this.stickyClass);
  }

  private getScrollTop() {
    if (typeof pageYOffset !== 'undefined') {
      // most browsers except IE before #9
      return pageYOffset;
    } else {
      const B = document.body; // IE 'quirks'
      let D = document.documentElement; // IE with doctype
      D = D.clientHeight ? D : B;
      return D.scrollTop;
    }
  }
}
