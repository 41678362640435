<label [class.form-control-label]="_getDisplayedMessages() === 'error'">
  <ng-content select="ot-label"></ng-content>
  <span class="text-danger required-mark" *ngIf="_control?.required">*
</span>
  <ng-content select="ot-label-right"></ng-content>
</label>
<ng-content></ng-content>
<ot-form-error key="required"> This field is required</ot-form-error>
<ot-form-error key="noEmptyString"> This field is required</ot-form-error>
<ot-form-error key="email"> Email is not valid</ot-form-error>
<ot-form-error key="pattern"> This field is not valid</ot-form-error>
<ot-form-error key="url"> URL is not valid</ot-form-error>
<ot-form-error key="minlength"> This field is too short</ot-form-error>
<ot-form-error key="maxlength"> This field is too long</ot-form-error>
<ot-form-error key="noPhoneNumber"> Phone number is not valid</ot-form-error>
<ot-form-error key="duplicated"> This field was duplicated </ot-form-error>

<ng-content select="ot-form-error"></ng-content>
