<!--<div [class]="'form-group ' + className" [class.has-danger]="isInvalid" [class.has-success]="isSuccess">
  <label [for]="name" *ngIf="label && hideLabel == false">

  </label>

  <ng-select [id]="name" [options]="options" [formControl]="control" [placeholder]="placeholder"
  [disabled]="disabled">
    &lt;!&ndash;[multiple]="true"&ndash;&gt;
  </ng-select>
  <div class="form-control-feedback" *ngIf="isInvalid">{{ actualErrors }}</div>
</div>-->
<ot-form-container [ngClass]="className" [class.hide-label]="!label || hideLabel != false">
  <ot-label *ngIf="label && hideLabel == false">
    {{label}}
    <span class="optional" *ngIf="optional">(optional)</span>
  </ot-label>
  <ot-select-box [clear]="clear" [forceEmpty]="forceEmpty" otInput [formControl]="control" [options]="options" [disabled]="disabled" [required]="required"
                 [placeholder]="placeholder" [id]="name"></ot-select-box>
  <ot-form-error (remove)="serverErrors=null" *ngIf="serverErrors">{{serverErrors[0]}}</ot-form-error>
</ot-form-container>
