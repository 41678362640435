import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[otFilter]'
})
export class FilterDirective {
  @Input('otFilter') public name = '';
  @Input('defaultValue') public defaultValue: string | string[] = '';

  constructor(public template: TemplateRef<any>) {}
}
