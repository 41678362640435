import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dealBoostGiftTypes',
})
export class DealBoostGiftTypes implements PipeTransform {
  public transform(value: any): string {
    if (value === 'E') {
      return 'E-Gift Card';
    } else if (value === 'JC') {
      return 'Jarrred Cakes ($55)';
    } else if (value === 'B') {
      return '1 Bottle of Wine ($15)';
    } else if (value === 'M') {
      return 'Chicago Magazine Subscription ($15)';
    } else if (value === 'N') {
      return 'No Gift';
    } else if (value === 'O') {
      return 'Other';
    } else {
      return value;
    }
  }
}
