<div class="stripe-modal">
  <div class="stripe-backdrop"></div>
  <div class="stripe-form">
    <div class="stripe-form-header">
      <span>Pay Application Fee</span>
    </div>
    <ng-container *ngIf="showCard">
      <div class="form-group">
        <p class="title">
          {{ applicationFee }}{{ applicationFeeAmount | currency }}
        </p>
      </div>
      <div class="form-group has-danger" id="card-form">
        <ngx-stripe-card
          [options]="cardOptions"
          [elementsOptions]="elementsOptions"
        ></ngx-stripe-card>
        <div class="form-control-feedback"></div>
      </div>
      <div class="stripe-form-footer">
        <button
          (click)="pay()"
          [disabled]="disabledPay"
          class="btn btn-primary btn-block btn-pay"
        >
          Pay {{ applicationFeeAmount | currency }}
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="showError">
      <div class="form-group">
        {{ errorMessage }}
      </div>
    </ng-container>
    <ng-container *ngIf="showSucceed">
      <div class="form-group">Your payment was successful</div>
    </ng-container>
  </div>
</div>
