// eslint-disable  @typescript-eslint/member-ordering
import {
  Directive,
  DoCheck,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Renderer2,
  Self
} from '@angular/core';
import {
  FormControl,
  FormGroupDirective,
  NgControl,
  NgForm
} from '@angular/forms';
import { Subject } from 'rxjs';
import {
  defaultErrorStateMatcher,
  ERROR_GLOBAL_OPTIONS,
  ErrorOptions,
  ErrorStateMatcher
} from './error-options';
import { FormFieldControl } from './form-field-control';

let nextUniqueId = 0;

@Directive({
  selector: 'input[otInput], textarea[otInput]',
  providers: [{ provide: FormFieldControl, useExisting: OtInputDirective }]
})
export class OtInputDirective
  implements FormFieldControl<any>, OnChanges, OnDestroy, DoCheck {
  protected _type = 'text';

  /** Input type of the element. */
  @Input()
  public get type() {
    return this._type;
  }

  public set type(value: string) {
    this._type = value || 'text';

    // When using Angular inputs, developers are no longer able to set the properties on the native
    // input element. To ensure that bindings for `type` work, we need to sync the setter
    // with the native property. Textarea elements don't support the type property or attribute.
    if (!this._isTextarea()) {
      this._renderer.setProperty(
        this._elementRef.nativeElement,
        'type',
        this._type
      );
    }
  }

  @HostBinding('disabled') protected _disabled = false;

  /** Whether the element is disabled. */
  @Input()
  get disabled() {
    return this.ngControl ? this.ngControl.disabled : this._disabled;
  }

  set disabled(value: any) {
    this._disabled = !!value;
  }

  @HostBinding('required') protected _required = false;

  /** Whether the element is required. */
  @Input()
  get required() {
    return this._required;
  }

  set required(value: any) {
    this._required = !!value;
  }

  protected _id: string;

  /** Unique id of the element. */
  @Input()
  get id() {
    return this._id;
  }

  set id(value: string) {
    this._id = value || this._uid;
  }

  /** The input element's value. */
  get value() {
    return this._elementRef.nativeElement.value;
  }

  set value(value: string) {
    if (value !== this.value) {
      this._elementRef.nativeElement.value = value;
      this.stateChanges.next();
    }
  }

  // Implemented as part of MdFormFieldControl.
  get empty(): boolean {
    return (this.value == null || this.value === '') && !this._isBadInput();
  }

  /** Whether the input is focused. */
  public focused = false;
  /** Whether the input is in an error state. */
  @HostBinding('class.form-control') public formControl = true;
  @HostBinding('class.is-valid') public formControlSuccess = false;
  @HostBinding('class.is-invalid') public formControlDanger = false;
  @HostBinding('attr.aria-invalid') public errorState = false;
  /** The aria-describedby attribute on the input for improved a11y. */
  @HostBinding('attr.aria-describedby') public _ariaDescribedby: string;
  /**
   * Stream that emits whenever the state of the input changes such that the wrapping `MdFormField`
   * needs to run change detection.
   */
  public stateChanges = new Subject<void>();
  /** Placeholder attribute of the element. */
  @HostBinding('attr.placeholder')
  @Input()
  public placeholder = '';
  /** A function used to control when error messages are shown. */
  @Input() public errorStateMatcher: ErrorStateMatcher;
  protected _uid = `ot-input-${nextUniqueId++}`;
  protected _errorOptions: ErrorOptions;
  protected _previousNativeValue = this.value;

  constructor(
    protected _elementRef: ElementRef,
    protected _renderer: Renderer2,
    @Optional()
    @Self()
    public ngControl: NgControl,
    @Optional() protected _parentForm: NgForm,
    @Optional() protected _parentFormGroup: FormGroupDirective,
    @Optional()
    @Inject(ERROR_GLOBAL_OPTIONS)
    errorOptions: ErrorOptions
  ) {
    this.id = this.id;
    this._errorOptions = errorOptions ? errorOptions : {};
    this.errorStateMatcher =
      this._errorOptions.errorStateMatcher || defaultErrorStateMatcher;
  }

  public ngOnChanges() {
    this.stateChanges.next();
  }

  public ngOnDestroy() {
    this.stateChanges.complete();
  }

  public ngDoCheck() {
    if (this.ngControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions). This means
      // that whatever logic is in here has to be super lean or we risk destroying the performance.
      this._updateErrorState();
    } else {
      // When the input isn't used together with `@angular/forms`, we need to check manually for
      // changes to the native `value` property in order to update the floating label.
      this._dirtyCheckNativeValue();
    }
  }

  /** Callback for the cases where the focused state of the input changes. */
  @HostListener('blur', ['false'])
  @HostListener('focus', ['true'])
  public _focusChanged(isFocused: boolean) {
    if (isFocused !== this.focused) {
      this.focused = isFocused;
      this.stateChanges.next();
    }
  }

  @HostListener('input')
  public _onInput() {
    // This is a noop function and is used to let Angular know whenever the value changes.
    // Angular will run a new change detection each time the `input` event has been dispatched.
    // It's necessary that Angular recognizes the value change, because when floatingLabel
    // is set to false and Angular forms aren't used, the placeholder won't recognize the
    // value changes and will not disappear.
    // Listening to the input event wouldn't be necessary when the input is using the
    // FormsModule or ReactiveFormsModule, because Angular forms also listens to input events.
  }

  // Implemented as part of MdFormFieldControl.
  public setDescribedByIds(ids: string[]) {
    this._ariaDescribedby = ids.join(' ');
  }

  // Implemented as part of MdFormFieldControl.
  public focus() {
    this._elementRef.nativeElement?.focus();
  }

  /** Re-evaluates the error state. This is only relevant with @angular/forms. */
  protected _updateErrorState() {
    const oldState = this.errorState;
    const ngControl = this.ngControl;
    const parent = this._parentForm || this._parentFormGroup;
    const newState =
      ngControl &&
      this.errorStateMatcher(ngControl.control as FormControl, parent);

    if (newState !== oldState) {
      this.errorState = newState;
      this.formControlDanger = newState;
      this.formControlSuccess = !this.formControlDanger;
      this.stateChanges.next();
    }
  }

  /** Does some manual dirty checking on the native input `value` property. */
  protected _dirtyCheckNativeValue() {
    const newValue = this.value;

    if (this._previousNativeValue !== newValue) {
      this._previousNativeValue = newValue;
      this.stateChanges.next();
    }
  }

  /** Checks whether the input is invalid based on the native validation. */
  protected _isBadInput() {
    // The `validity` property won't be present on platform-server.
    const validity = (this._elementRef.nativeElement as HTMLInputElement)
      .validity;
    return validity && validity.badInput;
  }

  /** Determines if the component host is a textarea. If not recognizable it returns false. */
  protected _isTextarea() {
    const nativeElement = this._elementRef.nativeElement;

    // In Universal, we don't have access to `nodeName`, but the same can be achieved with `name`.
    // Note that this shouldn't be necessary once Angular switches to an API that resembles the
    // DOM closer.
    const nodeName = nativeElement.nodeName;
    return nodeName ? nodeName?.toLowerCase() === 'textarea' : false;
  }
}
