<div [formGroup]="group.get('closing_questions')" *ngIf="dealClosingQuestionTemplates.length">
  <ng-container *ngFor="let item of dealClosingQuestionTemplates">
    <ng-container *ngIf="item.question_type === 'main' || item.question_type === 'show_boost'">
      <ng-container *ngTemplateOutlet="questions"></ng-container>
    </ng-container>
    <ng-container *ngIf="item.question_type === 'boost' && showBoost">
      <ng-container *ngTemplateOutlet="questions"></ng-container>
    </ng-container>
    <ng-template #questions>
      <ot-form-container>
        <ot-label>
          {{item.question}}
        </ot-label>
        <ot-select-box *ngIf="item.answer_type === 'dropdown' && item.question_type !== 'show_boost'"
          [required]="item.required" otInput formControlName="{{item.id}}">
          <ot-select-item *ngFor="let answer of item.answer_options" value="{{answer.text}}">{{answer.text}}
          </ot-select-item>
        </ot-select-box>
        <ot-select-box *ngIf="item.answer_type === 'dropdown' && item.question_type === 'show_boost'"
          [required]="item.required" otInput formControlName="{{item.id}}" (ngModelChange)="showBoostChange($event)">
          <ot-select-item *ngFor="let answer of item.answer_options" value="{{answer.text}}">{{answer.text}}
          </ot-select-item>
        </ot-select-box>
        <textarea *ngIf="item.answer_type === 'textarea'" [required]="item.required" otInput
          formControlName="{{item.id}}">
      </textarea>
        <input *ngIf="item.answer_type === 'decimal'" [required]="item.required" [textMask]="numberMask" otInput
          formControlName="{{item.id}}">
        <input *ngIf="item.answer_type === 'text'" [required]="item.required" otInput formControlName="{{item.id}}">
        <input *ngIf="item.answer_type === 'currency'" [required]="item.required" [textMask]="currencyMask" otInput
          formControlName="{{item.id}}">
        <ot-date *ngIf="item.answer_type === 'date'" [required]="item.required" otInput formControlName="{{item.id}}">
        </ot-date>
        <ot-form-error key="custom" (remove)="errors = null" *ngIf="errors && errors.length > 0">{{errors[0]}}
        </ot-form-error>
      </ot-form-container>
    </ng-template>
  </ng-container>
</div>