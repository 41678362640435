<div class="password__strength" #strength>
  <small *ngIf="barLabel">{{barLabel}}</small>
  <ul class="password__strength__bar">
    <li class="point" [style.background-color]="bar0"></li>
    <li class="point" [style.background-color]="bar1"></li>
    <li class="point" [style.background-color]="bar2"></li>
    <li class="point" [style.background-color]="bar3"></li>
    <li class="point" [style.background-color]="bar4"></li>
  </ul>
</div>
