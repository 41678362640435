// eslint-disable  @typescript-eslint/member-ordering

import {
  AfterContentChecked,
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  HostBinding,
  Input,
  QueryList,
  Renderer2,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { AutoUnsubscribe } from '@tenant/helpers';
import { Subscription } from 'rxjs';
import { startWith, take } from 'rxjs/operators';
import { FormFieldControl } from '../form-field-control';
import { OtFormErrorDirective } from '../ot-form-error.directive';
import { OtFormHintDirective } from '../ot-form-hint.directive';

let nextUniqueId = 0;

@Component({
  selector: 'ot-form-container',
  templateUrl: './form-container.component.html',
  styleUrls: ['./form-container.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class FormContainerComponent
  implements AfterContentInit, AfterContentChecked {
  private _hideRequiredMarker: boolean;

  @Input()
  get hideRequiredMarker() {
    return this._hideRequiredMarker;
  }

  set hideRequiredMarker(value: any) {
    this._hideRequiredMarker = !!value;
  }

  private _hintLabel = '';

  @Input()
  get hintLabel() {
    return this._hintLabel;
  }

  set hintLabel(value: string) {
    this._hintLabel = value;
    this._processHints();
  }

  public _hintLabelId = `md-hint-${nextUniqueId++}`;
  @ContentChild(FormFieldControl) public _control: FormFieldControl<any>;
  @ContentChildren(OtFormErrorDirective)
  public _errorChildren: QueryList<OtFormErrorDirective>;
  @ViewChildren(OtFormErrorDirective)
  public _defaultErrorChildren: QueryList<OtFormErrorDirective>;
  @ContentChildren(OtFormHintDirective)
  public _hintChildren: QueryList<OtFormHintDirective>;
  @HostBinding('class.form-group') private className = true;
  @HostBinding('class.has-success') private hasSuccess = false;
  @HostBinding('class.has-danger') private hasDanger = false;
  @HostBinding('class.ng-untouched') private ngUntouched = false;
  @HostBinding('class.ng-touched') private ngTouched = false;
  @HostBinding('class.ng-pristine') private ngPristine = false;
  @HostBinding('class.ng-dirty') private ngDirty = false;
  @HostBinding('class.ng-valid') private ngValid = false;
  @HostBinding('class.ng-invalid') private ngInvalid = false;
  @HostBinding('class.ng-pending') private ngPending = false;
  private tmpSubscription$: Subscription;

  constructor(
    public _elementRef: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef,
    private _renderer: Renderer2
  ) {}

  public ngAfterContentInit() {
    this._validateControlChild();

    // Subscribe to changes in the child control state in order to update the form field UI.
    if (!this._control) {
      return;
    }
    this._control.stateChanges.pipe(startWith(null)).subscribe(() => {
      this._syncDescribedByIds();
      this._showErrors();
      this.hasSuccess = (this._control as any).formControlSuccess;
      this.hasDanger = (this._control as any).formControlDanger;
      this._changeDetectorRef.markForCheck();
    });

    const ngControl = this._control.ngControl;
    if (ngControl && ngControl.valueChanges) {
      ngControl.valueChanges.subscribe(() => {
        this._changeDetectorRef.markForCheck();
      });
      ngControl.statusChanges.subscribe(() => {
        this.ngUntouched = this._shouldForward('untouched');
        this.ngTouched = this._shouldForward('touched');
        this.ngPristine = this._shouldForward('pristine');
        this.ngDirty = this._shouldForward('dirty');
        this.ngValid = this._shouldForward('valid');
        this.ngInvalid = this._shouldForward('invalid');
        this.ngPending = this._shouldForward('pending');
        this._changeDetectorRef.markForCheck();
      });
    }

    // Re-validate when the number of hints changes.
    this._hintChildren.changes.pipe(startWith(null)).subscribe(() => {
      this._processHints();
      this._changeDetectorRef.markForCheck();
    });

    // Update the aria-described by when the number of errors changes.
    this._errorChildren.changes.pipe(startWith(null)).subscribe(() => {
      this._syncDescribedByIds();
      this._showErrors();
      this._changeDetectorRef.markForCheck();
    });
  }

  public ngAfterContentChecked() {
    this._validateControlChild();
  }

  /** Determines whether a class from the NgControl should be forwarded to the host element. */
  public _shouldForward(prop: string): boolean {
    const ngControl = this._control ? this._control.ngControl : null;
    return ngControl && (ngControl as any)[prop];
  }

  /** Determines whether to display hints or errors. */
  public _getDisplayedMessages(): 'error' | 'hint' {
    const hasServerError = this._errorChildren.find((v) => v.key === 'custom');
    return ((this._errorChildren && this._errorChildren.length > 0) ||
      (this._defaultErrorChildren && this._defaultErrorChildren.length > 0)) &&
      ((this._control && this._control.errorState) || hasServerError)
      ? 'error'
      : 'hint';
  }

  /** Throws an error if the form field's control is missing. */
  protected _validateControlChild() {
    if (!this._control) {
      return Error(
        'ot-form-container must contain a FormFieldControl. ' +
          'Did you forget to add mdInput to the native input or textarea element?'
      );
    }
  }

  /** Does any extra processing that is required when handling the hints. */
  private _processHints() {
    this._syncDescribedByIds();
  }

  /**
   * Sets the list of element IDs that describe the child control. This allows the control to update
   * its `aria-describedby` attribute accordingly.
   */
  private _syncDescribedByIds() {
    if (this._control) {
      let ids: string[] = [];

      if (this._getDisplayedMessages() === 'hint') {
        ids = this._hintChildren.map((mdError) => mdError.id);
      } else if (this._errorChildren && this._defaultErrorChildren) {
        // eslint-disable-line
        ids = [
          ...this._errorChildren.map((mdError) => mdError.id),
          ...this._defaultErrorChildren.map((mdError) => mdError.id)
        ];
      }

      this._control.setDescribedByIds(ids);
    }
  }

  private _showErrors() {
    if (this._control) {
      const errors: any = {};
      if (this._defaultErrorChildren) {
        this._defaultErrorChildren.forEach((error) => {
          errors[error.key] = error;
          this._renderer.addClass(error.element.nativeElement, 'd-none');
        });
      }
      if (this._errorChildren) {
        this._errorChildren.forEach((error) => {
          errors[error.key] = error;
          this._renderer.addClass(error.element.nativeElement, 'd-none');
        });
      }

      if (this._getDisplayedMessages() === 'hint') {
        // pass
      } else {
        const actualErrors = this._control.ngControl
          ? Object.keys(this._control.ngControl.control.errors || {})
          : [];
        Object.keys(errors).forEach((key) => {
          const error = errors[key] as OtFormErrorDirective;
          if (
            (errors.custom && key === 'custom') ||
            (!errors.custom &&
              actualErrors.length > 0 &&
              key === actualErrors[0])
          ) {
            // this._changeDetectorRef.checkNoChanges();
            // errors[key].hidden = false;
            this._renderer.removeClass(error.element.nativeElement, 'd-none');

            if (error.key === 'custom' && error.destroy) {
              // eslint-disable-line , 
              if (this.tmpSubscription$) {
                this.tmpSubscription$.unsubscribe();
              }
              this.tmpSubscription$ = this._control.ngControl.control.valueChanges
                .pipe(take(1))
                .subscribe(() => {
                  error.destroy();
                });
            }
            return;
          }
          // this._changeDetectorRef.checkNoChanges();
          // errors[key].hidden = true;
        });
        this._changeDetectorRef.markForCheck();
        /*if (errors.custom) {
          errors.custom.hidden = false;
          this._changeDetectorRef.markForCheck();
          this.tmpSubscription$ = this._control.ngControl.control.valueChanges.take(1).subscribe(() => {
            if (errors.custom && errors.custom.destroy) {
              errors.custom.destroy();
            }
          });
          errors.forEach((err) => err.hidden = true);
        } else {
          if (actualErrors.length > 0) {
            const error = errors[actualErrors[0]];
            if (error) {
              error.hidden = false;
              this._changeDetectorRef.markForCheck();
            }
          }
        }*/
      }
    }
  }
}
