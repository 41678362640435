import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ExportExtensions } from './export-extensions';

@Component({
  selector: 'ot-export-button',
  templateUrl: 'export-button.component.html',
  styleUrls: ['export-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExportButtonComponent {
  @Input() public currentCount = 0;
  @Input() public allCount = 0;
  @Input() public selectedCount = 0;
  @Input()
  public extensions = [
    ExportExtensions.xlsx,
    ExportExtensions.csv,
    ExportExtensions.pdf
  ];
}
