import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError as _throw } from 'rxjs';
import {
  concat,
  delay,
  retryWhen,
  scan,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs/operators';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req.clone()).pipe(
      tap((res) => {
        if (res instanceof HttpResponse) {
          const redirect = res.headers.get('X-Redirect-To');
          if (redirect) {
            switch (redirect) {
              case 'DealList':
                this.router.navigate(['/deal']);
                break;
              case 'ContactList':
                this.router.navigate(['/contacts']);
                break;
              default:
                break;
            }
          }
        }
      }),
      retryWhen((errors) => {
        return errors.pipe(
          switchMap((error: HttpErrorResponse) => {
            if (
              error.status === 504 ||
              error.status === 503 ||
              error.status === 502
            ) {
              return of(error.status);
            }
            return _throw(error);
          }),
          scan((acc) => {
            return acc + 1;
          }, 0),
          takeWhile((acc) => acc < 15),
          delay(1000),
          concat(_throw({ message: '502 Error after 15 retry' }))
        );
      })
    );
  }
}
