import {
  CdkCell,
  CdkCellDef,
  CdkColumnDef,
  CdkHeaderCell,
  CdkHeaderCellDef
} from '@angular/cdk/table';
// eslint-disable  @angular-eslint/directive-class-suffix, @angular-eslint/component-class-suffix
// eslint-disable  max-classes-per-file, @angular-eslint/no-input-rename, @angular-eslint/directive-selector
import { Directive, ElementRef, Input } from '@angular/core';

export const _OtCellDef = CdkCellDef;
export const _OtHeaderCellDef = CdkHeaderCellDef;
export const _OtColumnDef = CdkColumnDef;
export const _OtHeaderCell = CdkHeaderCell;
export const _OtCell = CdkCell;

@Directive({
  selector: '[otCellDef]',
  providers: [{ provide: CdkCellDef, useExisting: OtCellDef }]
})
export class OtCellDef extends _OtCellDef {}

@Directive({
  selector: '[otHeaderCellDef]',
  providers: [{ provide: CdkHeaderCellDef, useExisting: OtHeaderCellDef }]
})
export class OtHeaderCellDef extends _OtHeaderCellDef {}

@Directive({
  selector: '[otColumnDef]',
  providers: [{ provide: CdkColumnDef, useExisting: OtColumnDef }]
})
export class OtColumnDef extends _OtColumnDef {
  // @ts-ignore
  @Input('otColumnDef') public name: string;
}

@Directive({
  selector: 'ot-header-cell',
  host: {
    class: 'ot-header-cell',
    role: 'columnheader'
  }
})
export class OtHeaderCell extends _OtHeaderCell {
  constructor(columnDef: CdkColumnDef, elementRef: ElementRef) {
    super(columnDef, elementRef);
    elementRef.nativeElement.classList.add(
      `ot-column-${columnDef.cssClassFriendlyName}`
    );
  }
}

@Directive({
  selector: 'ot-cell',
  host: {
    class: 'ot-cell',
    role: 'gridcell'
  }
})
export class OtCell extends _OtCell {
  constructor(columnDef: CdkColumnDef, elementRef: ElementRef) {
    super(columnDef, elementRef);
    elementRef.nativeElement.classList.add(
      `ot-column-${columnDef.cssClassFriendlyName}`
    );
  }
}
