import { Component, Input } from '@angular/core';
import { COMPANY_GROSS_COMMISSION_STATUSES } from '../../../deal/deal-details/commission-tracker-view/commission-tracker-view.model';

@Component({
  selector: 'ot-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent {
  @Input() status: string | COMPANY_GROSS_COMMISSION_STATUSES;

  get label() {
    return this.status?.replace('_', ' ') || '';
  }

  get statusClass() {
    return this.status?.toLowerCase() || '';
  }
}
