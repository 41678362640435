// eslint-disable  max-classes-per-file

import { TemplateRef } from '@angular/core';
import {
  TypeAHeadFormatFunction,
  TypeAHeadSearchFunction,
} from '@tenant/forms';
import { BaseDataSource } from './ds/base.data-source';

export interface TableCheckboxMeta {
  enable: boolean;
  templateHead?: TemplateRef<any>;
  templateCell?: TemplateRef<any>;
  checkboxStyle?: any;
  disabledIf?: (row: any) => boolean;
}

export interface TableMeta {
  order?: { key: string; direction: string };
  checkbox?: boolean;
}

export interface ITableColumn {
  label: string;
  field: string;
  sortable?: boolean;
  sortableKey?: string;
  headerTemplate?: TemplateRef<any>;
  cellTemplate?: TemplateRef<any>;
  hidden?: boolean;
  headerStyle?: any;
  cellStyle?: any;
  checkboxCell?: boolean;
  permissions?: string[];
}

export interface ITableFilter {
  type:
    | 'search'
    | 'text'
    | 'dropdown'
    | 'date-range'
    | 'typeahead'
    | 'checkbox';
  value?: any;
  requestKey: string;
  label?: string;
  clear?: boolean;
  options?: any[];
  meta?: any;
  style?: any;
  rangePrefix?: string | null;
  searchFunction?: TypeAHeadSearchFunction;
  formatResult?: TypeAHeadFormatFunction;
  hideOptions?: string[];
  permissions?: string[];
  customParams?: string;
  formatDateTime?: string;
}

export class TableColumn {
  public label: string;
  public field: string;
  public sortable = true;
  public sortableKey = null;
  public headerTemplate: TemplateRef<any> = null;
  public cellTemplate: TemplateRef<any> = null;
  public hidden = false;
  public headerStyle: any;
  public cellStyle: any;
  public permissions: string[];
  public checkboxCell = false;
  public customParams = null;
  constructor({
    label,
    field,
    sortable = true,
    headerTemplate = null,
    cellTemplate,
    headerStyle,
    cellStyle,
    sortableKey,
    permissions,
    hidden = false,
    checkboxCell = false,
  }: ITableColumn) {
    this.label = label;
    this.field = field;
    this.sortable =
      typeof sortable !== 'undefined'
        ? sortable
        : typeof sortableKey !== 'undefined';
    this.sortableKey = sortableKey;
    this.headerTemplate = headerTemplate;
    this.cellTemplate = cellTemplate;
    this.headerStyle = headerStyle;
    this.cellStyle = cellStyle;
    this.hidden = hidden;
    this.permissions = permissions || [];
    this.checkboxCell = checkboxCell;
  }
}

export class TableFilter implements ITableFilter {
  public type:
    | 'search'
    | 'text'
    | 'dropdown'
    | 'date-range'
    | 'typeahead'
    | 'checkbox';
  public value: any;
  public requestKey: string;
  public label: string;
  public options: any[] = [];
  public permissions: string[] = [];
  public meta: any;
  public rangePrefix: string | null;
  public clear: boolean;
  public style: any;
  public hideOptions: string[] = [];
  public searchFunction: TypeAHeadSearchFunction;
  public formatResult: TypeAHeadFormatFunction;
  public customParams = null;
  public formatDateTime = null;

  constructor({
    type,
    value,
    requestKey,
    label,
    options,
    meta,
    searchFunction,
    formatResult,
    style,
    permissions,
    clear,
    hideOptions,
    rangePrefix = null,
    customParams = null,
    formatDateTime = null,
  }: ITableFilter) {
    this.type = type;
    this.value = value;
    this.requestKey = requestKey;
    this.clear = clear;
    this.label = label;
    this.options = options;
    this.permissions = permissions || [];
    this.hideOptions = hideOptions || [];
    this.meta = meta;
    this.style = style;
    this.rangePrefix = rangePrefix;
    this.searchFunction = searchFunction;
    this.formatResult = formatResult;
    this.customParams = customParams;
    this.formatDateTime = formatDateTime;
  }
}

export class TableConfig {
  public columns: TableColumn[] = [];
  public filters: TableFilter[] = [];
  public dataSource: BaseDataSource;
  public meta: TableMeta = {};

  constructor({ columns, filters = [], dataSource, meta = {} }: any) {
    this.columns = columns;
    this.filters = filters;
    this.dataSource = dataSource;
    this.meta = meta;
  }
}
