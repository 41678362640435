interface IRule {
  field: string;
  operator: 'equal';
  value: string;
  result?: boolean;
  compareValue?: any;
}

export interface IConditionQuery {
  condition: 'AND' | 'OR';
  rules: Array<IRule | IConditionQuery>;
  result?: boolean;
  compareValue?: any;
}

import * as dot from 'dot-object';

export class ConditionQuery implements IConditionQuery {
  public get fields() {
    const fields = [];
    this.rules.forEach((rule) => {
      if (rule instanceof ConditionQuery) {
        fields.push(...rule.fields);
        return;
      }
      fields.push((rule as IRule).field);
    });
    return fields.filter((v, i, a) => a.indexOf(v) === i);
  }

  public condition: 'AND' | 'OR' = 'OR';
  public rules: Array<IRule | IConditionQuery> = [];
  public result;
  public compareValue?: any;

  constructor(query: IConditionQuery) {
    if (!query) {
      return;
    }
    this.condition = query.condition;
    if (query.rules) {
      this.rules = query.rules.map((q: any) => {
        q = Object.assign({}, q);
        if (q.rules) {
          return new ConditionQuery(q);
        }

        try {
          q.value = JSON.parse(q.value);
        } catch (e) {
          // pass
        }

        return q;
      });
    }
  }

  public check(values) {
    if (!this.rules || this.rules.length === 0) {
      return false;
    }
    const results = this.rules.map((rule) => {
      if (rule instanceof ConditionQuery) {
        rule.result = rule.check(values);
        return rule.result;
      }
      rule.result = this.checkRule(rule as IRule, values);
      rule.compareValue = values && values[(rule as IRule).field];
      return rule.result;
    });

    if (this.condition === 'AND') {
      return results.filter((v) => v === false).length === 0;
    } else {
      return results.filter((v) => v === true).length > 0;
    }
  }

  private checkRule(rule: IRule, values: any = {}) {
    switch (rule.operator) {
      case 'equal':
        // eslint-disable  eqeqeq
        if (!values) {
          return true;
        }
        let val = values[rule.field];
        if (Array.isArray(rule.field)) {
          val = dot.pick(rule.field.join('.'), values);
          if (!val) {
            val = values[rule.field.join(',')];
          }
        }
        return (
          val == null || val == rule.value || JSON.stringify(val) === rule.value
        );
      /* eslint-enable */
      default:
        return true;
    }
  }
}
