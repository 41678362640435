import { Component, OnInit, QueryList } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFieldDirective } from '../dynamic-field.directive';
import { AbstractField } from '../models/abstract-field';
import { Field } from '../models/field.interface';
import { GroupField } from '../models/group-field';

@Component({
  selector: 'tenant-dynamic-header',
  templateUrl: './dynamic-header.component.html',
  styleUrls: ['./dynamic-header.component.css'],
  providers: [{ provide: Field, useExisting: DynamicHeaderComponent }]
})
export class DynamicHeaderComponent implements Field {
  public config: AbstractField;
  public group: FormGroup;
  public rootGroup: FormGroup;
  public errors: any = null;
  public readonly: any = false;
  public inputs?: QueryList<DynamicFieldDirective>;
}
