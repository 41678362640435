export interface ProfileType {
  is_company_admin: boolean;
  is_listing_manager: boolean;
  is_agent: boolean;
  is_listing_user: boolean;
  is_ot_admin: boolean;
  is_teamlead: boolean;
  is_ot_agent: boolean;
}

export enum UserPermissions {
  USER_MANAGEMENT = 'User Management',
  USER_DOCUMENTS = 'User Documents',
  PAYLOAD_PERMISSION = 'Payload Permission',
}

export class Profile {
  public get firstName() {
    if (!this.first_name) {
      return 'Guest';
    }
    return this.first_name;
  }

  public id: number;
  public email: string;
  public first_name: string;
  public last_name: string;
  public types: string[];
  public roles: ProfileType;
  public permissions: UserPermissions[];

  constructor(json: any) {
    const { id, email, first_name, last_name, types, roles, permissions } =
      json;
    Object.assign(this, json);
    this.id = id;
    this.email = email;
    this.first_name = first_name;
    this.last_name = last_name;
    this.types = types;
    this.roles = roles;
    this.permissions = permissions;
  }
}
