import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ot-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputComponent implements OnInit, OnChanges {
  public get isInvalid() {
    if (this.noValidate) {
      return false;
    }
    return (
      (this.control.invalid &&
        (this.control.touched || this.submitted === true)) ||
      this.serverErrors
    );
  }

  public get isSuccess() {
    if (this.noValidate) {
      return false;
    }
    return this.control.valid && !this.serverErrors;
  }

  public get actualErrors() {
    if (this.serverErrors) {
      if (Array.isArray(this.serverErrors)) {
        return this.serverErrors[0];
      } else {
        return this.serverErrors;
      }
    }
    for (const error in this.control.errors) {
      if (this.errorMapping[error]) {
        return this.errorMapping[error];
      }
    }
    return '';
  }

  @ContentChild('errorsTmpl') public errorsTmpl: TemplateRef<any>;
  @ViewChild('inputField') public inputField: ElementRef;
  @ViewChild('datePicker') public datePicker: NgbInputDatepicker;
  @Input() public control: FormControl;
  @Input() public submitted = false;
  @Input() public hideLabel = false;
  @Input() public required = false;
  @Input() public type = 'text';
  @Input() public label = null;
  @Input() public placeholder = '';
  @Input() public errorLabel = null;
  @Input() public helpText = '';
  @Input() public afterInput = '';
  @Input() public name = null;
  @Input() public serverErrors = null;
  @Input() public minDate: NgbDateStruct;
  @Input() public maxDate: NgbDateStruct;
  // TODO[Dmitry Teplov] refactor: Extract errorMapping.
  @Input()
  public errorMapping = {
    required: 'This field is required',
    noEmptyString: 'This field is required',
    email: 'Email is not valid',
    pattern: 'This field is not valid',
    url: 'URL is not valid',
    minlength: 'This field is too short',
    maxlength: 'This field is too long',
    noPhoneNumber: 'Phone number is not valid',
    numeric: 'Value must be number',
    lessThan: 'Value is too high',
    equal: 'Should be equal',
    equalPassword: 'Passwords do not match'
  };
  @Input() public className = null;
  @Input() public optional = false;
  @Input() public readOnly = false;
  @Input() public noValidate = false;
  @Input() public maxLength = false;
  @Input()
  public mask = {
    mask: false
  };
  @Input() public showPasswordStrength = false;
  @Input()
  public quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      // ['blockquote', 'code-block'],

      // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link']
    ]
  };
  public currentPasswordStrength = 0;
  public requiredMark: any = true;
  public passwordVisible: any = false;
  private _valueChangesSubscription;

  public updateStrength($event) {
    this.currentPasswordStrength = $event;
  }

  public ngOnInit() {
    this.cleanErrors();
    this.requiredMark = this.control.hasError('required');
  }

  public ngOnChanges(changes) {
    if (changes.control) {
      this.cleanErrors();
    }
  }

  public togglePassword($event) {
    $event.preventDefault();
    this.passwordVisible = !this.passwordVisible;
    this.inputField.nativeElement.setAttribute(
      'type',
      this.passwordVisible ? 'text' : 'password'
    );
  }

  public openCalendar(d) {
    this.datePicker.open();
  }

  private cleanErrors() {
    if (this._valueChangesSubscription && this.control) {
      this._valueChangesSubscription.unsubscribe();
    }
    this._valueChangesSubscription = this.control.valueChanges.subscribe(() => {
      this.serverErrors = null;
    });
  }
}
