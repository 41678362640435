import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { HeaderDataService, HeaderTasks } from '@tenant/core';
import { flyInOut, flyInOutState } from '@tenant/ot';
import { timer } from 'rxjs';
import { concatMap, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'ot-tasks-dropdown',
  templateUrl: './tasks-dropdown.component.html',
  styleUrls: ['./tasks-dropdown.component.scss'],
  animations: [flyInOut, flyInOutState],
})
export class TasksDropdownComponent implements OnInit, OnDestroy {
  public showTasks = false;
  public tasks: HeaderTasks[] = [];
  public tasksCount = 0;
  private alive = true;

  constructor(
    private header: HeaderDataService,
    private router: Router,
    private eRef: ElementRef
  ) {}

  public ngOnDestroy(): void {
    this.alive = false;
  }

  public ngOnInit(): void {
    this.router.events.pipe(takeWhile(() => this.alive)).subscribe((e) => {
      if (e instanceof NavigationStart && this.showTasks) {
        this.showTasks = false;
      }
    });
    timer(0, 60000)
      .pipe(
        concatMap(() => this.header.tasks()),
        takeWhile(() => this.alive)
      )
      .subscribe((res) => {
        this.tasks = res.tasks_by_deals;
        this.tasksCount = res.total;
      });
  }

  @HostListener('click', ['$event'])
  public toggleTasks($event) {
    $event.preventDefault();
    const target = $event.target as any;
    if (target.nodeName !== 'A' && target.parentElement.nodeName !== 'A') {
      this.showTasks = !this.showTasks;
    }
  }

  @HostListener('document:click', ['$event'])
  public closeTasks($event) {
    if (!this.eRef.nativeElement.contains($event.target)) {
      this.showTasks = false;
    }
  }

  goToTask(task) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/tasks'], {
        queryParams: { 'task.deal__address__id': task?.address?.id },
      });
    });
  }

  goToTasks($event: Event) {
    $event.preventDefault();
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/tasks']);
    });
  }
}
