import { DefaultTableDataCollection } from '@tenant/table';

export class DashLeaderboardDataCollection<
  T
> extends DefaultTableDataCollection<T> {
  public processData(json: any[], meta?: any): void {
    this.data = [];
    super.processData(json, meta);
  }

  protected mapToModel(json: any[]): T[] {
    let max = 0;
    const targetArray = [...json, ...this.data];
    if (typeof json.map === 'function') {
      max = Math.max(...targetArray.map((v) => v.gross_volume));
    }
    json = json.map((v) => ({ ...v, maximalCommissions: max }));
    if (!this.modelClass) {
      return json;
    }
    return json.map((row) => new this.modelClass(row)).slice(0, 10);
  }
}
