import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ot-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckboxComponent implements OnInit {
  @Input() public label: string;
  @Input() public control: FormControl;
  public identifier: string;

  constructor() {
    this.identifier = 'checkbox' + +new Date() * Math.random();
  }

  public ngOnInit() {
    if (!this.control) {
      this.control = new FormControl(false);
    }
  }
}
