import {
  AbstractControl,
  FormArray,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { isPresent } from 'ngx-cookie';
import { parseSentenceForNumber } from '../ot/utils';
import * as moment from 'moment-mini-ts';

function isEmptyInputValue(value: any): boolean {
  // we don't check for string here so it also works with arrays
  return value == null || value.length === 0;
}

type ValidationResult = ValidationErrors | null;

export class OtValidators {
  public static email(control: AbstractControl): ValidationResult {
    const EMAIL_REGEXP = /^([\w+\-]\.?)+@[a-z\d\-]+(\.[a-z]+)*\.[a-z]{2,}$/i;
    const errors: { [key: string]: boolean } = {};
    if (control.value && control.value !== '') {
      if (!EMAIL_REGEXP.test(control.value)) {
        errors.email = true;
      }
      if (control.value.length >= 100) {
        errors.maxlength = true;
      }
    }
    return errors;
  }

  public static lessThan(maxValue: number): ValidatorFn {
    return (c: AbstractControl): ValidationResult => {
      let value = c.value;
      if (!value && value !== 0) {
        return;
      }
      if (typeof value === 'string') {
        value = parseSentenceForNumber(value);
      }
      if (value === '') {
        return;
      } else if (isNaN(parseFloat(value))) {
        return { numeric: true };
      } else {
        if (parseFloat(value) > maxValue) {
          return { lessThan: true };
        } else {
          return;
        }
      }
    };
  }

  public static equalValues(
    primary: string,
    equal: string,
    error: string = 'equal'
  ): ValidatorFn {
    return (form: AbstractControl): ValidationResult => {
      const primaryControl = form.get(primary);
      const equalControl = form.get(equal);

      if (equalControl.dirty) {
        if (primaryControl.value !== equalControl.value) {
          equalControl.setErrors({ ...equalControl.errors, [error]: true });
        }
      }

      return null;
    };
  }

  public static uniqueFormArray(control: string): ValidatorFn {
    return (form: AbstractControl): ValidationResult => {
      const toEqual = {};
      const formArray = form.get(control) as FormArray;
      formArray.value.forEach((item, i) => {
        const faCtrl = formArray.controls[i];
        if (item !== null && item !== '') {
          if (toEqual.hasOwnProperty(item)) {
            toEqual[item] += 1;
            faCtrl.setErrors({ ...faCtrl.errors, ...{ duplication: true } });
          } else {
            toEqual[item] = 0;
            if (faCtrl.hasError('duplication')) {
              delete faCtrl.errors.duplication;
              if (Object.keys(faCtrl.errors).length === 0) {
                faCtrl.setErrors(null);
              }
            }
            faCtrl.setErrors(faCtrl.errors);
          }
        }
      });
      return null;
    };
  }

  public static dependedRequired(control: string): ValidatorFn {
    return (c: AbstractControl): ValidationResult => {
      const group = c.parent;
      const value = c.value;
      if (value) {
        group.get(control).setValidators([Validators.required]);
        group.get(control).updateValueAndValidity();
      }
      if (!value && c.dirty) {
        group.get(control).setValidators(null);
        group.get(control).updateValueAndValidity();
      }
      return null;
    };
  }

  public static currencyRequired(c: AbstractControl): ValidationResult {
    let value = c.value.value;
    if (c.value && typeof c.value.value === 'string') {
      value = parseSentenceForNumber(value);
    }
    if (c.value && c.value.type === '%') {
      // if (value > 100) {
      //   return { lessThan: true };
      // }
    }
    return isEmptyInputValue(c.value && c.value.value)
      ? { required: true }
      : null;
  }

  public static currencyRequiredPercent(percents) {
    return (c: AbstractControl): ValidationResult => {
      let value = c.value.value;
      if (c.value && typeof c.value.value === 'string') {
        value = parseSentenceForNumber(value);
      }
      if (c.value && c.value.type === '%') {
        if (value > percents) {
          return { lessThan: true };
        }
      }
      return isEmptyInputValue(c.value && c.value.value)
        ? { required: true }
        : null;
    };
  }

  public static currencyRequiredWithoutLess(
    c: AbstractControl
  ): ValidationResult {
    let value = c.value.value;
    if (c.value && typeof c.value.value === 'string') {
      value = parseSentenceForNumber(value);
    }
    return isEmptyInputValue(c.value && c.value.value)
      ? { required: true }
      : null;
  }

  public static currencyLess(max): ValidatorFn {
    return (c: AbstractControl) => {
      let value = c.value;
      if (c.value && typeof c.value === 'string') {
        value = parseSentenceForNumber(value);
      }
      if (value > max) {
        return { lessThan: true };
      }
      return null;
    };
  }

  public static currencyMore(min): ValidatorFn {
    return (c: AbstractControl) => {
      let value = c.value;
      if (c.value && typeof c.value === 'string') {
        value = parseSentenceForNumber(value);
      }
      if (value <= min) {
        return { moreThan: true };
      }
      return null;
    };
  }

  public static url(control: AbstractControl) {
    if (isPresent(Validators.required(control))) {
      return null;
    }

    const v: string = control.value;
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      v
    )
      ? null
      : { url: true }; // eslint-disable-line
  }

  public static googleCalendar(control: AbstractControl) {
    if (isPresent(Validators.required(control))) {
      return null;
    }
    return control.value.trim().indexOf('calendar.google.com') != -1
      ? null
      : { google_calendar: true };
  }

  public static lessThanOrEqualsDate(maxValue: string): ValidatorFn {
    return (c: AbstractControl): ValidationResult => {
      const value = c.value;
      if (!value && value !== 0) {
        return;
      }
      if (moment(value) < moment(maxValue)) {
        return { lessThan: true };
      } else {
        return;
      }
    };
  }

  public static moreThanOrEqualsDate(maxValue: string): ValidatorFn {
    return (c: AbstractControl): ValidationResult => {
      const value = c.value;
      if (!value) {
        return;
      }

      if (moment(value) > moment(maxValue)) {
        return { lessThan: true };
      } else {
        return;
      }
    };
  }
}
