import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@tenant/helpers';
import { switchMap, tap } from 'rxjs/operators';
import { SessionService } from './session.service';

@Injectable()
export class TenantSessionService {
  constructor(public api: HttpClient, public session: SessionService) {}

  public createToken(code) {
    return this.api.post<any>(ApiConfig.token, { code }).pipe(
      switchMap((res: any) => {
        this.session.token = res.access;
        this.session.refreshToken = res.refresh;
        return this.tokenInfo();
      })
    );
  }

  public setTokenCfg(query) {
    this.session.token = query.auth_code;
    this.session.refreshToken = query.refresh;
  }

  public tokenInfo() {
    return this.api.get<any>(ApiConfig.tokenInfo);
  }

  public refreshToken() {
    const token = this.session.refreshToken;
    this.session.refreshToken = null;
    return this.api
      .post<any>(ApiConfig.tokenRefresh, { refresh: token })
      .pipe(
        tap((res: any) => {
          const data = res;
          this.session.token = data.access;
          this.session.refreshToken = data.refresh;
        })
      );
  }
}
