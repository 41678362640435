import { Injectable } from '@angular/core';
import {
  combineLatest as observableCombineLatest,
  Subject,
  Subscription
} from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Injectable()
export class ConditionService {
  public get state() {
    return this._stateChange.asObservable();
  }

  public _stateChange = new Subject();
  private valueChanges$: Subscription;
  private prevResult: boolean;

  public unsubscribe() {
    if (this.valueChanges$) {
      this.valueChanges$.unsubscribe();
    }
  }

  public subscribe(conditions, root, fields = null, doCheck = true) {
    let fieldsToSubscribe = fields || conditions.fields;

    fieldsToSubscribe = fieldsToSubscribe
      .map((field) => {
        const fc = root.get(field);
        return !fc
          ? null
          : fc.valueChanges.pipe(
              startWith(fc.value),
              map((v) => {
                if (fc.disabled) {
                  return {};
                }
                return { [field]: v };
              })
            );
      })
      .filter((v) => v);

    if (fieldsToSubscribe.length === 0) {
      return;
    }

    if (doCheck) {
      this.check(root.value, conditions);
    }
    this.valueChanges$ = observableCombineLatest(
      ...fieldsToSubscribe
    ).subscribe((values = []) => {
      // TODO: Refactor this code
      const val = Object.assign({}, ...values);
      if (doCheck) {
        this.check(val, conditions);
      } else {
        this._stateChange.next(val);
      }
    });
  }

  private check(values, conditions) {
    const result = conditions.check(values);

    if (this.prevResult === result) {
      return;
    }

    this._stateChange.next(result);

    this.prevResult = result;
  }
}
