import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { fromEvent as observableFromEvent, Subscription } from 'rxjs';

import { debounceTime, merge } from 'rxjs/operators';

@Directive({
  selector: '[otFitDatePicker]'
})
export class FitDateRangeDirective implements AfterViewInit, OnDestroy {
  private parentRect: ClientRect;
  private elemRect: ClientRect;
  private datePicker: HTMLElement;

  private sub: Subscription;

  constructor(private elRef: ElementRef) {}

  public ngAfterViewInit() {
    this.datePicker = this.elRef.nativeElement;

    this.parentRect = this.datePicker.parentElement.getBoundingClientRect();
    // eslint-disable-line , 

    this.updatePosition();
    this.sub = observableFromEvent(window, 'resize')
      .pipe(merge(observableFromEvent(window, 'scroll')), debounceTime(300))
      .subscribe(() => this.updatePosition());
  }

  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private updatePosition(): void {
    this.datePicker.style.top = 'initial';

    const bodyRect: ClientRect = document.body.getBoundingClientRect();
    const elemRect: ClientRect = this.datePicker.getBoundingClientRect();

    if (
      bodyRect.width - (this.parentRect.left + this.parentRect.width) >
      elemRect.width
    ) {
      this.datePicker.style.left = `${this.parentRect.width}px`;
    } else if (
      bodyRect.width - (this.parentRect.left + this.parentRect.width) <
        elemRect.width &&
      this.parentRect.left > elemRect.width
    ) {
      this.datePicker.style.left = `${-elemRect.width - 1}px`;
    } else {
      this.datePicker.style.left = `${0}px`;
    }

    // Top side is out of viewport, offset top to fit into viewport.
    if (elemRect.top < 0) {
      this.datePicker.style.top = `${this.parentRect.top}px`;
    }
  }
}
