import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OverlayNotificationModel } from './overlay-notification.model';

@Injectable()
export class OverlayNotificationsService {
  public get state() {
    return this._currentNotifyState.asObservable();
  }

  private _currentNotifyState = new BehaviorSubject<OverlayNotificationModel>(
    null
  );
  private _currentNotify: OverlayNotificationModel = null;
  private notifications = [];

  public add(params: OverlayNotificationModel) {
    this.notifications.push(params);
    this.checkCurrent();
  }

  public notificationsCount() {
    return this.notifications.length;
  }

  public remove(id) {
    this.notifications = this.notifications.filter(
      (notify) => notify.id !== id
    );
    if (this._currentNotify && this._currentNotify.id === id) {
      this._currentNotify = null;
      this._currentNotifyState.next(null);
      setTimeout(() => {
        this.checkCurrent();
      }, 300);
    }
  }

  private checkCurrent() {
    if (this._currentNotify == null) {
      if (this.notifications.length > 0) {
        this.setCurrent(this.notifications[0]);
      } else {
        this._currentNotify = null;
        this._currentNotifyState.next(null);
      }
    }
  }

  private setCurrent(notification: OverlayNotificationModel) {
    this._currentNotify = notification;
    this._currentNotifyState.next(notification);
    if (notification.timeout !== 0) {
      setTimeout(() => {
        this.remove(notification.id);
      }, notification.timeout);
    }
  }
}
