import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OverlayNotificationModel } from '../overlay-notification.model';

@Component({
  selector: 'ot-overlay-notification',
  templateUrl: './overlay-notification.component.html',
  styleUrls: ['./overlay-notification.component.scss'],
})
export class OverlayNotificationComponent {
  @Input() public data: OverlayNotificationModel;
  @Output() public onClose = new EventEmitter<any>();

  public processClose() {
    this.onClose.emit(this.data.id);
  }
}
