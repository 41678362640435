import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { HeaderDataService, Profile, WorkQueueData } from '@tenant/core';
import { flyInOut, flyInOutState } from '@tenant/ot';
import { timer } from 'rxjs';
import { concatMap, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'ot-work-queue-dropdown',
  templateUrl: './work-queue-dropdown.component.html',
  styleUrls: ['./work-queue-dropdown.component.scss'],
  animations: [flyInOut, flyInOutState],
})
export class WorkQueueDropdownComponent implements OnInit, OnDestroy {
  public showWorkQueue = false;
  public workQueue: WorkQueueData;
  public queueCount = 0;
  private alive = true;

  constructor(
    private header: HeaderDataService,
    private router: Router,
    private eRef: ElementRef
  ) {}

  @HostListener('click', ['$event'])
  public toggleWorkQueue($event: MouseEvent) {
    $event.preventDefault();
    const target = $event.target as any;
    if (target.nodeName !== 'A' && target.parentElement.nodeName !== 'A') {
      this.showWorkQueue = !this.showWorkQueue;
    }
  }

  @HostListener('document:click', ['$event'])
  public closeWorkQueue($event) {
    if (!this.eRef.nativeElement.contains($event.target)) {
      this.showWorkQueue = false;
    }
  }

  public ngOnDestroy(): void {
    this.alive = false;
  }

  public ngOnInit(): void {
    this.router.events.pipe(takeWhile(() => this.alive)).subscribe((e) => {
      if (e instanceof NavigationStart && this.showWorkQueue) {
        this.showWorkQueue = false;
      }
    });
    const user: Profile = JSON.parse(localStorage.getItem('user'));

    timer(0, 60000)
      .pipe(
        concatMap(() => this.header.workQueue(user)),
        takeWhile(() => this.alive)
      )
      .subscribe((workQueue) => {
        if (workQueue) {
          this.workQueue = workQueue;
          this.queueCount = Object.values(this.workQueue).reduce(
            (a, b) => a + b,
            0
          );
        }
      });
  }
}
