import {
  AfterContentInit,
  Component,
  ElementRef,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'ot-password-toggle',
  templateUrl: './password-toggle.component.html',
  styleUrls: ['./password-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PasswordToggleComponent implements AfterContentInit {
  public passwordVisible = false;
  public defaultType = 'password';
  private input: any;

  constructor(private elem: ElementRef, private renderer: Renderer2) {}

  public ngAfterContentInit(): void {
    this.input = this.elem.nativeElement.querySelector('input');
    this.defaultType = this.input.type;
  }

  public toggle() {
    this.renderer.setAttribute(
      this.input,
      'type',
      this.passwordVisible ? this.defaultType : 'text'
    );
    this.passwordVisible = !this.passwordVisible;
  }
}
