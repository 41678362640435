import { Injectable } from '@angular/core';

/* eslint-disable */
@Injectable()
export abstract class Logger {
  public info: any;
  public warn: any;
  public error: any;
  public log: any;

  public invokeConsoleMethod(type: string, args?: any): void {}
}
