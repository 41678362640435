import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dealDeadStatus',
})
export class DealDeadStatusPipe implements PipeTransform {
  public transform(value: any): string {
    if (value === 'UN') {
      return 'Unknown';
    } else if (value === 'BUOF') {
      return 'Buyer Unable to Obtain Financing';
    } else if (value === 'FATO') {
      return 'Found apartment through other agent';
    } else if (value === 'TDAR') {
      return 'Terminated During Attorney Review / Inspection Period';
    } else if (value === 'CDNM') {
      return 'Client Decided Not to Move';
    } else if (value === 'CSAE') {
      return 'Client Secured Apartment Elsewhere';
    } else if (value === 'LDC') {
      return 'Landlord Denied Client';
    } else if (value === 'CLMOS') {
      return 'Client Lost Multiple Offer Situation';
    } else if (value === 'LAE') {
      return 'Listing Agreement Expired';
    } else if (value === 'CTAS') {
      return 'Client Terminated Agent Services';
    } else if (value === 'MDS') {
      return 'Mistaken Deal Submission';
    } else {
      return value;
    }
  }
}
