import { Subject } from 'rxjs';

const storageKey = 'pendingApprovalModalState';

interface State {
  disabled: boolean;
  opened: boolean;
}

const defaultState: State = {
  disabled: false,
  opened: false
};

export class PendingApprovalModalService {
  public get state(): State {
    const state = JSON.parse(localStorage.getItem(storageKey));
    if (state === null) {
      this.initState();
      return this.state;
    } else {
      return state;
    }
  }

  public state$: Subject<State> = new Subject();

  constructor() {
    this.state$.next(this.state);
    this.setClosed();
  }

  public setOpened() {
    this.set({ opened: true });
    this.state$.next(this.state);
  }

  public setClosed() {
    this.set({ opened: false });
    this.state$.next(this.state);
  }

  public setEnabled() {
    this.set({ disabled: false });
    this.state$.next(this.state);
  }

  public setDisabled() {
    this.set({ disabled: true });
    this.state$.next(this.state);
  }

  private initState() {
    localStorage.setItem(storageKey, JSON.stringify(defaultState));
  }

  private set(newState: { [key: string]: boolean } | State): void {
    localStorage.setItem(
      storageKey,
      JSON.stringify({
        ...this.state,
        ...newState
      })
    );
  }
}
