import { Profile } from '@tenant/core';
import { Address } from 'libs/core/src/lib/models/address.model';

export interface ReportModel {
  id: number;
  type: ReportType;
  first_name: string;
  last_name: string;
  date_created?: string;
  property_address?: Address;
  applicant_address?: Address;
  requester?: ReportRequester;
  status: ReportStatuses;
  social_security_number?: string;
  provider: ReportProvider;
  data?:
    | CreditReport
    | CriminalSearchTu
    | CriminalSearchTaz
    | EvictionSearchTu
    | EvictionSearchTaz;
}

export enum ReportType {
  CREDIT_REPORT = 'CR', // 'Credit Report (with FICO Score)'
  CRIMINAL_SEARCH = 'CS', // 'Instant National Criminal Search '
  EVICTION_SEARCH = 'ES', //'Instant National Eviction Search'
}

export enum ReportProvider {
  TU = 'TU',
  TAZ = 'TAZ',
}

export enum ReportStatuses {
  NOT_SENT = 'Not sent',
  IN_PROGRESS = 'In progress',
  FAILED = 'Failed',
  SUCCEEDED = 'Succeeded',
}

export interface ReportMobileSorting {
  name: string;
  label: string;
}

export interface ReportRequester {
  id: number;
  first_name: string;
  last_name: string;
}

export interface CreateReport {
  first_name?: string;
  last_name?: string;
  social_security_number?: string;
  date_of_birth?: string;
  applicant_address?: Address;
  property_address?: Address;
  type?: ReportType;
  requester?: Profile;
  rental_form?: string;
}

export interface IdManagerVerification {
  verification_summary: IdManagerVerificationSummary;
  consumer_alert_messages: string[];
  multisource_matching_summary: SourceAnalysis[];
}

export interface IdManagerVerificationSummary {
  identity_risk_level: string;
  best_match_data_source: string;
  verification_point: string;
  risk_level_lower_threshold: string;
  risk_level_upper_threshold: string;
  key_messages: string[];
}

export interface SourceAnalysis {
  source: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  suffix: string;
  address: string;
  ssn: string;
  date_of_birth: string;
  phone: string;
}

export interface CreditReport {
  is_empty_report: boolean;
  frozen: boolean;
  personal_info: {
    name: {
      first: string;
      last: string;
      middle?: string;
    };
    date_of_birth: string;
    SSN: string;
    in_file_since?: string;
    current_address: {
      street: {
        number: string;
        name: string;
        type?: string;
      };
      location: {
        city: string;
        state: string;
        zip_code: string;
      };
    };
    previous_address?: {
      street: {
        number: string;
        name: string;
        type?: string;
      };
      location: {
        city: string;
        state: string;
        zip_code: string;
      };
    };
    aliases: string[];
  };
  scoring: {
    score: string;
    factors: string[];
  };
  subscriber_info: {
    issued_date: string;
    subscriber_name: string;
  };
  input: {
    first_name: string;
    last_name: string;
    date_of_birth: string;
    SSN: string;
    address: {
      address_line_1: string;
      address_line_2: string;
      city: string;
      state: string;
      country: string;
      zip_code: string;
    };
  };
  summary: {
    name: string;
    count: number;
    high_credit: string;
    credit_limit: string;
    current_balance: string;
    past_due: string;
    monthly_payment: string;
    percent_available_credit?: number;
    summaries?: any[];
  };
  credit_collections: [
    {
      subscriber: {
        industry_code: string;
        member_code: string;
        name: string;
      };
      account_number: string;
      account_rating: string;
      original: {
        creditor: string;
        balance: string;
      };
      account_type: string;
      remark_code: string;
      current_balance: string;
      past_due: string;
      date_opened: string;
      date_closed?: string;
      date_effective: string;
      update_method: string;
      closed_indicator: string;
      payment_history?: {
        delinquency?: {
          amount: string;
          delinquency_date: string;
        };
        payment_pattern?: {
          start_date: string;
          text: string;
        };
      };
    }
  ];
  trades: [
    {
      subscriber: {
        industry_code: string;
        member_code: string;
        name: string;
      };
      account_number: string;
      account_rating: string;
      portfolio_type: string;
      account_type: string;
      remark_code?: string;
      credit_limit?: string;
      high_credit?: string;
      terms?: string;
      current_balance?: string;
      past_due?: string;
      last_payment?: string;
      date_opened: string;
      date_paid?: string;
      date_closed?: string;
      late_payments?: {
        month_reviewed: number;
        late_30_days: number;
        late_60_days: number;
        late_90_days: number;
      };
      payment_history?: {
        delinquency?: {
          amount: string;
          delinquency_date: string;
        };
        payment_pattern?: {
          start_date: string;
          text: string;
        };
      };
      update_method: string;
    }
  ];
  public_records: [
    {
      type: string;
      court: string;
      date_filed: string;
      docket_number: string;
      plaintiff?: string;
      liability?: string;
      date_paid: string;
    }
  ];
  inquiries: [
    {
      inquiry_date: string;
      subscriber: {
        industry_code: string;
        member_code: string;
        name: string;
      };
    }
  ];
  consumer_statements: [
    {
      text: string;
    }
  ];
  high_risk_fraud_alert?: {
    message: string;
    status: string;
  };
  id_manager_verification: IdManagerVerification;
}

export interface CriminalSearchTu {
  applicant_ref: string;
  application_ref: string; // ????
  end_user: string;
  created?: string;
  applicant: {
    name: {
      first: string;
      last: string;
    };
    address: {
      address_line_1: string;
      address_line_2: string;
      city: string;
      state: string;
      country: string;
      zip_code: string;
    };
    birthday: string;
    ssn: string;
  };
  caveat: string[];
  counts: {
    court_action: number;
    arrest: number;
    sentencing: number;
    most_wanted: number;
    sex_offender: number;
    federal: number;
  };
  incidents: [
    {
      court: {
        location_county: string;
        jurisdiction?: string;
      };
      court_charges: [
        {
          sequence: string;
          description: string;
          counts: number;
          charge_disposition: {
            date: string;
            description: string;
            filing_date: string;
          };
        }
      ];
      charge_status: {
        appealed_from_lowed_court: boolean;
      };
    }
  ];
}

export interface EvictionSearchTu {
  applicant_ref: string;
  application_ref?: string;
  end_user: string;
  created?: string;
  applicant: {
    name: {
      first: string;
      last: string;
    };
    address: {
      address_line_1: string;
      address_line_2: string;
      city: string;
      state: string;
      country: string;
      zip_code: string;
    };
    birthday: string;
    ssn: string;
  };
  caveat: string[];
  record_count: number;
  eviction_searches: [
    {
      name: {
        first: string;
        last: string;
      };
      eviction_record: {
        component_id: string;
        component_description: string;
      };
      property_address: {
        address_line_1: string;
        address_line_2: string;
        city: string;
        state: string;
        country: string;
        zip_code: string;
      };
      event: {
        event_id: string;
        case_number: string;
        court_name: string;
        action_type: string;
        filing_date: string;
        filing_type: string;
        in_dispute: string;
        parties: string[];
        liability: string;
      };
    }
  ];
}

export interface CriminalSearchTaz {
  applicant: {
    name: {
      first: string;
      last: string;
    };
    address: {
      address_line_1: string;
      address_line_2: string;
      city: string;
      state: string;
      country: string;
      zip_code: string;
    };
    birthday: string;
    ssn: string;
  };
  count: number;
  records: CriminalSearchTazRecords[];
}

export interface CriminalSearchTazRecords {
  state: string;
  provider: string;
  offenses: TazRecordsOffenses[];
}

export interface TazRecordsOffenses {
  description: string;
  disposition: string;
  file_date: string;
  offense_date: string;
  type: string;
}

export interface EvictionSearchTaz {
  count?: number;
  applicant: {
    name: {
      first: string;
      last: string;
    };
    address: {
      address_line_1: string;
      address_line_2: string;
      city: string;
      state: string;
      country: string;
      zip_code: string;
    };
    birthday: string;
    ssn: string;
  };
  records: EvictionSearchTazRecords[];
}

export interface EvictionSearchTazRecords {
  action_type: string;
  case_number: string;
  court_name: string;
  disposition: string;
  file_date: string;
  liability: string;
  plaintiffs: string;
  record_id: string;
}
