import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@tenant/helpers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContactDetailModel } from '../models';

@Injectable()
export class ContactService {
  constructor(private api: HttpClient) {}

  public getContactList() {
    return this.api.get<any>(ApiConfig.contacts);
  }

  public create(data) {
    return this.api.post<any>(`${ApiConfig.contacts}/`, data);
  }

  public getContact(id) {
    return this.api
      .get<any>(`${ApiConfig.contacts}/${id}`)
      .pipe(map((res: any) => new ContactDetailModel(res)));
  }

  public patchContact(id, data) {
    return this.api
      .patch<any>(`${ApiConfig.contacts}/${id}`, data)
      .pipe(map((res: any) => new ContactDetailModel(res)));
  }

  public archiveContacts(ids: number[]) {
    return this.api.post<any>(`${ApiConfig.contacts}/archive/`, {
      contacts: ids,
    });
  }

  public restoreContacts(ids: number[]) {
    return this.api.post<any>(`${ApiConfig.contacts}/restore/`, {
      contacts: ids,
    });
  }

  public clearFailedReports(id: number): Observable<boolean> {
    return this.api.delete<any>(`${ApiConfig.contacts}/${id}/failed_reports`);
  }

  public getALLContactsByEmail(email: string) {
    return this.api.get<any>(
      `${ApiConfig.contactsBriefList}?email=${email}&page_size=999`
    );
  }
}
