import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { fromEvent as observableFromEvent, Subscription } from 'rxjs';

import { debounceTime, merge } from 'rxjs/operators';

@Directive({
  selector: '[otFitDropdown]'
})
export class FitDropdownDirective implements AfterViewInit, OnDestroy {
  private dropdown: any;
  private menu: any;

  private sub: Subscription;
  private mo: MutationObserver;
  private onUpdate = () => {
    if (this.dropdown.classList.contains('show')) {
      // dropdown opened

      this.menu.style.top = '100%';

      const heightToBottom =
        window.innerHeight - this.menu.getBoundingClientRect().top;
      const menuHeight = this.menu.offsetHeight;

      // Display menu up if not enough space below.
      if (menuHeight > heightToBottom) {
        this.menu.style.top = `-${menuHeight}px`;

        const offsetTop =
          this.menu.getBoundingClientRect().top + window.scrollY;
        if (offsetTop < 0) {
          this.menu.style.top = `-${menuHeight + offsetTop}px`;
        }
        // setTimeout(() => {
        //   const offsetTop = this.menu.getBoundingClientRect().top + window.scrollY;
        //   if (offsetTop < 0) {
        //     this.menu.style.top = `-${menuHeight + offsetTop}px`;
        //   }
        // });
      } else {
        this.menu.style.top = '100%';
      }
    }
  };

  constructor(private elRef: ElementRef) {}

  public ngAfterViewInit() {
    this.dropdown = this.elRef.nativeElement;
    this.menu = this.dropdown.getElementsByClassName('dropdown-menu')[0];

    this.sub = observableFromEvent(window, 'resize')
      .pipe(merge(observableFromEvent(window, 'scroll')), debounceTime(300))
      .subscribe(
        () => 1
        // this.onUpdate()
      );

    // this.mo = observeClass(
    //   this.dropdown,
    //   this.onUpdate
    // );
  }

  public ngOnDestroy() {
    this.sub.unsubscribe();
    // this.mo.disconnect();
  }
}
