import { HeaderLink } from './header-link';

export class HeaderConfig {
  public hasSearch = false;
  public searchTemplate = null;
  public hideSearch = false;
  public logo = '/assets/img/logo.png';
  public links: HeaderLink[] = [new HeaderLink({ isDivider: true })];
  public flyout = false;
  public searchFn = () => [];

  public resetLinks() {
    this.links = [];
  }

  public addLink(link: HeaderLink) {
    this.links.push(link);
  }

  public setBadge(url, badge = 0) {
    const link = this.links.find((lnk) => lnk.link.join('/') === url);
    if (link) {
      link.badge = badge;
    }
  }
}
