import { Type } from '@angular/core';
import { AbstractField } from './abstract-field';
import { Field } from './field.interface';

export class TypeaheadField extends AbstractField {
  public endpoint: string;
  public component: Type<Field>;

  constructor(field: any, components?) {
    super(field);
    if (components) {
      const tmp = components.find(
        (v) =>
          v.reference === field.reference ||
          v.reference === field.meta.reference
      );
      if (tmp) {
        this.component = tmp.component;
      }
    }
    // if (!field.endpoint) {
    //   throw new Error();
    // }
    this.endpoint = field.meta.endpoint;
  }
}
