<div [class]="'form-group ' + className" [class.has-danger]="isInvalid" [class.has-success]="isSuccess">
  <label [for]="name" *ngIf="label && hideLabel == false">
    {{label}} <span *ngIf="required" class="text-danger">*</span><span class="optional" *ngIf="optional">(optional)</span>

  </label>
  <ng-content select=".popup"></ng-content>
  <div [class.input-group]="type != 'rich'">


    <input [class.is-valid]="isSuccess" [class.is-invalid]="isInvalid"
           type="text" *ngIf="type == 'date'"
           ngbDatepicker #datePicker="ngbDatepicker"
           [minDate]="minDate" [maxDate]="maxDate"
           class="form-control" [formControl]="control" [id]="name" name="dp">
    <div class="click-area" *ngIf="type == 'date'" (click)="openCalendar(d)"></div>


    <input [class.is-valid]="isSuccess" [class.is-invalid]="isInvalid" [textMask]="mask"
           *ngIf="mask.mask && type != 'textarea' && type != 'date' && type != 'rich'"
           [readonly]="readOnly" [placeholder]="placeholder"
           [maxlength]="maxLength"
           class="form-control" [formControl]="control" [id]="name" [name]="name" [type]="type">
    <input #inputField [class.is-valid]="isSuccess" [class.is-invalid]="isInvalid"
           class="form-control"
           *ngIf="!mask.mask && type != 'textarea' && type != 'date' && type != 'rich'"
           [readonly]="readOnly" [placeholder]="placeholder"
           [maxlength]="maxLength"
           [ngStyle]="{'background-position': type == 'password'? 'center right 2.5625rem':null}"
           [formControl]="control" [id]="name" [name]="name" [type]="type">
    <textarea [class.is-valid]="isSuccess" [class.is-invalid]="isInvalid" [formControl]="control"
              *ngIf="type == 'textarea'" [id]="name"
              [name]="name"
              class="form-control"></textarea>
    <quill-editor *ngIf="type == 'rich'" [modules]="quillModules" [formControl]="control">
    </quill-editor>
    <span class="input-group-addon" id="basic-addon2" *ngIf="afterInput">{{ afterInput }}</span>
    <div class="toggle-password" *ngIf="type=='password'" aria-label="My icon" (click)="togglePassword($event)"
         [inlineSVG]="passwordVisible?'/assets/svg/hide.svg' : '/assets/svg/show.svg'"></div>
  </div>
  <div class="form-text text-danger" *ngIf="isInvalid">{{ actualErrors }}</div>
  <ot-password-strength-bar (strengthChanged)="updateStrength($event)" #passwordStrength *ngIf="showPasswordStrength"
                            [passwordToCheck]="control.value">
  </ot-password-strength-bar>
  <div class="help-block" *ngIf="showPasswordStrength && (currentPasswordStrength < 20 || control.hasError('pattern'))">
    <p class="form-text text-muted">Your password is weak. Consider using at least 8 chars password with letters and
      digits inside
      to secure you account.</p>
  </div>
  <div class="help-block" *ngIf="helpText">
    <p class="form-text text-muted">{{ helpText }}</p>
  </div>
</div>
