import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  template: 'ABSTRACT',
})
abstract class AbsComponentWithSelect implements OnDestroy {
  destroyed$: Subject<boolean> = new Subject<boolean>();

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  @Input() apiUrl!: string;

  @Input() items!: any[];

  @Input() footerLabel: string;

  @Input() label!: string;

  @Input() bindLabel = 'label';

  @Input() bindValue = 'value';

  @Input() placeholder!: string;

  @Input() tip?: string;

  @Input() entityName!: string;

  @Input() disabled!: boolean;

  @Input() required!: boolean | string;

  @Input() value!: any;

  @Input() optionTemplate?: TemplateRef<any>;

  @Input() footerTemplate?: TemplateRef<any>;

  @Input() labelTemplate?: TemplateRef<any>;

  @Input() searchable = true;

  @Input() clearable = false;

  @Input() dropdownPosition: 'bottom' | 'top' | 'auto' = 'auto';

  @Output() selectChange = new EventEmitter();

  loadingState = false;

  nextUrl!: string;

  protected searchInitialized: boolean;

  protected constructor(protected readonly cd: ChangeDetectorRef) {}

  public addItem(item: any): void {
    this.items = [item, ...this.items];
    // this.cd.detectChanges();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch: any = () => {};

  trackByFn(item: any) {
    return item.id;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string): void {
    this.value = value;
    this.cd.markForCheck();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.markForCheck();
  }

  onModelChange(e: string): void {
    this.value = e;

    this.onChange(e);
  }

  onSelectChange(event: any): void {
    this.selectChange.next(event);
  }
}

export { AbsComponentWithSelect };
