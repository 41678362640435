import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface ICheckboxGroup {
  id: number | string;
  label: string;
  checked?: any;
  disabled?: boolean;
}

export const CHECKBOX_GROUP_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxGroupComponent), // eslint-disable-line
  multi: true
};

@Component({
  selector: 'ot-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.css'],
  providers: [CHECKBOX_GROUP_VALUE_ACCESSOR]
})
export class CheckboxGroupComponent implements ControlValueAccessor {
  public _value: any = [];

  public get value() {
    return this._value || [];
  }

  public set value(val) {
    if (typeof val[0] === 'object') {
      val = val.filter((v) => v.checked === true).map((v) => v.id);
    }
    this._value = val;
    if (this.onChange) {
      this.onChange(val);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  private _checkboxes: ICheckboxGroup[] = [];

  public get checkboxes(): ICheckboxGroup[] {
    return this._checkboxes;
  }

  @Input()
  public set checkboxes(value: ICheckboxGroup[]) {
    // eslint-disable-line , , 
    this._checkboxes = value.map((v) => {
      return Object.assign({}, v);
    });
    this._checkboxes.forEach((ch) => {
      // eslint-disable-line , , , , 
      ch.checked = this.value.indexOf(ch.id) > -1;
    });
  }

  public onChange: any = null;
  public onTouched: any = null;

  public writeValue(obj: any): void {
    if (obj) {
      if (obj[0] && typeof obj[0].id === 'number') {
        const val = obj.map((item) => item.id);
        this._checkboxes.forEach((ch) => {
          ch.checked = val.indexOf(ch.id) > -1;
        });
        this.value = val;
      } else {
        this.value = obj;
      }
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }

  public changeCheckboxValue(item, value) {
    item.checked = value;
    this.value = this._checkboxes;
  }
}
