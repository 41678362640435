import { Component, OnInit } from '@angular/core';
import { flyInOut } from '@tenant/ot';
import { Observable } from 'rxjs';
import { OverlayNotificationModel } from './overlay-notification.model';
import { OverlayNotificationsService } from './overlay-notifications.service';

@Component({
  selector: 'ot-overlay-notifications',
  templateUrl: './overlay-notifications.component.html',
  styleUrls: ['./overlay-notifications.component.scss'],
  animations: [flyInOut]
})
export class OverlayNotificationsComponent implements OnInit {
  public notificationData: Observable<OverlayNotificationModel>;

  constructor(private service: OverlayNotificationsService) {}

  public ngOnInit() {
    this.notificationData = this.service.state;
  }

  public closeNotify($event) {
    this.service.remove($event);
  }
}
