import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ot-print-button',
  templateUrl: 'print-button.component.html',
  styleUrls: ['print-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrintButtonComponent {
  @Input() public selectedCount = 0;
}
