<div [formGroup]="group">
  <ot-form-container *ngIf="config && group">
    <ot-label>
      {{ config.label }}
      <!-- <span class="icon-24 correct-spaces icon-black" [ngbTooltip]="config?.meta?.tooltip" *ngIf="config?.meta?.tooltip" [inlineSVG]="'/assets/svg/help.svg'"></span>-->
    </ot-label>
    <input type="text" otInput [formControlName]="config.field" [textMask]="currencyMask" [required]="config?.required"
           [placeholder]="config?.meta?.placeholder || config?.meta?.tooltip || ''">
    <ot-form-error key="lessThan">{{generateMessage(config?.meta?.max_number ?
      (this.config.meta.max_number|number) :
      commissionName)}}
    </ot-form-error>
    <ot-form-error key="custom" (remove)="errors = null" *ngIf="errors && errors.length > 0">{{errors[0]}}
    </ot-form-error>
  </ot-form-container>
</div>
