import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@tenant/helpers';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Profile, UserPermissions } from './profile.model';
import { SessionService } from './session.service';

@Injectable()
export class ProfileService {
  private _state = new BehaviorSubject<Profile>(null);

  public get state(): Observable<Profile | null> {
    return this._state.asObservable();
  }

  public get userId() {
    return this._state.getValue().id;
  }

  private userObj: Profile = null;

  constructor(
    private api: HttpClient,
    private _session: SessionService,
    private perm: NgxPermissionsService
  ) {
    const usrTmp = JSON.parse(localStorage.getItem('user'));
    if (usrTmp) {
      this.userObj = new Profile(usrTmp);
      this._state.next(this.userObj);
    }
    this.initRoles();
    _session.state.subscribe((loggedIn) => {
      if (!loggedIn) {
        return;
      }
      this.loadData().subscribe(() => {
        return '';
      });
    });
    this.state.subscribe((v) => {
      if (v) {
        localStorage.setItem('user', JSON.stringify(v));
        this.userObj = v;
      }
    });
  }

  public forgetProfile() {
    localStorage.removeItem('user');
    this._state.next(null);
  }

  public loadData() {
    return this.api.get(ApiConfig.currentUserInfo).pipe(
      map((res) => {
        const json = new Profile(res);
        this._state.next(json);
        this.initRoles();
        return json;
      })
    );
  }

  public getUserDetails() {
    return this.api.get(ApiConfig.currentUserInfo).pipe(
      map((res) => {
        const json = new Profile(res);
        return json;
      })
    );
  }

  public update(data) {
    return this.api.patch(ApiConfig.userInfo, { user: data }).pipe(
      map((res) => {
        const json = new Profile(res);
        this._state.next(json);
        return json;
      })
    );
  }

  private initRoles(): void {
    this.perm.addPermission(
      [
        'company_admin',
        'accounting',
        'deal_manage_approve',
        'deal_change_commission_status',
        'deal_change_agent_paid_status',
      ],
      () => {
        if (this._state.value) {
          return this._state.value.roles.is_ot_admin;
        }
        return false;
      }
    );

    this.perm.addPermission(
      [
        'filter_team',
        'contacts_assigned_agent',
        'filter_agents',
        'work_queue_reports',
        'work_queue',
        'deal_manage_reject',
        'reports_team_rankings',
      ],
      () => {
        if (this._state.value) {
          return (
            this._state.value.roles.is_ot_admin ||
            this._state.value.roles.is_teamlead
          );
        }
        return false;
      }
    );

    this.perm.addPermission(['team_lead'], () => {
      if (this._state.value) {
        return this._state.value.roles.is_teamlead;
      }
      return false;
    });

    this.perm.addPermission(
      [
        'all_roles',
        'reports_lead_sources',
        'reports_agent_role',
        'reports_production_detail',
        'reports_company_leaderboard',
        'reports_payment_history',
      ],
      () => {
        if (this._state.value) {
          return true;
        }
        return false;
      }
    );

    this.perm.addPermission(['is_connect_user'], () => {
      if (this._state.value) {
        return (
          this._state.value.roles.is_listing_manager ||
          this._state.value.roles.is_listing_user
        );
      }
      return false;
    });

    this.perm.addPermission(['user_management_permission'], () => {
      if (this._state.value && this._state.value?.permissions) {
        return this._state.value.permissions?.includes(
          UserPermissions.USER_MANAGEMENT
        );
      }
      return false;
    });

    this.perm.addPermission(['applications_reports', 'tenant_reports', 'view_reports'], () => {
      return this._state?.value?.roles?.is_ot_admin
        || (this._state?.value?.roles?.is_teamlead && this._state?.value?.permissions?.includes(
          UserPermissions.TEAM_LEAD_APPLICATION_PROCESSING,
        ));
    });
  }
}
