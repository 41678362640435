import { HttpClient } from '@angular/common/http';
import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import * as download from 'downloadjs';

@Directive({
  selector: 'a[download]', // eslint-disable-line
})
export class DownloadDirective {
  @Input() private href;
  @HostBinding('attr.href')
  @Input()
  private download;

  constructor(private http: HttpClient) {
    // eslint-disable-line , , , ,
  }

  @HostListener('click', ['$event'])
  public click($event) {
    $event.preventDefault();
    let fileName = this.download || this.href;
    fileName = fileName.split('/').reverse()[0];
    if (fileName && fileName.indexOf('?') !== -1) {
      fileName = fileName.slice(0, fileName.indexOf('?'));
    }
    this.http
      .get(this.href, {
        responseType: 'blob',
        observe: 'response',
      })
      .subscribe(({ body, headers }) => {
        const fileNameResp = headers.get('content-disposition');
        fileName = fileNameResp ? this.parseFileName(fileNameResp) : fileName;
        download(body, fileName, body.type);
      });
  }

  private parseFileName(str: string): string {
    const re = /"(.*?)"/;
    return str.match(re)[1];
  }
}
