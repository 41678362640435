import { Injectable } from '@angular/core';
import { FormConfigType } from './dynamic-form';
import { AbstractField } from './models/abstract-field';
import { AddressField } from './models/address-field';
import { ArrayField } from './models/array-field';
import { CommissionCurrencyField } from './models/commission-currency-field';
import { DropdownField } from './models/dropdown-field';
import { GrossCurrencyField } from './models/gross-currency-field';
import { GroupField } from './models/group-field';
import { TextField } from './models/text-field';
import { TypeaheadField } from './models/typeahead-field';

@Injectable({
  providedIn: 'root',
})
export class DynamicFormConfigService {
  constructor() {}

  public processFormConfig(form: any[]): FormConfigType[] {
    return form
      .sort(this.compareFn.bind(this))
      .map(this.processField.bind(this));
  }

  public processField(field: any): FormConfigType {
    switch (field.type) {
      case 'custom_address':
      case 'address':
      case 'deal_address':
        return new AddressField(field);
      case 'array':
        const array = new ArrayField(field);
        if (field.children && field.children.length > 0) {
          array.children = this.processFormConfig(field.children);
        }
        return array;
      case 'group':
        const group = new GroupField(field);
        if (field.children && field.children.length > 0) {
          group.children = this.processFormConfig(field.children);
        }
        return group;
      case 'custom_dropdown':
      case 'dropdown':
        return new DropdownField(field);
      case 'reference':
        return new TypeaheadField(field);
      case 'gross':
        return new GrossCurrencyField(field);
      case 'text':
      case 'number':
      case 'paragraph':
        return new TextField(field);
      case 'commission_currency':
        return new CommissionCurrencyField(field);
      default:
        return new AbstractField(field);
    }
  }

  private compareFn(a, b) {
    return a.order > b.order ? 1 : a.order === b.order ? 0 : -1;
  }
}
