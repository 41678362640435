import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { flyInOut } from '@tenant/ot';

export const TAG_INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TagInputComponent), // eslint-disable-line
  multi: true
};

@Component({
  selector: 'ot-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss'],
  providers: [TAG_INPUT_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [flyInOut]
})
export class TagInputComponent
  implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() public placeholder = 'Please Select';
  @Input() public readonly = false;
  @Input() public debounce = 300;
  @Input() public options;
  @Output() public onTextChange = new EventEmitter<string>();
  @Output() public onAdd = new EventEmitter();
  @Output() public onRemove = new EventEmitter();
  @Output() public onFocus = new EventEmitter();
  @Output() public onBlur = new EventEmitter();
  @Input() public additionalActionText;
  @Output() public additionalAction = new EventEmitter();

  @ViewChild('input') public input: ElementRef;
  public control = new FormControl();
  public focused = false;
  public showDropdown = false;
  private _value;
  private onChange;
  private onTouched;
  private destroy$: Subject<void> = new Subject<void>();

  public get value() {
    return this._value;
  }

  public set value(val) {
    if (val) {
      this._value = val;
      if (this.onChange) {
        this.onChange(val.value);
      }
      if (this.onTouched) {
        this.onTouched();
      }
      this.control.setValue('', { emitEvent: false });
    } else {
      this._value = null;
      this.onChange(null);
      this.control.setValue('', { emitEvent: false });
      this.onTextChange.emit('');
    }
    this.cd.markForCheck();
  }

  constructor(private cd: ChangeDetectorRef, private _element: ElementRef) {}

  public ngOnInit() {
    this.control.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(this.debounce))
      .subscribe((val) => {
        this.onTextChange.emit(val);
      });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public reset() {
    this.control.setValue('');
    this.onTextChange.emit('');
    this.showDropdown = false;
    this.cd.markForCheck();
  }

  public selectItem(item) {
    this.onAdd.emit(item);
    this.control.setValue('');
    this.showDropdown = false;
    this.cd.markForCheck();
  }

  public writeValue(val) {
    if (val instanceof Object) {
      this._value = val;
    }
    if (this._value) {
      this.control.setValue(this._value.label);
    } else {
      this.control.setValue(null);
    }
    this.cd.markForCheck();
  }

  public focus() {
    if (this.readonly) {
      return;
    }
    this.focused = true;
    this.onFocus.emit();
    this.cd.markForCheck();
  }

  public blur() {
    this.focused = false;
    this.onBlur.emit();
    this.cd.markForCheck();
  }

  public inputClick($event) {
    $event.preventDefault();
    this.showDropdown = true;
  }

  public onClearInput() {
    this.showDropdown = false;
    this.control.reset();
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(e) {
    const keyDown = e.which === 40 || e.keyCode === 40;
    const keyUp = e.which === 38 || e.keyCode === 38;
    const enter = e.which === 13 || e.keyCode === 13;
    const escape = e.which === 27 || e.keyCode === 27;
    const tab = e.which === 9 || e.keyCode === 9;
    if (escape) {
      this.input.nativeElement.blur();
      this.showDropdown = false;
      this.cd.markForCheck();
      return;
    }
    if (!this.showDropdown) {
      this.showDropdown = true;
      this.cd.markForCheck();
    }
    if (enter) {
      return;
    }
    if (tab && this.showDropdown && this.options.length === 0) {
      this.showDropdown = false;
      this.cd.markForCheck();
      return;
    }
    if (e.target.nodeName?.toLowerCase() === 'input' && (keyDown || tab)) {
      const btns = this._element.nativeElement.querySelectorAll('button');
      if (btns.length > 0) {
        btns[0]?.focus();
      }
      e.preventDefault();
      this.cd.markForCheck();
      return;
    }
    if (tab && this.showDropdown && e.srcElement.nextElementSibling) {
      e.preventDefault();
      e.srcElement.nextElementSibling?.focus();
    }
    if (
      tab &&
      !e.srcElement.nextElementSibling &&
      (e.target.nodeName?.toLowerCase() !== 'input' && this.showDropdown)
    ) {
      this.showDropdown = false;
      this.cd.markForCheck();
    }
    if (keyDown) {
      if (e.srcElement.nextElementSibling) {
        e.srcElement.nextElementSibling?.focus();
      }
    }
    if (keyUp) {
      if (e.srcElement.previousElementSibling) {
        e.srcElement.previousElementSibling?.focus();
      } else {
        this.input.nativeElement?.focus();
      }
    }
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    this.cd.markForCheck();
    if (!targetElement || this.showDropdown !== true) {
      return;
    }
    const clickedInside = this._element.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.showDropdown = false;
      // this.resetValue();
      // if (!this._value || !this._value.label) {
      //   // this.filter.emit('');
      // }
      this.cd.markForCheck();
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }
}
