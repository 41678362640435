import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { PaidStatusComponent } from './paid-status/paid-status.component';

@NgModule({
  imports: [CommonModule, NgbDropdownModule],
  declarations: [PaidStatusComponent],
  exports: [PaidStatusComponent]
})
export class DealsCommonModule {}
