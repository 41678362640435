import { Pipe, PipeTransform } from '@angular/core';
import { DealAgents, AgentTypes } from 'apps/admin/src/app/deal/models/deal';

@Pipe({ name: 'agentsForCommissionTracker' })
export class AgentsForCommissionTrackerPipe implements PipeTransform {
  transform(value: DealAgents[]): DealAgents[] {
    const dataP = value.filter((a) => a.agent_type === AgentTypes.PRIMARY);
    const dataA = value.filter((a) => a.agent_type === AgentTypes.ADDITIONAL);
    return dataP.concat(dataA);
  }
}
