import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { deepCompare } from '@tenant/ot';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  takeWhile,
} from 'rxjs/operators';
import { TableFilter } from '../table.interfaces';

@Component({
  selector: 'ot-table-filters',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.scss'],
})
export class TableFiltersComponent implements OnChanges, OnDestroy {
  // noinspection ReservedWordAsName
  public get default() {
    const newVal = {};
    this.filters.forEach((v) => {
      newVal[v.requestKey] = v.value === null || v.value ? v.value : '';
      if (typeof v.value === 'boolean') {
        newVal[v.requestKey] = v.value;
      }
    });
    return newVal;
  }

  public get filtersChanged() {
    return !this.compare(this.default, this.filtersGroup.value);
  }

  @Input() public filters: TableFilter[] = [];
  @Output() public filterChange = new EventEmitter();
  public filterChangeSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public filtersGroup: FormGroup = new FormGroup({});
  private vc: Subscription;
  private destroy: boolean;

  constructor() {
    this.filterChangeSubject
      .pipe(
        filter((v: any) => v),
        takeWhile(() => !this.destroy)
      )
      .subscribe((val) => {
        this.filterChange.emit(val);
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.filters) {
      this.initFilters();
    }
  }

  public ngOnDestroy() {
    if (this.vc) {
      this.vc.unsubscribe();
    }
    this.destroy = true;
  }

  public resetFilters() {
    this.filtersGroup.patchValue(this.default);
  }

  private initFilters() {
    if (this.vc) {
      this.vc.unsubscribe();
    }
    this.filtersGroup = new FormGroup({});
    this.filters.forEach((f) => {
      if (!this.filtersGroup.contains(f.requestKey)) {
        let filterDefValue = f.value === null || f.value ? f.value : '';
        if (typeof f.value === 'boolean') {
          filterDefValue = f.value;
        }
        this.filtersGroup.addControl(
          f.requestKey,
          new FormControl(filterDefValue)
        );
      }
    });
    if (!this.compare(this.default, this.filterChangeSubject.getValue())) {
      this.filterChangeSubject.next(this.default);
    }

    this.vc = this.filtersGroup.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((newValue) => {
        if (this.compare(this.filterChangeSubject.value, newValue) === false) {
          this.filterChangeSubject.next(newValue);
        }
      });
  }

  private compare(obj1, obj2) {
    return deepCompare(obj1, obj2);
  }
}
