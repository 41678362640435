import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig, dynamicSort } from '@tenant/helpers';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class OptionsAccessorService {
  private officeUsers$ = new BehaviorSubject<any[]>(null);

  constructor(private api: HttpClient) {}

  public contactTypes(): Observable<any[]> {
    return this.api.get<any[]>(ApiConfig.contactTypes).pipe(map((res) => res));
  }

  public leadSourceWithDeleted(): Observable<any[]> {
    return this.api
      .get<any[]>(ApiConfig.leadSource, { params: { deleted: 'true' } })
      .pipe(map((res: any) => res.sort(dynamicSort('order'))));
  }

  public leadSource(): Observable<any[]> {
    return this.api
      .get<any[]>(ApiConfig.leadSource)
      .pipe(map((res: any) => res.sort(dynamicSort('order'))));
  }

  public assignedAgents(): Observable<any[]> {
    return this.api
      .get<any[]>(`${ApiConfig.assignedAgents}`)
      .pipe(map((res) => res));
  }

  public assignedAgentsWithoutAdmins(): Observable<any[]> {
    return this.api
      .get<any[]>(`${ApiConfig.assignedAgentsWithoutAdmins}`)
      .pipe(map((res) => res));
  }

  public dealAssignedAgents(): Observable<any[]> {
    return this.api
      .get<any[]>(ApiConfig.dealAssignedAgents)
      .pipe(map((res) => res));
  }

  public dealStatuses(onlyActive = false): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('only_active', onlyActive.toString());
    return this.api
      .get<any[]>(ApiConfig.dealStatuses, { params })
      .pipe(map((res) => res));
  }

  public teams(): Observable<any[]> {
    return this.api.get<any[]>(ApiConfig.teams);
  }

  public applicationAgents(): Observable<any[]> {
    return this.api
      .get<any[]>(`${ApiConfig.applicationAgents}`)
      .pipe(map((res) => res));
  }

  public staffProfiles(): Observable<any[]> {
    return this.api
      .get<any[]>(`${ApiConfig.staffProfiles}`)
      .pipe(map((res) => res));
  }
}
