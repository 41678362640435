<span *ngIf="disabled">-</span>
<div ngbDropdown placement="bottom-right" *ngIf="editable && !disabled" container="body">
  <div ngbDropdownToggle="">
    <ng-container *ngIf="display">{{display}}</ng-container>
    <ng-container *ngIf="!display">{{value ? label : 'Not ' + label}}</ng-container>
    <ng-content select="[arrow]"></ng-content>
  </div>
  <div ngbDropdownMenu>
    <button class="dropdown-item" (click)="changeValue(true)">{{label}}</button>
    <button class="dropdown-item" *ngIf="!disableFalse" (click)="changeValue(false)">Not {{label}}</button>
  </div>
</div>

<div *ngIf="!editable && !disabled">
  <ng-container *ngIf="display">{{display}}</ng-container>
  <ng-container *ngIf="!display">{{value ? label : 'Not ' + label}}</ng-container>
</div>
