<div class="modal-body">
  <div class="container-fluid">
    <div class="row heading-section text-center">
      <div class="col-12 pt-4 pb-4">
        <span class="caption-1">Let's Get Started!</span>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center">
      <!--<div class="d-flex align-items-center simple-steps">-->
        <!--<span>Three simple steps to awake full power of the Office Troop</span>-->
      <!--</div>-->
      <div class="d-inline-flex mb-3">
        <div class="d-flex justify-content-center step-number">
          <span>1</span>
        </div>
        <div class="pl-3">
          <span class="caption-2">Schedule a simple screening process with Office Troop</span>
        </div>
      </div>
      <div class="text-center">
        <span>
          In order to begin processing credit reports a new agency must pass <br/>a screening process required by TransUnion.
        </span>
      </div>
      <div class="row justify-content-center contacts mt-4">
        <div class="col-4 d-flex justify-content-center align-items-start">
          <span [inlineSVG]="'/assets/svg/phone-svg.svg'"></span>
          <span class="phone-number">(872) 760-3429</span>
        </div>
        <div class="col-4 d-flex justify-content-center align-items-start">
          <span [inlineSVG]="'/assets/svg/email-svg.svg'"></span>
          <span class="email">hello@officetroop.com</span>
        </div>
      </div>
      <div class="d-inline-flex mt-5 mb-3">
        <div class="d-flex justify-content-center step-number">
          <span>2</span>
        </div>
        <div class="pl-3">
          <span class="caption-2">Connect your Stripe account</span>
        </div>
      </div>
      <div class="text-center">
        <span>
          In order to start receiving payments from rental applications you<br>
          need to connect your Stripe account with your Office Troop profile
        </span>
      </div>
      <div class="mt-4">
        <button type="button" class="btn btn-primary modal-btn" (click)="goToSettings('profile')">Enter Stripe
          Credentials
        </button>
      </div>
      <div class="stripe-info mt-2">
        <span>
          <strong>New to Stripe?</strong> It is a web solution that allows
          you to accept credit card payments from the applicants.
          You can create your free Stripe account
          <a href="https://dashboard.stripe.com/register" target="_blank">here</a>.
        </span>
      </div>
      <div class="d-inline-flex mt-5 mb-3">
        <div class="d-flex justify-content-center step-number">
          <span>3</span>
        </div>
        <div class="pl-3">
          <span class="caption-2">Begin customizing your rental application</span>
        </div>
      </div>
      <div class="text-center">
        <span>
          Fine-tune our basic rental application to suit you needs.
        </span>
      </div>
      <div class="mt-4 mb-5">
        <button type="button" class="btn btn-primary modal-btn-1" (click)="goToSettings('rental-application')">
          Go To Rental Application
        </button>
      </div>
    </div>
    <div class="row footer-section">
      <div class="col-12 mt-4 mb-3 d-flex justify-content-center">
        <button type="button" class="btn btn-secondary modal-btn-2" (click)="activeModal.dismiss('Cancel click')">
          I'll do it later
        </button>
      </div>
      <div class="col-12 mb-3 d-flex justify-content-center align-items-center c-pointer">
        <input type="checkbox" class="c-pointer" (change)="disableModal($event)" id="dont-remind">
        <label for="dont-remind" class="c-pointer mb-0 ml-1">Don't remind again.</label>
      </div>
      <div class="col-12 mb-4 text-center">
        <span class="footer-hint">
          You can do it anytime in your
          <span class="company-settings" (click)="goToSettings('profile')">Company Settings</span>
        </span>
      </div>
    </div>
  </div>
</div>
