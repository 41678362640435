import { Component } from '@angular/core';
import { CommonService } from '@tenant/core';
import { flyInOut, flyInOutState } from '@tenant/ot';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'apps/admin/src/environments/environment';

@Component({
  selector: 'ot-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [flyInOut, flyInOutState],
})
export class SearchComponent {
  public deals = {
    limit: 20,
    offset: 0,
    page: 1,
    data: [],
    count: 0,
  };
  public contacts = {
    limit: 20,
    offset: 0,
    page: 1,
    data: [],
    count: 0,
  };
  public invoices = {
    limit: 20,
    offset: 0,
    page: 1,
    data: [],
    count: 0,
  };

  searchFn = this.search.bind(this);

  environment = environment;

  width = window.screen.width;
  private _search: string;
  constructor(private common: CommonService) {}

  public search(value) {
    if (!value) {
      value = '';
    }
    this._search = value;
    this.deals.offset = 0;
    this.deals.page = 1;
    this.contacts.offset = 0;
    this.contacts.page = 1;
    this.invoices.offset = 0;
    this.invoices.page = 1;

    const params = {
      limit: 20,
      offset: 0,
      search: value,
    };
    return combineLatest([
      this.common.searchDeals(params),
      this.common.searchContacts(params),
      this.common.searchDealsInvoice(params),
    ]).pipe(
      map(([deals, contacts, invoices]) => {
        this.deals.data = deals.results;
        this.deals.count = deals.count;
        this.contacts.data = contacts.results;
        this.contacts.count = contacts.count;
        this.invoices.data = invoices.results;
        this.invoices.count = invoices.count;
      })
    );
  }

  loadMoreDeals($event: Event) {
    $event.preventDefault();
    this.deals.offset += 20;
    this.deals.page += 1;
    const params = {
      limit: 20,
      offset: this.deals.offset,
      search: this._search,
    };
    this.common.searchDeals(params).subscribe((data) => {
      this.deals.data = this.deals.data.concat(data.results);
    });
  }

  loadMoreContacts($event: Event) {
    $event.preventDefault();
    this.contacts.offset += 20;
    this.contacts.page += 1;
    const params = {
      limit: 20,
      offset: this.contacts.offset,
      search: this._search,
    };
    this.common.searchContacts(params).subscribe((data) => {
      this.contacts.data = this.contacts.data.concat(data.results);
    });
  }

  loadMoreInvoices($event: Event) {
    $event.preventDefault();
    this.invoices.offset += 20;
    this.invoices.page += 1;
    const params = {
      limit: 20,
      offset: this.invoices.offset,
      search: this._search,
    };
    this.common.searchDealsInvoice(params).subscribe((data) => {
      this.invoices.data = this.invoices.data.concat(data.results);
    });
  }
}
