import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-mini-ts';

@Pipe({
  name: 'otDate',
  pure: true
})
export class OtDatePipe extends DatePipe implements PipeTransform {
  transform(
    value: any,
    format?: string,
    timezone?: string,
    locale?: string
  ): any {
    if (value) {
      value = moment.utc(value).toDate();
    }
    return super.transform(value, format, timezone || 'UTC', locale);
  }
}
