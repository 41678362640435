import * as moment from 'moment-mini-ts';

export interface ReportFullApplicantServerModel {
  name: string;
  date_of_birth: string;
  ssn: string;
  address: string;
}

export interface ReportFullServerModel {
  id: number;
  report_type: string;
  requester: string;
  requested_at: string;
  status: string;
  applicant: ReportFullApplicantServerModel;
  report_data: any;
}

// TODO[Dmitry Teplov] figure out which status values are valid.
export const ReportFullModelStatus = {
  pending: 'pending',
  waiting: 'waiting',
  completed: 'completed',
  failed: 'failed',
};

export const ReportFullModelType = {
  criminal: 'Instant National Criminal Search',
  eviction: 'Instant National Eviction Search',
  credit: 'Credit Report',
};

export const ReportFullModelTypeNameMap = {
  [ReportFullModelType.criminal]: 'criminal',
  [ReportFullModelType.eviction]: 'eviction',
  [ReportFullModelType.credit]: 'credit',
};

export const ReportFullModelName = {
  criminal: 'Criminal Report',
  eviction: 'Eviction Report',
  credit: 'Credit Report',
};

export interface ReportFullApplicantModel {
  name: string;
  dateOfBirth: string;
  ssn: string;
  address: string;
}

export class ReportFullModel {
  public _requestedDate = '';

  public get requestedDate() {
    return moment(this._requestedDate).format('MMM D, YYYY');
  }

  public get name(): string {
    return ReportFullModelName[ReportFullModelTypeNameMap[this.type]];
  }

  public get typeDash() {
    return this.type
      ?.toLowerCase()
      .replace(/\s/g, '_')
      .replace('instant_national_', '');
  }

  public id = 0;
  public data: any = null;
  public requester = '';
  public status: string = null;
  public type: string = null;
  public applicant: ReportFullApplicantModel;

  constructor(serverModel: ReportFullServerModel) {
    this.id = serverModel.id;
    this._requestedDate = serverModel.requested_at;
    this.data = serverModel.report_data;
    this.requester = serverModel.requester;
    this.status = serverModel.status;
    this.type = serverModel.report_type;
    this.applicant = {
      name: serverModel.applicant.name,
      dateOfBirth: serverModel.applicant.date_of_birth,
      ssn: serverModel.applicant.ssn,
      address: serverModel.applicant.address,
    };
  }
}
