<span class="field-value-wrapp" [ngClass]="{'field-value-wrapp-disabled': disabled}" (click)="toggleEdit($event)"
  [hidden]="editMode === true">
  <ng-content></ng-content>
  <a *ngIf="!disabled" href="" class="toggle-edit" [ngStyle]="{'display': inline ? 'inline-block' : 'none'}"
    inlineSVG="/assets/svg/edit.svg"></a>
</span>
<div class="inline-edit" *ngIf="!disabled" [hidden]="editMode === false">
  <ng-content select="ot-form-container"></ng-content>
  <span inlineSVG="/assets/svg/reject.svg" (click)="cancelEdit($event)" class="cancel-icon"></span>
  <span inlineSVG="/assets/svg/succeed.svg" (click)="saveEdit($event)" class="confirm-icon"></span>
</div>