import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthCodeGuard implements CanActivate {
  constructor(private router: Router) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (
      !!next.queryParams.auth_code ||
      !!next.queryParams.adminId ||
      !!next.queryParams.adminName ||
      !!next.queryParams.userId
    ) {
      const queryParams = {
        ...next.queryParams,
        auth_code: null,
        adminId: null,
        adminName: null,
        userId: null,
      };
      this.router.navigate(
        next.url.map((v) => v.path),
        { queryParams }
      );
      return true;
    }
    return true;
  }
}
