<div>
  <ot-form-container *ngIf="config && group">
    <ot-label>
      {{ config.label }}
    </ot-label>
    <div class="input-group">
      <input type="text" otInput [formControl]="group.get(config.field)" [textMask]="dateMask"
        [required]="config?.required">
      <button class="input-group-append" (click)="openDatePicker($event)" type="button">
        <img src="/assets/svg/date-picker.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;" />
      </button>
      <ngb-datepicker #dp [minDate]="minDate" [maxDate]="maxDate" [ngModel]="datePickerModel" [dayTemplate]="customDay"
        (ngModelChange)="changeDate($event)" *ngIf="showDatePicker"></ngb-datepicker>
    </div>
    <!--<ot-date otInput-->
    <!--[formControl]="group.get(config.field)"-->
    <!--[required]="config?.required"-->
    <!--[minDate]="minDate"-->
    <!--[maxDate]="maxDate"-->
    <!--&gt;</ot-date>-->
    <!--<input type="text" otInput [formControlName]="config.field">-->
    <div *ngIf="config.hint && config.hint.length > 0">
      <ot-form-hint (remove)="errors = null">{{config.hint}}</ot-form-hint>
    </div>
    <ot-form-error key="custom" (remove)="errors = null" *ngIf="currentError">
      {{currentError}}
    </ot-form-error>
  </ot-form-container>
</div>

<ng-template #customDay let-date="date" let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled"
  let-focused="focused">
  <span class="custom-day" [class.focused]="focused" [class.bg-primary]="selected" [class.today]="isToday(date)"
    [class.text-muted]="date.month !== currentMonth">
    {{ date.day }}
  </span>
</ng-template>
