import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const TIME_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TimepickerComponent), // eslint-disable-line
  multi: true
};

@Component({
  selector: 'ot-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  providers: [TIME_VALUE_ACCESSOR]
})
export class TimepickerComponent implements OnChanges, ControlValueAccessor {
  private _value;

  public get value(): any {
    return this._value;
  }

  public set value(value: any) {
    if (this.value === value) {
      return;
    }
    this._value = value;
    if (this.onChange) {
      this.onChange(value);
    }
  }

  @Input() public timePickerModel = null;
  @Input() public readonly = false;
  public disabled: boolean;
  private onChange: (val: any) => void;
  private onTouched: () => void;
  private focused;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.timePickerModel && changes.timePickerModel.currentValue) {
      const time = changes.timePickerModel.currentValue.split(':');
      this.timePickerModel = {
        hour: +time[0],
        minute: +time[1],
        second: 0
      };
    }
  }

  public changeTime(time) {
    if (this.readonly || this.disabled) {
      this.timePickerModel = Object.assign({}, this.timePickerModel);
      return;
    }
    if (time) {
      this.value = `${time.hour}:${time.minute}`;
    }
    this.timePickerModel = time;
    if (this.onTouched) {
      this.onTouched();
    }
  }

  public writeValue(obj: any): void {
    if (typeof obj === 'object') {
      this.timePickerModel = obj;
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public focus() {
    this.focused = true;
  }

  /*@HostBinding(‘blur’)
  private blur() {
    if (this.onTouched) {
      this.onTouched();
    }
  }*/
}
