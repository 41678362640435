import { Component, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'ot-select-item',
  templateUrl: './select-item.component.html',
  styleUrls: ['./select-item.component.scss']
})
export class SelectItemComponent {
  @Input() public value;
  @Input() public label: string;

  constructor(public elem: ElementRef) {}
}
