<i
  class="tasks-drop-icon"
  [class.disabled]="tasks?.length === 0"
  [inlineSVG]="'/assets/svg/notifications.svg'"
></i>
<div class="badge badge-danger badge-pos" *ngIf="tasksCount">
  {{ tasksCount }}
</div>

<div
  class="tasks-drop-wrap"
  [@flyInOutState]="showTasks ? 'shown' : 'hidden'"
  (clickOutside)="closeTasks($event)"
>
  <div class="tasks-drop-header">
    <h4>My Tasks</h4>
  </div>
  <div class="tasks-drop-content">
    <a
      class="tasks-drop-item"
      href=""
      *ngFor="let task of tasks | slice: 0:5"
      (click)="goToTask(task)"
    >
      <p>{{ task.address | address }}</p>
      <span [ngPlural]="task.active_tasks_count"
        >{{ task.active_tasks_count }}
        <ng-template ngPluralCase="=1">task </ng-template>
        <ng-template ngPluralCase="other">tasks</ng-template>
      </span>
    </a>
    <p class="no-found text-muted" *ngIf="tasks?.length === 0">
      You have no open tasks
    </p>
  </div>
  <div class="tasks-drop-footer">
    <a class="btn-link btn-block" href="" (click)="goToTasks($event)"
      >See all Tasks</a
    >
  </div>
</div>
