import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DealActionPipe } from './deal-action.pipe';

@NgModule({
  declarations: [DealActionPipe],
  imports: [CommonModule],
  exports: [DealActionPipe],
})
export class DealActionsModule {}
