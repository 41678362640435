import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DynamicFieldDirective } from '../../dynamic-form/dynamic-field.directive';
import { OtSelectBoxComponent } from '../../select-box/select-box.component';
import { Field } from '../models/field.interface';
import { TypeaheadField } from '../models/typeahead-field';
import { AgentsTeamPipe } from '@tenant/ot';

@Component({
  selector: 'ot-dynamic-reference',
  templateUrl: './dynamic-reference.component.html',
  styleUrls: ['./dynamic-reference.component.scss'],
  providers: [AgentsTeamPipe],
})
export class DynamicReferenceComponent implements Field, OnInit, OnDestroy {
  private _config: TypeaheadField;

  public get config(): TypeaheadField {
    return this._config;
  }

  public set config(value: TypeaheadField) {
    this._config = value;
  }

  @ViewChild(OtSelectBoxComponent) public selectCmp: OtSelectBoxComponent;
  public group: FormGroup;
  public rootGroup: FormGroup;
  public options = [];
  public errors: any;
  public inputs: QueryList<DynamicFieldDirective>;
  public readonly: any;
  @ViewChild(OtSelectBoxComponent) private select: OtSelectBoxComponent;
  private destroy = new Subject();

  constructor(
    private api: HttpClient,
    private agentsTeamPipe: AgentsTeamPipe
  ) {}

  public ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }

  public ngOnInit(): void {
    this.config.readonlyState.pipe(takeUntil(this.destroy)).subscribe((val) => {
      this.readonly = val;
    });
  }

  public requestData($event = '') {
    let params = new HttpParams();
    params = params.set('limit', '9999');

    if ($event && $event.length) {
      params = params.set('search', $event);
    }
    this.api.get<any>(this.config.endpoint, { params }).subscribe((res) => {
      const val = this.group.get(this.config.field).value;

      if (this.config.field === 'landlord_name_landlords') {
        this.options = res.results.map((v) => ({
          value: v.name,
          label: v.name,
        }));
      } else {
        this.options = res.map((v) => ({
          value: v.id,
          label:
            this.config.field === 'lead_source'
              ? v.name
              : `${v?.first_name} ${v?.last_name} ${this.agentsTeamPipe.transform(
                  v.teams
                )}`,
        }));
      }
      if (this.config.field === 'agent_name') {
        this.options = this.options.filter(
          (o) =>
            o.value !== this.rootGroup.get('primary_agent_name')?.value &&
            this.rootGroup
              .get('agent_section')
              ?.value?.findIndex((v) => v?.agent_name === o?.value) === -1
        );
      }
      if (!this.select.value && val) {
        this.select.writeValue(val);
      }
    });
  }
}
