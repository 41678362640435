<div class="tasks-header">
  <h2>My Tasks</h2>
  <!--  <button class="btn btn-primary" (click)="addTask($event)" *ngIf="!loading && tasks?.length !== 0">New Task</button>-->
</div>
<div class="tasks-section" *ngFor="let deal of tasks">
  <div
    class="tasks-section-head"
    (click)="toggleDeal(deal.id)"
    [class.active]="selectedDeal === deal.id"
  >
    <div class="tasks-address-wrapper">
      <div class="tasks-address">
        <p>{{ deal.address | address }}</p>
      </div>
      <div class="number" [class.finished]="!deal.active_tasks">
        <ng-container *ngIf="deal.active_tasks">{{
          deal.active_tasks
        }}</ng-container>
        <span
          *ngIf="!deal.active_tasks"
          [inlineSVG]="'/assets/svg/approved.svg'"
        ></span>
      </div>
      <div class="arrow"></div>
    </div>
    <div class="tasks-address-link">
      <a [routerLink]="['/deal', deal.id]" (click)="$event.stopPropagation()"
        >Deal Details</a
      >
    </div>
  </div>
  <ot-dash-tasks-list
    (tasksUpdate)="tasksUpdate()"
    [@collapse]="selectedDeal === deal.id ? 'expanded' : 'collapsed'"
    [data]="deal.deal_tasks"
    [deal]="deal"
  ></ot-dash-tasks-list>
</div>
<div class="no-deal" *ngIf="!loading && tasks?.length === 0">
  No active deals are available
</div>

<ot-loader *ngIf="loading"></ot-loader>
