import { CommonModule } from '@angular/common';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { PendingApprovalModalService } from '@tenant/modals';
import { LaddaModule } from 'angular2-ladda';
import { InlineSVGModule } from 'ng-inline-svg';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { ConfirmClickDirective } from './directives/confirm-click.directive';
import { IfRoleDirective } from './directives/if-role.directive';
import { AdminUserGuard } from './guards/admin-user.guard';
import { AgencyUserGuard } from './guards/agency-user.guard';
import { AuthCodeGuard } from './guards/auth-code.guard';
import { LoggedInGuard } from './guards/logged-in.guard';

import { AuthorizationInterceptor } from './interceptors/authorization.interceptor';
import { RetryInterceptor } from './interceptors/retry.interceptor';
import { ApiHttpService } from './services/api-http.service';
import { AppLoadService } from './services/app-load.service';
import { CommonService } from './services/common.service';
import { HeaderDataService } from './services/header-data.service';
import { ProfileService } from './services/profile.service';
import { SessionService } from './services/session.service';
import { SweetAlertService } from './services/sweetalert.service';
import { TenantSessionService } from './services/tenant-session.service';
import { ApplicationProcessService } from './shared/application-process.service';
import { ContactService } from './shared/contact.service';
import { DocumentProcessingService } from './shared/document-processing.service';
import { OptionsAccessorService } from './shared/options-accessor.service';
import { ReportsDetailsResolver } from './shared/report-details.resolver';
import { EncodeHttpParamsInterceptor } from './interceptors/custom-encoder.interceptor';
import { LandlordsService } from './shared/landlords.service';

const providers = [
  TenantSessionService,
  AuthCodeGuard,
  LoggedInGuard,
  AgencyUserGuard,
  AdminUserGuard,

  ContactService,
  ReportsDetailsResolver,
  OptionsAccessorService,
  DocumentProcessingService,
  PendingApprovalModalService,
  ProfileService,
  SessionService,
  ApiHttpService,
  SweetAlertService,
  CommonService,
  HeaderDataService,
  ApplicationProcessService,
  AppLoadService,
  LandlordsService
];

const entryComponents = [ConfirmModalComponent];
const components = [entryComponents, ConfirmClickDirective, IfRoleDirective];
export const interceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthorizationInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RetryInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: EncodeHttpParamsInterceptor,
    multi: true,
  },
];

const exportedModules = [HttpClientModule, InlineSVGModule, LaddaModule];

@NgModule({
    imports: [CommonModule, exportedModules],
    exports: [exportedModules, components],
    declarations: [components],
    providers: [interceptors]
})
export class CoreModule {
  public static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: CoreModule,
      providers: [providers],
    };
  }
}
