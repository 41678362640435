import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dealContactTypesFilter',
})
export class DealContactTypesFilter implements PipeTransform {
  public transform(items: any[], filter: string): any {
    if (!items || !filter) {
      return items;
    }
    return items.filter((item) => item.contact_type.indexOf(filter) !== -1);
  }
}
