// eslint-disable  @typescript-eslint/member-ordering
import { Component, OnDestroy, OnInit, QueryList } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConditionService } from '../condition.service';
import { DynamicFieldDirective } from '../dynamic-field.directive';
import { DropdownField } from '../models/dropdown-field';
import { Field } from '../models/field.interface';
import { dynamicSort } from '@tenant/helpers';

@Component({
  selector: 'ot-dynamic-dropdown',
  templateUrl: './dynamic-dropdown.component.html',
  styleUrls: ['./dynamic-dropdown.component.scss'],
  providers: [
    {
      provide: Field,
      useExisting: DynamicDropdownComponent
    },
    ConditionService
  ]
})
export class DynamicDropdownComponent implements Field, OnInit, OnDestroy {
  private _config: DropdownField;

  public get config(): DropdownField {
    return this._config;
  }

  public set config(value: DropdownField) {
    this._config = value;
    this.processOptions(value);
  }

  public group: FormGroup;
  public rootGroup: FormGroup;
  public options;
  public errors: any;
  public inputs: QueryList<DynamicFieldDirective>;
  public readonly: any;
  private destroy = new Subject();

  constructor(private cond: ConditionService) {}

  public ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }

  public ngOnInit(): void {
    this.config.readonlyState.pipe(takeUntil(this.destroy)).subscribe((val) => {
      this.readonly = val;
    });
    if (this.config.field !== 'deal_status') {
      return;
    }
    let fields = this.config.options.map((v) => v.conditions.fields);

    if (fields.length > 0) {
      fields = fields
        .reduce((a, b) => a.concat(b))
        .filter((v, i, a) => a.indexOf(v) === i);
      this.cond.state.subscribe((values) => {
        this.options = this.config.availableOptions(values);
      });
      this.cond.subscribe(null, this.rootGroup, fields, false);
    }
  }

  private processOptions(config: DropdownField) {
    this.options = config.availableOptions(
      this.rootGroup ? this.rootGroup.value : {}
    );
    if (config.options != null) {
      this.options = this.options.sort(dynamicSort('order'));
    }
  }
}
