import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HeaderService } from '@tenant/ot-global';
import { environment } from '../environments/environment';
import { RouterHistoryService } from '@tenant/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ot-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  public environment = environment;
  public menuIsOpen = false;
  public isMobileMenuBarOpen = false;

  constructor(
    private header: HeaderService,
    private routerHistoryService: RouterHistoryService,
    private route: ActivatedRoute
  ) {}

  public ngOnInit() {
    (window as any).FreshworksWidget('hide', 'launcher');
    this.header.agencyLinks();
    this.routerHistoryService.loadRouting();
  }

  public openMenu(event: boolean) {
    this.menuIsOpen = event;
  }
}
