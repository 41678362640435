<div class="list">
  <div *ngFor="let task of tasks">
    <ot-dash-tasks-item [task]="task" [deal]="dealOption" (taskUpdated)="taskChanged()"></ot-dash-tasks-item>
  </div>
  <p class="deal-task-document-title bold" *ngIf="documents.length > 0">Documents</p>
  <div *ngFor="let doc of documents">
    <ot-dash-tasks-item type="document" [task]="doc" [deal]="dealOption" (taskUpdated)="taskChanged()"></ot-dash-tasks-item>
  </div>
  <!--<a class="toggle-link" href="" (click)="toggleStatus($event)">-->
    <!--{{status === 'active' ? 'Show Completed' : 'Hide Completed'}}</a>-->
</div>
