<footer class="footer">
  <section class="footer_bottom">
    <div class="container">
      <div class="row bottom-row">
        <div class="footer_bottom-right">
          <a href="/terms-of-use/">Terms of Use</a>
          <span class="footer_bottom-right-divider"> | </span>
          <a href="/privacy-policy/">Privacy Policy</a>
        </div>
        <div class="footer_bottom-center">
          <span>© 2022 FULTON GRACE REALTY</span>
          <span class="footer_bottom-center-divider"> | </span>
          <span>ALL RIGHTS RESERVED</span>
        </div>
        <div class="footer_bottom-icons">
          <ul class="footer_bottom-icons-list">
            <li>
              <a
                href="https://www.facebook.com/fultongracerealty"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  loading="lazy"
                  src="/assets/icon/facebook-icon-circle.svg"
                  alt="facebook"
                />
              </a>
            </li>

            <li>
              <a
                href="https://www.linkedin.com/company/fulton-grace-realty"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  loading="lazy"
                  src="/assets/icon/linkedin-icon-circle.svg"
                  alt="linkedin"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/fultongracerealty/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  loading="lazy"
                  src="/assets/icon/instagram-icon-circle.svg"
                  alt="instagram"
                />
              </a>
            </li>

            <li>
              <a
                href="https://twitter.com/fultongrace/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  loading="lazy"
                  src="/assets/icon/twitter-icon-circle.svg"
                  alt="twitter"
                />
              </a>
            </li>

            <li>
              <a
                href="http://www.pinterest.com/fgrealty/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  loading="lazy"
                  src="/assets/icon/pinterest-icon-circle.svg"
                  alt="pinterest"
                />
              </a>
            </li>

            <li>
              <a
                href="https://vimeo.com/fultongrace"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  loading="lazy"
                  src="/assets/icon/vimeo-icon-circle.svg"
                  alt="vimeo"
                />
              </a>
            </li>

            <li>
              <a
                href="https://www.youtube.com/channel/UCmjueoB6eff4B55olkzv07g/featured"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  loading="lazy"
                  src="/assets/icon/youtube-icon-circle.svg"
                  alt="youtube"
                />
              </a>
            </li>
          </ul>
          <ul class="footer_bottom-icons-extra">
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                [href]="fultongrace + '/equal-housing'"
              >
                <img
                  loading="lazy"
                  src="/assets/icon/ic_house.svg"
                  alt="housing opportunity"
                />
              </a>
            </li>
            <li>
              <img loading="lazy" src="/assets/icon/ic_r.svg" alt="realtor" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</footer>
