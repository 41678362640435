import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddressPipe } from './address.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { FileSizePipe } from './file-size.pipe';
import { Nl2brPipe } from './nl2br.pipe';
import { OtCurrencyPipe } from './ot-currency.pipe';
import { OtDatePipe } from './ot-date.pipe';
import { PhonePipe } from './phone.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SnakeToSpacePipe } from './snake-to-space.pipe';
import { UrlConcatPipe } from './url-concat.pipe';
import { DealStatusPipe } from './deal-status.pipe';
import { DealTypePipe } from './deal-type.pipe';
import { DealAgentRole } from './deal-agent-role.pipe';
import { ReceiveCommissionPipe } from './deal-recive-commission';
import { DealBoostGiftTypes } from './deal-boost-gift-types';
import { DealBoostGiftCardTypes } from './deal-boost-gift-card-types';
import { DealContactTypesPipe } from './deal-contact-types.pipe';
import { DealContactTypesFilter } from './deal-contact-types-filter';
import { DealDeadStatusPipe } from './deal-dead-status.pipe';
import { DealAgentType } from './deal-agent-type.pipe';
import { AgentsForCommissionTrackerPipe } from './agents-for-commission-tracker.pipe';
import { TenantReportType } from './tenant-report-type.pipe';
import { PrimaryAgent } from './primary-agent.pipe';
import { AgentsTeamPipe } from './agents-team.pipe';
import { SumInArrayPipe } from './sum-in-array.pipe';
import { UrlFileNamePipe } from './url-file-name.pipe';
import { OfficeTroopImagePipe } from './image.pipe';
import { UrlFileExtensionPipe } from './url-file-extension.pipe';

const PIPES = [
  SafeHtmlPipe,
  CapitalizePipe,
  FileSizePipe,
  Nl2brPipe,
  PhonePipe,
  AddressPipe,
  SnakeToSpacePipe,
  UrlConcatPipe,
  OtCurrencyPipe,
  OtDatePipe,
  DealStatusPipe,
  DealTypePipe,
  DealAgentRole,
  ReceiveCommissionPipe,
  DealBoostGiftTypes,
  DealBoostGiftCardTypes,
  DealContactTypesPipe,
  DealContactTypesFilter,
  DealDeadStatusPipe,
  DealAgentType,
  AgentsForCommissionTrackerPipe,
  TenantReportType,
  PrimaryAgent,
  AgentsTeamPipe,
  SumInArrayPipe,
  UrlFileNamePipe,
  OfficeTroopImagePipe,
  UrlFileExtensionPipe,
];

@NgModule({
  imports: [CommonModule],
  declarations: [PIPES],
  exports: [PIPES],
})
export class PipesModule {}
