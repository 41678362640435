// eslint-disable  @angular-eslint/component-selector

import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CdkColumnDef } from '@angular/cdk/table';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { merge, Subscription } from 'rxjs';
import { SortDirective } from './sort.directive';

const SORT_ANIMATION_TRANSITION = '200ms ease-in';

@Component({
  selector: '[ot-sort-header]',
  exportAs: 'otSortHeader',
  templateUrl: './sort-header.component.html',
  styleUrls: ['./sort-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('directionToggle', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(100, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        style({ opacity: 1 }),
        animate(100, style({ opacity: 0 }))
      ]),
      state('asc', style({ transform: 'rotate(0deg)' })),
      state('desc', style({ transform: 'rotate(180deg)' })),
      transition('asc <=> desc', animate(SORT_ANIMATION_TRANSITION))
    ])
  ]
})
export class SortHeaderComponent implements OnInit, OnDestroy {
  private _disableClear: boolean;

  @Input()
  get disableClear(): boolean {
    return this._disableClear;
  }

  set disableClear(v) {
    this._disableClear = coerceBooleanProperty(v);
  }

  private _disabled = false;

  get disabled() {
    return this._disabled;
  }

  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }

  @Input('ot-sort-header') id: string;
  @Input() arrowPosition: 'before' | 'after' = 'after';
  /** Overrides the sort start value of the containing OtSort for this OtSortable. */
  @Input('start') start: 'asc' | 'desc';
  private _rerenderSubscription: Subscription;

  constructor(
    cd: ChangeDetectorRef,
    @Optional() public _sort: SortDirective,
    @Optional() public _cdkColumnDef: CdkColumnDef
  ) {
    if (!this._sort) {
      throw new Error('OtSort not found');
    }
    this._rerenderSubscription = merge(
      _sort.sortChange,
      _sort._stateChanges
    ).subscribe(() => cd.markForCheck());
  }

  public ngOnInit(): void {
    if (!this.id && this._cdkColumnDef) {
      this.id = this._cdkColumnDef.name;
    }
    this._sort.register(this);
  }

  public ngOnDestroy(): void {
    this._sort.unregister(this);
    this._rerenderSubscription.unsubscribe();
  }

  @HostListener('click')
  public click($event) {
    this._sort.sort(this);
  }

  public _isDisabled() {
    return this._sort.disabled || this.disabled;
  }
}
