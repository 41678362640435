<div [formGroup]="group" *ngIf="config && config.field && group">
  <ot-form-container>
    <ot-label>
      {{ config.label }}
    </ot-label>
    <input
      *ngIf="config.inputType !== 'textarea' && config.numberField"
      [textMask]="numberMask"
      type="text"
      otInput
      [formControlName]="config.field" [tabindex]="config.read_only ? '-1' : '0'"
      [readonly]="config.read_only"
      [pattern]="inputPattern" [placeholder]="placeholder"
      [required]="config?.required">
    <input
      *ngIf="config.inputType !== 'textarea' && !config.numberField"
      type="text"
      otInput [tabindex]="config.read_only ? '-1' : '0'"
      [formControlName]="config.field"
      [readonly]="config.read_only" [placeholder]="placeholder"
      [pattern]="inputPattern"
      [required]="config?.required">
    <textarea *ngIf="config.inputType === 'textarea'" type="text" otInput [formControlName]="config.field"
              [readonly]="config.read_only" [placeholder]="placeholder"
              [required]="config?.required"></textarea>
    <ot-form-error key="custom" (remove)="errors = null" *ngIf="errors && errors.length > 0">{{errors[0]}}
    </ot-form-error>
  </ot-form-container>
</div>
