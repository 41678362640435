import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';
// eslint-disable  @angular-eslint/directive-selector
let nextUniqueId = 0;

@Directive({
  selector: 'ot-form-hint'
})
export class OtFormHintDirective {
  @HostBinding('attr.id')
  @Input()
  public id = `ot-hint-${nextUniqueId++}`;
  @HostBinding('class.d-none') public hidden = false;
  @Input() public key;

  @HostBinding('class.text-warn') protected className = true;
  @Output() private remove = new EventEmitter();

  constructor(public element: ElementRef) {}

  public destroy() {
    this.remove.emit(this.id);
  }
}
