import { Component } from '@angular/core';
import { environment } from '../../../../../apps/admin/src/environments/environment';


@Component({
  selector: 'ot-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public fultongrace = environment.FULTONGRACE_URL
}
