import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Patterns } from '@tenant/helpers';
import * as moment from 'moment-mini-ts';
import { createAutoCorrectedDatePipe } from 'text-mask-addons/dist/textMaskAddons';
import { DynamicFieldDirective } from '../dynamic-field.directive';
import { AbstractField } from '../models/abstract-field';
import { Field } from '../models/field.interface';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ot-dynamic-date',
  templateUrl: './dynamic-date.component.html',
  styleUrls: ['./dynamic-date.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicDateComponent implements Field, OnInit {
  public get currentError() {
    if (this.errors && this.errors.length > 0) {
      return this.errors[0];
    }
    return this.localValidation;
  }

  public datePickerModel = null;
  public localValidation: any;
  public config: AbstractField;
  public group: FormGroup;
  public rootGroup: FormGroup;
  public errors: any;
  public dateMask = {
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    pipe: createAutoCorrectedDatePipe('mm/dd/yyyy'),
    keepCharPositions: true,
  };
  public showDatePicker: boolean;
  public maxDate: { year: number; month: number; day: number };
  public minDate: { year: number; month: number; day: number };
  public inputs: QueryList<DynamicFieldDirective>;
  public readonly: any;
  private conditionDate: any;
  private conditionType: any;
  private minYear: number;
  private maxYear: number;
  private first: any = true;
  public today: NgbDateStruct;

  constructor(private eRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  public clickOutside($event) {
    if (
      !this.eRef.nativeElement.contains(event.target) &&
      this.showDatePicker
    ) {
      this.showDatePicker = false;
    }
  }

  public ngOnInit(): void {
    const now = new Date();
    this.today = {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate(),
    };
    this.minYear = now.getFullYear() - 10;
    this.maxYear = now.getFullYear() + 10;
    this.datePickerModel = {};
    if (this.config.meta && this.config.meta.min) {
      this.subscribeToDateChange(this.config.meta.min, 'min');
    } else if (this.config.meta && this.config.meta.max) {
      this.subscribeToDateChange(this.config.meta.max, 'max');
    }
    this.group.get(this.config.field).valueChanges.subscribe((val) => {
      this.localValidation = this.minDateValidation();
      if (val) {
        const year = val.split('/')[2];
        if (
          year &&
          year.length === 4 &&
          (this.minYear > +year || this.maxYear < +year)
        ) {
          this.localValidation = `You can choose the year in the range from ${this.minYear} to ${this.maxYear}`;
        } else {
          this.localValidation = null;
        }
      }
    });
    const validators = [Validators.pattern(Patterns.DATE)];
    if (this.config.required) {
      validators.push(Validators.required);
    }
    this.group.get(this.config.field).setValidators(validators);
    this.group.get(this.config.field).updateValueAndValidity();
  }

  public openDatePicker($event) {
    this.first = true;
    this.showDatePicker = !this.showDatePicker;
  }

  public changeDate(date: any) {
    const curr = this.datePickerModel || {};
    if (
      Object.values(date).join('') !== Object.values(curr).join('') ||
      !this.first
    ) {
      this.showDatePicker = false;
      this.datePickerModel = date;
      const tmp = `${date.month < 10 ? '0' : ''}${date.month}/${
        date.day < 10 ? '0' : ''
      }${date.day}/${date.year}`;
      this.group.get(this.config.field).setValue(tmp);
    }
    this.first = false;
  }

  public isToday(date: NgbDateStruct): boolean {
    if (!this.today) return false;
    return (
      date.year === this.today.year &&
      date.month === this.today.month &&
      date.day === this.today.day
    );
  }

  private subscribeToDateChange(field, type) {
    this.conditionType = type;
    this.rootGroup.get(field).valueChanges.subscribe((val) => {
      const date = moment(val);
      if (val && date.isValid()) {
        this.conditionDate = date;
        let year = date.year();
        if (this.minYear > year || this.maxYear < year) {
          year = moment().year();
        }
        const tmp = { year, month: date.month() + 1, day: date.date() };
        if (type === 'min') {
          this.minDate = tmp;
        } else {
          this.maxDate = tmp;
        }
        this.localValidation = this.minDateValidation();
      } else {
        if (type === 'min') {
          this.minDate = null;
        } else {
          this.maxDate = null;
        }
      }
    });
  }

  private minDateValidation() {
    const c = this.group.get(this.config.field);
    const targetDate = moment(c.value);
    // eslint-disable-line ,
    if (c.value && targetDate.isValid() && this.conditionDate) {
      if (
        this.conditionType === 'min' &&
        targetDate.isBefore(this.conditionDate)
      ) {
        return `This field must be more than ${this.conditionDate.format('L')}`;
      }
      if (
        this.conditionType === 'max' &&
        targetDate.isAfter(this.conditionDate)
      ) {
        return `This field must be less than ${this.conditionDate.format('L')}`;
      }
    }
    return null;
  }
}
