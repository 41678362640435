import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  Renderer2
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFieldDirective } from '../dynamic-field.directive';
import { AbstractField } from '../models/abstract-field';
import { Field } from '../models/field.interface';

@Component({
  selector: 'ot-dynamic-checkbox',
  templateUrl: './dynamic-checkbox.component.html',
  styleUrls: ['./dynamic-checkbox.component.scss']
})
export class DynamicCheckboxComponent implements Field, OnInit {
  public config: AbstractField;
  public group: FormGroup;
  public rootGroup: FormGroup;
  public errors: any;
  public inputs: QueryList<DynamicFieldDirective>;
  public readonly: any;

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  public ngOnInit(): void {
    const field = this.group.get(this.config.field);
    field.setValue(field.value === true || field.value === 'true');
    this.renderer.addClass(
      this.element.nativeElement,
      'field-' + this.config.field
    );
  }
}
