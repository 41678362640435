import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dealBoostGiftCardTypes',
})
export class DealBoostGiftCardTypes implements PipeTransform {
  public transform(value: any): string {
    if (value === 'S') {
      return 'Starbucks';
    } else if (value === 'A') {
      return 'Amazon';
    } else if (value === 'B') {
      return 'Bed, Bath & Beyond';
    } else if (value === 'G') {
      return 'Giftly';
    } else {
      return value;
    }
  }
}
