import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormConfigType } from './dynamic-form';
import { DynamicFormConfigService } from './dynamic-form-config.service';
import { AbstractField } from './models/abstract-field';

type IFindFn = (field: AbstractField) => boolean;

@Injectable()
export class DynamicFormService {
  public processed = false;
  public get config(): FormConfigType[] {
    return this.formConfig;
  }

  public form: FormGroup = new FormGroup({});
  private formConfig: FormConfigType[] = [];

  constructor(private dfc: DynamicFormConfigService) {}

  public setFormConfig(form: FormConfigType[]) {
    this.formConfig = form;
  }

  public initConfig(form?: any[]) {
    if (form) {
      if (form[0] instanceof AbstractField) {
        this.setFormConfig(form);
      } else {
        this.formConfig = this.dfc.processFormConfig(form);
      }
    }
    this.form = new FormGroup({});
    this.createFormControls();
    this.prepareConditions();
    this.processed = true;
  }

  find(findFn: IFindFn): AbstractField {
    return this.formConfig.find(findFn);
  }

  filter(findFn: IFindFn): AbstractField[] {
    return this.formConfig.filter(findFn);
  }

  private createFormControls() {
    this.formConfig.forEach((field: AbstractField) => {
      this.form.setControl(field.field, field.control());
    });
    this.form.updateValueAndValidity();
  }

  private prepareConditions() {
    this.formConfig.forEach(() => {});
  }
}
