import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@tenant/helpers';
import { map } from 'rxjs/operators';

@Injectable()
export class LandlordsService {
  constructor(private api: HttpClient) {}

  public getLandlordsList() {
    return this.api.get<any>(ApiConfig.landlords);
  }

  public create(data) {
    return this.api.post<any>(ApiConfig.landlords, data);
  }

  public getLandlord(id) {
    return this.api.get<any>(`${ApiConfig.landlords}/${id}`);
  }

  public patchLandlord(id, data) {
    return this.api.patch<any>(`${ApiConfig.landlords}/${id}`, data);
  }
}
