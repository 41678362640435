import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dealAgentRole',
})
export class DealAgentRole implements PipeTransform {
  public transform(value: any): string {
    switch (value) {
      case 'LA':
        return 'Listing Agent';
      case 'TA':
        return 'Tenant Agent';
      case 'BA':
        return 'Buyer Agent';
      case 'SA':
        return 'Seller Agent';
      case 'RA':
        return 'Referral Agent';
      case 'DA':
        return 'Dual Agent';
      case 'DSA':
        return 'Dual Sales Agent';
      case 'DRA':
        return 'Dual Rental Agent';
      case 'SLA':
        return 'Sales Listing Agent';
      case 'RLA':
        return 'Rental Listing Agent';
      default:
        return value;
    }
  }
}
