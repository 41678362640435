import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ɵConsole,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { environment } from '../../environments/environment';

@Component({
  selector: 'ot-applications-payment',
  templateUrl: './applications-payment.component.html',
  styleUrls: ['./applications-payment.component.scss'],
})
export class ApplicationsPaymentComponent implements OnInit, AfterViewInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  clientSecret = '';
  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  applicationFee = 'Application Fee - ';
  showCard = true;
  showSucceed = false;
  showError = false;
  errorMessage = '';
  disabledPay = true;

  applicationFeeAmount;

  constructor(
    private fb: FormBuilder,
    private stripeService: StripeService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.token) {
        this.clientSecret = params.token;
        this.applicationFeeAmount = params.amount;
      } else {
        window.location.href = `${environment.FULTONGRACE_URL}`;
      }
    });
  }

  ngAfterViewInit() {
    this.card.change.subscribe((card) => {
      this.disabledPay = !card.complete;
    });
  }

  pay(): void {
    this.disabledPay = true;
    this.stripeService
      .confirmCardPayment(this.clientSecret, {
        payment_method: {
          card: this.card.element,
          billing_details: {
            name: `${this.applicationFee} $${this.applicationFeeAmount}`,
          },
        },
      })
      .subscribe((result) => {
        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          this.showError = true;
          this.showCard = false;
          this.showSucceed = false;

          this.errorMessage = result.error.message;
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            // Show a success message to your customer
            this.showCard = false;
            this.showError = false;
            this.showSucceed = true;
          }
        }
      });
  }
}
