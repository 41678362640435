import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dealStatus',
})
export class DealStatusPipe implements PipeTransform {
  public transform(value: any): string {
    if (value === 'P') {
      return 'Pending';
    } else if (value === 'CL') {
      return 'Closed';
    } else if (value === 'AC') {
      return 'Active';
    } else if (value === 'D') {
      return 'Dead';
    } else if (value === 'CS') {
      return 'Coming soon';
    } else if (value === 'AR') {
      return 'Archived';
    } else {
      return value;
    }
  }
}
