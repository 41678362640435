import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dealContactTypes',
})
export class DealContactTypesPipe implements PipeTransform {
  public transform(value: any): string {
    if (value === 'B') {
      return 'Buyer';
    } else if (value === 'S') {
      return 'Seller';
    } else if (value === 'T') {
      return 'Tenant';
    } else if (value === 'L') {
      return 'Landlord';
    } else if (value === 'C') {
      return 'Co-Op Agent';
    } else {
      return value;
    }
  }
}
