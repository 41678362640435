import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { MenuBarDefiniton } from '../menu-bar-definition';
import { MenuBar } from '../menu-bar.model';

@Component({
  selector: 'ot-menu-bar-wrapper',
  templateUrl: './menu-bar-wrapper.component.html',
  styleUrls: ['./menu-bar-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MenuBarWrapperComponent implements OnInit {
  @Output() showMenuBar: EventEmitter<boolean> = new EventEmitter(false);
  @Output() closeMobileMenu: EventEmitter<boolean> = new EventEmitter(false);
  @Input() isMobileMenu: boolean = false;

  public menuBarItems: MenuBar[] = MenuBarDefiniton;
  public menuIsOpen: boolean = false;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  public toggleDropDown(id: number) {
    this.menuBarItems.map((item) => {
      if (item.dropdown) {
        if (item.id === id) {
          item.dropdown.isOpen = !item.dropdown.isOpen;
        } else {
          item.dropdown.isOpen = false;
        }
        if (id < 0) {
          item.dropdown.isOpen = false;
        }
      }
      return item;
    });
  }

  public onCloseMobileMenu() {
    this.toggleDropDown(-1);
    this.closeMobileMenu.emit(false);
  }

  public onToggleMenu() {
    this.menuIsOpen = !this.menuIsOpen;
    !this.menuIsOpen && this.toggleDropDown(-1);
    this.showMenuBar.emit(this.menuIsOpen);
  }

  public onNavigate(item: MenuBar) {
    if (item?.link) {
      void this.router.navigate([`/${item.link}`]);
      this.closeMobileMenu.emit(false);
    }

    if (item.dropdown) {
      this.toggleDropDown(item.id);
    }

    if (!item.dropdown && this.isMobileMenu) {
      this.toggleDropDown(-1);
    }
  }
}
