<i class="work-queue-icon" [inlineSVG]="'/assets/svg/notifications.svg'"></i>
<div class="badge badge-danger badge-pos d-none d-md-block" *ngIf="queueCount">
  {{ queueCount }}
</div>

<div
  class="work-queue-wrap"
  [@flyInOutState]="showWorkQueue ? 'shown' : 'hidden'"
>
  <div class="work-queue-header-drop">
    <h4>Work Queue</h4>
  </div>
  <div class="work-queue-content">
    <a class="work-queue-item" routerLink="/tasks">
      <p>My Tasks</p>
      <span *ngIf="workQueue?.my_tasks" class="badge badge-danger">{{
        workQueue?.my_tasks
      }}</span>
    </a>
    <a
      *ngxPermissionsOnly="['applications_reports']"
      class="work-queue-item"
      routerLink="/work-queue/applications"
    >
      <p>Applications</p>
      <span *ngIf="workQueue?.applications" class="badge badge-danger">{{
        workQueue?.applications
      }}</span>
    </a>
    <a
      class="work-queue-item"
      routerLink="/work-queue/deal-approval"
      *ngxPermissionsOnly="['work_queue']"
    >
      <p>Deal Approvals</p>
      <span *ngIf="workQueue?.deal_approvals" class="badge badge-danger">{{
        workQueue?.deal_approvals
      }}</span>
    </a>
    <a
      *ngxPermissionsOnly="['accounting']"
      class="work-queue-item"
      routerLink="/work-queue/accounting"
    >
      <p>Accounting</p>
      <span *ngIf="workQueue?.accounting" class="badge badge-danger">{{
        workQueue?.accounting
      }}</span>
    </a>
    <a
      *ngxPermissionsOnly="['tenant_reports']"
      class="work-queue-item"
      routerLink="/work-queue/reports"
    >
      <p>Tenant Reports</p>
    </a>
  </div>
</div>
