<div class="modal-header">
  <h4 class="modal-title">{{ addressConfig.title }}</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="active.dismiss(false)"
  >
    <span aria-hidden="true" [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>
</div>
<div class="modal-body">
  <form (submit)="save()" name="addressEditForm" id="addressEditForm">
    <ot-address
      [hideCountry]="addressConfig.hideCountry"
      [errors]="errors"
    ></ot-address>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="active.dismiss()">
    Close
  </button>
  <button
    type="submit"
    class="btn btn-primary"
    for="addressEditForm"
    formtarget="addressEditForm"
    form="addressEditForm"
  >
    Save
  </button>
</div>
