export interface ContactDetails {
  id: number;
  name: string;
  email: string;
  phone: string;
  company: string;
  address: ContactAddress;
  assigned_users: ContactUser[];
  deals: ContactDeal[];
  notes: ContactNote[];
  documents: ContactDocument[];
  date_created: string;
  date_modified: string;
  date_archived: string;
}

export interface ContactAddress {
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
}

export interface ContactUser {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  teams?: string[];
}

export interface ContactDeal {
  id: number;
  address: ContactAddress;
  contact_types: string[];
  deal_type: string;
  status: string;
}

export interface ContactNote {
  id: number;
  text: string;
  edited: boolean;
  creator: ContactUser;
  date_created: string;
  date_modified: string;
}

export interface ContactDocument {
  id: number;
  file: any;
  file_name: string;
  file_content_type: string;
  file_size: number;
  date_created: string;
  date_modified: string;
}

/* eslint-disable */
export class ContactDetailModel {
  public get fullAddress() {
    const address = this.address;
    if (!address) {
      return '';
    }
    let addressLines = address.address_line_1;
    if (address.address_line_2) {
      addressLines = address.address_line_1 + ', ' + address.address_line_2;
    }
    const addressParts = [
      addressLines,
      address.city,
      address.state,
      address.zip_code,
      address.country,
    ];
    return (
      addressParts.filter((v) => v && v.length > 0).join(', ') ||
      address.country
    );
  }

  id: number;
  name: string;
  email: string;
  phone: string;
  company: string;
  address: ContactAddress;
  assigned_users: ContactUser[];
  deals: ContactDeal[];
  notes: ContactNote[];
  documents: ContactDocument[];
  date_created: string;
  date_modified: string;
  date_archived: string;

  // { [propName: string ]: any };
  constructor(json: ContactDetails) {
    this.id = json.id;
    this.name = json.name;
    this.email = json.email;
    this.phone = json.phone;
    this.company = json.company;
    this.address = json.address;
    this.assigned_users = json.assigned_users;
    this.deals = json.deals;
    this.notes = json.notes;
    this.documents = json.documents;
    this.date_created = json.date_created;
    this.date_modified = json.date_modified;
    this.date_archived = json.date_archived;
  }
}
