import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[otTrimLines]'
})
export class TrimLinesDirective {
  private _text = '';

  get text(): string {
    return this._text;
  }

  @Input()
  set text(value: string) {
    this._text = value;
    let height;
    const clamp = this.otTrimLines;

    const elem = this.elem.nativeElement;
    const clampText = () => {
      this.initialize();
      height = this.getMaxHeight(elem, clamp);

      if (height < elem.clientHeight) {
        this.truncate(elem, height);
      }
    };
    if (value !== undefined) {
      elem.innerHTML = value;
    }
    setTimeout(() => {
      clampText();
    });
  }

  @Input() otTrimLines = 2;
  @Input() truncateChar = '...';
  chunks = null;
  lastChunk = null;
  splitChar = null;
  splitChars = ['.', ',', ' ', ''];

  constructor(private elem: ElementRef) {}

  getLineHeight(elem) {
    const style = window.getComputedStyle(elem, null);
    let lineHeight: any = style.getPropertyValue('line-height');
    if (lineHeight === 'normal') {
      //normal line height uses a default value of roughly 1.2
      lineHeight = parseInt(style.getPropertyValue('font-size'), 10) * 1.2;
    }

    return parseInt(lineHeight, 10);
  }

  getMaxHeight(elem, clamp) {
    const lineHeight = this.getLineHeight(elem);
    return lineHeight * clamp;
  }

  truncate(elem, height) {
    let target;
    let nodeValue;

    if (!height) return;

    target = elem.lastChild;
    nodeValue = target.nodeValue.replace(this.truncateChar, '');

    if (!this.chunks) {
      if (this.splitChars.length > 0) {
        this.splitChar = this.splitChars.shift();
      }

      this.chunks = nodeValue.split(this.splitChar);
    }

    if (this.chunks.length > 1) {
      this.lastChunk = this.chunks.pop();
      this.applyEllipsis(target, this.chunks.join(this.splitChar));
    } else {
      this.chunks = null;
    }

    if (this.chunks) {
      if (elem.clientHeight <= height) {
        if (this.splitChars.length >= 0 && this.splitChar != '') {
          // eslint-disable-line
          this.applyEllipsis(
            target,
            this.chunks.join(this.splitChar) + this.splitChar + this.lastChunk
          );
          this.chunks = null;
        } else {
          return elem.innerText;
        }
      }
    }

    return this.truncate(elem, height);
  }

  applyEllipsis(target, str) {
    target.nodeValue = str + this.truncateChar;
  }

  initialize() {
    this.chunks = null;
    this.lastChunk = null;
    this.splitChar = null;
    this.splitChars = ['.', ',', ' ', ''];
  }
}
