export class LeaderboardModel {
  public get grossCommission() {
    return this.gross_volume;
  }

  public get totalPercents() {
    return (this.grossCommission / this._maximalCommissions) * 100;
  }

  public get salesPercents() {
    return (this.sales / this.grossCommission) * 100;
  }

  public get rentalPercents() {
    return (this.rental / this.grossCommission) * 100;
  }

  public number_of_closings: number;
  public sales: number;
  public rental: number;
  public name: string;
  public id: number;
  public rank: number;
  public gross_volume: number;
  public _maximalCommissions: number;

  constructor(json) {
    this.number_of_closings = json.number_of_closings;
    this.sales = json.sales;
    this.rental = json.rental;
    this.name = json.name;
    this.gross_volume = json.gross_volume;
    this.id = json.id;
    this.rank = json.rank;
    this._maximalCommissions = json.maximalCommissions;
  }
}
