import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DownloadDirective } from './download.directive';
import { FitDateRangeDirective } from './fit-date-range.directive';
import { FitDropdownDirective } from './fit-dropdown.directive';
import { InputFileDirective } from './input-file.directive';
import { ScrollToErrorDirective } from './scroll-to-error.directive';
import { StickyDirective } from './sticky.directive';
import { TimePickerFixDirective } from './time-picker-fix.directive';
import { TrimLinesDirective } from './trim-lines.directive';
import { DebounceClickDirective } from './debounce-click.directive';

const DIRECTIVES = [
  FitDropdownDirective,
  FitDateRangeDirective,

  StickyDirective,
  InputFileDirective,
  TimePickerFixDirective,
  DownloadDirective,
  TrimLinesDirective,
  ScrollToErrorDirective,
  DebounceClickDirective,
];

@NgModule({
  imports: [CommonModule],
  declarations: [DIRECTIVES],
  exports: [DIRECTIVES],
})
export class DirectivesModule {}
