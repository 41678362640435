import { DefaultTableDataCollection } from './default.table-data-collection';

export class ServerSourceConf {
  protected static readonly SORT_FIELD_KEY = 'order_field';
  protected static readonly SORT_DIR_KEY = 'order_dir';
  protected static readonly PAGER_PAGE_KEY = 'page';
  protected static readonly PAGER_LIMIT_KEY = 'page_size';
  protected static readonly TOTAL_KEY = 'meta.total';
  protected static readonly DATA_KEY = 'data';

  public endPoint: string;

  public sortFieldKey: string;
  public sortDirKey: string;
  public pagerPageKey: string;
  public pagerLimitKey: string;
  public totalKey: string;
  public dataKey: string;
  public dataCollection: DefaultTableDataCollection<any>;

  constructor({
    endPoint = '',
    sortFieldKey = '',
    sortDirKey = '',
    pagerPageKey = '',
    pagerLimitKey = '',
    totalKey = '',
    dataKey = '',
    dataCollection = null,
  } = {}) {
    this.endPoint = endPoint ? endPoint : '';

    this.sortFieldKey = sortFieldKey
      ? sortFieldKey
      : ServerSourceConf.SORT_FIELD_KEY;
    this.sortDirKey = sortDirKey ? sortDirKey : ServerSourceConf.SORT_DIR_KEY;
    this.pagerPageKey = pagerPageKey
      ? pagerPageKey
      : ServerSourceConf.PAGER_PAGE_KEY;
    this.pagerLimitKey = pagerLimitKey
      ? pagerLimitKey
      : ServerSourceConf.PAGER_LIMIT_KEY;
    this.totalKey = totalKey ? totalKey : ServerSourceConf.TOTAL_KEY;
    this.dataKey = dataKey ? dataKey : ServerSourceConf.DATA_KEY;
    this.dataCollection = dataCollection
      ? dataCollection
      : new DefaultTableDataCollection<any>();
  }
}
