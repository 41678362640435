import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@tenant/helpers';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class TasksService {
  progress: number;

  constructor(private api: HttpClient) {}

  public addTask(dealId, data) {
    const fd = new FormData();
    fd.append('assignee', data.assignee);
    fd.append('summary', data.summary);
    fd.append('task_type', data.task_type);
    fd.append('required', data.required);
    if (data?.shared_with?.length) {
      data.shared_with.forEach((i) => {
        fd.append('document.shared_with', i);
      });
    }
    if (data.due_date) {
      fd.append('due_date', data.due_date);
    }
    if (data?.document?.file) {
      fd.append('document.file', data.document.file);
      fd.append('document.file_name', data.document.file_name);
    }

    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    return this.api
      .post<any>(`${ApiConfig.deal}${dealId}/tasks`, fd, {
        headers,
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((event: any) => {
          if (event.type == HttpEventType.UploadProgress) {
            this.progress = Math.round((100 / event.total) * event.loaded);
          } else if (event.type == HttpEventType.Response) {
            this.progress = null;
            return event;
          }
        }),
        catchError((err: any) => {
          return (this.progress = null);
        })
      );
  }

  public getDealTasks(id) {
    return this.api.get<any>(`${ApiConfig.deal}${id}/tasks`).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  public getDealDocuments(id) {
    return this.api
      .get<any>(`${ApiConfig.tasks}/?type=document&status=all&deal=${id}`)
      .pipe(
        map((res: any) => {
          return res.data;
        })
      );
  }

  public updateTask(id, data) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    const fd = new FormData();
    if (data.assignee) {
      fd.append('assignee', data.assignee);
    }
    if (data.summary) {
      fd.append('summary', data.summary);
    }
    if (data.task_type) {
      fd.append('task_type', data.task_type);
    }
    if (data.required) {
      fd.append('required', data.required);
    }
    if (data.due_date) {
      fd.append('due_date', data.due_date);
    }
    if (data?.document?.file) {
      fd.append('document.file', data.document.file);
      fd.append('document.file_name', data.document.file_name);
    }

    if (data?.shared_with?.length) {
      data.shared_with.forEach((i) => {
        fd.append('document.shared_with', i);
      });
    }

    return this.api
      .patch<any>(`${ApiConfig.dealTasks}/${id}`, fd, {
        headers,
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((event: any) => {
          if (event.type == HttpEventType.UploadProgress) {
            this.progress = Math.round((100 / event.total) * event.loaded);
          } else if (event.type == HttpEventType.Response) {
            this.progress = null;
            return event;
          }
        }),
        catchError((err: any) => {
          return (this.progress = null);
        })
      );
  }

  public deleteTask(id) {
    return this.api.delete<any>(`${ApiConfig.tasks}/${id}`);
  }

  public taskEvent(id, event, data) {
    return this.api
      .post<any>(`${ApiConfig.dealTasks}/${id}/${event}`, data)
      .pipe(map((res: any) => res));
  }

  public getDealsAddresses(type) {
    let url = `${ApiConfig.dealTasks}/addresses/`;
    if (type === 'active') {
      url = url + 'active';
    } else {
      url = url + 'completed';
    }
    return this.api.get<any>(url);
  }

  public getDealForTasks() {
    return this.api
      .get<any>(`${ApiConfig.deal}for-tasks`)
      .pipe(map((res: any) => res));
  }

  public getDealById(id) {
    return this.api
      .get<any>(`${ApiConfig.deal}${id}`)
      .pipe(map((res: any) => res));
  }
}
