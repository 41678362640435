import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouterHistoryService {
  private history = [];
  private queryHistory = [];

  constructor(private router: Router, private route: ActivatedRoute) {}

  public loadRouting(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.history = [...this.history, event.urlAfterRedirects];
        this.queryHistory = [
          ...this.queryHistory,
          this.route.snapshot.queryParams,
        ];
        if (this.history.length > 3) {
          this.history.shift();
        }

        if (this.queryHistory.length > 3) {
          this.queryHistory.shift();
        }
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2];
  }

  public getPreviousQuery(): string {
    return this.queryHistory[this.queryHistory.length - 2];
  }
}
