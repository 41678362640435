import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dealReceiveCommission',
})
export class ReceiveCommissionPipe implements PipeTransform {
  public transform(value: any): string {
    if (value === 'R') {
      return 'Receive check at closing';
    } else if (value === 'TO') {
      return 'Tenant will pay FGR online';
    } else if (value === 'TBC') {
      return 'Tenant will bay FGR by check';
    } else if (value === 'MLS') {
      return 'FGR to invoice MLS agency';
    } else if (value === 'PM') {
      return 'FGR to invoice 3rd party PM Company';
    } else if (value === 'CEA') {
      return 'Commission held in FGR escrow account';
    } else if (value === 'P') {
      return 'Partial commission held in FGR escrow and partial commission received by check at closing';
    } else {
      return value;
    }
  }
}
