import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import {
  BehaviorSubject,
  merge,
  Observable,
  range,
  Subscription,
  throwError,
} from 'rxjs';
import { catchError, combineAll, map, skip, tap } from 'rxjs/operators';
import { SortDirective } from '../sort/sort.directive';
import { TableDataBase, TableMeta, TableResponse } from './ds-meta';

export class OtDataSource<T> implements DataSource<T> {
  private _state = new BehaviorSubject<T[]>([]);
  public data: T[];

  public get state() {
    return this._state.asObservable();
  }

  public get metaSync() {
    return this.meta.getValue();
  }

  public get page(): number {
    if (this.metaSync) {
      return this.metaSync.page || 1;
    }
    return 1;
  }

  public set page(page: number) {
    const meta = {
      total: this.metaSync.total,
      page: page,
      per_page: 15,
      ...this.metaSync,
    };
    this.meta.next(meta);
    this.pageState.next(page);
    // this.load({ page: page });
  }

  public meta = new BehaviorSubject<TableMeta>(null);
  public loading = new BehaviorSubject<boolean>(false);
  public pageState = new BehaviorSubject<number>(1);
  private lastReq: Subscription;
  private _filtersState = new BehaviorSubject<any>({});
  private sort$: Subscription;
  private _filtersState$: Subscription;
  private pageState$: Subscription;

  public onSortChanges$ = new BehaviorSubject({});

  constructor(private db: TableDataBase<T>, private sort: SortDirective) {
    if (sort) {
      this.sort$ = merge(sort.sortChange).subscribe((v) => {
        this.onSortChanges$.next(this.sort);
        this.refresh();
      });
    }
    this.load({ page: 1 });
    this._filtersState$ = this._filtersState
      .asObservable()
      .pipe(skip(1))
      .subscribe((v) => {
        this.load({ page: 1 });
      });
    this.pageState$ = this.pageState
      .asObservable()
      .pipe(skip(1))
      .subscribe((v) => {
        this.load({ page: v });
      });
  }

  public hasNext() {
    if (!this.metaSync) {
      return false;
    }
    return this.metaSync.page * this.metaSync.per_page < this.metaSync.total;
  }

  public setFilters(filters) {
    if (filters.date) {
      const formatDate = (date) => {
        return date ? date : null;
      };

      const dateParamsArr: string[] = typeof filters.date.customParams === 'string'
        ? filters.date.customParams.split(',')
        : filters.date.customParams;

      if (filters.date.customParams) {
        filters[dateParamsArr[0]] = formatDate(
          filters.date.from
        );
        filters[dateParamsArr[1]] = formatDate(
          filters.date.to
        );
        filters.date = null;
      } else {
        filters.from = formatDate(filters.date.from);
        filters.to = formatDate(filters.date.to);
        filters.date = null;
      }
    }
    this._filtersState.next(filters);
  }

  public getFilters() {
    return this._filtersState.value;
  }

  public connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this._state.asObservable().pipe(
      tap((data: any) => {
        this.data = data;
      })
    );
  }

  public disconnect(collectionViewer: CollectionViewer): void {
    if (this.sort$) {
      this.sort$.unsubscribe();
    }
    this._filtersState$.unsubscribe();
    this.pageState$.unsubscribe();
  }

  public load(params) {
    params.offset = (params.page - 1) * 15;
    params.limit = 15;
    this.lastReq?.unsubscribe();
    this.lastReq = this.makeRequest(params).subscribe((v: TableResponse<T>) => {
      let old = [];
      let meta = {
        total: v.count,
        page: params.page,
        per_page: 15,
        ...v?.meta,
      };
      this.meta.next(meta);
      if (this.page !== 1) {
        old = this._state.getValue();
      }
      const data = old.concat(v.results);
      this._state.next(data);
    });
  }

  public refresh() {
    this.stopLastRequest();
    range(1, this.page)
      .pipe(
        map((v) => this.makeRequest({ page: v })),
        combineAll()
      )
      .subscribe((res: any[]) => {
        const data = res
          .map((v) => v.results)
          .reduce((a, b) => a.concat(b), []);
        this._state.next(data);
        let meta = {
          total: res[0]?.count,
          page: this.page,
          per_page: 15,
          ...res[0]?.meta,
        };
        this.meta.next(meta);
      });
  }

  public loadNext() {
    this.page = this.page + 1;
  }

  public stopLastRequest() {
    if (this.lastReq) {
      this.lastReq.unsubscribe();
    }
  }

  public changeSortParams(sort) {
    this.sort = sort;
    this.refresh();
  }

  private makeRequest(params: any = {}) {
    this.loading.next(true);
    let sortParams = this.prepareSort(this.sort);
    params = Object.assign({}, sortParams, params, this._filtersState.value);
    return this.db.load(params).pipe(
      tap(() => {
        this.loading.next(false);
      }),
      catchError((err) => {
        this.loading.next(false);
        return throwError(err);
      })
    );
  }

  prepareSort(sort: any) {
    if (!sort || !sort.direction) {
      return null;
    }
    let ordering = sort.active;
    if (sort.direction?.toLowerCase() === 'desc') {
      ordering = `-${ordering}`;
    }
    return {
      ordering,
    };
  }
}
