import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../components/confirm-modal/confirm-modal.component';

export interface SwalOpts {
  title?: string;
  text?: string;
  type?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

@Injectable()
export class SweetAlertService {
  constructor(private modalService: NgbModal) {}

  public swal(params) {
    // return sweetalert(params);
    return null;
  }

  public prompt(options: SwalOpts) {
    const baseOptions = {
      showCancelButton: true,
      confirmButtonText: 'Submit',
      input: 'text',
    };
    // return sweetalert(Object.assign(baseOptions, options));
    return null;
  }

  public confirm(options: SwalOpts) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      keyboard: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = options.title;
    modalRef.componentInstance.text = options.text;
    if (options.confirmButtonText) {
      modalRef.componentInstance.confirmButtonText = options.confirmButtonText;
    }
    if (options.cancelButtonText) {
      modalRef.componentInstance.cancelButtonText = options.cancelButtonText;
    }
    return modalRef.result;
    /*const baseOptions = {
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      type: 'warning'
    };
    return sweetalert(Object.assign(baseOptions, options));*/
  }

  public alert(options: SwalOpts) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      keyboard: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = options.title;
    modalRef.componentInstance.text = options.text;
    modalRef.componentInstance.type = 'alert';
    if (options.confirmButtonText) {
      modalRef.componentInstance.confirmButtonText = options.confirmButtonText;
    }
    return modalRef.result;
  }

  public question(options) {
    return this.alert(Object.assign({ type: 'question' }, options));
  }

  public success(options) {
    return this.alert(Object.assign({ type: 'success' }, options));
  }

  public error(options) {
    return this.alert(Object.assign({ type: 'error' }, options));
  }

  public warn(options) {
    return this.alert(Object.assign({ type: 'warn' }, options));
  }

  public info(options) {
    return this.alert(Object.assign({ type: 'info' }, options));
  }
}
