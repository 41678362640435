import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiConfig } from '@tenant/helpers';

@Injectable()
export class AddressService {
  constructor(private http: HttpClient) {}

  search(params) {
    if (params.search.length < 3) {
      return of([]);
    }
    return this.http
      .get(ApiConfig.listingsAddresses, {
        params: params,
      })
      .pipe(
        map((response: any) => {
          return response.results;
        })
      );
  }
}
