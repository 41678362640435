import { Component, QueryList } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AddressField } from '@tenant/forms';
import { DynamicFieldDirective } from '../dynamic-field.directive';
import { Field } from '../models/field.interface';

@Component({
  selector: 'ot-dynamic-address',
  templateUrl: './dynamic-address.component.html',
  styleUrls: ['./dynamic-address.component.scss'],
})
export class DynamicAddressComponent implements Field {
  public config: AddressField;
  public group: FormGroup;
  public rootGroup: FormGroup;
  public errors: any;
  public inputs: QueryList<DynamicFieldDirective>;
  public readonly: any;
}
