import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { ReportService } from '../../../../tenant-report/src/lib/services/report.service';

@Injectable()
export class ReportsDetailsResolver implements Resolve<any> {
  constructor(private srv: ReportService) {}

  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.srv.details(route.params['id']);
  }
}
