import { Component, QueryList } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Mask } from '@tenant/helpers';
import { DynamicFieldDirective } from '../dynamic-field.directive';
import { Field } from '../models/field.interface';
import { TextField } from '../models/text-field';

@Component({
  selector: 'ot-dynamic-persents',
  templateUrl: './dynamic-percent.component.html',
  styleUrls: ['./dynamic-percent.component.scss']
})
export class DynamicPercentComponent implements Field {
  public config: TextField;
  public group: FormGroup;
  public rootGroup: FormGroup;
  public errors: any;
  public percentsMask = Mask.PERCENTS;

  public inputs: QueryList<DynamicFieldDirective>;
  public readonly: any;
}
