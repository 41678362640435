<i class="notifications-icon" [inlineSVG]="'/assets/svg/notifications.svg'" (click)="toggleNotifications($event)"></i>

<div class="notifications-wrap"
     (clickOutside)="closeNotification($event)"
     [@flyInOutState]="showNotifications ? 'shown' : 'hidden'">
  <div class="notifications-header">
    <h4>My Notifications</h4>
    <button class="btn btn-secondary">All Read</button>
  </div>
  <div class="notifications-content">
    <div class="notifications-item">
      <i class="icon-32 approved" [inlineSVG]="'/assets/svg/approved.svg'"></i>
      <div>
        <b>James Lemon</b> approved your deal <a href="">100 N Hermitage, Unit 713, Chicago, IL</a>
        <div class="notifications-item-time">11 minutes ago</div>
      </div>
      <i class="icon-24 close" [inlineSVG]="'/assets/svg/close.svg'"></i>
    </div>
    <div class="notifications-item">
      <i class="icon-32 closed" [inlineSVG]="'/assets/svg/closed.svg'"></i>
      <div>
        <b>Kate Wilson</b> changed status of your deal <a href="">100 N Hermitage, Unit 713, Chicago, IL</a> to pending
        <div class="notifications-item-time">11 minutes ago</div>
      </div>
      <i class="icon-24 close" [inlineSVG]="'/assets/svg/close.svg'"></i>
    </div>
    <div class="notifications-item">
      <i class="icon-32 return" [inlineSVG]="'/assets/svg/return.svg'"></i>
      <div>
        <b>James Lemon</b> approved your deal <a href="">100 N Hermitage, Unit 713, Chicago, IL</a>
        <div class="notifications-item-time">11 minutes ago</div>
      </div>
      <i class="icon-24 close" [inlineSVG]="'/assets/svg/close.svg'"></i>
    </div>
    <div class="notifications-item">
      <i class="icon-32 approved" [inlineSVG]="'/assets/svg/approved.svg'"></i>
      <div>
        <b>Kate Wilson</b> changed status of your deal <a href="">100 N Hermitage, Unit 713, Chicago, IL</a> to pending
        <div class="notifications-item-time">11 minutes ago</div>
      </div>
      <i class="icon-24 close" [inlineSVG]="'/assets/svg/close.svg'"></i>
    </div>
  </div>
  <div class="notifications-footer">
    <a href="">See all notification</a>
  </div>
</div>
