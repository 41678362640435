<div class="filter-wrapper">
  <ng-content select="ot-filter-container"></ng-content>
  <ng-content select="[addNewBtn]"></ng-content>
</div>
<div class="meta-block" *ngIf="meta && !hideMeta">
  <ng-container
    *ngTemplateOutlet="metaTemplate || metaDefault; context: { meta: meta }"
  ></ng-container>
</div>
<ng-content></ng-content>
<div class="next-page" *ngIf="ds?.hasNext()">
  <button class="btn-load-more" (click)="ds?.loadNext()">Load More</button>
</div>

<ng-template #metaDefault let-meta="meta">
  <div class="meta-pages" *ngIf="meta">
    Showing {{ meta?.visible }} out of {{ meta?.total }}
  </div>
</ng-template>

<ot-loader *ngIf="ds?.loading | async"></ot-loader>
<div class="no-content" *ngIf="ds?.metaSync?.total === 0">
  <ng-content select=".no-table-content"></ng-content>
</div>
