import { Injectable } from '@angular/core';
import { NgControl } from '@angular/forms';
import { RadioControlDirective } from './radio-control.directive';

@Injectable()
export class OtRadioRegistryService {
  private _accessors: any[] = [];

  add(control: NgControl, accessor: RadioControlDirective) {
    this._accessors.push([control, accessor]);
  }

  remove(accessor: RadioControlDirective) {
    for (let i = this._accessors.length - 1; i >= 0; --i) {
      if (this._accessors[i][1] === accessor) {
        this._accessors.splice(i, 1);
        return;
      }
    }
  }

  select(accessor: RadioControlDirective) {
    this._accessors.forEach((c) => {
      if (this._isSameGroup(c, accessor) && c[1] !== accessor) {
        c[1].fireUncheck(accessor.value);
      }
    });
  }

  private _isSameGroup(
    controlPair: [NgControl, RadioControlDirective],
    accessor: RadioControlDirective
  ): boolean {
    if (!controlPair[0].control) {
      return false;
    }
    return (
      (controlPair[0] as any)._parent === (accessor._control as any)._parent &&
      controlPair[1].name === accessor.name
    );
  }
}
