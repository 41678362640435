import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { componentMappingToken } from './component-mapping.token';
import { AbstractField } from './models/abstract-field';
import { Field } from './models/field.interface';

@Directive({
  selector: '[otDynamicField]',
})
export class DynamicFieldDirective implements OnChanges, OnInit, OnDestroy {
  @Input() public config: AbstractField;

  @Input() public group: FormGroup;

  @Input() public rootGroup: FormGroup;

  @Input() public errors: any;

  public component: ComponentRef<Field>;
  private destroyState = new Subject();

  constructor(
    private resolver: ComponentFactoryResolver,
    private renderer: Renderer2,
    private element: ElementRef,
    public container: ViewContainerRef,
    @Inject(componentMappingToken) private componentsMapping: Type<Field>[]
  ) {}

  public ngOnChanges() {
    if (this.component) {
      this.component.instance.config = this.config;
      this.component.instance.group = this.group;
      this.component.instance.rootGroup = this.rootGroup;
      this.component.instance.errors =
        this.errors && this.errors[this.config.field]
          ? this.errors[this.config.field]
          : null;
    }
  }

  public ngOnInit() {
    this.config.rerenderState
      .pipe(takeUntil(this.destroyState))
      .subscribe(() => {
        this.destroy();
        this.render();
      });
    this.render();
  }

  private render() {
    if (!this.componentsMapping[this.config.type] && !this.config.component) {
      const supportedTypes = Object.keys(this.componentsMapping).join(', ');
      // throw new Error(
      //   `Trying to use an unsupported type (${this.config.type}).
      //   Supported types: ${supportedTypes}`
      // );
      return;
    }
    const componentClass =
      this.config.component || this.componentsMapping[this.config.type];
    const component =
      this.resolver.resolveComponentFactory<Field>(componentClass);
    this.component = this.container.createComponent(component);
    this.component.instance.config = this.config;
    this.component.instance.group = this.group;
    this.component.instance.rootGroup = this.rootGroup;
    this.component.instance.errors =
      this.errors && this.errors[this.config.field]
        ? this.errors[this.config.field]
        : null;
  }

  private destroy() {
    if (this.component) {
      this.component.onDestroy(() => {
        this.container.clear();
      });
    }
  }

  public ngOnDestroy(): void {
    this.destroyState.next(true);
    this.destroyState.complete();
    this.destroy();
  }
}
