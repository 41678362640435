import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@tenant/helpers';
import {
  TableDataBase,
  TableResponse,
} from '../../../../table/src/lib/data-table/ds-meta';
import { Observable } from 'rxjs';

@Injectable()
export class ApplicationProcessService implements TableDataBase<any> {
  public id = null;

  constructor(private api: HttpClient) {}

  public process(query = null): Observable<any> {
    if (!this.id) {
      throw new Error('Application id not found');
    }
    const tmp = Object.assign({}, query);
    let params = new HttpParams();
    params = params.append('rental_form_id', this.id);

    for (const key in tmp) {
      if (tmp.hasOwnProperty(key) && tmp[key] != null) {
        params = params.append(key, tmp[key]);
      }
    }

    return this.api.get<any>(`${ApiConfig.deal}mergeable-with-application`, {
      params,
    });
  }

  public createContact(agentId): Observable<any> {
    if (!this.id) {
      throw new Error('Application id not found');
    }
    return this.api.post<any>(
      `${ApiConfig.processApplications}/${this.id}/contacts`,
      {
        rental_application_id: this.id,
        agent_id: agentId,
      }
    );
  }

  public addContactToDeal(contact, dealId): Observable<any> {
    return this.api.post<any>(`${ApiConfig.deal}${dealId}/contacts`, contact);
  }

  public merge(dealId, created = false) {
    if (!this.id) {
      throw new Error('Application id not found');
    }
    return this.api.post<any>(
      `${ApiConfig.applications}${this.id}/merge-with-deal`,
      {
        deal_id: dealId,
        rental_application_id: this.id,
        created,
      }
    );
  }

  public load(query): Observable<TableResponse<any>> {
    const tmp = Object.assign({}, query);
    let params = new HttpParams();

    for (const key in tmp) {
      if (tmp.hasOwnProperty(key) && tmp[key] != null) {
        params = params.append(key, tmp[key]);
      }
    }
    return this.api.get<any>(`${ApiConfig.deal}mergeable-with-application`, {
      params,
    });
  }
}
