<div [formGroup]="commissionGroup">
  <ot-form-container *ngIf="config && group">
    <ot-label>
      {{ config.label }} <span class="icon-24 correct-spaces icon-black" [ngbTooltip]="config?.meta?.tooltip" *ngIf="config?.meta?.tooltip"
                               [inlineSVG]="'/assets/svg/help.svg'"></span>
    </ot-label>
    <div class="group">
      <input type="text" otInput formControlName="value" [textMask]="currencyMask" [required]="config?.required" [readonly]="config?.read_only">
      <ot-select-box *ngIf="types.length > 1" class="no-min-width" formControlName="type" [options]="types" [readonly]="config?.read_only"></ot-select-box>
    </div>
    <div *ngIf="config.hint && config.hint.length > 0">
      <ot-form-hint (remove)="errors = null">{{config.hint}}</ot-form-hint>
    </div>
    <p *ngIf="commissionGroup.get('type').value === 'P'" class="form-text">
      <ng-container *ngIf="config.meta.company_gross_commission > 0">Agent Commission: {{(config.meta.company_gross_commission * commissionGroup.get('value').value / 100 ) |
        otCurrency:'USD':true:'1.0-2'}}
      </ng-container>
      <ng-container *ngIf="!config.meta.company_gross_commission || config.meta.company_gross_commission === 0">Actual amount will be calculated once Company Gross Commission is available</ng-container>
    </p>
    <ot-form-error key="lessThan">{{generateMessage(config?.meta?.max_currency_value ?
      (this.config.meta.max_currency_value|number) :
      maxValue)}}
    </ot-form-error>
    <ot-form-error key="custom" (remove)="errors = null" *ngIf="errors && errors.length > 0">{{errors[0]}}
    </ot-form-error>
  </ot-form-container>
</div>
