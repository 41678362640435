<div ngbDropdown class="d-inline-block" placement="bottom-right">
  <button ngbDropdownToggle class="button" [inlineSVG]="'/assets/svg/reload.svg'"></button>
  <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownBasic1">
    <ng-container *ngFor="let extension of extensions">
      <button class="dropdown-item">
        <span class="export-icon" [inlineSVG]="'/assets/svg/'+extension+'.svg'"></span>
        {{extension.toUpperCase()}} - Current View ({{currentCount}})
      </button>
      <button class="dropdown-item">
        <span class="export-icon" [inlineSVG]="'/assets/svg/'+extension+'.svg'"></span>
        {{extension.toUpperCase()}} - All ({{allCount}})
      </button>
      <button class="dropdown-item" *ngIf="selectedCount > 0">
        <span class="export-icon" [inlineSVG]="'/assets/svg/'+extension+'.svg'"></span>
        {{extension.toUpperCase()}} - Selected ({{selectedCount}})
      </button>
    </ng-container>
  </div>
</div>
