import {
  Directive,
  forwardRef,
  Host,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import { takeWhile } from 'rxjs/operators';
import { OtRadioRegistryService } from './ot-radio-registry.service';
import { RadioComponent } from './radio.component';

const OT_RADIO_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioControlDirective), // eslint-disable-line
  multi: true,
};

@Directive({
  selector: 'ot-radio[formControlName],ot-radio[formControl],ot-radio[ngModel]', // eslint-disable-line  @angular-eslint/directive-selector
  providers: [OT_RADIO_VALUE_ACCESSOR],
})
export class RadioControlDirective
  implements ControlValueAccessor, OnDestroy, OnInit
{
  @Input() name: string;
  @Input() formControlName: string;
  @Input() value: any;
  public _control: NgControl;
  private alive = true;
  private onChange: (val: any) => any;
  private onTouched: () => never;
  private disabled: boolean;

  constructor(
    @Host() private radio: RadioComponent,
    private _registry: OtRadioRegistryService,
    private _injector: Injector
  ) {}

  public writeValue(obj: any): void {
    this.radio.checked = obj === this.value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = () => {
      fn(this.value);
      this._registry.select(this);
    };
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public ngOnDestroy(): void {
    this.alive = false;
    this._registry.remove(this);
  }

  public fireUncheck(value: any): void {
    this.writeValue(value);
  }

  public ngOnInit(): void {
    this._control = this._injector.get(NgControl);
    this._checkName();
    this._registry.add(this._control, this);

    this.radio.select.subscribe((res) => {
      this.writeValue(res);
      this.onChange(res);
      this.onTouched();
    });
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this.radio) {
      this.radio.disabled = isDisabled;
    }
  }

  private _checkName(): void {
    if (
      this.name &&
      this.formControlName &&
      this.name !== this.formControlName
    ) {
      this._throwNameError();
    }
    if (!this.name && this.formControlName) {
      this.name = this.formControlName;
    }
  }

  private _throwNameError(): void {
    throw new Error(`
      If you define both a name and a formControlName attribute on your radio button, their values
      must match. Ex: <ot-radio formControlName="food" name="food"></ot-radio>
    `);
  }
}
