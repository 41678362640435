import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthCodeGuard } from '@tenant/core';
import { ApplicationsPaymentComponent } from './applications-payment/applications-payment.component';
import { DashboardComponent } from './dashboard/dashboard.component';

const ROUTES: Routes = [
  // {
  //   path: 'dashboard',
  //   canActivate: [AuthCodeGuard],
  //   component: DashboardComponent,
  //   data: {
  //     title: 'dashboard',
  //   },
  // },
  // {
  //   path: 'contacts',
  //   canActivate: [AuthCodeGuard],
  //   loadChildren: () =>
  //     import('./contacts/contacts.module').then((m) => m.ContactsModule),
  // },
  {
    path: 'work-queue',
    canActivate: [AuthCodeGuard],
    loadChildren: () =>
      import('./work-queue/work-queue.module').then((m) => m.WorkQueueModule),
  },
  {
    path: 'applications-payment',
    component: ApplicationsPaymentComponent,
  },
  {
    path: 'deal',
    canActivate: [AuthCodeGuard],
    loadChildren: () => import('./deal/deal.module').then((m) => m.DealModule),
  },
  {
    path: 'tasks',
    data: {
      title: 'My Tasks',
    },
    canActivate: [AuthCodeGuard],
    loadChildren: () =>
      import('./my-tasks/my-tasks.module').then((m) => m.MyTasksModule),
  },
  {
    path: 'reports',
    canActivate: [AuthCodeGuard],
    loadChildren: () =>
      import('./reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'landlords',
    canActivate: [AuthCodeGuard],
    loadChildren: () =>
      import('./landlords/landlords.module').then((m) => m.LandlordsModule),
  },
  { path: '', pathMatch: 'full', redirectTo: 'deal' },
  { path: '**', redirectTo: 'deal' },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled', })],
  exports: [RouterModule],
})
export class AppRouterModule {}
