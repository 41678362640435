import { Component, OnDestroy, OnInit, QueryList } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Mask, OtValidators, parseSentenceForNumber } from '@tenant/helpers';
import { Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  skipWhile,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import { DynamicFieldDirective } from '../dynamic-field.directive';
import { CommissionCurrencyField } from '../models/commission-currency-field';
import { Field } from '../models/field.interface';

@Component({
  selector: 'ot-dynamic-comission-currency',
  templateUrl: './dynamic-commission-currency.component.html',
  styleUrls: ['./dynamic-commission-currency.component.scss'],
  providers: [
    {
      provide: Field,
      useExisting: DynamicCommissionCurrencyComponent,
    },
  ],
})
export class DynamicCommissionCurrencyComponent
  implements Field, OnInit, OnDestroy
{
  get types(): { value: string; label: string }[] {
    if (!this.config.meta || !this.config.meta.allowed_types) {
      return this._types;
    }
    return this._types.filter(
      (v) => this.config.meta.allowed_types.indexOf(v.value) > -1
    );
  }

  public generateMessage(number) {
    let metaMessage =
      this.config && this.config.meta && this.config.meta.errorMessage;
    if (!metaMessage) {
      metaMessage = 'This field must be less than NUMBER_HERE';
    }
    return metaMessage.replace('NUMBER_HERE', number.toString());
  }

  public get maxValue() {
    if (this.commissionGroup.get('type').value === 'P') {
      return this.config.field === 'total_deal_commission' ||
        this.config.field === 'potential_commission'
        ? 1000
        : 100;
    }
    if (this.config.meta && this.config.meta.max) {
      return (
        parseSentenceForNumber(
          this.rootGroup.get(this.config.meta.max).value
        ) || 0
      );
    }
    if (this.config.meta && this.config.meta.max_currency_value) {
      return this.config.meta.max_currency_value;
    }
    return '';
  }

  commissionGroup: FormGroup;
  public config: CommissionCurrencyField;
  public group: FormGroup;
  public rootGroup: FormGroup;
  public errors: any;
  private _types = [
    { value: 'M', label: '$' },
    { value: 'P', label: '%' },
  ];
  public currencyMask: any = Mask.NUMBER;
  public inputs: QueryList<DynamicFieldDirective>;
  public readonly: any;
  private lastPrice: any = 0;
  private lastValidators: string;
  protected destroy = new Subject();

  public ngOnInit(): void {
    this.commissionGroup = this.group.get(this.config.field) as FormGroup;

    this.commissionGroup.valueChanges
      .pipe(
        filter((v: any) => {
          const tmp = parseSentenceForNumber(v.value);
          return !(!v.value || tmp === v.value || !(tmp > 0) || v.value === '');
        }),
        debounceTime(1000),
        takeUntil(this.destroy)
      )
      .subscribe((v) => {
        if (v.value.toString().endsWith('.')) {
          return;
        }
        const tmp = parseSentenceForNumber(v.value);
        // eslint-disable-line , , , ,
        v.value = tmp;
        // this.commissionGroup.patchValue(v, { emitEvent: false });
      });
    this.changeValidation();
    // TODO: refactor this
    this.commissionGroup
      .get('type')
      .valueChanges.pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.changeValidation();
      });
    if (this.config.meta && this.config.meta.max) {
      const maxField = this.rootGroup.get(this.config.meta.max);
      maxField.valueChanges
        .pipe(startWith(maxField.value), takeUntil(this.destroy))
        .subscribe((currency) => {
          if (currency instanceof Object) {
            currency = currency.value;
          }
          currency = parseSentenceForNumber(currency);
          this.lastPrice = currency;
          this.changeValidation();
        });
    }
  }

  private changeValidation() {
    let limit = this.lastPrice;
    if (
      this.config.meta &&
      this.config.meta.max_currency_value &&
      !this.config.meta.max
    ) {
      limit = this.config.meta.max_currency_value;
    }

    if (this.commissionGroup.get('type').value === 'P') {
      limit = 1000;
    }

    const comm = this.commissionGroup.get('value');
    const validators = this.config.getValidators();
    let tmp = '';
    if (limit > 0) {
      validators.push(OtValidators.lessThan(limit));
      tmp = tmp + 'limit' + limit;
    }
    if (tmp === this.lastValidators) {
      return;
    }
    comm.setValidators(validators);
    comm.updateValueAndValidity();
    this.lastValidators = tmp;
  }

  public ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }
}
