import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  OverlayNotificationModel,
  OverlayNotificationsService,
} from '@tenant/ot-global';
import { throwError as _throw } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TaskModel } from '../taskModel';
import { TasksService } from '../tasks.service';
import { showOnDirtyErrorStateMatcher } from '../../forms/src/lib/error-options';

@Component({
  selector: 'ot-task-exempt-modal',
  templateUrl: './task-exempt-modal.component.html',
  styleUrls: ['./task-exempt-modal.component.scss'],
})
export class TaskExemptModalComponent {
  @Input() public task: TaskModel;
  public exemptFg: FormGroup;
  public errors: any;
  public errorStateMatcher = showOnDirtyErrorStateMatcher;

  constructor(
    public active: NgbActiveModal,
    public tasksService: TasksService,
    private overlay: OverlayNotificationsService
  ) {
    this.exemptFg = new FormGroup({
      exempt_reason: new FormControl(null, [Validators.required]),
    });
  }

  public checkSubmit(event: KeyboardEvent, form: NgForm) {
    const code = event.keyCode || event.which;
    if (code === 13 && !event.shiftKey && event.ctrlKey && !event.altKey) {
      this.exempt(event);
    }
  }

  public exempt($event) {
    $event.preventDefault();
    this.tasksService
      .taskEvent(this.task.id, 'exempt', this.exemptFg.value)
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            if (+err.status === 403) {
              const errorMessage = `Task can't be exempted`;
              this.overlay.add(
                new OverlayNotificationModel({
                  text: errorMessage,
                  type: 'error',
                  timeout: 3000,
                })
              );
              this.active.dismiss('access not allowed');
            }
          }
          return _throw(err);
        })
      )
      .subscribe(
        (res) => {
          this.active.close();
        },
        (errors) => {
          this.errors = errors.error;
        }
      );
  }
}
