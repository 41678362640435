import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit,
  QueryList
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { Mask, OtValidators } from '@tenant/helpers';
import { DynamicFieldDirective } from '../dynamic-field.directive';
import { Field } from '../models/field.interface';
import { TextField } from '../models/text-field';

@Component({
  selector: 'ot-dynamic-input',
  templateUrl: './dynamic-input.component.html',
  styleUrls: ['./dynamic-input.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicInputComponent implements Field, OnInit {
  private _group: FormGroup;

  public get group(): FormGroup {
    return this._group;
  }

  public set group(value: FormGroup) {
    this._group = value;
    this.cd.detectChanges();
  }

  private _rootGroup: FormGroup;

  public get rootGroup(): FormGroup {
    return this._rootGroup;
  }

  public set rootGroup(value: FormGroup) {
    this._rootGroup = value;
    this.cd.detectChanges();
  }

  private _errors: any;

  public get errors(): any {
    return this._errors;
  }

  public set errors(value: any) {
    this._errors = value;
    this.cd.detectChanges();
  }

  private _config: TextField;

  public get config(): TextField {
    return this._config;
  }

  public set config(value: TextField) {
    this._config = value;
    this.cd.detectChanges();
  }

  public get inputPattern() {
    if (this._config.meta && this._config.meta.numberOnly) {
      return /^\d*$/;
    }
    return null;
  }

  public inputs: QueryList<DynamicFieldDirective>;
  public readonly: any;
  public numberMask = Mask.NUMBER_WITH_SEPARATOR;
  public placeholder = 'Text';

  constructor(private cd: ChangeDetectorRef) {}

  public ngOnInit() {  }
}
