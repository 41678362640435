import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PendingApprovalModalService } from './pending-approval-modal.service';

@Component({
  selector: 'ot-pending-approval-modal',
  templateUrl: 'pending-approval-modal.component.html',
  styleUrls: ['pending-approval-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PendingApprovalModalComponent {
  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private pendingApprovalModalService: PendingApprovalModalService
  ) {}

  public goToSettings(option) {
    this.activeModal.close();
    this.router.navigate(['/settings/', option]);
  }

  public disableModal(event): void {
    if (event.target.checked) {
      this.pendingApprovalModalService.setDisabled();
    } else {
      this.pendingApprovalModalService.setEnabled();
    }
  }
}
