import { Component, OnInit, QueryList } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Mask, OtValidators } from '@tenant/helpers';
import { merge } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { DynamicFieldDirective } from '../dynamic-field.directive';
import { Field } from '../models/field.interface';
import { TextField } from '../models/text-field';

@Component({
  selector: 'ot-dynamic-currency',
  templateUrl: './dynamic-currency.component.html',
  styleUrls: ['./dynamic-currency.component.scss'],
})
export class DynamicCurrencyComponent implements Field, OnInit {
  private get commissionField() {
    const protentialCommission = this.rootGroup.get(
      'deal_details.protential_commission'
    );
    const totalDealCommission = this.rootGroup.get(
      'deal_details.total_deal_commission'
    );
    if (protentialCommission && protentialCommission.enabled) {
      this.commissionName = 'Potential Commission';
      return protentialCommission;
    }
    if (totalDealCommission && totalDealCommission.enabled) {
      this.commissionName = 'Total Deal Commission';
      return totalDealCommission;
    }
  }

  private get priceField() {
    const salePrice = this.rootGroup.get('deal_details.sale_price');
    const rentalPrice = this.rootGroup.get('deal_details.rental_price');
    if (salePrice && salePrice.enabled) {
      return salePrice;
    }
    if (rentalPrice && rentalPrice.enabled) {
      return rentalPrice;
    }
  }

  public config: TextField;
  public group: FormGroup;
  public rootGroup: FormGroup;
  public errors: any;
  public currencyMask = Mask.AMOUNT;
  public maxValue: number;
  public commissionName = 'Total Deal Commission';
  public inputs: QueryList<DynamicFieldDirective>;
  public readonly: any;

  public generateMessage(number) {
    let metaMessage =
      this.config && this.config.meta && this.config.meta.errorMessage;
    if (!metaMessage) {
      metaMessage = 'This field must be less than NUMBER_HERE';
    }
    return metaMessage.replace('NUMBER_HERE', number.toString());
  }

  public ngOnInit(): void {
    if (this.config.field === 'coop_mls_agency_payment_due') {
      const fieldToValidator = [
        'deal_details.sale_price',
        'deal_details.rental_price',
        'deal_details.protential_commission',
        'deal_details.total_deal_commission',
      ]
        .map((v) => {
          const field = this.rootGroup.get(v);
          return field ? field.valueChanges.pipe(startWith(field.value)) : null;
        })
        .filter((value) => value);
      merge(...fieldToValidator).subscribe(() => {
        this.changeValidators();
      });
      this.changeValidators();
    }
  }

  public changeValidators() {
    const payment = this.group.get(this.config.field);
    if (this.config.meta && this.config.meta.max_number) {
      payment.setValidators([
        Validators.required,
        OtValidators.lessThan(this.config.meta.max_number),
      ]);
      payment.updateValueAndValidity({ emitEvent: false });
      return;
    }

    const commission = this.commissionField;
    const price = this.priceField;
    if (!price || !price.value) {
      return;
    }
    if (!commission || !commission.value) {
      return;
    }

    let commissionValue = 0;

    if (commission.value.type === '%') {
      commissionValue =
        (commission.value.value * this.parseSentenceForNumber(price.value)) /
        100;
    } else {
      commissionValue = commission.value.value;
    }

    this.maxValue = commissionValue;

    payment.setValidators([
      Validators.required,
      OtValidators.lessThan(commissionValue),
    ]);
    payment.updateValueAndValidity({ emitEvent: false });
  }

  private parseSentenceForNumber(sentence): number {
    if (typeof sentence === 'number') {
      return sentence;
    }
    if (!sentence) {
      return 0;
    }
    if (typeof sentence !== 'string') {
      return 0;
    }
    const matches = sentence
      .replace(/,/g, '')
      .match(/([+-])?((\d+(\.\d+)?)|(\.\d+))/);
    return parseInt(matches && matches[0], 10) || 0;
  }
}
