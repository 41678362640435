export class Dot {
  static serialize(obj, parent = []) {
    let result = {};
    if (typeof obj !== 'object') {
      result[parent.join('.')] = obj;
      return result;
    }
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const val = obj[key];
        if (typeof val === 'object' && !Array.isArray(val)) {
          const parentKeys = [...parent, key];
          result = { ...result, ...Dot.serialize(val, parentKeys) };
        } else if (Array.isArray(val)) {
          val.forEach((value, index) => {
            const parentKeys = [...parent, key, index];
            result = { ...result, ...Dot.serialize(value, parentKeys) };
          });
        } else {
          const parentKeys = [...parent, key];
          result[parentKeys.join('.')] = val;
        }
      }
    }
    return result;
  }

  static deserialize(obj) {
    const result = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const path = key.split('.');
        let prev = result;
        while (path.length > 0) {
          const curr = path.shift();
          if (isNaN(parseInt(path[0], 10))) {
            if (!prev[curr]) {
              prev[curr] = {};
            }
            if (path.length === 0) {
              prev[curr] = obj[key];
            }
          } else {
            if (!prev[curr]) {
              prev[curr] = [];
            }
            if (path.length === 0) {
              prev[curr] = obj[key];
            }
          }
          prev = prev[curr];
        }
      }
    }
    return result;
  }
}
