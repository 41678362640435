export class ApplicationFieldModel {
  public name: string;
  public fieldType: string;
  public fieldTypeName: string;
  public hint: string;
  public order: string;

  constructor(json: any) {
    this.name = json.name;
    this.fieldType = json.field_type;
    this.fieldTypeName = json.field_type_name;
    this.hint = json.hint;
    this.order = json.order;
  }
}
