export class DefaultTableDataCollection<T> {
  public data: T[] = [];
  public meta: any;

  protected modelClass: any;

  constructor(modelClass?: any) {
    this.modelClass = modelClass;
  }

  public processData(json: any[], meta?: any) {
    if (
      meta.order_field &&
      ['last_sale', 'last_rental', 'last_total'].indexOf(meta.order_field) > -1
    ) {
      const direction = meta.order_dir === 'asc' ? 1 : -1;
      json = json.sort((a, b) => {
        return a[meta.order_field] > b[meta.order_field]
          ? direction
          : -direction;
      });
    }
    this.data = this.mapToModel(json);
    this.meta = meta;
  }

  public append(json: any[], meta?: any) {
    this.data.push(...this.mapToModel(json));
    this.meta = meta;
  }

  protected mapToModel(json: any[]): T[] {
    if (!this.modelClass) {
      return json;
    }
    return json.map((row) => new this.modelClass(row));
  }
}
