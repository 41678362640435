<div class="summary-header">
  <div class="name">
    Gross Volume Summary - This Month
  </div>
  <button type="button" class="btn btn-primary" [routerLink]="['/deal/create']">New Deal</button>
</div>

<div class="summary-content">
  <div class="summary-circle active-circle">
    <div class="summary-text">
      <span class="num">{{data?.active_deals?.count}}</span>
      <span class="type">active</span>
      <span class="amount">{{data?.active_deals?.amount|currency:'USD':'symbol':'1.0-2'}}</span>
    </div>
  </div>
  <div class="summary-circle pending">
    <div class="summary-text">
      <span class="num">{{data?.pending_deals?.count}}</span>
      <span class="type">pending</span>
      <span class="amount">{{data?.pending_deals?.amount|currency:'USD':'symbol':'1.0-2'}}</span>
    </div>
  </div>
  <div class="summary-circle closed">
    <div class="summary-text">
      <span class="num">{{data?.closed_deals?.count}}</span>
      <span class="type">closed</span>
      <span class="amount">{{data?.closed_deals?.amount|currency:'USD':'symbol':'1.0-2'}}</span>
    </div>
  </div>
</div>