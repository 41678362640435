<div [formGroup]="group">
  <ot-form-container>
    <ot-label>
      {{config.label}}
    </ot-label>
    <ot-select-box otInput [formControlName]="config.field" [options]="options" [typeahead]="true"
                   [required]="config?.required" [readonly]="readonly" [placeholder]="config?.meta?.placeholder || 'Please Select'"
                   (filter)="requestData($event)"></ot-select-box>
    <ot-form-error key="custom" (remove)="errors = null" *ngIf="errors && errors.length > 0">{{errors[0]}}
    </ot-form-error>
    <div *ngIf="config.hint && config.hint.length > 0">
      <ot-form-hint (remove)="errors = null">{{config.hint}}</ot-form-hint>
    </div>
  </ot-form-container>
</div>
