// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  CI_COMMIT_SHA: process.env.CI_COMMIT_SHA,
  FULTONGRACE_URL: 'https://dev.fultongrace.com',
  STRIPE_PUBLISHABLE_KEY: 'pk_test_hMEJ9gyfHFlT31qkYtTqQm7B',
  DOMAIN: 'dev.fultongrace.com',
  ENV_NAME: 'dev',
  // for local
  // DOMAIN: 'agency.ot.local',
  // ENV_NAME: 'local',
};
