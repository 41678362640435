import {
  CDK_ROW_TEMPLATE,
  CdkHeaderRow,
  CdkHeaderRowDef,
  CdkRow,
  CdkRowDef
} from '@angular/cdk/table';
// eslint-disable  , , @angular-eslint/directive-class-suffix, @angular-eslint/component-class-suffix
// eslint-disable  max-classes-per-file, @angular-eslint/no-input-rename
import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  ViewEncapsulation
} from '@angular/core';

/** Workaround for https://github.com/angular/angular/issues/17849 */
export const _OtHeaderRowDef = CdkHeaderRowDef;
export const _OtCdkRowDef = CdkRowDef;
export const _OtHeaderRow = CdkHeaderRow;
export const _OtRow = CdkRow;

@Directive({
  selector: '[otHeaderRowDef]',
  providers: [{ provide: CdkHeaderRowDef, useExisting: OtHeaderRowDef }],
  inputs: ['columns: otHeaderRowDef']
})
export class OtHeaderRowDef extends _OtHeaderRowDef {}

@Directive({
  selector: '[otRowDef]',
  providers: [{ provide: CdkRowDef, useExisting: OtRowDef }],
  inputs: ['columns: otRowDefColumns', 'when: otRowDefWhen']
})
export class OtRowDef<T> extends _OtCdkRowDef<T> {}

@Component({
  selector: 'ot-header-row',
  template: CDK_ROW_TEMPLATE,
  host: {
    class: 'ot-header-row',
    role: 'row'
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  exportAs: 'otHeaderRow'
  // preserveWhitespaces: false,
})
export class OtHeaderRow extends _OtHeaderRow {}

@Component({
  selector: 'ot-row',
  template: CDK_ROW_TEMPLATE,
  host: {
    class: 'ot-row',
    role: 'row'
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  exportAs: 'otRow'
  // preserveWhitespaces: false,
})
export class OtRow extends _OtRow {}
