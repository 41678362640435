import { Component, Input } from '@angular/core';
import { fadeIn } from '../../helpers/animations';

@Component({
  selector: 'ot-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
  animations: [fadeIn]
})
export class ImagePreviewComponent {
  @Input() public images = [];

  public currentIndex = 0;

  public openImages(images, index = 0) {
    this.images = images;
    this.currentIndex = index;
  }

  public showNext() {
    if (this.currentIndex + 1 >= this.images.length) {
      this.currentIndex = 0;
    } else {
      this.currentIndex++;
    }
  }

  public showPrev() {
    if (this.currentIndex <= 0) {
      this.currentIndex = this.images.length - 1;
    } else {
      this.currentIndex--;
    }
  }

  public close() {
    this.images = [];
  }
}
