import { HttpClient, HttpParams } from '@angular/common/http';
import { combineLatest, interval, Observable, Subscription } from 'rxjs';
import { debounce, debounceTime, map } from 'rxjs/operators';
import { DefaultTableDataCollection } from './default.table-data-collection';
import { LocalDataSource } from './local.data-source';
import { ServerSourceConf } from './server-source.conf';

export { ServerSourceConf };

export class ServerDataSource extends LocalDataSource {
  private sub$ = new Subscription();
  public collection: DefaultTableDataCollection<any>;

  protected conf: ServerSourceConf;

  protected lastRequestCount = 0;

  constructor(protected http: HttpClient, conf: ServerSourceConf | any = {}) {
    super();

    this.conf = new ServerSourceConf(conf);

    this.collection = this.conf.dataCollection;

    if (!this.conf.endPoint) {
      throw new Error(
        'At least endPoint must be specified as a configuration of the server data source.'
      );
    }
  }

  public count(): number {
    return this.lastRequestCount;
  }

  public refreshElements(): Promise<any> {
    const page = this.getCurrentPage();
    const obs = [];
    for (let i = 1; i <= page; i++) {
      obs.push(this.requestElements(i));
    }
    return combineLatest(obs)
      .pipe(
        map((responses: any[]) => {
          // this.collection.processData([])
          responses.forEach((res, k) => {
            this.onLoadingData.next(false);
            this.lastRequestCount = this.extractTotalFromResponse(res);
            this.data = this.extractDataFromResponse(res);

            if (this.collection.data.length === 0 || k === 0) {
              this.collection.processData(
                this.data,
                this.extractMetaFromResponse(res)
              );
            } else {
              this.collection.append(
                this.data,
                this.extractMetaFromResponse(res)
              );
            }
          });
          return this.collection;
        })
      )
      .toPromise();
  }

  public getElements(): Promise<any> {
    return this.requestElements()
      .pipe(
        map((res) => {
          this.onLoadingData.next(false);
          this.lastRequestCount = this.extractTotalFromResponse(res);
          this.data = this.extractDataFromResponse(res);

          if (
            this.collection.data.length === 0 ||
            this.getCurrentPage() === 1
          ) {
            this.collection.processData(
              this.data,
              this.extractMetaFromResponse(res)
            );
          } else {
            this.collection.append(
              this.data,
              this.extractMetaFromResponse(res)
            );
          }

          return this.collection;
        })
      )
      .toPromise();
  }

  public requestElements(page = null): Observable<any> {
    this.onLoadingData.next(true);
    return this.http.get(this.conf.endPoint, {
      ...this.createRequestOptions(page),
      observe: 'response',
    });
  }

  protected extractMetaFromResponse(res): any {
    const rawData = res.body;
    if (rawData.meta) {
      return rawData.meta;
    }

    if (rawData.count) {
      return {
        total: rawData.count,
        per_page: 15,
        page: this.getNextPage(rawData.next),
      };
    }

    if (rawData.period || rawData.total) {
      return { period: rawData?.period, total: rawData?.total };
    }
    
    return {};
  }

  /**
   * Extracts array of data from server response
   * @param res
   * @returns {any}
   */

  protected extractDataFromResponse(res): any[] {
    const rawData = res.body;
    const data = rawData.results || rawData;

    if (data instanceof Array) {
      return data;
    }

    if (data?.items instanceof Array) {
      return data.items;
    }

    throw new Error(`Data must be an array. Please check that data extracted from the server response by the key
    '${this.conf.dataKey}' exists and is array.`);
  }

  /**
   * Extracts total rows count from the server response
   * Looks for the count in the heders first, then in the response body
   * @param res
   * @returns {any}
   */
  protected extractTotalFromResponse(res): number {
    if (res.headers.has(this.conf.totalKey)) {
      return +res.headers.get(this.conf.totalKey);
    } else {
      const rawData = res;
      return rawData.count ? rawData.count : 0;
    }
  }

  protected createRequestOptions(page = null): any {
    let requestOptions: any = {};
    requestOptions.params = new HttpParams();

    requestOptions = this.addSortRequestOptions(requestOptions);
    requestOptions = this.addFilterRequestOptions(requestOptions);
    return this.addPagerRequestOptions(requestOptions, page);
  }

  protected addSortRequestOptions(requestOptions) {
    let searchParams: HttpParams = requestOptions.params as HttpParams;

    if (this.sortConf) {
      this.sortConf.forEach((fieldConf) => {
        if (fieldConf.direction && fieldConf.field) {
          searchParams = searchParams.set(
            'ordering',
            fieldConf.direction?.toLowerCase() === 'desc'
              ? `-${fieldConf.field}`
              : `${fieldConf.field}`
          );
        }
      });
    }

    requestOptions.params = searchParams;

    return requestOptions;
  }

  protected addFilterRequestOptions(requestOptions) {
    let searchParams: HttpParams = requestOptions.params as HttpParams;
    if (this.filterConf.filters) {
      this.filterConf.filters.forEach((fieldConf) => {
        if (fieldConf['key'] && fieldConf['value'] != null) {
          searchParams = searchParams.set(fieldConf['key'], fieldConf['value']);
        }
      });
    }
    requestOptions.params = searchParams;

    return requestOptions;
  }

  protected addPagerRequestOptions(requestOptions, page = null) {
    let searchParams: HttpParams = requestOptions.params as HttpParams;

    if (
      this.pagingConf &&
      this.pagingConf['page'] &&
      this.pagingConf['perPage']
    ) {
      searchParams = searchParams.set(
        this.conf.pagerPageKey,
        '' + (page || this.pagingConf['page'])
      );
      searchParams = searchParams.set(
        this.conf.pagerLimitKey,
        '' + this.pagingConf['perPage']
      );
      searchParams = searchParams.set('limit', `${this.pagingConf['perPage']}`);
      searchParams = searchParams.set(
        'offset',
        `${+((page || this.pagingConf['page']) - 1) * 15}`
      );
    }
    requestOptions.params = searchParams;
    return requestOptions;
  }

  getNextPage(next: string): number {
    const urlParams = new URLSearchParams(next);
    const myParam = urlParams.get('page');
    return parseInt(myParam, 10);
  }
}
