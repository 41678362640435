<div class="form-group">
  <label [for]="identifier" [class.readonly]="readonly">
    <input type="checkbox" [checked]="_value" [disabled]="disabled" (click)="switchCheckbox($event)"
           [id]="identifier" (click)="stop($event)">
    <span
      class="svg-wrapper" [class.readonly]="readonly"
      [class.active]="!!_value"
      [class.disabled]="disabled"
      [inlineSVG]="'/assets/svg/vector.svg'"></span>
    <ng-content></ng-content>
  </label>
</div>
