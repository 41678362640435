import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Route,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';

@Injectable()
export class AgencyUserGuard implements CanActivate {
  constructor(private session: SessionService, private router: Router) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.session.subdomain !== 'admin') {
      setTimeout(() => {
        document.getElementById('site-preloader').style.display = 'none';
      }, 100);
      return true;
    }
    this.router.navigate(['/']);
    return false;
  }

  public canLoad(
    route: Route
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.session.subdomain !== 'admin';
  }
}
