import {
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ot-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectComponent implements OnInit, OnChanges {
  public get actualErrors() {
    if (this.serverErrors) {
      if (Array.isArray(this.serverErrors)) {
        return this.serverErrors[0];
      } else {
        return this.serverErrors;
      }
    }
    for (const error in this.control.errors) {
      if (
        this.control.errors.hasOwnProperty(error) &&
        this.errorMapping[error]
      ) {
        return this.errorMapping[error];
      }
    }
    return '';
  }

  public get isInvalid() {
    if (this.noValidate) {
      return false;
    }
    return (
      (this.control.invalid &&
        (this.control.touched || this.submitted === true)) ||
      this.serverErrors
    );
  }

  public get isSuccess() {
    if (this.noValidate) {
      return false;
    }
    return this.control.valid && !this.serverErrors;
  }

  @Input() public options = [];
  @Input() public clear = false;
  @Input() public forceEmpty = false;
  @Input() public disabled = false;
  @Input() public control: FormControl;
  @Input() public submitted = false;
  @Input() public label = null;
  @Input() public placeholder = '';
  @Input() public hideLabel = false;
  @Input() public required = false;
  @Input() public name = null;
  @Input() public serverErrors = null;
  @Input() public className = null;
  @Input() public noValidate = false;
  @Input() public optional = false;
  @Input()
  public errorMapping = {
    required: 'This field is required',
    email: 'Email is not valid',
    pattern: 'This field is not valid',
    url: 'URL is not valid',
    minlength: 'This field is too short',
    noPhoneNumber: 'Phone number is not valid'
  };
  public requiredMark: any = true;
  private _valueChangesSubscription;

  public ngOnInit() {
    this.cleanErrors();
    if (!this.control) {
      this.control = new FormControl();
    }
    this.requiredMark = this.control.hasError('required');
  }

  public ngOnChanges(changes) {
    if (changes.control) {
      this.cleanErrors();
    }
  }

  private cleanErrors() {
    if (this._valueChangesSubscription) {
      this._valueChangesSubscription.unsubscribe();
    }
    this._valueChangesSubscription = this.control.valueChanges.subscribe(() => {
      this.serverErrors = null;
    });
  }
}
