import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dealAgentType',
})
export class DealAgentType implements PipeTransform {
  public transform(value: any): string {
    switch (value) {
      case 'A':
        return 'Additional';
      case 'P':
        return 'Primary';
      default:
        return value;
    }
  }
}
