import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  HostBinding,
  Input,
  OnDestroy,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Sort, SortDirective } from '../../sort/sort.directive';
import { combineLatest, noop, of } from 'rxjs';
import { startWith, takeWhile } from 'rxjs/operators';
import { Dot } from '@tenant/helpers';
import { OtDataSource } from '../datasource';
import { TableMeta } from '../ds-meta';
import { FilterContainerComponent } from '../filters/filter-container/filter-container.component';
import { OtDataTable } from '../table';

@Component({
  selector: 'ot-table-wrapper',
  templateUrl: './table-wrapper.component.html',
  styleUrls: ['./table-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TableWrapperComponent
  implements AfterViewInit, OnDestroy, AfterContentInit, AfterContentChecked
{
  public get meta() {
    if (!this.ds || !this.ds.metaSync) {
      return null;
    }
    const meta = this.ds.metaSync;
    meta.visible = meta.page * meta.per_page;
    if (meta.total < meta.visible) {
      meta.visible = meta.total;
    }
    return meta;
  }

  @ContentChild(OtDataTable) public table: OtDataTable<any>;
  @ContentChild(SortDirective) public sort: SortDirective;
  @ContentChild(FilterContainerComponent)
  public filters: FilterContainerComponent;
  @ContentChild('metaTemplate') public metaTemplate: TemplateRef<TableMeta>;
  public ds: OtDataSource<any>;
  @Input() public hideMeta = false;
  @Input() public tableNamespace = null;
  @HostBinding('class.no-content') public noContent = false;
  private destroy = false;
  private prevFilter = null;

  constructor(
    public cd: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public ngOnDestroy(): void {
    this.destroy = true;
  }

  public ngAfterViewInit(): void {}

  public ngAfterContentInit(): void {
    // this.ds = this.table.dataSource as OtDataSource<any>;
    // this.restoreFilters();
    // this.cd.detectChanges();
    // if (this.ds) {
    //   this.ds.state.subscribe(() => {
    //     if (this.ds && this.ds.metaSync) {
    //       this.noContent = this.ds.metaSync.total === 0;
    //     }
    //   });
    //   this.ds.meta.pipe(takeWhile(() => !this.destroy)).subscribe(() => {
    //     this.cd.detectChanges();
    //   });
    // }
    //
    // this.subscribeFilterChange();
  }

  ngAfterContentChecked() {
    if (!this.ds) {
      this.ds = this.table.dataSource as OtDataSource<any>;
      this.restoreFilters();
      this.cd.detectChanges();
      if (this.ds) {
        this.ds.state.subscribe(() => {
          if (this.ds && this.ds.metaSync) {
            this.noContent = this.ds.metaSync.total === 0;
          }
        });
        this.ds.meta.pipe(takeWhile(() => !this.destroy)).subscribe(() => {
          this.cd.detectChanges();
        });
      }

      this.subscribeFilterChange();
    }
  }

  private subscribeFilterChange() {
    if (this.filters || this.sort) {
      let sortChange = of(null);
      let filterChange = of(null);
      if (this.filters) {
        filterChange = this.filters.filterChange.pipe(startWith(null));
      }
      if (this.sort) {
        sortChange = this.sort.sortChange.pipe(startWith(null));
      }
      combineLatest([sortChange, filterChange])
        .pipe(takeWhile(() => !this.destroy))
        .subscribe(([sort, currentFilter]) => {
          const filters = JSON.parse(JSON.stringify(currentFilter));
          if (JSON.stringify(this.prevFilter) !== JSON.stringify(filters)) {
            this.prevFilter = filters;
            this.saveFilters(filters, sort);
            if (filters) {
              this.ds?.setFilters(filters);
            }
          }
        });
    }
  }

  private saveFilters(currentFilter: any, sort: any) {
    if (this.tableNamespace) {
      const filtersObj: any = {
        ...Dot.deserialize(this.route.snapshot.queryParams),
      };
      if (currentFilter) {
        filtersObj[this.tableNamespace] = currentFilter;
      }
      if (sort) {
        filtersObj[this.tableNamespace] = Object.assign(
          {},
          filtersObj[this.tableNamespace],
          { sort }
        );
        if (!sort.direction) {
          filtersObj[this.tableNamespace]['sort'] = null;
        }
      }

      if (
        this.tableNamespace === 'dealList' &&
        filtersObj &&
        filtersObj[this.tableNamespace] &&
        filtersObj[this.tableNamespace].flag === false
      ) {
        filtersObj[this.tableNamespace].flag = null;
      }

      const filters = Dot.serialize(filtersObj);
      this.router
        .navigate([], {
          queryParams: filters,
          replaceUrl: true,
        })
        .then(() => noop());
    }
  }

  private restoreFilters() {
    if (this.tableNamespace) {
      const queryParams = Dot.deserialize(this.route.snapshot.queryParams);
      if (queryParams[this.tableNamespace]) {
        const restoredSort = queryParams[this.tableNamespace].sort as Sort;
        this.ds?.stopLastRequest();
        if (this.filters) {
          if (
            this.tableNamespace === 'dealList' &&
            queryParams[this.tableNamespace] &&
            queryParams[this.tableNamespace].flag === 'false'
          ) {
            queryParams[this.tableNamespace].flag = false;
          }

          if (queryParams[this.tableNamespace].flagged_first === 'false') {
            queryParams[this.tableNamespace].flagged_first = false;
          }
          if (queryParams[this.tableNamespace].flagged_first === 'true') {
            queryParams[this.tableNamespace].flagged_first = true;
          }

          this.filters.restoredFiters = queryParams[this.tableNamespace];
          const filters = JSON.parse(
            JSON.stringify(queryParams[this.tableNamespace])
          );
          delete filters.sort;
          this.ds?.setFilters(filters);
        }
        if (this.sort && restoredSort) {
          setTimeout(() => {
            this.sort.active = restoredSort.active;
            this.sort.direction = restoredSort.direction;
            this.sort.sortChange.next({
              active: restoredSort.active,
              direction: restoredSort.direction,
            });
          });
        }
        this.cd.detectChanges();
      }
    }
  }
}
