import { Pipe, PipeTransform } from '@angular/core';
import { ReportType } from 'libs/tenant-report/src/lib/report.model';

@Pipe({
  name: 'tenantReportType',
})
export class TenantReportType implements PipeTransform {
  public transform(value: ReportType): string {
    if (value === ReportType.CREDIT_REPORT) {
      return 'Credit Report';
    } else if (value === ReportType.CRIMINAL_SEARCH) {
      return 'Instant National Criminal Search';
    } else if (value === ReportType.EVICTION_SEARCH) {
      return 'Instant National Eviction Search';
    } else {
      return value;
    }
  }
}
